import getApi from "../../configs/api";
import { ResponseModel } from "../../models/api/comum/response-base";
import {
  DadosInicializacaoFiltros as DadosConfiguracoesDeEmpresa,
  DadosConfiguracoesEstoque,
  DadosConfiguracoesMdfe,
} from "../../models/api/tokens/configuracoes";

const nomeEndpoint: string = "Configuracoes";

async function obterConfiguracoesMdfe() {
  const api = getApi();
  const response = await api.get<ResponseModel<DadosConfiguracoesMdfe>>(
    `${nomeEndpoint}/ConfiguracoesMdfe`
  );

  return response.data.model;
}

async function obterConfiguracoesDeEmpresa() {
  const api = getApi();
  const response = await api.get<ResponseModel<DadosConfiguracoesDeEmpresa>>(
    `${nomeEndpoint}/ConfiguracoesDeEmpresa`
  );

  return response.data.model;
}

async function obterConfiguracoesDeEstoque() {
  const api = getApi();
  const response = await api.get<ResponseModel<DadosConfiguracoesEstoque>>(
    `${nomeEndpoint}/ConfiguracoesDeEstoque`
  );

  return response.data.model;
}

export default {
  obterConfiguracoesMdfe,
  obterConfiguracoesDeEmpresa,
  obterConfiguracoesDeEstoque,
};
