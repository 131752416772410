import { OrdemDeProducaoRequest } from "../../../../models/api/producao/ordem-de-producao/ordem-de-producao";

export enum OrdemDeProducaoAbasIndices {
  Insumos = 0,
  ReservaEVinculos = 1,
  Aparas = 2,
  RetiradaDeMateriais = 3,
  Conclusoes = 4,
  OutroDados = 5,
  Anexos = 6,
  Anotacoes = 7,
}

export const dicionarioDosIndicesDasAbasPorCampo: {
  [key in keyof OrdemDeProducaoRequest]?: number;
} = {
  quantidadeDePerdaPrevista: OrdemDeProducaoAbasIndices.OutroDados,
  quantidadeBruta: OrdemDeProducaoAbasIndices.OutroDados,
  observacoes: OrdemDeProducaoAbasIndices.OutroDados,
};
