import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import BotaoLadoDireitoInput from "../../../../../components/botoes/botao-lado-direito-input";
import { FormNumberBox } from "../../../../../components/formularios";
import {
  FormBase2,
  FormularioEdicaoBaseProps,
} from "../../../../../components/layout/form-base2";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import { useCarregarRegistro } from "../../../../../hooks/form.hooks";
import AuditavelDTO from "../../../../../models/api/comum/auditavel-dto";
import { LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcfAlterarRequest } from "../../../../../models/api/informativo-composicao-custos-lucro-real/linha-apuracao-informativo-composicao-custos-ecf";
import { CallBackModal } from "../../../../../models/shared/ui/callback-modal";
import LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcfService from "../../../../../services/informativo-composicao-custos-lucro-real/linha-apuracao-informativo-composicao-custos-ecf";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import { obterFormatStringNumero } from "../../../../../utils/formatadores/formatador-de-numeros";

const service =
  new LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcfService();

const novoRegistro: LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcfAlterarRequest =
  {
    id: 0,
    valor: 0,
  };

export default function ModalEdicaoValorLinhaApuracaoInformativoComposicaoCustos(
  props: FormularioEdicaoBaseProps
) {
  const [carregando, setCarregando] = useState(false);
  const [dadosAuditoria, setDadosAuditoria] = useState<AuditavelDTO>();
  const [valorEstaFixo, setValorEstaFixo] = useState(false);

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    valor: yup.number().required(),
  });

  const { control, reset, register, getValues, handleSubmit } =
    useForm<LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcfAlterarRequest>({
      mode: "onChange",
      reValidateMode: "onChange",
      resolver: yupResolver(schema),
    });

  useCarregarRegistro(props.idRegistroEdicao, carregarTela);

  // Usado para limpar o formulário se for NaN
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEdicao)) {
      reset(novoRegistro);
    }
  }, [props.idRegistroEdicao]);

  function fechar(info: CallBackModal) {
    if (props.callBackFecharModal) {
      props.callBackFecharModal(info);
    }
  }

  function handleCancelar() {
    fechar({ concluido: false, precisaAtualizar: false });
  }

  async function carregarTela() {
    try {
      setCarregando(true);
      if (props.idRegistroEdicao > 0) {
        await carregarModel();
      } else {
        reset(novoRegistro);
        setDadosAuditoria(undefined);
      }
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  async function carregarModel() {
    try {
      const resposta = await service.ObterPorIdComDadosAuditoria(
        props.idRegistroEdicao
      );
      checarResponse(resposta);
      reset(resposta.model);
      setDadosAuditoria(resposta.model);
      setValorEstaFixo(resposta.model.valorEstaFixo);
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    }
  }

  function callBackUnprocessableEntity() {
    fechar({
      concluido: false,
      precisaAtualizar: true,
    });
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues();
    try {
      const resposta = await service.Alterar(model);

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar({
          concluido: true,
          precisaAtualizar: true,
        });
      }
      fechar({
        concluido: true,
        precisaAtualizar: false,
      });
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  async function handleValorLivre() {
    try {
      const resposta = await exibirConfirmacao(
        "Confirmação",
        "Deseja tornar livre o valor?"
      );

      if (resposta) {
        setCarregando(true);
        const retorno = await service.TornarValorLivre(props.idRegistroEdicao);
        checarResponse(retorno);

        if (retorno.sucesso) {
          setValorEstaFixo(false);
        }
      }
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  return (
    <FormBase2
      carregando={carregando}
      titulo={
        "valor da linha da apuração do informativo da composição de custos"
      }
      onClickSalvar={handleSubmit(handleSalvar)}
      onClickCancelar={handleCancelar}
      visivel={props.visivel}
      modoEdicao={"editar"}
      auditoria={dadosAuditoria}
      configuracoesModal={props.configuracoesModal}
    >
      <input type="hidden" {...register("id")} defaultValue={0} />
      <Linha>
        <Coluna md={6}>
          <BotaoLadoDireitoInput
            textoTooltip="Tornar livre"
            icone="ic-keep"
            onClick={handleValorLivre}
            visivel={valorEstaFixo}
          >
            <FormNumberBox
              name="valor"
              titulo="Valor"
              control={control}
              requerido
              formato={obterFormatStringNumero(2)}
            />
          </BotaoLadoDireitoInput>
        </Coluna>
      </Linha>
    </FormBase2>
  );
}
