import * as React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { TokenBypassRequest } from "../../../models/api/tokens/token-requests";
import Sessao from "../../../models/dto/sessao/sessao";
import {
  getDadosSessao,
  getTokenSSO,
} from "../../../services/tokens/tokens.service";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../utils/common/notificacoes-utils";
import { GravarSessaoReduxELocalStorage } from "../../../utils/oauth/oauth-utils";

/* 
  Faz login usando parâmetros da URL ao receber uma string criptografada com dados para logar.
  Caso o login seja bem sucedido é redirecionado para a URL que foi passada como parâmetro.
  Essa page é usada em rotas anônimas, para rotas autenticadas é usada a `SingleSignOnAutenticado`.
  */
export default function SingleSignOn() {
  const navigate = useNavigate();
  const account = useParams();
  const initialized = React.useRef(false);
  const [searchParams] = useSearchParams();

  function VoltaProLogin() {
    navigate("/");
  }

  async function efetuarLogin() {
    try {
      // Pega parâmetros da URL
      const segredo = searchParams.get("segredo") || "";
      const redirect = "../" + searchParams.get("redirect");

      // Verifica se estão vazios
      if (account == undefined || segredo == "") {
        VoltaProLogin();
      }

      // Faz login com esses dados
      const loginData: TokenBypassRequest = {
        segredo: segredo,
      };
      const resposta = await getTokenSSO(loginData);

      if (resposta) {
        // Se a resposta for bem sucedida cria os dados de sessão e redireciona
        const dadosSessaoResposta = await getDadosSessao(resposta);
        const sessao: Sessao = {
          dadosSessao: dadosSessaoResposta,
        };
        GravarSessaoReduxELocalStorage(sessao, resposta);
        navigate(redirect);
      } else {
        // Se der erro volta para a tela de login.
        exibirNotificacaoToast({
          mensagem: "Falha no login",
          tipo: TipoNotificacao.Erro,
        });
        VoltaProLogin();
      }
    } catch {
      VoltaProLogin();
    }
  }

  React.useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      efetuarLogin();
    }
  }, [efetuarLogin]);

  return <></>;
}

/* 
  Redireciona para a URL que foi passada como parâmetro.
  Essa page é usada em rotas autenticadas, para rotas anônimas é usada a `SingleSignOn`.
  */
export function SingleSignOnAutenticado() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  React.useEffect(() => {
    const redirect = "../" + searchParams.get("redirect");
    navigate(redirect);
  }, []);

  return <></>;
}
