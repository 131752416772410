import {
  EstadoContaContabil,
  TipoAgrupamentoBalanceteComparativo,
} from "../../../api/contabilidade/balancete-comparativo";
import SelectItem from "../../../shared/ui/select-item";

export interface FiltrosBalanceteComparativoViewModel {
  dataInicial: string | null;
  dataFinal: string | null;
  minhaEmpresaId: number | null;
  incluirLancamentosDeEncerramento: boolean;
  exibirContasZeradas: boolean;
  agrupamento: TipoAgrupamentoBalanceteComparativo;
  estadoDaConta: number[];
  colunasExibir: string[];
}

export enum ColunasOcultaveisBalanceteComparativo {
  SaldoInicial = "saldo_inicial",
  SaldoFinal = "saldo_final",
  Creditos = "creditos",
  Debitos = "debitos",
  Liquido = "liquido",
}

export const OpcoesEstadosContaContabil: SelectItem[] = [
  { valor: EstadoContaContabil.Ativas, descricao: "Ativas" },
  { valor: EstadoContaContabil.Inativas, descricao: "Inativas" },
];

export const OpcoesAgrupamentoBalanceteComparativo: SelectItem[] = [
  { valor: TipoAgrupamentoBalanceteComparativo.Mensal, descricao: "Mensal" },
  {
    valor: TipoAgrupamentoBalanceteComparativo.Trimestral,
    descricao: "Trimestral",
  },
  { valor: TipoAgrupamentoBalanceteComparativo.Anual, descricao: "Anual" },
];

export const OpcoesColunasOcultaveisBalanceteComparativo: SelectItem[] = [
  {
    valor: ColunasOcultaveisBalanceteComparativo.SaldoInicial,
    descricao: "Saldo inicial",
  },
  {
    valor: ColunasOcultaveisBalanceteComparativo.Debitos,
    descricao: "Débitos",
  },
  {
    valor: ColunasOcultaveisBalanceteComparativo.Creditos,
    descricao: "Créditos",
  },
  {
    valor: ColunasOcultaveisBalanceteComparativo.Liquido,
    descricao: "Líquido",
  },
  {
    valor: ColunasOcultaveisBalanceteComparativo.SaldoFinal,
    descricao: "Saldo final",
  },
];
