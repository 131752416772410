export interface TokenResponse {
  usaAutenticacaoDeDoisFatores: boolean;
  simplificado: boolean;
  token: string;
  refreshToken: string;
  refreshTokenExpiryTime: string;
}

export interface AcaoAdicionalLoginDto {
  acao: AcaoAdicionalLogin;
  hash: string;
}

export enum AcaoAdicionalLogin {
  QuestionarDerrubarSessoesAnteriores = 1,
}
