import getApi from "../../configs/api";
import {
  ResponseBase,
  ResponseModel,
} from "../../models/api/comum/response-base";
import { DemonstrativoSaldosParteBLacsAlterarRequest } from "../../models/api/demonstrativo-saldos/demonstrativo-saldos-parte-b-lacs/demonstrativo-saldos-parte-b-lacs";
import criarDatasourceGrid, {
  criarDatasourceGridComParametros,
} from "../../utils/grid/back-end-grid-utils";
import { NomesEndpoints } from "../comum/nomesEndpoints";

export class DemonstrativoSaldosParteBLacsService {
  private _nomeEndpoint: NomesEndpoints;

  constructor() {
    this._nomeEndpoint = NomesEndpoints.DemonstrativoDeSaldosDaParteBDoLacs;
  }

  public async ObterPorIdComDadosAuditoria<T>(id: number) {
    const api = getApi();
    const response = await api.get<ResponseModel<T>>(
      `${this._nomeEndpoint}/ObterPorIdComDadosAuditoria?id=${id}`
    );

    return response.data;
  }

  public async Atualizar(model: DemonstrativoSaldosParteBLacsAlterarRequest) {
    const api = getApi();
    const response = await api.put<ResponseBase>(this._nomeEndpoint, model);

    return response.data;
  }

  public GetGridSource() {
    return criarDatasourceGrid(`${this._nomeEndpoint}/grid`);
  }

  public async TornarSaldoInicialLivre(id: number) {
    const api = getApi();
    const response = await api.patch<ResponseBase>(
      `${this._nomeEndpoint}/TornarSaldoInicialLivre?id=${id}`
    );

    return response.data;
  }

  public GetGridSourceComParametros(parametros: object) {
    return criarDatasourceGridComParametros(
      `${this._nomeEndpoint}/grid`,
      parametros
    );
  }
}
