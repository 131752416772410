import { Column } from "devextreme-react/data-grid";
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { renderToString } from "react-dom/server";
import { MxpGrid } from "../../../../../components/grid";
import {
  useEditarRegistroGrid,
  useExcluirRegistroGrid,
  useNovoRegistroGrid,
  usePropagarReferenciaGrid,
} from "../../../../../hooks/grid.hooks";
import { NumeroDeSeriePrefixoGridModel } from "../../../../../models/api/producao/numero-de-serie-prefixo/numero-de-serie-prefixo";
import { CallBackModal } from "../../../../../models/shared/ui/callback-modal";
import { IGridSelecao } from "../../../../../models/shared/ui/formularios";
import EditFormNumeroDeSeriePrefixo from "../../../../../pages/producao/numero-de-serie-prefixo/edit-form";
import { NomesEndpoints } from "../../../../../services/comum/nomesEndpoints";
import API from "../../../../../services/comum/serviceBase";
import {
  checarResponseExibeMensagemExclusaoDeSucesso,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import { GridBaseProps } from "../../../../../utils/grid/grid-utils";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import GetColunasDeAuditoria from "../../../../layout/grid-defaults/colunasDeAuditoria";

const nomeEndpoint = NomesEndpoints.NumeroDeSeriePrefixo;
const dataSource = API.getGridSource(nomeEndpoint);
const nameOfGridHandler = criarNameof<NumeroDeSeriePrefixoGridModel>();
const colunas = [
  <Column
    key={nameOfGridHandler("prefixo")}
    dataField={nameOfGridHandler("prefixo")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Prefixo"
  />,
  <Column
    key={nameOfGridHandler("sufixo")}
    dataField={nameOfGridHandler("sufixo")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Sufixo"
  />,
  <Column
    key={nameOfGridHandler("proximoSequencial")}
    dataField={nameOfGridHandler("proximoSequencial")}
    {...obterConfiguracaoColuna("codigoNumerico")}
    width={160}
    caption="Próximo sequencial"
  />,
  <Column
    key={nameOfGridHandler("proximoNumeroDeSerie")}
    dataField={nameOfGridHandler("proximoNumeroDeSerie")}
    {...obterConfiguracaoColuna("stringGG")}
    caption="Próximo número"
  />,
  ...GetColunasDeAuditoria(),
];

export const GridNumeroDeSeriePrefixo = forwardRef(
  (props: GridBaseProps, ref) => {
    const gridRef = useRef<IGridSelecao>(null);
    useImperativeHandle(ref, () => gridRef.current as IGridSelecao);
    usePropagarReferenciaGrid(ref, gridRef);

    const [modalEdicaoVisivel, setModalEdicaoVisivel] = useState(false);
    const [idRegistroEdicao, setIdRegistroEdicao] = useState(NaN);

    function handleAtualizarGrid() {
      if (gridRef.current?.atualizarGrid) {
        gridRef.current?.atualizarGrid();
      }
    }

    const handleNovoRegistro = useNovoRegistroGrid(() => {
      setIdRegistroEdicao(0);
      setModalEdicaoVisivel(true);
    });

    const handleEditarRegistro = useEditarRegistroGrid((id: number) => {
      setIdRegistroEdicao(id);
      setModalEdicaoVisivel(true);
    });

    const handleExcluirRegistro = useExcluirRegistroGrid(
      async (registro: NumeroDeSeriePrefixoGridModel) => {
        const mensagem = renderToString(<>{obterMensagemExclusao(registro)}</>);
        const excluir = await exibirConfirmacao("Confirmar exclusão", mensagem);

        if (excluir) {
          try {
            const resposta = await API.excluir(registro.id, nomeEndpoint);

            if (resposta) {
              checarResponseExibeMensagemExclusaoDeSucesso(resposta);
              handleAtualizarGrid();
            }
          } catch (erro) {
            tratarErroApi(erro);
          }
        }
      }
    );

    function obterMensagemExclusao(registro: NumeroDeSeriePrefixoGridModel) {
      return `Tem certeza de que deseja excluir o prefixo de lotes e números de série ${registro.prefixo}?`;
    }

    const handleModalCallback = useCallback((info: CallBackModal) => {
      setModalEdicaoVisivel(false);
      setIdRegistroEdicao(NaN);

      if (info.precisaAtualizar) {
        handleAtualizarGrid();
      }
    }, []);

    return (
      <>
        <MxpGrid<NumeroDeSeriePrefixoGridModel>
          dataSource={dataSource}
          id={"numero-de-serie-prefixo"}
          ref={gridRef}
          colunas={colunas}
          novoRegistro={handleNovoRegistro}
          editarRegistro={handleEditarRegistro}
          excluirRegistro={handleExcluirRegistro}
          nomeDoArquivoAoExportar={NomesTelas.numeroDeSeriePrefixoESufixo}
        />
        <EditFormNumeroDeSeriePrefixo
          visivel={modalEdicaoVisivel}
          idRegistroEdicao={idRegistroEdicao}
          configuracoesModal={{ largura: "max(50vw, 800px)", altura: "auto" }}
          callBackFecharModal={handleModalCallback}
        />
      </>
    );
  }
);
