import { useState } from "react";
import Pagina from "../../../components/layout/pagina";
import RequerPermissao from "../../../components/seguranca/requer-permissao";
import { NumeroDeSeriePrefixoPermissoes } from "../../../models/permissoes/producao/numero-de-serie-prefixo/NumeroDeSeriePrefixoPermissoes";
import { GridNumeroDeSeriePrefixo } from "../../../parts/producao/numero-de-serie-prefixo/grids/grid-padrao";
import NomesTelas from "../../../utils/common/nomes-telas";
import { ItemContextMenu } from "../../../utils/context-menu/context-menu-utils";

export default function PageNumeroDeSeriePrefixo() {
  const [menus, setMenus] = useState<ItemContextMenu[]>();

  return (
    <Pagina
      id="numero-de-serie-prefixo-page"
      titulo={NomesTelas.numeroDeSeriePrefixoESufixo}
      area={"Itens"}
      subMenuItems={menus}
    >
      <RequerPermissao
        codigoPermissoes={[NumeroDeSeriePrefixoPermissoes.Consultar]}
      >
        <GridNumeroDeSeriePrefixo definirMenu={setMenus} />
      </RequerPermissao>
    </Pagina>
  );
}
