import criarDatasourceGrid from "../../utils/grid/back-end-grid-utils";
import { NomesEndpoints } from "../comum/nomesEndpoints";
import { ServiceBase } from "../comum/serviceBase";

export class LancamentoNaParteBDoLacsService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.LancamentoNaParteBDoLacs);
  }

  public GetGridSourceFiltradaPorApuracao(idApuracao: number) {
    return criarDatasourceGrid(
      `${this._nomeEndpoint}/GridFiltradaPorApuracao?idApuracao=${idApuracao}`
    );
  }
}
