import { gerarSelectItemDoDicionario } from "../../../utils/common/enum-utils";
import {
  estadoDaDepreciacaoDecodificado,
  estadoDoCiapDoImobilizadoDecodificado,
  estadoDoImobilizadoDecodificado,
  tipoDeDepreciacaoDecodificado,
} from "../../api/imobilizado/imobilizado";
import SelectItem from "../../shared/ui/select-item";

export const SelectItemEnumEstadoDoImobilizado: SelectItem[] =
  gerarSelectItemDoDicionario(estadoDoImobilizadoDecodificado);

export const SelectItemEnumTipoDeDepreciacao: SelectItem[] =
  gerarSelectItemDoDicionario(tipoDeDepreciacaoDecodificado);

export const SelectItemEnumEstadoDaDepreciacao: SelectItem[] =
  gerarSelectItemDoDicionario(estadoDaDepreciacaoDecodificado);

export const SelectItemEnumEstadoDoCiapDoImobilizado: SelectItem[] =
  gerarSelectItemDoDicionario(estadoDoCiapDoImobilizadoDecodificado);
