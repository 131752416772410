import { AxiosResponse } from "axios";
import { FieldValues, UseFormGetValues } from "react-hook-form";
import TabContainer from "../../../../../components/layout/tab-container";
import RequerPermissao from "../../../../../components/seguranca/requer-permissao";
import { ResponseBaseArquivo } from "../../../../../models/api/comum/response-base";
import { PermissoesLote } from "../../../../../models/direitos/estoque/lote/permissoes-lote";
import { GridEmMemoriaArquivo } from "../../../../arquivo/grids/grid-em-memoria";

interface AnexosProps {
  idRegistro: number;
  getValues: UseFormGetValues<FieldValues>;
  funcaoParaBaixarAnexo: (
    idAnexo: number
  ) => Promise<AxiosResponse<ResponseBaseArquivo, any>>;
}

export default function LoteAbaAnexos({
  idRegistro,
  getValues,
  funcaoParaBaixarAnexo,
}: AnexosProps) {
  return (
    <TabContainer>
      <RequerPermissao
        codigoPermissoes={[PermissoesLote.VisualizarEBaixarAnexos]}
      >
        <GridEmMemoriaArquivo
          idRegistro={idRegistro}
          arquivos={getValues().arquivos}
          funcaoParaBaixarAnexo={funcaoParaBaixarAnexo}
          permissaoVisualizarEBaixar={PermissoesLote.VisualizarEBaixarAnexos}
          permissaoIncluir={PermissoesLote.IncluirAnexos}
          permissaoExcluir={PermissoesLote.ExcluirAnexos}
        />
      </RequerPermissao>
    </TabContainer>
  );
}
