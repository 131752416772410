import getApi from "../../configs/api";
import {
  ResponseBase,
  ResponseModel,
} from "../../models/api/comum/response-base";
import {
  LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcfAlterarRequest,
  LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcfResponseDTO,
} from "../../models/api/informativo-composicao-custos-lucro-real/linha-apuracao-informativo-composicao-custos-ecf";
import criarDatasourceGrid from "../../utils/grid/back-end-grid-utils";
import { NomesEndpoints } from "../comum/nomesEndpoints";

export default class LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcfService {
  protected _nomeEndpoint: NomesEndpoints;
  constructor() {
    this._nomeEndpoint =
      NomesEndpoints.LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcf;
  }

  public async ObterPorIdComDadosAuditoria(id: number) {
    const response = await getApi().get<
      ResponseModel<LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcfResponseDTO>
    >(`${this._nomeEndpoint}/ObterPorIdComDadosAuditoria?id=${id}`);

    return response.data;
  }

  public async Alterar(
    model: LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcfAlterarRequest
  ) {
    const response = await getApi().put<ResponseBase>(
      `${this._nomeEndpoint}`,
      model
    );

    return response.data;
  }

  public async TornarValorLivre(id: number) {
    const response = await getApi().patch<ResponseBase>(
      `${this._nomeEndpoint}/TornarValorLivre?id=${id}`
    );

    return response.data;
  }

  public GetGridSourceDaApuracao(apuracaoId: number) {
    return criarDatasourceGrid(
      `${this._nomeEndpoint}/grid?apuracaoId=${apuracaoId}`
    );
  }
}
