import { useState } from "react";
import Pagina from "../../../components/layout/pagina";
import RequerPermissao from "../../../components/seguranca/requer-permissao";
import { PermissoesContaParteB } from "../../../models/permissoes/contabilidade/conta-parte-b/permissoes-conta-parte-b";
import { GridContaDaParteB } from "../../../parts/contabilidade/conta-parte-b";
import NomesTelas from "../../../utils/common/nomes-telas";
import { ItemContextMenu } from "../../../utils/context-menu/context-menu-utils";

export default function ContaDaParteBPage() {
  const [menus, setMenus] = useState<ItemContextMenu[]>();

  return (
    <Pagina
      id="conta-da-parte-b-page"
      titulo={NomesTelas.contaDaParteB}
      area="Contabilidade > Cadastros"
      subMenuItems={menus}
    >
      <RequerPermissao codigoPermissoes={[PermissoesContaParteB.Consultar]}>
        <GridContaDaParteB definirMenu={setMenus} />
      </RequerPermissao>
    </Pagina>
  );
}
