import getApi from "../../configs/api";
import {
  ResponseBase,
  ResponseModel,
} from "../../models/api/comum/response-base";
import { EfdReinfNumeracaoTipo } from "../../models/api/efd-reinf/efd-reinf";
import criarDatasourceGrid from "../../utils/grid/back-end-grid-utils";
import { NomesEndpoints } from "../comum/nomesEndpoints";

const nomeEndpoint = NomesEndpoints.EfdReinf;

function getGridSource(efdReinfNumeracaoTipo: EfdReinfNumeracaoTipo) {
  return criarDatasourceGrid(`${nomeEndpoint}/${efdReinfNumeracaoTipo}/grid`);
}

async function excluir(
  id: number,
  efdReinfNumeracaoTipo: EfdReinfNumeracaoTipo
) {
  const api = getApi();
  const response = await api.delete<ResponseBase>(
    `${nomeEndpoint}/${efdReinfNumeracaoTipo}?id=${id}`
  );

  return response.data;
}

async function consultar(
  id: number,
  efdReinfNumeracaoTipo: EfdReinfNumeracaoTipo
) {
  const api = getApi();
  const response = await api.get<ResponseBase>(
    `${nomeEndpoint}/${efdReinfNumeracaoTipo}/Consultar?id=${id}`
  );

  return response.data;
}

async function enviar<T>(
  ids: number[],
  efdReinfNumeracaoTipo: EfdReinfNumeracaoTipo
) {
  const api = getApi();
  const response = await api.post<ResponseModel<T>>(
    `${nomeEndpoint}/${efdReinfNumeracaoTipo}/Enviar`,
    ids
  );

  return response.data;
}

export default {
  getGridSource,
  excluir,
  consultar,
  enviar,
};
