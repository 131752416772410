import {
  CompetenciaRegistroBalanceteComparativo,
  RegistroBalanceteComparativo,
  TipoAgrupamentoBalanceteComparativo,
  TipoContaContabil,
} from "../../../models/api/contabilidade/balancete-comparativo";
import {
  ChavesBalanceteComparativoGridModel,
  PrefixosBalanceteComparativoGridModel,
} from "../../../models/const/contabilidade/balacete-comparativo-chaves";
import {
  BalanceteComparativoGridViewModel,
  DefinicaoColunaBalancete,
} from "../../../models/viewmodels/contabilidade/balancete-comparativo/balancete-comparativo-grid-view-model";
import { mesPorExtenso } from "../../common/date-utils";
import { arredondar2Casas } from "../../common/number-utils";

export function adaptRegistroBalanceteComparativo_to_BalanceteComparativoGridViewModel(
  balanceteComparativo: RegistroBalanceteComparativo[]
): BalanceteComparativoGridViewModel {
  const view: BalanceteComparativoGridViewModel = {
    competencias: gerarDefinicoesColunas(
      balanceteComparativo.length > 0
        ? balanceteComparativo[0].competencias
        : [],
      balanceteComparativo.length > 0
        ? balanceteComparativo[0].competencias[0].agrupamento
        : TipoAgrupamentoBalanceteComparativo.Mensal
    ),
    dados: balanceteComparativo.map((x) => mapInterno(x)),
  };

  return view;
}

function mapInterno(item: RegistroBalanceteComparativo): any {
  const dado: any = {};
  dado[ChavesBalanceteComparativoGridModel.Id] = item.contaContabilId;
  dado[ChavesBalanceteComparativoGridModel.Codigo] = item.codigo;
  dado[ChavesBalanceteComparativoGridModel.Classificacao] = item.classificacao;
  dado[ChavesBalanceteComparativoGridModel.Descricao] = item.descricao;
  dado[ChavesBalanceteComparativoGridModel.Nivel] = item.nivel;
  dado[ChavesBalanceteComparativoGridModel.Sintetica] =
    item.tipo == TipoContaContabil.Sintetica;

  for (const competencia of item.competencias) {
    const posFixo = normalizaPosfixoCompetencia(competencia.competencia);
    dado[`${PrefixosBalanceteComparativoGridModel.SaldoInicial}${posFixo}`] =
      arredondar2Casas(competencia.saldoInicial);
    dado[`${PrefixosBalanceteComparativoGridModel.SaldoFinal}${posFixo}`] =
      arredondar2Casas(competencia.saldoFinal);
    dado[`${PrefixosBalanceteComparativoGridModel.Debitos}${posFixo}`] =
      arredondar2Casas(competencia.debito);
    dado[`${PrefixosBalanceteComparativoGridModel.Creditos}${posFixo}`] =
      arredondar2Casas(competencia.credito);
    dado[`${PrefixosBalanceteComparativoGridModel.Liquido}${posFixo}`] =
      arredondar2Casas(competencia.liquido);
  }

  return dado;
}

function gerarDefinicoesColunas(
  competencias: CompetenciaRegistroBalanceteComparativo[],
  agrupamento: TipoAgrupamentoBalanceteComparativo
) {
  return competencias.map((x) =>
    gerarDefinicaoColuna(x.competencia, agrupamento)
  );
}

function normalizaPosfixoCompetencia(competencia: string) {
  return competencia.replace("/", "_").replace("-", "_");
}

function formatarCompetencia(
  competencia: string,
  agrupamento: TipoAgrupamentoBalanceteComparativo
) {
  switch (agrupamento) {
    case TipoAgrupamentoBalanceteComparativo.Mensal: {
      const fragmentosMensal = competencia.split("/");
      const mes = fragmentosMensal[0];
      const ano = fragmentosMensal[1];
      return `${mesPorExtenso(mes)}/${ano}`;
    }

    case TipoAgrupamentoBalanceteComparativo.Trimestral: {
      const fragmentosTrimestre = competencia.split("-");
      const anoTrimestre = fragmentosTrimestre[0];
      const trimestre = fragmentosTrimestre[1];
      return `${trimestre}º trimestre/${anoTrimestre}`;
    }

    default: {
      return competencia;
    }
  }
}

function gerarDefinicaoColuna(
  competencia: string,
  agrupamento: TipoAgrupamentoBalanceteComparativo
): DefinicaoColunaBalancete {
  return {
    chave: normalizaPosfixoCompetencia(competencia),
    descricao: formatarCompetencia(competencia, agrupamento),
  };
}
