import { yupResolver } from "@hookform/resolvers/yup";
import TabPanel, { Item } from "devextreme-react/cjs/tab-panel";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
  FormDateBox,
  FormTextBoxSimples,
} from "../../../../components/formularios";
import {
  FormBase2,
  FormularioEdicaoBaseProps,
} from "../../../../components/layout/form-base2";
import { Coluna, Linha } from "../../../../components/layout/grid-system";
import TabContainer from "../../../../components/layout/tab-container";
import { useCarregarRegistro } from "../../../../hooks/form.hooks";
import { useAppSelector } from "../../../../hooks/store.hooks";
import AuditavelDTO from "../../../../models/api/comum/auditavel-dto";
import { ApuracaoDoInformativoDaComposicaoDeCustosDaEcfDTO } from "../../../../models/api/informativo-composicao-custos-lucro-real/apuracao-informativo-composicao-custos-ecf";
import { CallBackModal } from "../../../../models/shared/ui/callback-modal";
import { ApuracaoDoInformativoDaComposicaoDeCustosDaEcfService } from "../../../../services/informativo-composicao-custos-lucro-real/apuracao-informativo-composicao-custos-ecf";
import { checarResponse, tratarErroApi } from "../../../../utils/api/api-utils";
import NomesModais from "../../../../utils/common/nomes-modais";
import { formatarCpfCnpj } from "../../../../utils/formatadores/formatador-de-strings";
import GridInternaGridInternaLinhaApuracaoInformativoComposicaoCustosEcf from "./linha-apuracao-informativo-composicao-custos-ecf";

const service = new ApuracaoDoInformativoDaComposicaoDeCustosDaEcfService();

const novoRegistro: ApuracaoDoInformativoDaComposicaoDeCustosDaEcfDTO = {
  id: 0,
  dataInicial: "",
  dataFinal: "",
};

const rederizarTitulo = (item: any) => item.text;

export default function EditFormEdicaoApuracaoDoInformativoDaComposicaoDeCustosDaEcf(
  props: FormularioEdicaoBaseProps
) {
  const [carregando, setCarregando] = useState(false);
  const [dadosAuditoria, setDadosAuditoria] = useState<AuditavelDTO>();

  const empresaLogada = useAppSelector((state) => {
    return {
      Apelido: state.sessao.dadosSessao?.empresa.apelido,
      CpfCpj: state.sessao.dadosSessao?.empresa.cnpjCpfCodigo,
    };
  });

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
  });

  const { register, control, reset } =
    useForm<ApuracaoDoInformativoDaComposicaoDeCustosDaEcfDTO>({
      mode: "onChange",
      reValidateMode: "onChange",
      resolver: yupResolver(schema),
    });

  useCarregarRegistro(props.idRegistroEdicao, carregarTela);

  // Usado para limpar o formulário se for NaN
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEdicao)) {
      reset(novoRegistro);
    }
  }, [props.idRegistroEdicao]);

  function fechar(info: CallBackModal) {
    if (props.callBackFecharModal) {
      setDadosAuditoria(undefined);
      props.callBackFecharModal(info);
    }
  }

  function handleCancelar() {
    fechar({ concluido: false, precisaAtualizar: false });
  }

  async function carregarTela() {
    try {
      setCarregando(true);
      if (props.idRegistroEdicao > 0) {
        await carregarModel();
      } else {
        reset(novoRegistro);
        setDadosAuditoria(undefined);
      }
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  async function carregarModel() {
    try {
      const resposta = await service.ObterPorIdComDadosAuditoria(
        props.idRegistroEdicao
      );
      checarResponse(resposta);
      reset(resposta.model);
      setDadosAuditoria(resposta.model);
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    }
  }

  function callBackUnprocessableEntity() {
    fechar({
      concluido: false,
      precisaAtualizar: true,
    });
  }

  return (
    <>
      <div>
        <FormBase2
          visivel={props.visivel}
          carregando={carregando}
          onClickCancelar={handleCancelar}
          configuracoesModal={props.configuracoesModal}
          modoEdicao={props.idRegistroEdicao == 0 ? "criar" : "editar"}
          titulo={NomesModais.informativoComposicaoCustosLucroReal}
          auditoria={dadosAuditoria}
        >
          <input type="hidden" {...register("id")} defaultValue={0} />
          <Linha>
            <Coluna md={8}>
              <FormTextBoxSimples
                titulo={"Minha empresa"}
                valor={`${empresaLogada.Apelido} - ${formatarCpfCnpj(
                  empresaLogada.CpfCpj!
                )}`}
                somenteLeitura
              />
            </Coluna>
          </Linha>
          <Linha>
            <Coluna md={3}>
              <FormDateBox
                name="dataInicial"
                titulo="Período de"
                control={control}
                requerido
                exibirBotaoLimpar
                tipo="date"
                aceitaValorCustomizado={true}
                formatoExibicao="dd/MM/yy"
                aceitaDigitacaoComMascara={true}
                somenteLeitura
              />
            </Coluna>
            <Coluna md={3}>
              <FormDateBox
                name="dataFinal"
                titulo="a"
                control={control}
                requerido
                exibirBotaoLimpar
                tipo="date"
                aceitaValorCustomizado={true}
                formatoExibicao="dd/MM/yy"
                aceitaDigitacaoComMascara={true}
                somenteLeitura
              />
            </Coluna>
          </Linha>
          <TabPanel
            showNavButtons
            swipeEnabled={false}
            itemTitleRender={rederizarTitulo}
            height={"max(40vh, 485px)"}
          >
            <Item text="Informativo da composição de custos">
              <TabContainer>
                <GridInternaGridInternaLinhaApuracaoInformativoComposicaoCustosEcf
                  idApuracaoDoDoInformativoDaComposicaoDeCustosDaEcf={
                    props.idRegistroEdicao
                  }
                  modalPaiVisivel={props.visivel}
                />
              </TabContainer>
            </Item>
          </TabPanel>
        </FormBase2>
      </div>
    </>
  );
}
