import { gerarSelectItemDoDicionario } from "../../../utils/common/enum-utils";
import {
  tipoCarroceriaDecodificado,
  tipoProprietarioDecodificado,
  tipoRodadoDecodificado,
  tipoUnidadeTransporteDecodificado,
} from "../../api/veiculo/veiculo";
import SelectItem from "../../shared/ui/select-item";

export const SelectItemEnumTipoCarroceria: SelectItem[] =
  gerarSelectItemDoDicionario(tipoCarroceriaDecodificado);

export const SelectItemEnumTipoProprietario: SelectItem[] =
  gerarSelectItemDoDicionario(tipoProprietarioDecodificado);

export const SelectItemEnumTipoRodado: SelectItem[] =
  gerarSelectItemDoDicionario(tipoRodadoDecodificado);

export const SelectItemEnumTipoUnidadeTransporte: SelectItem[] =
  gerarSelectItemDoDicionario(tipoUnidadeTransporteDecodificado);
