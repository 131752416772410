import { useState } from "react";
import * as yup from "yup";
import ProvedorAjuda from "../../../../components/ajuda/provedor-ajuda";
import {
  FormDateBox,
  FormTextBoxSimples,
} from "../../../../components/formularios";
import {
  FormBase2,
  FormularioEdicaoBaseProps,
} from "../../../../components/layout/form-base2";
import { Coluna, Linha } from "../../../../components/layout/grid-system";
import {
  useHookForms,
  useLimparFormSeIdForNaN,
} from "../../../../hooks/form.hooks";
import { useAppSelector } from "../../../../hooks/store.hooks";
import { ApuracaoLalurIrpjCadastrarRequest } from "../../../../models/api/apuracao-lalur-irpj/apuracao-lalur-irpj";
import { CallBackModal } from "../../../../models/shared/ui/callback-modal";
import { ApuracaoLalurIrpjService } from "../../../../services/apuracao-lalur-irpj/apuracao-lalur-irpj.service";
import { checarResponse, tratarErroApi } from "../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../utils/common/notificacoes-utils";
import { formatarCpfCnpj } from "../../../../utils/formatadores/formatador-de-strings";
const novoRegistro: ApuracaoLalurIrpjCadastrarRequest = {
  dataInicial: "",
  dataFinal: "",
};
const service = new ApuracaoLalurIrpjService();

export default function EditFormInsercaoApuracaoLalurIrpj(
  props: FormularioEdicaoBaseProps
) {
  const [carregando, setCarregando] = useState(false);
  const empresaLogada = useAppSelector((state) => {
    return {
      Apelido: state.sessao.dadosSessao?.empresa.apelido,
      CpfCpj: state.sessao.dadosSessao?.empresa.cnpjCpfCodigo,
    };
  });

  const schema = yup.object().shape({
    dataInicial: yup
      .string()
      .required()
      .test(
        "data_inicial_valida",
        "A data inicial está maior do que a final.",
        function (value, contexto) {
          if (value && contexto.parent.dataFinal) {
            const dataInicial = new Date(value);
            const dataFinal = new Date(contexto.parent.dataFinal);
            return dataInicial <= dataFinal;
          }
          return true;
        }
      ),
    dataFinal: yup
      .string()
      .required()
      .test(
        "data_final_valida",
        "A data final está menor do que a inicial.",
        function (valor, contexto) {
          if (valor && contexto.parent.dataInicial) {
            const dataInicial = new Date(contexto.parent.dataInicial);
            const dataFinal = new Date(valor);
            return dataInicial <= dataFinal;
          }
          return true;
        }
      ),
  });

  const hookForm = useHookForms(schema);
  useLimparFormSeIdForNaN(hookForm, novoRegistro, props.idRegistroEdicao);

  const { control, handleSubmit, getValues } = hookForm;

  function handleCancelar() {
    fechar({ concluido: false, precisaAtualizar: false });
  }

  function fechar(info: CallBackModal) {
    if (props.callBackFecharModal) {
      props.callBackFecharModal(info);
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues();
    try {
      const resposta = await service.Cadastrar(model);

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar({
          concluido: true,
          precisaAtualizar: true,
        });
      }
      fechar({
        concluido: true,
        precisaAtualizar: false,
      });
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  function callBackUnprocessableEntity() {
    fechar({
      concluido: false,
      precisaAtualizar: true,
    });
  }

  return (
    <>
      <FormBase2
        carregando={carregando}
        titulo={"apuração do LALUR e IRPJ do Lucro Real"}
        onClickSalvar={handleSubmit(handleSalvar)}
        onClickCancelar={handleCancelar}
        visivel={props.visivel}
        modoEdicao={"criar"}
        isNomeTelaFeminino
        configuracoesModal={props.configuracoesModal}
      >
        <ProvedorAjuda id="edit-form-apuracao-lalur-irpj">
          <Linha>
            <Coluna md={8}>
              <FormTextBoxSimples
                titulo={"Minha empresa"}
                valor={`${empresaLogada.Apelido} - ${formatarCpfCnpj(
                  empresaLogada.CpfCpj!
                )}`}
                somenteLeitura
              />
            </Coluna>
          </Linha>
          <Linha>
            <Coluna md={3}>
              <FormDateBox
                name="dataInicial"
                titulo="Período de"
                control={control}
                requerido
                exibirBotaoLimpar
                tipo="date"
                aceitaValorCustomizado={true}
                formatoExibicao="dd/MM/yy"
                aceitaDigitacaoComMascara={true}
                somenteLeitura={props.idRegistroEdicao > 0}
              />
            </Coluna>
            <Coluna md={3}>
              <FormDateBox
                name="dataFinal"
                titulo="a"
                control={control}
                requerido
                exibirBotaoLimpar
                tipo="date"
                aceitaValorCustomizado={true}
                formatoExibicao="dd/MM/yy"
                aceitaDigitacaoComMascara={true}
                somenteLeitura={props.idRegistroEdicao > 0}
              />
            </Coluna>
          </Linha>
        </ProvedorAjuda>
      </FormBase2>
    </>
  );
}
