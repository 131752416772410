import { Column } from "devextreme-react/cjs/data-grid";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { renderToString } from "react-dom/server";
import { useDispatch } from "react-redux";
import { MxpGrid } from "../../../../components/grid";
import {
  useEditarRegistroGrid,
  useExcluirRegistroGrid,
  useGerarItensAdicionais,
  useNovoRegistroGrid,
  usePropagarReferenciaGrid,
} from "../../../../hooks/grid.hooks";
import { ApuracaoDoInformativoDaComposicaoDeCustosDaEcfGridModel } from "../../../../models/api/informativo-composicao-custos-lucro-real/apuracao-informativo-composicao-custos-ecf";
import { PermissoesInformativoComposicaoCustosLucroReal } from "../../../../models/permissoes/fiscal/informativo-composicao-custos-lucro-real/permissoes-informativo-composicao-lucro-real";
import { CallBackModal } from "../../../../models/shared/ui/callback-modal";
import { IGridSelecao } from "../../../../models/shared/ui/formularios";
import { ApuracaoDoInformativoDaComposicaoDeCustosDaEcfService } from "../../../../services/informativo-composicao-custos-lucro-real/apuracao-informativo-composicao-custos-ecf";
import { bloquearUI, desbloquearUI } from "../../../../store/ui/ui.slice";
import {
  checarResponseExibeMensagemExclusaoDeSucesso,
  checarResponseExibeMensagemExecutadoComSucesso,
} from "../../../../utils/api/api-utils";
import criarNameof from "../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../utils/common/nomes-telas";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../utils/common/permissoes-utils";
import { exibirConfirmacao } from "../../../../utils/dialogos";
import { formatarData } from "../../../../utils/formatadores/formatador-de-datas";
import { GridBaseProps } from "../../../../utils/grid/grid-utils";
import obterConfiguracaoColuna from "../../../../utils/grid/padroes-colunas";
import GetColunasDeAuditoria from "../../../layout/grid-defaults/colunasDeAuditoria";
import EditFormEdicaoApuracaoDoInformativoDaComposicaoDeCustosDaEcf from "../form-edicao";
import EditFormInsercaoApuracaoDoInformativoDaComposicaoDeCustosDaEcf from "../form-insercao";

const service = new ApuracaoDoInformativoDaComposicaoDeCustosDaEcfService();
const dataSource = service.GetGridSource();

const nameOfGridHandler =
  criarNameof<ApuracaoDoInformativoDaComposicaoDeCustosDaEcfGridModel>();
const colunas = [
  <Column
    key={nameOfGridHandler("dataInicial")}
    dataField={nameOfGridHandler("dataInicial")}
    {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
    caption="Data inicial"
  />,
  <Column
    key={nameOfGridHandler("dataFinal")}
    dataField={nameOfGridHandler("dataFinal")}
    {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
    caption="Data final"
  />,
  ...GetColunasDeAuditoria(),
];

export const GridInformativoComposicaoCustosLucroReal = forwardRef(
  (props: GridBaseProps, ref) => {
    const gridRef = useRef<IGridSelecao>(null);
    useImperativeHandle(ref, () => gridRef.current as IGridSelecao);
    usePropagarReferenciaGrid(ref, gridRef);

    const [modalInsercaoVisivel, setModalInsercaoVisivel] = useState(false);
    const [modalEdicaoVisivel, setModalEdicaoVisivel] = useState(false);
    const [idRegistroEdicao, setIdRegistroEdicao] = useState(NaN);

    const dispatch = useDispatch();

    const handleNovoRegistro = useNovoRegistroGrid(() => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesInformativoComposicaoCustosLucroReal.InserirEditar,
        ])
      ) {
        return;
      }
      setIdRegistroEdicao(0);
      setModalInsercaoVisivel(true);
    });

    const handleEditarRegistro = useEditarRegistroGrid((id) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesInformativoComposicaoCustosLucroReal.InserirEditar,
        ])
      ) {
        return;
      }
      setIdRegistroEdicao(id);
      setModalEdicaoVisivel(true);
    });

    function handleAtualizarGrid() {
      if (gridRef.current?.atualizarGrid) {
        gridRef.current?.atualizarGrid();
      }
    }

    const handleExcluirRegistro = useExcluirRegistroGrid(
      async (
        registro: ApuracaoDoInformativoDaComposicaoDeCustosDaEcfGridModel
      ) => {
        if (
          !verificaComNotificacaoSeUsuarioPossuiPermissoes([
            PermissoesInformativoComposicaoCustosLucroReal.Excluir,
          ])
        ) {
          return;
        }
        const mensagem = renderToString(<>{obterMensagemExclusao(registro)}</>);
        const excluir = await exibirConfirmacao("Confirmar exclusão", mensagem);

        if (excluir) {
          const resposta = await service.Excluir(registro.id);

          if (resposta) {
            checarResponseExibeMensagemExclusaoDeSucesso(resposta);
            handleAtualizarGrid();
          }
        }
      }
    );

    function obterMensagemExclusao(
      registro: ApuracaoDoInformativoDaComposicaoDeCustosDaEcfGridModel
    ) {
      return `Tem certeza que deseja excluir o informativo da composição de custos do Lucro Real de ${formatarData(
        registro.dataInicial
      )} a ${formatarData(registro.dataFinal)}?`;
    }

    function handleModalInsercaoCallback(info: CallBackModal) {
      setModalInsercaoVisivel(false);
      setIdRegistroEdicao(NaN);

      if (info.precisaAtualizar) {
        handleAtualizarGrid();
      }
    }

    function handleModalEdicaoCallback(info: CallBackModal) {
      setModalEdicaoVisivel(false);
      setIdRegistroEdicao(NaN);

      if (info.precisaAtualizar) {
        handleAtualizarGrid();
      }
    }

    async function handleRecalcular(id: number) {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesInformativoComposicaoCustosLucroReal.InserirEditar,
        ])
      ) {
        return;
      }

      const recalcular = await exibirConfirmacao(
        "Confirmar recálculo",
        "Tem certeza de que deseja recalcular a apuração?"
      );

      if (recalcular) {
        try {
          dispatch(bloquearUI());
          const resultado = await service.Recalcular(id);
          checarResponseExibeMensagemExecutadoComSucesso(resultado);
          if (resultado.sucesso) {
            handleAtualizarGrid();
          }
        } finally {
          dispatch(desbloquearUI());
        }
      }
    }

    const handleGerarItensAdicionais = useGerarItensAdicionais(
      (
        getData: () =>
          | ApuracaoDoInformativoDaComposicaoDeCustosDaEcfGridModel
          | undefined
      ) => {
        const itensAdicionais = [
          {
            text: "Ações",
            icon: "ic-material-symbols-outlined ic-vertical",
            hint: "Menu com opções de ações",
            items: [
              {
                text: "Recalcular apuração",
                onClick: async () => await handleRecalcular(getData()!.id),
                mostraNaColunaDeAcoes: true,
              },
            ],
          },
        ];

        return itensAdicionais;
      }
    );

    return (
      <>
        <MxpGrid<ApuracaoDoInformativoDaComposicaoDeCustosDaEcfGridModel>
          dataSource={dataSource}
          id={"apuracao-informativo-composicao-custos-ecf"}
          ref={gridRef}
          colunas={colunas}
          nomeDoArquivoAoExportar={
            NomesTelas.informativoDaComposicaoDeCustosDoLucroReal
          }
          novoRegistro={handleNovoRegistro}
          editarRegistro={handleEditarRegistro}
          excluirRegistro={handleExcluirRegistro}
          gerarItensAdicionaisDeContexto={handleGerarItensAdicionais}
          definirMenuSuperior={props.definirMenu}
        />
        <EditFormInsercaoApuracaoDoInformativoDaComposicaoDeCustosDaEcf
          visivel={modalInsercaoVisivel}
          idRegistroEdicao={idRegistroEdicao}
          configuracoesModal={{ largura: "max(50vw, 800px)", altura: "auto" }}
          callBackFecharModal={handleModalInsercaoCallback}
        />

        <EditFormEdicaoApuracaoDoInformativoDaComposicaoDeCustosDaEcf
          visivel={modalEdicaoVisivel}
          idRegistroEdicao={idRegistroEdicao}
          configuracoesModal={{ largura: "max(50vw, 800px)", altura: "80vh" }}
          callBackFecharModal={handleModalEdicaoCallback}
        />
      </>
    );
  }
);
