import { useState } from "react";
import Pagina from "../../../components/layout/pagina";
import RequerPermissao from "../../../components/seguranca/requer-permissao";
import { PermissoesApuracaoLacsCsll } from "../../../models/permissoes/fiscal/apuracao-lacs-csll/permissoes-apuracao-lacs-csll";
import { GridApuracaoLacsCsll } from "../../../parts/fiscal/apuracao-lacs-csll/grid";
import NomesTelas from "../../../utils/common/nomes-telas";
import { ItemContextMenu } from "../../../utils/context-menu/context-menu-utils";

export default function ApuracaoLacsCsllPage() {
  const [menus, setMenus] = useState<ItemContextMenu[]>();

  return (
    <Pagina
      id="apuracao-lacs-css-page"
      area={"Fiscal > Apurações"}
      titulo={NomesTelas.apuracaoLacsCsll}
      subMenuItems={menus}
    >
      <RequerPermissao
        codigoPermissoes={[PermissoesApuracaoLacsCsll.Consultar]}
      >
        <GridApuracaoLacsCsll definirMenu={setMenus} />
      </RequerPermissao>
    </Pagina>
  );
}
