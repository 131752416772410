import { ValueChangedEvent } from "devextreme/ui/check_box";
import { useState } from "react";
import {
  Control,
  FieldValues,
  UseFormGetValues,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";
import {
  FormCheckBox,
  FormDateBox,
  FormSelectBoxLazy,
  FormTextBox,
} from "../../../../../components/formularios";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import TabContainer from "../../../../../components/layout/tab-container";
import { SelectBoxLazyFiltros } from "../../../../../models/api/comum/selectboxlazy-options";
import {
  Estado,
  EstocagemTipo,
} from "../../../../../models/api/item/item-enums";
import {
  loteDoFabricanteOuNumeroDeSerieMaxLength,
  LoteRequestDTO,
} from "../../../../../models/api/lote/lote";
import { NomesEndpoints } from "../../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../../services/comum/serviceBase";
import APINumeroDeSeriePrefixo from "../../../../../services/item/numero-de-serie-prefixo/numero-de-serie-prefixo.service";
import criarNameof from "../../../../../utils/common/cria-name-of";
import FiltrosGridItem from "../../../../../utils/filtros-grid/itens/item/filtros-grid-item";
import { GridEmpresa } from "../../../../empresas/empresa/grids";
import { GridItem } from "../../../../itens/item/grids/grid-padrao";
import { UtilizaPrefixoCheckboxContainer } from "./styles";

interface DadosGeraisProps {
  control: Control<FieldValues, any>;
  loteDataRequerido: boolean | undefined;
  isRegistroEmEdicao: boolean;
  getValues: UseFormGetValues<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  trigger: UseFormTrigger<FieldValues>;
  controleLoteFabricacao: boolean | undefined;
}

function carregaItens(filtrosAdicionais: SelectBoxLazyFiltros[] = []) {
  const filtros: SelectBoxLazyFiltros[] = [
    { nomeCampo: "EstocagemPor", operador: "<>", valor: EstocagemTipo.Codigo },
    { nomeCampo: "EstocagemPor", operador: "<>", valor: EstocagemTipo.Interno },
    { nomeCampo: "Estado", operador: "<>", valor: Estado.Inativo },
  ];

  for (const filtro of filtrosAdicionais) {
    filtros.push(filtro);
  }

  return APIBase.getDataSourceSelectBoxLazy(
    {
      camposRetorno: ["Id", "Codigo", "Descricao", "EstocagemPor"],
      filtros: filtros,
      camposOrdenacao: [
        {
          nomeCampo: "Codigo",
          desc: false,
        },
      ],
    },
    NomesEndpoints.Item
  );
}

const fabricantes = APIBase.getDataSourceSelectBoxLazy(
  {
    camposRetorno: ["Id", "Apelido", "RazaoSocial"],
    camposOrdenacao: [
      {
        nomeCampo: "Apelido",
        desc: false,
      },
    ],
  },
  NomesEndpoints.Empresa
);

const fabricantesExprecaoBusca = ["Apelido", "RazaoSocial"];
const exibeFabricante = (c: any) => {
  if (c) {
    return c.RazaoSocial == null
      ? `${c.Apelido}`
      : `${c.Apelido} (${c.RazaoSocial})`;
  }

  return "";
};

const itensExpressaoBusca = ["Codigo", "Descricao"];
const exibeItem = (c: any) => {
  if (c) {
    return c.Descricao == null ? `${c.Codigo}` : `${c.Codigo} (${c.Descricao})`;
  }

  return "";
};

const nameOfLoteRequestDTOHandler = criarNameof<LoteRequestDTO>();

export default function LoteAbaDadosGerais({
  control,
  loteDataRequerido,
  isRegistroEmEdicao,
  getValues,
  setValue,
  watch,
  trigger,
  controleLoteFabricacao,
}: DadosGeraisProps) {
  const itemIdInicialDoLoteEmEdicao = isRegistroEmEdicao
    ? (getValues().itemId as number)
    : undefined;
  const utilizarPrefixoDoItem = watch(
    nameOfLoteRequestDTOHandler("utilizaPrefixo")
  );

  const [itens] = useState(carregaItens);
  const [loteDoFabricanteOuNumeroDeSerie, setLoteDoFabricanteOuNumeroDeSerie] =
    useState("");

  async function utilizaPrefixoCheckBoxHandler(e: ValueChangedEvent) {
    setValue(nameOfLoteRequestDTOHandler("utilizaPrefixo"), e.value as boolean);

    const loteDoFabricanteOuNumeroDeSerieTexto =
      loteDoFabricanteOuNumeroDeSerie ||
      (getValues().loteDoFabricanteOuNumeroDeSerie as string);

    setValue(
      nameOfLoteRequestDTOHandler("loteDoFabricanteOuNumeroDeSerie"),
      e.value ? loteDoFabricanteOuNumeroDeSerieTexto : ""
    );

    trigger(nameOfLoteRequestDTOHandler("loteDoFabricanteOuNumeroDeSerie"));
  }

  const onItemChanged = async (e: any) => {
    ajustaLoteDoFabricanteOuNumeroDeSerieComPrefixo(e?.selectedItem?.Id);
  };

  async function ajustaLoteDoFabricanteOuNumeroDeSerieComPrefixo(
    itemId: number
  ) {
    if (itemId == itemIdInicialDoLoteEmEdicao) {
      setLoteDoFabricanteOuNumeroDeSerie(
        getValues().loteDoFabricanteOuNumeroDeSerie as string
      );

      return;
    }

    if (itemId > 0) {
      const numeroDeSerieOuLotePrefixo =
        await APINumeroDeSeriePrefixo.getNumeroSerieOuLotePrefixoAsync(itemId);

      setLoteDoFabricanteOuNumeroDeSerie(numeroDeSerieOuLotePrefixo);
      if (utilizarPrefixoDoItem) {
        setValue(
          nameOfLoteRequestDTOHandler("loteDoFabricanteOuNumeroDeSerie"),
          numeroDeSerieOuLotePrefixo
        );
        trigger(nameOfLoteRequestDTOHandler("loteDoFabricanteOuNumeroDeSerie"));
      }
    }
  }

  const camposDataLote = () => {
    return (
      <>
        <Coluna md={3}>
          <FormDateBox
            name="fabricacaoData"
            titulo="Data de fabricação"
            control={control}
            exibirBotaoLimpar
            tipo="date"
            aceitaValorCustomizado={true}
            formatoExibicao="dd/MM/yyyy"
            aceitaDigitacaoComMascara={true}
            requerido={loteDataRequerido}
          />
        </Coluna>
        <Coluna md={3}>
          <FormDateBox
            name="vencimentoData"
            titulo="Data de vencimento"
            control={control}
            exibirBotaoLimpar
            tipo="date"
            aceitaValorCustomizado={true}
            formatoExibicao="dd/MM/yyyy"
            aceitaDigitacaoComMascara={true}
            requerido={loteDataRequerido}
          />
        </Coluna>
        <Coluna md={3}>
          <FormDateBox
            name="reanaliseData"
            titulo="Data de reanálise"
            control={control}
            exibirBotaoLimpar
            tipo="date"
            aceitaValorCustomizado={true}
            formatoExibicao="dd/MM/yyyy"
            aceitaDigitacaoComMascara={true}
          />
        </Coluna>
      </>
    );
  };

  return (
    <TabContainer>
      <Linha>
        <Coluna md={6}>
          <FormSelectBoxLazy
            name="itemId"
            titulo="Item"
            control={control}
            requerido
            nomeCampoChave="Id"
            nomeCampoExibicao={exibeItem}
            expressaoDeBusca={itensExpressaoBusca}
            dataSource={itens}
            lupaConfig={{
              modo: "selecaoUnica",
              titulo: "Selecionar item",
              componente: (r) => (
                <GridItem
                  valorPadraoDoFiltro={FiltrosGridItem.itemLoteOuSerieAtivo}
                  sobreporFiltroSalvoComOFiltroPadrao
                  ocultarBotaoNovo
                  ref={r}
                />
              ),
            }}
            labelSemDados="Sem dados"
            onSelectionChanged={onItemChanged}
          />
        </Coluna>
        <Coluna md={6}>
          <FormSelectBoxLazy
            name="fabricanteId"
            titulo="Fabricante"
            control={control}
            requerido
            nomeCampoChave="Id"
            nomeCampoExibicao={exibeFabricante}
            expressaoDeBusca={fabricantesExprecaoBusca}
            dataSource={fabricantes}
            lupaConfig={{
              modo: "selecaoUnica",
              titulo: "Selecionar fabricante",
              componente: (r) => <GridEmpresa ocultarBotaoNovo ref={r} />,
            }}
            labelSemDados="Sem dados"
          />
        </Coluna>
      </Linha>
      <Linha>
        <Coluna md={6}>
          <FormTextBox
            name="loteDoFabricanteOuNumeroDeSerie"
            titulo="Lote do fabricante / número de série"
            control={control}
            requerido
            tamanhoMaximo={loteDoFabricanteOuNumeroDeSerieMaxLength}
            somenteLeitura={utilizarPrefixoDoItem}
          />
        </Coluna>
        <Coluna md={6}>
          <UtilizaPrefixoCheckboxContainer>
            <FormCheckBox
              name="utilizaPrefixo"
              titulo="Utilizar prefixo e sufixo do item"
              control={control}
              requerido
              onValueChanged={utilizaPrefixoCheckBoxHandler}
            />
          </UtilizaPrefixoCheckboxContainer>
        </Coluna>
      </Linha>
      <Linha>{controleLoteFabricacao ? camposDataLote() : ""}</Linha>
    </TabContainer>
  );
}
