export interface TokenAcessoResponseDTO {
  id: number;
  usuarioTokenId: number;
  tipo: TiposTokenAcesso;
  token: string;
}

export interface TokenAcessoRequestDTO {
  usuarioTokenId: number;
  tipo: TiposTokenAcesso;
}

export enum TiposTokenAcesso {
  GraphQl = 1,
}
