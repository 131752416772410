import { Control, FieldValues } from "react-hook-form";
import { ProvedorBaixarInsumo } from "../../../../../components/insumo/provedor-baixar-insumo";
import TabContainer from "../../../../../components/layout/tab-container";
import { GridEmbutidaInsumoDaOrdemDeProducao } from "../../../insumo-da-ordem-de-producao/grids/grid-embutida/insumo-da-ordem-de-producao-grid-embutida";

interface OrdemDeProducaoAbaInsumosProps {
  idRegistro: number;
  control: Control<FieldValues, any>;
}

export default function OrdemDeProducaoAbaInsumos({
  idRegistro,
}: OrdemDeProducaoAbaInsumosProps) {
  return (
    <TabContainer>
      <ProvedorBaixarInsumo>
        <GridEmbutidaInsumoDaOrdemDeProducao ordemDeProducaoId={idRegistro} />
      </ProvedorBaixarInsumo>
    </TabContainer>
  );
}
