import { Column } from "devextreme-react/data-grid";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { MxpGrid } from "../../../../../components/grid";
import {
  useExcluirRegistroGrid,
  usePropagarReferenciaGrid,
} from "../../../../../hooks/grid.hooks";
import { CentroDeCustosGridModel } from "../../../../../models/api/centro-de-custos/centro-de-custos";
import { IGridSelecao } from "../../../../../models/shared/ui/formularios";
import { NomesEndpoints } from "../../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../../services/comum/serviceBase";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import GetColunasDeAuditoria from "../../../../layout/grid-defaults/colunasDeAuditoria";

const nomeEndpoint = NomesEndpoints.CentroDeCustos;
const dataSource = APIBase.getGridSource(nomeEndpoint);
const nameOfGridHandler = criarNameof<CentroDeCustosGridModel>();
const colunas = [
  <Column
    key={nameOfGridHandler("codigo")}
    dataField={nameOfGridHandler("codigo")}
    {...obterConfiguracaoColuna("stringP")}
    caption="Código"
  />,
  <Column
    key={nameOfGridHandler("classificacao")}
    dataField={nameOfGridHandler("classificacao")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Classificação"
    sortOrder="asc"
  />,
  <Column
    key={nameOfGridHandler("descricao")}
    dataField={nameOfGridHandler("descricao")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Descrição"
  />,
  ...GetColunasDeAuditoria(),
];

export const GridCentroDeCustos = forwardRef((_, ref) => {
  const gridRef = useRef<IGridSelecao>(null);
  useImperativeHandle(ref, () => gridRef.current as IGridSelecao);

  usePropagarReferenciaGrid(ref, gridRef);

  const handleExcluirRegistro = useExcluirRegistroGrid(async () => {
    exibirNotificacaoToast({
      mensagem: "Funcionalidade não implementada.",
      tipo: TipoNotificacao.Informacao,
    });

    return;
  });

  return (
    <>
      <MxpGrid<CentroDeCustosGridModel>
        dataSource={dataSource}
        id={"centro-de-custos"}
        ref={gridRef}
        colunas={colunas}
        exibirIconeEditarMenuAcoes={false}
        excluirRegistro={handleExcluirRegistro}
        nomeDoArquivoAoExportar={NomesTelas.centrosCusto}
      />
    </>
  );
});
