import { Control, FieldValues, UseFormWatch } from "react-hook-form";
import {
  FormNumberBox,
  FormTextBoxSimples,
} from "../../../../../components/formularios";
import FormTextArea from "../../../../../components/formularios/textarea";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import TabContainer from "../../../../../components/layout/tab-container";
import {
  OrdemDeProducaoRequest,
  quantidadeDePerdaPrevistaMaxima,
  quantidadeDePerdaPrevistaMinima,
} from "../../../../../models/api/producao/ordem-de-producao/ordem-de-producao";
import criarNameof from "../../../../../utils/common/cria-name-of";

interface OrdemDeProducaoAbaOutrosDadosProps {
  idRegistro: number;
  control: Control<FieldValues, any>;
  watch: UseFormWatch<FieldValues>;
}

const nameOfOrdemDeProducao = criarNameof<OrdemDeProducaoRequest>();

export default function OrdemDeProducaoAbaOutrosDados({
  control,
  watch,
}: OrdemDeProducaoAbaOutrosDadosProps) {
  const quantidade = watch("quantidade");
  const quantidadeDePerdaPrevista = watch("quantidadeDePerdaPrevista");
  const quantidadeBruta =
    (quantidade == null || Number.isNaN(quantidade) ? 0 : quantidade) +
    (quantidadeDePerdaPrevista == null ||
    Number.isNaN(quantidadeDePerdaPrevista)
      ? 0
      : quantidadeDePerdaPrevista);

  return (
    <TabContainer>
      <Linha>
        <Coluna md={4}>
          <FormNumberBox
            name={nameOfOrdemDeProducao("quantidadeDePerdaPrevista")}
            titulo="Perda prevista"
            control={control}
            minimo={quantidadeDePerdaPrevistaMinima.toNumber()}
            maximo={quantidadeDePerdaPrevistaMaxima.toNumber()}
          />
        </Coluna>
        <Coluna md={4}>
          <FormTextBoxSimples
            titulo="Quantidade bruta"
            somenteLeitura
            valor={quantidadeBruta.toString()}
          />
        </Coluna>
      </Linha>
      <Linha>
        <Coluna md={12}>
          <FormTextArea
            name="observacoes"
            titulo={"Observações"}
            valor={""}
            control={control}
            height={25}
          />
        </Coluna>
      </Linha>
    </TabContainer>
  );
}
