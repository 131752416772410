import { useState } from "react";
import * as yup from "yup";
import ProvedorAjuda from "../../../../components/ajuda/provedor-ajuda";
import {
  FormFile,
  FormGrupo,
  FormTextBox,
} from "../../../../components/formularios";
import {
  FormBase2,
  FormularioEdicaoBaseProps,
} from "../../../../components/layout/form-base2";
import { Coluna, Linha } from "../../../../components/layout/grid-system";
import {
  useCarregarRegistro,
  useHookForms,
  useLimparFormSeIdForNaN,
} from "../../../../hooks/form.hooks";
import {
  CertificadoDigitalRequestDTO,
  CertificadoDigitalResponseDTO,
} from "../../../../models/api/certificado-digital/certificado-digital";
import AuditavelDTO from "../../../../models/api/comum/auditavel-dto";
import { CallBackModal } from "../../../../models/shared/ui/callback-modal";
import APICertificadoDigital from "../../../../services/certificado-digital/certificado-digital.service";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../services/comum/serviceBase";
import { checarResponse, tratarErroApi } from "../../../../utils/api/api-utils";
import NomesModais from "../../../../utils/common/nomes-modais";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../utils/common/notificacoes-utils";
import {
  descricaoTamanhoMaximo,
  extensoesAceitas,
  senhaTamanhoMaximo,
} from "./constantes";

const novoRegistro: CertificadoDigitalRequestDTO = {
  id: 0,
  descricao: null,
  senha: null,
  arquivo: null,
};

const nomeEndpoint = NomesEndpoints.CertificadoDigital;

export const EditFormCertificadoDigital = (
  props: FormularioEdicaoBaseProps
) => {
  const [carregando, setCarregando] = useState(false);
  const [dadosAuditoria, setDadosAuditoria] = useState<AuditavelDTO>();

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    descricao: yup.string().nullable().max(250),
    senha: yup.string().required().max(512),
    arquivo: yup.mixed<File>().required(),
  });

  const hookForm = useHookForms(schema);
  useCarregarRegistro(props.idRegistroEdicao, carregarTela);
  useLimparFormSeIdForNaN(hookForm, novoRegistro, props.idRegistroEdicao);

  const { register, control, handleSubmit, getValues, reset } = hookForm;

  async function carregarTela() {
    try {
      setCarregando(true);
      if (props.idRegistroEdicao > 0) {
        await carregarModel();
      } else {
        reset(novoRegistro);
        setDadosAuditoria(undefined);
      }
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  async function carregarModel() {
    try {
      const resposta =
        await APIBase.obterPorIdComDadosAuditoria<CertificadoDigitalResponseDTO>(
          props.idRegistroEdicao,
          nomeEndpoint
        );
      checarResponse(resposta);
      reset(resposta.model);
      setDadosAuditoria(resposta.model);
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues();
    try {
      const resposta = await APICertificadoDigital.uploadAsync(
        model as CertificadoDigitalRequestDTO
      );

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar({
          concluido: true,
          precisaAtualizar: true,
        });
      }
      fechar({
        concluido: true,
        precisaAtualizar: false,
      });
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  function fechar(info: CallBackModal) {
    if (props.callBackFecharModal) {
      props.callBackFecharModal(info);
    }
  }

  function handleCancelar() {
    setDadosAuditoria(undefined);
    fechar({ concluido: false, precisaAtualizar: false });
  }

  function callBackUnprocessableEntity() {
    fechar({
      concluido: false,
      precisaAtualizar: true,
    });
  }

  return (
    <>
      <FormBase2
        visivel={props.visivel}
        carregando={carregando}
        onClickSalvar={handleSubmit(handleSalvar)}
        onClickCancelar={handleCancelar}
        configuracoesModal={props.configuracoesModal}
        modoEdicao={props.idRegistroEdicao == 0 ? "criar" : "editar"}
        titulo={NomesModais.certificadoDigital}
        auditoria={dadosAuditoria}
      >
        <ProvedorAjuda id="edit-form-certificado-digital">
          <input type="hidden" {...register("id")} defaultValue={0} />
          <FormGrupo>
            <Linha>
              <Coluna md={12}>
                <FormTextBox
                  name="descricao"
                  titulo="Descrição"
                  control={control}
                  tamanhoMaximo={descricaoTamanhoMaximo}
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={5}>
                <FormTextBox
                  name="senha"
                  titulo="Senha"
                  control={control}
                  requerido
                  tipo="password"
                  tamanhoMaximo={senhaTamanhoMaximo}
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={5}>
                <FormFile
                  name="arquivo"
                  control={control}
                  extensoesAceitas={extensoesAceitas}
                  limparArquivosSelecionados={true}
                />
              </Coluna>
            </Linha>
          </FormGrupo>
        </ProvedorAjuda>
      </FormBase2>
    </>
  );
};

export default EditFormCertificadoDigital;
