import Pagina from "../../../components/layout/pagina";
import RequerPermissao from "../../../components/seguranca/requer-permissao";
import { PermissoesAdmin } from "../../../models/permissoes/seguranca/usuario/permissoes-admin";
import { GridUsuariosLogados } from "../../../parts/seguranca/controle-de-acesso/grid-usuarios-logados";
import NomesTelas from "../../../utils/common/nomes-telas";

export default function UsuariosLogadosPage() {
  return (
    <Pagina id={"usuarios-logados-page"} titulo={NomesTelas.usuariosLogados}>
      <RequerPermissao
        codigoPermissoes={[PermissoesAdmin.VisualizarUsuariosLogadosNoSistema]}
        ignoraSeForMaster
      >
        <GridUsuariosLogados />
      </RequerPermissao>
    </Pagina>
  );
}
