import { AxiosResponse } from "axios";
import { FieldValues, UseFormGetValues } from "react-hook-form";
import TabContainer from "../../../../../components/layout/tab-container";
import RequerPermissao from "../../../../../components/seguranca/requer-permissao";
import { ResponseBaseArquivo } from "../../../../../models/api/comum/response-base";
import { PermissoesOrdemDeProducao } from "../../../../../models/permissoes/producao/ordem-de-producao/OrdemDeProducaoPermissoes";
import { GridEmMemoriaArquivo } from "../../../../arquivo/grids/grid-em-memoria";

interface AnexosProps {
  idRegistro: number;
  getValues: UseFormGetValues<FieldValues>;
  funcaoParaBaixarAnexo: (
    idAnexo: number
  ) => Promise<AxiosResponse<ResponseBaseArquivo, any>>;
}

export default function OrdemDeProducaoAbaAnexos({
  idRegistro,
  getValues,
  funcaoParaBaixarAnexo,
}: AnexosProps) {
  return (
    <TabContainer>
      <RequerPermissao
        codigoPermissoes={[PermissoesOrdemDeProducao.ConsultarAnexos]}
      >
        <GridEmMemoriaArquivo
          idRegistro={idRegistro}
          arquivos={getValues().arquivos}
          funcaoParaBaixarAnexo={funcaoParaBaixarAnexo}
          permissaoIncluir={PermissoesOrdemDeProducao.InserirAnexos}
          permissaoExcluir={PermissoesOrdemDeProducao.ExcluirAnexos}
          permissaoVisualizarEBaixar={PermissoesOrdemDeProducao.ConsultarAnexos}
        />
      </RequerPermissao>
    </TabContainer>
  );
}
