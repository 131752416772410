import { useState } from "react";
import * as yup from "yup";
import ProvedorAjuda from "../../../../components/ajuda/provedor-ajuda";
import {
  FormNumberBox,
  FormSelectBox,
  FormTextBox,
} from "../../../../components/formularios";
import FormTextArea from "../../../../components/formularios/textarea";
import {
  FormBase2,
  FormularioEdicaoBaseProps,
} from "../../../../components/layout/form-base2";
import { Coluna, Linha } from "../../../../components/layout/grid-system";
import {
  useCarregarRegistro,
  useHookForms,
  useLimparFormSeIdForNaN,
} from "../../../../hooks/form.hooks";
import AuditavelDTO from "../../../../models/api/comum/auditavel-dto";
import {
  SerieFiscalRequestDTO,
  SerieFiscalResponseDTO,
  TipoDocumentoEletronico,
  tipoDocumentoEletronicoDecodificado,
} from "../../../../models/api/serie-fiscal/serie-fiscal";
import { SelectItemEnumTipoDocumentoEletronico } from "../../../../models/const/dicionario-combos/serie-fiscal";
import { CallBackModal } from "../../../../models/shared/ui/callback-modal";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../services/comum/serviceBase";
import { checarResponse, tratarErroApi } from "../../../../utils/api/api-utils";
import NomesModais from "../../../../utils/common/nomes-modais";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../utils/common/notificacoes-utils";
import { digitosNumericosSemEspaco } from "../../../../utils/common/regex-padrao";
import { NUMERO_INTEIRO_FORMATADOR } from "../../../../utils/formatadores/formatador-de-numeros";
import { observacoesTamanhoMaximo } from "./constantes";

const novoRegistro: SerieFiscalRequestDTO = {
  id: 0,
  serie: "",
  proximoNumero: 0,
  tipoDocumentoEletronico: TipoDocumentoEletronico.Nenhum,
  observacoes: null,
};

const tiposDocumentoParaCarecteresNumericosNaSerie: TipoDocumentoEletronico[] =
  [
    TipoDocumentoEletronico.MDFe,
    TipoDocumentoEletronico.NFe,
    TipoDocumentoEletronico.NFCe,
  ];

const nomeEndpoint = NomesEndpoints.SerieFiscal;

export const EditFormSerieFiscal = (props: FormularioEdicaoBaseProps) => {
  const [carregando, setCarregando] = useState(false);
  const [dadosAuditoria, setDadosAuditoria] = useState<AuditavelDTO>();

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    serie: yup
      .string()
      .transform((v) => (v ? v : undefined))
      .required()
      .when(["tipoDocumentoEletronico", "id"], (valores, schema) => {
        const tipoDocumentoEletronico = valores[0] as TipoDocumentoEletronico;
        const id = valores[1] as number;

        // Caso seja alteração, não é necessário validar tamanho máximo pois o campo é bloqueado
        if (id > 0) {
          return schema;
        }

        if (
          tipoDocumentoEletronico ==
            TipoDocumentoEletronico.ConformeOperacaoFiscal ||
          tipoDocumentoEletronico == TipoDocumentoEletronico.Nenhum
        ) {
          return schema.max(4);
        }

        schema = schema.max(3);

        if (
          tiposDocumentoParaCarecteresNumericosNaSerie.includes(
            tipoDocumentoEletronico
          )
        ) {
          schema = schema.matches(
            new RegExp(digitosNumericosSemEspaco),
            "Preencha apenas com números para este tipo de documento."
          );
        }

        return schema;
      }),
    proximoNumero: yup
      .number()
      .transform((v) => (v ? v : undefined))
      .required()
      .min(1)
      .max(9_999_999_999),
    tipoDocumentoEletronico: yup
      .mixed<TipoDocumentoEletronico>()
      .oneOf(
        Object.values(TipoDocumentoEletronico).map((x) => x as number),
        "Valor inválido"
      )
      .required()
      .test(
        "testTipoParaSerie",
        `Para série que possui 4 caracteres, só é permitido "${
          tipoDocumentoEletronicoDecodificado[TipoDocumentoEletronico.Nenhum]
        }" ou "${
          tipoDocumentoEletronicoDecodificado[
            TipoDocumentoEletronico.ConformeOperacaoFiscal
          ]
        }"`,
        function (value) {
          if (!this.parent.serie || this.parent.serie.length != 4) {
            return true;
          }

          return (
            this.parent.serie.length == 4 &&
            (value == TipoDocumentoEletronico.Nenhum ||
              value == TipoDocumentoEletronico.ConformeOperacaoFiscal)
          );
        }
      ),
  });

  const hookForm = useHookForms(schema);
  useCarregarRegistro(props.idRegistroEdicao, carregarTela);
  useLimparFormSeIdForNaN(hookForm, novoRegistro, props.idRegistroEdicao);

  const { register, control, handleSubmit, getValues, reset, watch } = hookForm;

  async function carregarTela() {
    try {
      setCarregando(true);
      if (props.idRegistroEdicao > 0) {
        await carregarModel();
      } else {
        reset(novoRegistro);
        setDadosAuditoria(undefined);
      }
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  async function carregarModel() {
    try {
      const resposta =
        await APIBase.obterPorIdComDadosAuditoria<SerieFiscalResponseDTO>(
          props.idRegistroEdicao,
          nomeEndpoint
        );
      checarResponse(resposta);
      reset(resposta.model);
      setDadosAuditoria(resposta.model);
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    }
  }

  function fechar(info: CallBackModal) {
    if (props.callBackFecharModal) {
      props.callBackFecharModal(info);
    }
  }

  function handleCancelar() {
    setDadosAuditoria(undefined);
    fechar({ concluido: false, precisaAtualizar: false });
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues();
    try {
      const resposta =
        props.idRegistroEdicao > 0
          ? await APIBase.atualizar(model, nomeEndpoint)
          : await APIBase.cadastrar(model, nomeEndpoint);

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar({
          concluido: true,
          precisaAtualizar: true,
        });
      }
      fechar({
        concluido: true,
        precisaAtualizar: false,
      });
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  function callBackUnprocessableEntity() {
    fechar({
      concluido: false,
      precisaAtualizar: true,
    });
  }

  const serieSomenteLeitura = watch("id") > 0;

  return (
    <>
      <div id="edit-form-serie-fiscal">
        <FormBase2
          visivel={props.visivel}
          carregando={carregando}
          onClickSalvar={handleSubmit(handleSalvar)}
          onClickCancelar={handleCancelar}
          configuracoesModal={props.configuracoesModal}
          modoEdicao={props.idRegistroEdicao == 0 ? "criar" : "editar"}
          titulo={NomesModais.serieDeDocumentosFiscais}
          isNomeTelaFeminino={true}
          auditoria={dadosAuditoria}
        >
          <ProvedorAjuda id="edit-form-serie-fiscal">
            <input type="hidden" {...register("id")} defaultValue={0} />
            <Linha>
              <Coluna md={6}>
                <FormTextBox
                  name="serie"
                  titulo={"Série"}
                  requerido
                  tamanhoMaximo={4}
                  control={control}
                  somenteLeitura={serieSomenteLeitura}
                />
              </Coluna>
              <Coluna md={6}>
                <FormNumberBox
                  name="proximoNumero"
                  titulo="Próximo número"
                  control={control}
                  requerido
                  formato={NUMERO_INTEIRO_FORMATADOR}
                  minimo={1}
                  maximo={9_999_999_999}
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={12}>
                <FormSelectBox
                  name="tipoDocumentoEletronico"
                  titulo="Tipo de documento"
                  control={control}
                  requerido
                  dataSource={SelectItemEnumTipoDocumentoEletronico}
                  habilitaBusca
                  tipoBusca="contains"
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={12}>
                <FormTextArea
                  name="observacoes"
                  titulo={"Observações"}
                  valor={""}
                  control={control}
                  tamanhoMaximo={observacoesTamanhoMaximo}
                  height={25}
                />
              </Coluna>
            </Linha>
          </ProvedorAjuda>
        </FormBase2>
      </div>
    </>
  );
};
