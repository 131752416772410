import { yupResolver } from "@hookform/resolvers/yup";
import { HttpStatusCode } from "axios";
import { useState } from "react";
import { renderToString } from "react-dom/server";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import {
  FormCheckBox,
  FormDateBox,
  FormTextBox,
} from "../../../../../components/formularios";
import {
  FormBase2,
  FormularioEdicaoBaseProps,
} from "../../../../../components/layout/form-base2";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import { RodapeAuditoriaContainer } from "../../../../../components/layout/rodape-auditoria/styles";
import {
  useHookForms,
  useLimparFormSeIdForNaN,
} from "../../../../../hooks/form.hooks";
import { useAppSelector } from "../../../../../hooks/store.hooks";
import {
  InformacoesApuracoesDivergentesLucroPresumidoResponse,
  InformacoesApuracoesDivergentesLucroRealResponse,
} from "../../../../../models/api/ecf/ecf";
import { GerarLucroPresumidoRequest } from "../../../../../models/api/ecf/lucro-presumido/lucro-presumido";
import { RegimeTributarioTipo } from "../../../../../models/api/empresa/empresa";
import { CallBackModal } from "../../../../../models/shared/ui/callback-modal";
import { EcfService } from "../../../../../services/contabilidade/ecf/ecf.service";
import {
  checarResponseBaixarArquivo,
  tratarErroApiComoModal,
} from "../../../../../utils/api/api-utils";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { exibirConfirmacao } from "../../../../../utils/dialogos";

const novoRegistro: GerarLucroPresumidoRequest = {
  dataInicial: "",
  dataFinal: "",
  arquivoRetificado: null,
  numeroDoReciboAnterior: null,
};

const service = new EcfService();

export const EditFormEcfLucroPresumido = (props: FormularioEdicaoBaseProps) => {
  const [carregando, setCarregando] = useState(false);
  const regimeEmpresa = useAppSelector(
    (state) => state.sessao.dadosSessao?.empresa.regimeTributario
  );

  const schema = yup.object().shape({
    dataInicial: yup
      .string()
      .required()
      .test(
        "data_inicial_valida",
        "A data inicial está maior do que a final.",
        function (value, contexto) {
          if (value && contexto.parent.dataFinal) {
            const dataInicial = new Date(value);
            const dataFinal = new Date(contexto.parent.dataFinal);
            return dataInicial <= dataFinal;
          }
          return true;
        }
      ),
    dataFinal: yup
      .string()
      .required()
      .test(
        "data_final_valida",
        "A data final está menor do que a inicial.",
        function (valor, contexto) {
          if (valor && contexto.parent.dataInicial) {
            const dataInicial = new Date(contexto.parent.dataInicial);
            const dataFinal = new Date(valor);
            return dataInicial <= dataFinal;
          }
          return true;
        }
      ),
    numeroDoReciboAnterior: yup.string().max(41),
  });

  const hookForm = useHookForms(schema);
  useLimparFormSeIdForNaN(hookForm, novoRegistro, props.idRegistroEdicao);

  const { getValues, control, handleSubmit } =
    useForm<GerarLucroPresumidoRequest>({
      mode: "onChange",
      reValidateMode: "onChange",
      resolver: yupResolver(schema),
    });

  function fechar(info: CallBackModal) {
    if (props.callBackFecharModal) {
      props.callBackFecharModal(info);
    }
  }

  function handleCancelar() {
    fechar({ concluido: false, precisaAtualizar: false });
  }

  async function GerarArquivoRegimeLucroPresumidoQuestionandoInformacoesDivergentes(
    infos: InformacoesApuracoesDivergentesLucroPresumidoResponse
  ) {
    const model = getValues();
    const mensagem = renderToString(
      <>
        A forma de apuração de sua empresa está configurada como &quot;
        {infos.periodoDeApuracao}&quot; e não foram encontradas{" "}
        {infos.quantidadeDeApuracoesEsperadas} apurações para o período
        informado. Verifique abaixo quais foram as apurações inconsistentes:
        <ul>
          <li>
            Foram encontradas {infos.quantidadeApuracoesIrpjEncontradas}{" "}
            apurações de IRPJ.
          </li>
          <li>
            Foram encontradas {infos.quantidadeApuracoesCsllEncontradas}{" "}
            apurações de CSLL.
          </li>
        </ul>
        A falta de todas as apurações pode causar inconsistências no momento da
        validação do arquivo no validador da ECF da Receita Federal. Tem certeza
        de que deseja continuar?
      </>
    );

    const usuarioVaiContinuar = await exibirConfirmacao("Atenção", mensagem);

    if (usuarioVaiContinuar) {
      const { response, nomeArquivo, extensao } =
        await service.ObterArquivoIgnorandoDivergencias(model);
      const sucesso = checarResponseBaixarArquivo(
        response,
        nomeArquivo ?? NomesTelas.ecf,
        extensao
      );

      if (sucesso) {
        exibirNotificacaoToast({
          mensagem: "Arquivo da ECF gerado com sucesso.",
          tipo: TipoNotificacao.Sucesso,
        });
      }
    }
  }

  async function GerarArquivoRegimeLucroRealQuestionandoInformacoesDivergentes(
    infos: InformacoesApuracoesDivergentesLucroRealResponse
  ) {
    const model = getValues();
    const mensagem = renderToString(
      <>
        A forma de apuração de sua empresa está configurada como &quot;
        {infos.periodoDeApuracao}&quot; e não foram encontradas{" "}
        {infos.quantidadeDeApuracoesEsperadas} apurações para o período
        informado. Verifique abaixo quais foram as apurações inconsistentes:
        <ul>
          <li>
            Foram encontradas {infos.quantidadeApuracoesLalurEncontradas}{" "}
            apurações de LALUR e IRPJ.
          </li>
          <li>
            Foram encontradas {infos.quantidadeApuracoesLacsEncontradas}{" "}
            apurações de LACS e CSLL.
          </li>
          <li>
            Foram encontradas{" "}
            {infos.quantidadeApuracoesInformativoDeCustosEncontradas} do
            informativo da composição de custos.
          </li>
        </ul>
        A falta de todas as apurações pode causar inconsistências no momento da
        validação do arquivo no validador da ECF da Receita Federal. Tem certeza
        de que deseja continuar?
      </>
    );

    const usuarioVaiContinuar = await exibirConfirmacao("Atenção", mensagem);

    if (usuarioVaiContinuar) {
      const { response, nomeArquivo, extensao } =
        await service.ObterArquivoIgnorandoDivergencias(model);
      const sucesso = checarResponseBaixarArquivo(
        response,
        nomeArquivo ?? NomesTelas.ecf,
        extensao
      );

      if (sucesso) {
        exibirNotificacaoToast({
          mensagem: "Arquivo da ECF gerado com sucesso.",
          tipo: TipoNotificacao.Sucesso,
        });
      }
    }
  }

  async function handleGerar() {
    setCarregando(true);
    const model = getValues();
    try {
      const { response, nomeArquivo, extensao } = await service.ObterArquivo(
        model
      );

      const sucesso = checarResponseBaixarArquivo(
        response,
        nomeArquivo ?? NomesTelas.ecf,
        extensao
      );

      if (sucesso) {
        exibirNotificacaoToast({
          mensagem: "Arquivo da ECF gerado com sucesso.",
          tipo: TipoNotificacao.Sucesso,
        });
      }
    } catch (erro: any) {
      if (erro?.response?.status != HttpStatusCode.PreconditionRequired) {
        tratarErroApiComoModal(erro, callBackUnprocessableEntity);
        return;
      }

      if (regimeEmpresa == RegimeTributarioTipo.LucroPresumido) {
        await GerarArquivoRegimeLucroPresumidoQuestionandoInformacoesDivergentes(
          erro.response!
            .data as InformacoesApuracoesDivergentesLucroPresumidoResponse
        );
      } else if (regimeEmpresa == RegimeTributarioTipo.LucroReal) {
        await GerarArquivoRegimeLucroRealQuestionandoInformacoesDivergentes(
          erro.response!
            .data as InformacoesApuracoesDivergentesLucroRealResponse
        );
      }
    } finally {
      setCarregando(false);
    }
  }

  function callBackUnprocessableEntity() {
    fechar({
      concluido: false,
      precisaAtualizar: true,
    });
  }

  return (
    <>
      <div id="edit-form-ecf-gerar-arquivo">
        <FormBase2
          visivel={props.visivel}
          carregando={carregando}
          onClickSalvar={handleSubmit(handleGerar)}
          onClickCancelar={handleCancelar}
          configuracoesModal={props.configuracoesModal}
          modoEdicao={"não aplicável"}
          titulo={NomesTelas.ecf}
          botaoSalvar={{
            texto: "Gerar ECF",
            icon: "ic-material-symbols-outlined ic-file-save icone-linha-grid",
          }}
        >
          <ProvedorAjuda id="edit-form-ecf-gerar-arquivo">
            <Linha>
              <Coluna md={3}>
                <FormDateBox
                  name="dataInicial"
                  titulo="Período de"
                  requerido
                  control={control}
                  exibirBotaoLimpar
                  tipo="date"
                  aceitaValorCustomizado={true}
                  formatoExibicao="dd/MM/yy"
                  aceitaDigitacaoComMascara={true}
                />
              </Coluna>
              <Coluna md={3}>
                <FormDateBox
                  name="dataFinal"
                  titulo="a"
                  control={control}
                  requerido
                  exibirBotaoLimpar
                  tipo="date"
                  aceitaValorCustomizado={true}
                  formatoExibicao="dd/MM/yy"
                  aceitaDigitacaoComMascara={true}
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={4}>
                <FormCheckBox
                  name="arquivoRetificado"
                  titulo="Arquivo retificador"
                  defaultValue={false}
                  control={control}
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={7}>
                <FormTextBox
                  name="numeroDoReciboAnterior"
                  titulo="Número do recibo anterior"
                  control={control}
                  tamanhoMaximo={41}
                />
              </Coluna>
            </Linha>
            <RodapeAuditoriaContainer>
              O arquivo será salvo conforme as configurações do seu navegador.
              <br />
              Importe o arquivo baixado no validador da Receita Federal e
              recupere os dados a partir do arquivo da ECD (SPED Contábil).
            </RodapeAuditoriaContainer>
          </ProvedorAjuda>
        </FormBase2>
      </div>
    </>
  );
};
