import DataSource from "devextreme/data/data_source";
import { useState } from "react";
import * as yup from "yup";
import ProvedorAjuda from "../../../../components/ajuda/provedor-ajuda";
import {
  FormGrupo,
  FormNumberBox,
  FormSelectBox,
  FormSelectBoxLazy,
  FormTextBox,
  FormTextBoxSimples,
} from "../../../../components/formularios";
import {
  FormBase2,
  FormularioEdicaoBaseProps,
} from "../../../../components/layout/form-base2";
import { Coluna, Linha } from "../../../../components/layout/grid-system";
import useEffectOnLoad from "../../../../hooks/effect.hooks";
import {
  useCarregarRegistro,
  useHookForms,
  useLimparFormSeIdForNaN,
} from "../../../../hooks/form.hooks";
import { useAppSelector } from "../../../../hooks/store.hooks";
import AuditavelDTO from "../../../../models/api/comum/auditavel-dto";
import { SelectBoxLazyFiltros } from "../../../../models/api/comum/selectboxlazy-options";
import {
  TipoCarroceria,
  TipoProprietario,
  TipoRodado,
  TipoUnidadeTransporte,
  VeiculoRequestDTO,
  VeiculoResponseDTO,
} from "../../../../models/api/veiculo/veiculo";
import {
  SelectItemEnumTipoCarroceria,
  SelectItemEnumTipoProprietario,
  SelectItemEnumTipoRodado,
  SelectItemEnumTipoUnidadeTransporte,
} from "../../../../models/const/dicionario-combos/veiculo";
import { CallBackModal } from "../../../../models/shared/ui/callback-modal";
import SelectItem from "../../../../models/shared/ui/select-item";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../services/comum/serviceBase";
import APIEmpresa from "../../../../services/empresa/empresa.service";
import APIUF from "../../../../services/uf/uf.service";
import { checarResponse, tratarErroApi } from "../../../../utils/api/api-utils";
import NomesModais from "../../../../utils/common/nomes-modais";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../utils/common/notificacoes-utils";
import { obterFormatStringNumero } from "../../../../utils/formatadores/formatador-de-numeros";

const novoRegistro: VeiculoRequestDTO = {
  id: 0,
  descricao: "",
  tipoRodado: TipoRodado.Outros,
  placa: "",
  renavam: null,
  idUF: 0,
  tara: null,
  capacidadeKG: null,
  capacidadeM3: null,
  tipoCarroceria: TipoCarroceria.Aberta,
  unidadeTransporte: TipoUnidadeTransporte.Outros,
  tipoProprietario: null,
  idProprietario: null,
};

const nomeEndpointEmpresa = NomesEndpoints.Empresa;
const nomeEndpoint = NomesEndpoints.Veiculo;

const PLACA_TAMANHO = 7;

export const EditFormVeiculo = (props: FormularioEdicaoBaseProps) => {
  const [carregando, setCarregando] = useState(false);
  const [dadosAuditoria, setDadosAuditoria] = useState<AuditavelDTO>();
  const [ufs, setUf] = useState<SelectItem[]>([]);
  const [proprietarios, setProprietario] = useState<DataSource>();
  const [rntrcProprietario, setRntrcProprietario] = useState<string>("");
  const idEmpresa = useAppSelector(
    (state) => state.sessao.dadosSessao?.empresa.id
  );

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    descricao: yup.string().required().max(120),
    tipoRodado: yup
      .mixed<TipoRodado>()
      .transform((v) => (v ? v : null))
      .oneOf(
        Object.values(TipoRodado).map((x) => x as number),
        "Valor inválido"
      )
      .required(),
    placa: yup.string().required().min(PLACA_TAMANHO).max(PLACA_TAMANHO),
    renavam: yup
      .string()
      .nullable()
      .matches(/.{9,}/, {
        excludeEmptyString: true,
        message: "Mínimo de 9 caracteres",
      })
      .max(11),
    idUF: yup
      .number()
      .required()
      .positive()
      .integer()
      .transform((v) => (v ? v : null))
      .oneOf(
        ufs.map((x) => x.valor as number),
        "Valor inválido"
      ),
    tara: yup
      .number()
      .integer()
      .moreThan(-1)
      .transform((v) => (v >= 0 ? v : undefined))
      .test("tara_test", "Campo obrigatório.", function (value) {
        if (!value && value != 0) {
          return false;
        }
        return value >= 0;
      }),
    capacidadeKG: yup.number().when("unidadeTransporte", {
      is: TipoUnidadeTransporte.RodoviarioTracao,
      then: (schema) =>
        schema
          .required()
          .transform((v) => (v ? v : null))
          .integer()
          .moreThan(-1),
      otherwise: (schema) => schema.notRequired().nullable(),
    }),
    capacidadeM3: yup.number().nullable().integer().moreThan(-1),
    tipoCarroceria: yup
      .mixed<TipoCarroceria>()
      .transform((v) => (v >= 0 ? v : null))
      .oneOf(
        Object.values(TipoCarroceria).map((x) => x as number),
        "Valor inválido"
      )
      .required(),
    unidadeTransporte: yup
      .mixed<TipoUnidadeTransporte>()
      .transform((v) => (v ? v : null))
      .oneOf(
        Object.values(TipoUnidadeTransporte).map((x) => x as number),
        "Valor inválido"
      )
      .required(),
    tipoProprietario: yup
      .mixed<TipoProprietario>()
      .transform((v) => (v >= 0 ? v : null))
      .oneOf(
        Object.values(TipoProprietario).map((x) => x as number),
        "Valor inválido"
      )
      .nullable()
      .test(
        "tipoProprietario_idProprietario",
        'Obrigatório, pois o "Proprietário" está preenchido.',
        function (value) {
          return !(this.parent.idProprietario != null && value == null);
        }
      ),
    idProprietario: yup
      .number()
      .notRequired()
      .nullable()
      .positive()
      .test(
        "idProprietario_tipoProprietario",
        'Obrigatório, pois "Tipo de proprietário" está preenchido.',
        function (value) {
          return !(value == null && this.parent.tipoProprietario != null);
        }
      ),
  });

  const hookForm = useHookForms(schema);
  useCarregarRegistro(props.idRegistroEdicao, carregarTela);
  useLimparFormSeIdForNaN(hookForm, novoRegistro, props.idRegistroEdicao);

  const { register, control, handleSubmit, getValues, reset, watch } = hookForm;

  useEffectOnLoad(() => {
    carregarUfs();
    carregarProprietarios();
  });

  async function carregarTela() {
    try {
      setCarregando(true);
      if (props.idRegistroEdicao > 0) {
        await carregarModel();
      } else {
        reset(novoRegistro);
        setDadosAuditoria(undefined);
      }
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  async function carregarModel() {
    try {
      const resposta =
        await APIBase.obterPorIdComDadosAuditoria<VeiculoResponseDTO>(
          props.idRegistroEdicao,
          nomeEndpoint
        );
      checarResponse(resposta);
      reset(resposta.model);
      setDadosAuditoria(resposta.model);
      if (resposta.model.idProprietario) {
        carregarRntrc(resposta.model.idProprietario);
      }
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues();
    try {
      const resposta =
        props.idRegistroEdicao > 0
          ? await APIBase.atualizar(model, nomeEndpoint)
          : await APIBase.cadastrar(model, nomeEndpoint);

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar({
          concluido: true,
          precisaAtualizar: true,
        });
      }
      fechar({
        concluido: true,
        precisaAtualizar: false,
      });
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  function fechar(info: CallBackModal) {
    if (props.callBackFecharModal) {
      props.callBackFecharModal(info);
    }
  }

  function handleCancelar() {
    setDadosAuditoria(undefined);
    fechar({ concluido: false, precisaAtualizar: false });
  }

  function callBackUnprocessableEntity() {
    fechar({
      concluido: false,
      precisaAtualizar: true,
    });
  }

  async function carregarUfs() {
    try {
      const resposta = await APIUF.obterListagemSimples();
      checarResponse(resposta);
      setUf(
        resposta.model.map((x) => ({
          valor: x.valor,
          descricao: x.descricao,
        }))
      );
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  function carregarProprietarios(
    filtrosAdicionais: SelectBoxLazyFiltros[] = []
  ) {
    const filtros: SelectBoxLazyFiltros[] = [
      { nomeCampo: "Transportadora", operador: "=", valor: true },
    ];

    for (const filtro of filtrosAdicionais) {
      filtros.push(filtro);
    }

    setProprietario(
      APIBase.getDataSourceSelectBoxLazy(
        {
          camposRetorno: ["Id", "Apelido", "RntrcProprietario"],
          filtros: filtros,
          camposOrdenacao: [
            {
              nomeCampo: "Apelido",
              desc: false,
            },
          ],
        },
        nomeEndpointEmpresa
      )
    );
  }

  async function carregarRntrc(idProprietario: number) {
    try {
      const resposta = await APIEmpresa.obterRntrc(idProprietario);
      checarResponse(resposta);
      setRntrcProprietario(resposta.model != null ? resposta.model : "");
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  const onProprietarioChanged = (e: any) => {
    setRntrcProprietario(
      e?.selectedItem?.RntrcProprietario != null
        ? e.selectedItem.RntrcProprietario
        : ""
    );
  };

  function atualizarProprietarios(e: number) {
    const unidade = e;

    if (unidade !== TipoUnidadeTransporte.RodoviarioTracao) {
      carregarProprietarios();
      return;
    }
    carregarProprietarios([
      { nomeCampo: "Id", operador: "<>", valor: idEmpresa?.toString() ?? "" },
    ]);
  }

  return (
    <>
      <FormBase2
        visivel={props.visivel}
        carregando={carregando}
        onClickSalvar={handleSubmit(handleSalvar)}
        onClickCancelar={handleCancelar}
        configuracoesModal={props.configuracoesModal}
        modoEdicao={props.idRegistroEdicao == 0 ? "criar" : "editar"}
        titulo={NomesModais.veiculo}
        auditoria={dadosAuditoria}
      >
        <ProvedorAjuda id="edit-form-veiculo">
          <input type="hidden" {...register("id")} defaultValue={0} />
          <FormGrupo>
            <Linha>
              <Coluna md={8}>
                <FormTextBox
                  name="descricao"
                  titulo="Descrição"
                  control={control}
                  requerido
                  tamanhoMaximo={120}
                />
              </Coluna>
              <Coluna md={4}>
                <FormTextBox
                  name="placa"
                  titulo="Placa"
                  control={control}
                  requerido
                  tamanhoMaximo={7}
                  mask="LLL 0A00"
                  transform="uppercase"
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={6}>
                <FormSelectBox
                  name="idUF"
                  titulo="UF de Licenciamento"
                  control={control}
                  requerido
                  dataSource={ufs}
                  habilitaBusca
                  tipoBusca="contains"
                />
              </Coluna>
              <Coluna md={6}>
                <FormTextBox
                  name="renavam"
                  titulo="Renavam"
                  control={control}
                  tamanhoMaximo={11}
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={4}>
                <FormNumberBox
                  name="tara"
                  titulo="Tara"
                  toolTip="Tara"
                  control={control}
                  requerido
                  minimo={0}
                  maximo={999999}
                  formato={obterFormatStringNumero(0)}
                />
              </Coluna>
              <Coluna md={4}>
                <FormNumberBox
                  name="capacidadeKG"
                  titulo="Capacidade (kg)"
                  control={control}
                  requerido={
                    watch("unidadeTransporte") ==
                    TipoUnidadeTransporte.RodoviarioTracao
                  }
                  maximo={999999}
                  minimo={0}
                  formato={obterFormatStringNumero(0)}
                  quantidadeIncrementarDecrementar={1}
                />
              </Coluna>
              <Coluna md={4}>
                <FormNumberBox
                  name="capacidadeM3"
                  titulo="Capacidade (m³)"
                  control={control}
                  minimo={0}
                  maximo={999}
                  formato={obterFormatStringNumero(0)}
                  quantidadeIncrementarDecrementar={1}
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={6}>
                <FormSelectBox
                  name="tipoRodado"
                  titulo="Tipo de rodado"
                  control={control}
                  requerido
                  dataSource={SelectItemEnumTipoRodado}
                  habilitaBusca
                  tipoBusca="contains"
                />
              </Coluna>
              <Coluna md={6}>
                <FormSelectBox
                  name="tipoCarroceria"
                  titulo="Tipo de carroceria"
                  control={control}
                  requerido
                  dataSource={SelectItemEnumTipoCarroceria}
                  habilitaBusca
                  tipoBusca="contains"
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={6}>
                <FormSelectBox
                  name="unidadeTransporte"
                  titulo="Unidade de transporte"
                  control={control}
                  requerido
                  dataSource={SelectItemEnumTipoUnidadeTransporte}
                  habilitaBusca
                  tipoBusca="contains"
                  onValueChange={atualizarProprietarios}
                />
              </Coluna>
              <Coluna md={6}>
                <FormSelectBox
                  name="tipoProprietario"
                  titulo="Tipo de proprietário"
                  control={control}
                  dataSource={SelectItemEnumTipoProprietario}
                  habilitaBusca
                  requerido={watch("idProprietario") != null}
                  tipoBusca="contains"
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={7}>
                <FormSelectBoxLazy
                  name="idProprietario"
                  titulo="Proprietário"
                  nomeCampoChave="Id"
                  nomeCampoExibicao="Apelido"
                  control={control}
                  requerido={watch("tipoProprietario") != null}
                  dataSource={proprietarios}
                  labelSemDados="Sem dados"
                  onSelectionChanged={onProprietarioChanged}
                />
              </Coluna>
              <Coluna md={5}>
                <FormTextBoxSimples
                  valor={rntrcProprietario}
                  titulo="RNTRC do proprietário"
                  somenteLeitura={true}
                />
              </Coluna>
            </Linha>
          </FormGrupo>
        </ProvedorAjuda>
      </FormBase2>
    </>
  );
};

export default EditFormVeiculo;
