import { StatusTransmissaoEfdReinf } from "../../../models/api/efd-reinf/efd-reinf";

export function verificaStatusDoRegistroParaConsulta(
  status?: StatusTransmissaoEfdReinf | null
) {
  return (
    status &&
    (status == StatusTransmissaoEfdReinf.EmProcessamento ||
      status == StatusTransmissaoEfdReinf.Sucesso)
  );
}
