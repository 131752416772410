import { useState } from "react";
import Pagina from "../../../components/layout/pagina";
import RequerPermissao from "../../../components/seguranca/requer-permissao";
import { PermissoesInformativoComposicaoCustosLucroReal } from "../../../models/permissoes/fiscal/informativo-composicao-custos-lucro-real/permissoes-informativo-composicao-lucro-real";
import { GridInformativoComposicaoCustosLucroReal } from "../../../parts/fiscal/informativo-composicao-custos-lucro-real/grid";
import NomesTelas from "../../../utils/common/nomes-telas";
import { ItemContextMenu } from "../../../utils/context-menu/context-menu-utils";

export default function InformativoComposicaoCustosLucroRealPage() {
  const [menus, setMenus] = useState<ItemContextMenu[]>();

  return (
    <Pagina
      id="informativo-composicao-custos-lucro-real-page"
      area={"Fiscal > Apurações"}
      titulo={NomesTelas.informativoDaComposicaoDeCustosDoLucroReal}
      subMenuItems={menus}
    >
      <RequerPermissao
        codigoPermissoes={[
          PermissoesInformativoComposicaoCustosLucroReal.Consultar,
        ]}
      >
        <GridInformativoComposicaoCustosLucroReal definirMenu={setMenus} />
      </RequerPermissao>
    </Pagina>
  );
}
