import { Button } from "devextreme-react";
import ArrayStore from "devextreme/data/array_store";
import { useContext, useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../../hooks/store.hooks";
import {
  EstoqueGridModelSelecionar,
  EstoqueSelecionadoParaBaixaDeInsumo,
  EstoqueSituacao,
} from "../../../models/api/estoque/estoque";
import { IGridSelecao } from "../../../models/shared/ui/formularios";
import { GridSelecaoEstoque } from "../../../parts/estoque/estoque/grids/grid-selecao-de-estoque";
import { InsumoDaOrdemDeProducaoService } from "../../../services/insumo-da-ordem-de-producao/insumo-da-ordem-de-producao";
import { bloquearUI, desbloquearUI } from "../../../store/ui/ui.slice";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../utils/common/notificacoes-utils";
import { exibirAlerta } from "../../../utils/dialogos";
import FormBase from "../../layout/form-base";
import { Modal } from "../../layout/modal";
import ContextoBaixarInsumo from "../contexto-baixar-insumo";

const APIInsumos = new InsumoDaOrdemDeProducaoService();

export interface ModalBaixarInsumosProps {
  popupVisivel: boolean;
  dados: ArrayStore;
  insumoId: number;
}

export const ModalBaixarInsumo = () => {
  const gridSelecaoEstoqueRef = useRef<IGridSelecao>(null);
  const [selecionarEstoqueVisisvel, setSelecionarEstoqueVisisvel] =
    useState(false);
  const [idInsumoParaBaixar, setIdInsumoParaBaixar] = useState(NaN);
  const [dataSourceSelecionarEstoque, setDataSourceSelecionarEstoque] =
    useState<ArrayStore>(new ArrayStore());

  const dispatch = useAppDispatch();

  const { funcoes } = useContext(ContextoBaixarInsumo);

  useEffect(() => {
    funcoes.definfirSetModalBaixarInsumosProps((prop) => {
      setDataSourceSelecionarEstoque(prop.dados);
      setSelecionarEstoqueVisisvel(prop.popupVisivel);
      setIdInsumoParaBaixar(prop.insumoId);
    });
  }, []);

  const fecharModal = () => {
    setSelecionarEstoqueVisisvel(false);
    gridSelecaoEstoqueRef?.current?.getGridRef().instance.cancelEditData();
    setIdInsumoParaBaixar(NaN);
    setDataSourceSelecionarEstoque(
      new ArrayStore({
        data: [],
        key: "id",
      })
    );
  };

  async function baixarEstoquesSelecionadosSemForcarConclusao() {
    await baixarEstoquesSelecionados(false);
  }

  async function baixarEstoquesSelecionadosForcandoConclusao() {
    await baixarEstoquesSelecionados(true);
  }

  async function baixarEstoquesSelecionados(forcarConclusao: boolean) {
    // Verifica se a referência do grid está disponível
    const gridRef = gridSelecaoEstoqueRef.current;
    if (!gridRef) {
      exibirNotificacaoToast({
        mensagem: "Não foi possível carregar os estoques.",
        tipo: TipoNotificacao.Erro,
      });
      fecharModal();
      return;
    }

    // Bloqueia a UI e extrai dados do grid
    dispatch(bloquearUI("Carregando..."));

    const estoqueParaBaixar = gridRef
      .getGridRef()
      .instance.getVisibleRows()
      .map((row) => row.data as EstoqueGridModelSelecionar)
      .filter(
        (data) =>
          data.quantidadeMovimentar > 0 &&
          data.situacaoEstoque !== EstoqueSituacao.Indisponivel
      )
      .map(
        (data) =>
          new EstoqueSelecionadoParaBaixaDeInsumo(
            data.id,
            data.idReserva,
            data.quantidadeMovimentar
          )
      );

    try {
      // Chama a API e processa a resposta
      const resposta = await APIInsumos.BaixarEstoquesSelecionadosParaInsumo(
        idInsumoParaBaixar,
        estoqueParaBaixar,
        forcarConclusao
      );

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
      }
    } catch (error) {
      // Trata erros na chamada da API
      exibirAlerta("Erro", "Não foi possível realizar a operação.");
    } finally {
      // Desbloqueia a UI e fecha o modal independentemente do resultado
      dispatch(desbloquearUI());
      fecharModal();
    }
  }

  return (
    <Modal
      titulo={"Seleção de estoque para baixa de insumos da OP"}
      visivel={selecionarEstoqueVisisvel}
      onFechar={fecharModal}
    >
      <FormBase
        formId={"form-seletor-estoque"}
        modal
        onClickCancelar={fecharModal}
        onClickSalvar={baixarEstoquesSelecionadosSemForcarConclusao}
        botaoSalvar={{
          texto: "Confirmar",
          icon: "ic-material-symbols-outlined ic-download",
        }}
        botoesAdicionais={[
          <Button
            key="btn-forcar-baixa"
            type="success"
            text="Confirmar e forçar conclusão"
            onClick={baixarEstoquesSelecionadosForcandoConclusao}
            icon="ic-material-symbols-outlined ic-task-alt"
          />,
        ]}
      >
        <GridSelecaoEstoque
          ref={gridSelecaoEstoqueRef}
          dataSource={dataSourceSelecionarEstoque}
        ></GridSelecaoEstoque>
      </FormBase>
    </Modal>
  );
};
