import { IColumnProps } from "devextreme-react/data-grid";
import { naoPorExtenso, simPorExtenso } from "../common/constantes";
import {
  formatarNumero,
  formatarValorContabilComSufixo,
} from "../formatadores/formatador-de-numeros";
import {
  formatarCNPJ,
  formatarCPF,
} from "../formatadores/formatador-de-strings";

const DataAnoCurtoComHoraMinutoSegundos = "dd/MM/yy HH:mm:ss";
const DataAnoCurtoSemHora = "dd/MM/yy";

const Padroes = {
  id: ensureType({
    caption: "ID",
    width: 50,
    allowResizing: true,
    dataType: "number",
    visible: false,
  }),
  idPropridadeDeNavegacaoNaoVisivel: ensureType({
    caption: "",
    width: 0,
    allowResizing: false,
    visible: false,
    dataType: "number",
    showInColumnChooser: false,
  }),
  apenasParaFiltro: ensureType({
    caption: "",
    width: 0,
    allowResizing: false,
    visible: false,
    dataType: "number",
    showInColumnChooser: false,
  }),
  acoes: ensureType({
    caption: "Ações",
    allowResizing: false,
    allowFiltering: false,
    allowExporting: false,
    allowReordering: false,
    allowSorting: false,
    allowSearch: false,
    allowHiding: false,
    fixed: true,
    width: "auto",
    minWidth: 65,
  }),
  cpfCnpj: ensureType({
    caption: "CPF/CNPJ",
    width: 190,
    dataType: "string",
    allowResizing: true,
  }),
  cnpjFormatado: ensureType({
    caption: "CNPJ",
    width: 200,
    dataType: "string",
    allowResizing: true,
    customizeText: (value) => formatarCNPJ(value.valueText),
  }),
  cpfFormatado: ensureType({
    caption: "CPF",
    width: 200,
    dataType: "string",
    allowResizing: true,
    customizeText: (value) => formatarCPF(value.valueText),
  }),
  nomeFantasia: ensureType({
    caption: "Nome fantasia",
    allowResizing: true,
    dataType: "string",
    width: 200,
  }),
  razaoSocial: ensureType({
    caption: "Razão social",
    allowResizing: true,
    dataType: "string",
    width: 300,
  }),
  apelido: ensureType({
    caption: "Apelido",
    allowResizing: true,
    dataType: "string",
    width: 120,
  }),
  chaveAcesso: ensureType({
    caption: "Chave de acesso",
    allowResizing: true,
    width: 345,
  }),
  serie: ensureType({
    caption: "Série",
    allowResizing: true,
    width: 80,
  }),
  uf: ensureType({
    caption: "UF",
    allowResizing: true,
    dataType: "string",
    width: 120,
  }),
  stringPP: ensureType({
    allowResizing: true,
    dataType: "string",
    width: 50,
  }),
  stringP: ensureType({
    allowResizing: true,
    dataType: "string",
    width: 100,
  }),
  stringMP: ensureType({
    allowResizing: true,
    dataType: "string",
    width: 125,
  }),
  stringM: ensureType({
    allowResizing: true,
    dataType: "string",
    width: 150,
  }),
  stringMG: ensureType({
    allowResizing: true,
    dataType: "string",
    width: 175,
  }),
  stringG: ensureType({
    allowResizing: true,
    dataType: "string",
    width: 200,
  }),
  stringGG: ensureType({
    allowResizing: true,
    dataType: "string",
    width: 300,
  }),
  stringXG: ensureType({
    allowResizing: true,
    dataType: "string",
    width: 400,
  }),
  stringXXG: ensureType({
    allowResizing: true,
    dataType: "string",
    width: 500,
  }),
  codigo: ensureType({
    caption: "Código",
    allowResizing: true,
    dataType: "string",
    width: 70,
  }),
  codigoNumerico: ensureType({
    allowResizing: true,
    width: 100,
    dataType: "number",
  }),
  dataAnoCurtoComHoraMinutoSegundos: ensureType({
    allowResizing: true,
    width: 150,
    dataType: "datetime",
    selectedFilterOperation: "between",
    format: DataAnoCurtoComHoraMinutoSegundos,
  }),
  dataAnoCurtoSemHora: ensureType({
    allowResizing: true,
    width: 80,
    dataType: "date",
    selectedFilterOperation: "between",
    format: DataAnoCurtoSemHora,
  }),
  criadoEm: ensureType({
    caption: "Criado em",
    allowResizing: true,
    width: 150,
    dataType: "date",
    selectedFilterOperation: "between",
    visible: false,
    format: DataAnoCurtoComHoraMinutoSegundos,
  }),
  alteradoEm: ensureType({
    caption: "Alterado em",
    allowResizing: true,
    width: 150,
    dataType: "date",
    selectedFilterOperation: "between",
    visible: false,
    format: DataAnoCurtoComHoraMinutoSegundos,
  }),
  criadoPor: ensureType({
    caption: "Criado por",
    allowResizing: true,
    dataType: "string",
    width: 180,
    visible: false,
  }),
  alteradoPor: ensureType({
    caption: "Alterado por",
    dataType: "string",
    allowResizing: true,
    width: 180,
    visible: false,
  }),
  dataHoraSemFormatacao: ensureType({
    allowResizing: true,
    width: 150,
    dataType: "datetime",
    selectedFilterOperation: "between",
  }),
  percentual: ensureType({
    allowResizing: true,
    width: 80,
    dataType: "number",
    format: {
      type: "fixedPoint",
      precision: 2,
    },
  }),
  monetario: ensureType({
    allowResizing: true,
    width: 100,
    dataType: "number",
    customizeText: (value) => formatarNumero(Number(value.valueText), 2),
  }),
  monetarioBalanceteCD: ensureType({
    allowResizing: true,
    width: 140,
    dataType: "number",
    format: {
      formatter: (v) => formatarValorContabilComSufixo(v as number),
    },
  }),
  monetarioBalancete: ensureType({
    allowResizing: true,
    width: 140,
    dataType: "number",
    customizeText: (value) => formatarNumero(Number(value.valueText), 2),
  }),
  peso: ensureType({
    allowResizing: true,
    width: 80,
    dataType: "number",
    format: {
      type: "fixedPoint",
      precision: 3,
    },
  }),
  custoPorHora: ensureType({
    caption: "Custo/h (R$)",
    allowResizing: true,
    width: 115,
    dataType: "number",
  }),
  horasPorDia: ensureType({
    caption: "h/dia",
    allowResizing: true,
    width: 80,
    dataType: "number",
  }),
  unidade: ensureType({
    caption: "Un",
    allowResizing: true,
    dataType: "string",
    width: 80,
  }),
  centroDeTrabalhoGrupo: ensureType({
    caption: "Grupo do centro de trabalho",
    dataType: "string",
    allowResizing: true,
    width: 220,
    visible: false,
  }),
  grupoDeCentroDeTrabalhoCalendario: ensureType({
    caption: "Calendário do grupo de centro de trabalho",
    dataType: "string",
    allowResizing: true,
    width: 300,
    visible: false,
  }),
  boolSimNao: ensureType({
    allowResizing: true,
    dataType: "boolean",
    width: 90,
    alignment: "left",
    trueText: simPorExtenso,
    falseText: naoPorExtenso,
  }),
  quantidade: ensureType({
    caption: "Qt",
    allowResizing: true,
    width: 150,
    alignment: "right",
    dataType: "number",
    format: {
      type: "fixedPoint",
      precision: 2,
    },
  }),
};

function ensureType(obj: IColumnProps) {
  return obj;
}

function obterConfiguracaoColuna(tipo: keyof typeof Padroes) {
  return Padroes[tipo];
}

export default obterConfiguracaoColuna;
