import DataGrid, { Column } from "devextreme-react/cjs/data-grid";
import { useCallback, useRef, useState } from "react";
import { renderToString } from "react-dom/server";
import {
  useEditarRegistroGrid,
  useExcluirRegistroGrid,
  useNovoRegistroGrid,
} from "../../../../../hooks/grid.hooks";
import { decodificaBooleanoEmSimNao } from "../../../../../models/api/comum/decodificadores";
import { LancamentoNaParteBLacsGridModel } from "../../../../../models/api/lancamento-parte-b/lancamento-parte-b-lacs/lancamento-parte-b-lacs";
import { PermissoesApuracaoLalurIrpj } from "../../../../../models/permissoes/fiscal/apuracao-lalur-irpj/permissoes-apuracao-lalur-irpj";
import { CallBackModal } from "../../../../../models/shared/ui/callback-modal";
import EditFormLancamentoNaParteBLacs from "../../../../../pages/contabilidade/lancamento-parte-b-lacs/edit-form";
import { LancamentoNaParteBDoLacsService } from "../../../../../services/lancamento-parte-b-lacs/lancamento-parte-b-lacs";
import { checarResponseExibeMensagemExclusaoDeSucesso } from "../../../../../utils/api/api-utils";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import {
  criarItensMenuContextoPadrao,
  tratarDadosContextMenu,
} from "../../../../../utils/context-menu/context-menu-utils";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import { getGridDefaultProps } from "../../../../../utils/grid/grid-utils";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import GridDefaults from "../../../../layout/grid-defaults";
import GetColunasDeAuditoria from "../../../../layout/grid-defaults/colunasDeAuditoria";

const nameOfGridHandler = criarNameof<LancamentoNaParteBLacsGridModel>();
const service = new LancamentoNaParteBDoLacsService();

interface GridInternaLancamentoParteBDoLacsProps {
  idApuracaoDaLacsEDaCsll: number;
  modalPaiVisivel: boolean;
}

export default function GridInternaLancamentoParteBDoLacs(
  props: GridInternaLancamentoParteBDoLacsProps
) {
  const [modalEdicaoVisivel, setModalEdicaoVisivel] = useState(false);
  const [idRegistroEdicao, setIdRegistroEdicao] = useState(NaN);

  const gridRef = useRef<DataGrid<any, any>>(null);

  function getGridRef() {
    return gridRef.current!;
  }

  function atualizarGrid() {
    gridRef.current?.instance.refresh();
  }

  const handleNovoRegistro = useNovoRegistroGrid(() => {
    setIdRegistroEdicao(0);
    setModalEdicaoVisivel(true);
  });

  const handleEditarRegistro = useEditarRegistroGrid((id: number) => {
    setIdRegistroEdicao(id);
    setModalEdicaoVisivel(true);
  });

  const handleExcluirRegistro = useExcluirRegistroGrid(
    async (registro: LancamentoNaParteBLacsGridModel) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesApuracaoLalurIrpj.Consultar,
        ])
      ) {
        return;
      }
      const mensagem = renderToString(<>{obterMensagemExclusao(registro)}</>);
      const excluir = await exibirConfirmacao("Confirmar exclusão", mensagem);

      if (excluir) {
        const resposta = await service.Excluir(registro.id);

        if (resposta) {
          checarResponseExibeMensagemExclusaoDeSucesso(resposta);
          atualizarGrid();
        }
      }
    }
  );

  function obterMensagemExclusao(registro: LancamentoNaParteBLacsGridModel) {
    return `Tem certeza que deseja excluir o lançamento da parte B da conta ${registro.contaDaParteB}?`;
  }

  const handleModalCallback = useCallback((info: CallBackModal) => {
    setModalEdicaoVisivel(false);
    setIdRegistroEdicao(NaN);

    if (info.precisaAtualizar) {
      atualizarGrid();
    }
  }, []);

  function gerarContextMenu(data: any) {
    const [getData] = tratarDadosContextMenu<LancamentoNaParteBLacsGridModel>(
      data,
      gridRef,
      getGridRef
    );

    return criarItensMenuContextoPadrao({
      editar: () => {
        const data = getData();
        handleEditarRegistro(data!.id);
      },
      excluir: () => handleExcluirRegistro(getData()!),
    });
  }

  return (
    <>
      <DataGrid
        ref={gridRef}
        dataSource={
          props.modalPaiVisivel &&
          service.GetGridSourceFiltradaPorApuracao(
            props.idApuracaoDaLacsEDaCsll
          )
        }
        {...getGridDefaultProps({
          nomeDoArquivoAoExportar: NomesTelas.lancancamentoDaParteB,
          getGridRef: getGridRef,
          gerarOpcoesDoRegistro: gerarContextMenu,
          style: {
            height: "100%",
          },
        })}
      >
        {GridDefaults({
          gridId: "grid-lancamento-parte-b-lacs-editform-apuracao",
          novoRegistro: handleNovoRegistro,
          atualizarGrid,
          exportavel: false,
          filtravel: false,
          paginavel: false,
          selecionavel: false,
          getGridRef,
          gerarContextMenu,
        })}

        <Column
          key={nameOfGridHandler("contaDaParteB")}
          dataField={nameOfGridHandler("contaDaParteB")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Conta da parte B"
        />
        <Column
          key={nameOfGridHandler("valor")}
          dataField={nameOfGridHandler("valor")}
          {...obterConfiguracaoColuna("monetario")}
          caption="Valor"
        />
        <Column
          key={nameOfGridHandler("indicadorDoLancamento")}
          dataField={nameOfGridHandler("indicadorDoLancamento")}
          {...obterConfiguracaoColuna("stringG")}
          caption="Indicador do lançamento"
        />
        <Column
          key={nameOfGridHandler("historico")}
          dataField={nameOfGridHandler("historico")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Histórico"
        />
        <Column
          key={nameOfGridHandler("tributacaoDiferida")}
          dataField={nameOfGridHandler("tributacaoDiferida")}
          {...obterConfiguracaoColuna("boolSimNao")}
          caption="Tributação diferida"
          width={150}
          cellRender={({ data }) => {
            const dados = data as LancamentoNaParteBLacsGridModel;
            if (dados.tributacaoDiferida != undefined) {
              return decodificaBooleanoEmSimNao(dados.tributacaoDiferida);
            }
          }}
        />
        <Column
          key={nameOfGridHandler("incluirNaParteA")}
          dataField={nameOfGridHandler("incluirNaParteA")}
          {...obterConfiguracaoColuna("boolSimNao")}
          caption="Incluir na parte A"
          width={150}
          cellRender={({ data }) => {
            const dados = data as LancamentoNaParteBLacsGridModel;
            if (dados.incluirNaParteA != undefined) {
              return decodificaBooleanoEmSimNao(dados.incluirNaParteA);
            }
          }}
        />
        <Column
          key={nameOfGridHandler(
            "adicaoOuExclusaoOuCompensacaoDoLacsDoLucroReal"
          )}
          dataField={nameOfGridHandler(
            "adicaoOuExclusaoOuCompensacaoDoLacsDoLucroReal"
          )}
          {...obterConfiguracaoColuna("stringXG")}
          caption="Adição ou exclusão do ou compensação do LACS/LALUR"
        />
        {GetColunasDeAuditoria()}
      </DataGrid>

      <EditFormLancamentoNaParteBLacs
        idApuracaoDoLacsEDaCsll={props.idApuracaoDaLacsEDaCsll}
        visivel={modalEdicaoVisivel}
        idRegistroEdicao={idRegistroEdicao}
        configuracoesModal={{ largura: "max(40vw, 700px)", altura: "auto" }}
        callBackFecharModal={handleModalCallback}
      />
    </>
  );
}
