import SelectItem from "../../shared/ui/select-item";
import AuditavelDTO from "../comum/auditavel-dto";
import AuditavelGridModel from "../comum/auditavel-grid";
import { EstoqueMovimentacaoSelecionarGridModel } from "../estoque-movimentacao/estoque-movimentacao";
import { EstoqueGridModelSelecionar } from "../estoque/estoque";
import {
  EstadoDaTerceirizacao,
  OrigemInsumo,
  TipoDoEstoqueInsumo,
} from "./insumo-da-ordem-de-producao-enums";

export interface InsumoDaOrdemDeProducaoGridModel extends AuditavelGridModel {
  id: number;
  estado: InsumoEstado;
  codigo: string;
  descricao: string | null;
  ordemDeProducao: string;
  operacao: string;
  descricaoComplementar: string | null;
  quantidadeSemPercentualDePerda: number;
  perdaEmPercentual: number;
  quantidadeTotal: number;
  unidade: string;
  quantidadeParaUmaUnidade: number;
  quantidadeBaixada: number;
  quantidadeABaixar: number;
  loteInterno: string;
  loteDoFabricante: string;
  enderecoDeEstoque: string;
  estoqueDisponivel: string;
  estoqueDisponivelExplosao: string;
  reservado: boolean;
  exigeBaixa: boolean;
  dono: string;
  tipoDoEstoque: string;
  itemPai: string;
  descricaoDoItemPai: string;
}

export const ObterDescricaoInsumo = (
  insumo?: InsumoDaOrdemDeProducaoGridModel
) => {
  if (!insumo) {
    return "(insumo não encontrado)";
  }

  return `${insumo.codigo} - ${insumo.descricao} - (operação ${insumo.operacao})`;
};

export enum InsumoEstado {
  /// <summary>
  /// Concluída.
  /// </summary>
  ABaixar = 1,

  /// <summary>
  /// Iniciada.
  /// </summary>
  BaixadoParcialmente = 2,

  /// <summary>
  /// Baixado.
  /// </summary>
  BaixadoCompletamente = 3,
}

export interface InsumoDaOrdemDeProducaoCadastrarRequest {
  ordemDeProducaoId: number;
  operacaoId: number;
  itemId: number;
  quantidadeLiquida: number;
  observacoes: string | null;
  ativo: boolean;
  origem: OrigemInsumo;
  itemPaiId: number | undefined;
  ordem: number;
  perdaEmPercentual: number;
  estoqueId: number | null;
  numeroDeSerieId: number | null;
  tipoDoEstoque: TipoDoEstoqueInsumo | null;
  estadoDaTerceirizacao: EstadoDaTerceirizacao;
}

export interface InsumoDaOrdemDeProducaoAlterarRequest
  extends InsumoDaOrdemDeProducaoCadastrarRequest {
  id: number;
}

export const InsumosTipoEstoque: SelectItem[] = [
  { valor: TipoDoEstoqueInsumo.ConsumirEstoque, descricao: "Consumir estoque" },
  {
    valor: TipoDoEstoqueInsumo.ManterInsumo,
    descricao: "Manter insumo consumido em estoque separado",
  },
];

export interface InsumoDaOrdemDeProducaoBaixarResponse {
  quantidadeBaixada: number;
  estoquesParaBaixar: EstoqueGridModelSelecionar[];
}
export interface InsumoDaOrdemDeProducaoResponseDTO
  extends InsumoDaOrdemDeProducaoAlterarRequest,
    AuditavelDTO {}

export interface EstornarBaixasDoInsumoResponse {
  sucesso: boolean;
  mensagem: string;
  precisaSelecionarMovs: boolean;
  movimentacoes: EstoqueMovimentacaoSelecionarGridModel[];
}
