import { Column } from "devextreme-react/data-grid";
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { renderToString } from "react-dom/server";
import { MxpGrid } from "../../../../components/grid";
import {
  useEditarRegistroGrid,
  useExcluirRegistroGrid,
  useNovoRegistroGrid,
  usePropagarReferenciaGrid,
} from "../../../../hooks/grid.hooks";
import { CodigoTributacaoServicoGridModel } from "../../../../models/api/tributacao/codigo-tributacao-servico";
import { CallBackModal } from "../../../../models/shared/ui/callback-modal";
import { IGridSelecao } from "../../../../models/shared/ui/formularios";
import EditFormCodigoTributacaoServico from "../../../../pages/tributacao/codigo-tributacao-servico/edit-form";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../services/comum/serviceBase";
import {
  checarResponseExibeMensagemExclusaoDeSucesso,
  tratarErroApi,
} from "../../../../utils/api/api-utils";
import criarNameof from "../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../utils/common/nomes-telas";
import { exibirConfirmacao } from "../../../../utils/dialogos";
import { formatarPercentual } from "../../../../utils/formatadores/formatador-de-numeros";
import { GridBaseProps } from "../../../../utils/grid/grid-utils";
import obterConfiguracaoColuna from "../../../../utils/grid/padroes-colunas";
import GetColunasDeAuditoria from "../../../layout/grid-defaults/colunasDeAuditoria";

const nomeEndpoint = NomesEndpoints.TributacaoDeServicoCodigo;
const dataSource = APIBase.getGridSource(nomeEndpoint);
const nameOfGridHandler = criarNameof<CodigoTributacaoServicoGridModel>();
const colunas = [
  <Column
    key={nameOfGridHandler("codigo")}
    dataField={nameOfGridHandler("codigo")}
    caption="Código"
    allowResizing
    width={80}
  />,
  <Column
    key={nameOfGridHandler("descricao")}
    dataField={nameOfGridHandler("descricao")}
    {...obterConfiguracaoColuna("stringGG")}
    caption="Descrição"
  />,
  <Column
    key={nameOfGridHandler("aliquotaIss")}
    dataField={nameOfGridHandler("aliquotaIss")}
    {...obterConfiguracaoColuna("percentual")}
    caption="Alíquota de ISS"
    width={200}
    cellRender={({ data }) => {
      const dados = data as CodigoTributacaoServicoGridModel;
      return formatarPercentual(dados.aliquotaIss);
    }}
  />,
  <Column
    key={nameOfGridHandler("codigoServico")}
    dataField={nameOfGridHandler("codigoServico")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Código do serviço"
  />,
  <Column
    key={nameOfGridHandler("descricaoServico")}
    dataField={nameOfGridHandler("descricaoServico")}
    {...obterConfiguracaoColuna("stringGG")}
    caption="Descrição (serviço)"
  />,
  ...GetColunasDeAuditoria(),
];

export const GridCodigoTributacaoServico = forwardRef(
  (props: GridBaseProps, ref) => {
    const gridRef = useRef<IGridSelecao>(null);
    useImperativeHandle(ref, () => gridRef.current as IGridSelecao);
    usePropagarReferenciaGrid(ref, gridRef);

    const [modalEdicaoVisivel, setModalEdicaoVisivel] = useState(false);
    const [idRegistroEdicao, setIdRegistroEdicao] = useState(NaN);

    function handleAtualizarGrid() {
      if (gridRef.current?.atualizarGrid) {
        gridRef.current?.atualizarGrid();
      }
    }

    const handleNovoRegistro = useNovoRegistroGrid(() => {
      setIdRegistroEdicao(0);
      setModalEdicaoVisivel(true);
    });

    const handleEditarRegistro = useEditarRegistroGrid((id: number) => {
      setIdRegistroEdicao(id);
      setModalEdicaoVisivel(true);
    });

    const handleExcluirRegistro = useExcluirRegistroGrid(
      async (registro: CodigoTributacaoServicoGridModel) => {
        const mensagem = renderToString(<>{obterMensagemExclusao(registro)}</>);
        const excluir = await exibirConfirmacao("Confirmar exclusão", mensagem);

        if (excluir) {
          try {
            const resposta = await APIBase.excluir(registro.id, nomeEndpoint);

            if (resposta) {
              checarResponseExibeMensagemExclusaoDeSucesso(resposta);
              handleAtualizarGrid();
            }
          } catch (erro) {
            tratarErroApi(erro);
          }
        }
      }
    );

    function obterMensagemExclusao(registro: CodigoTributacaoServicoGridModel) {
      return `Tem certeza que deseja excluir o registro ${registro.descricao}?`;
    }

    const handleModalCallback = useCallback((info: CallBackModal) => {
      setModalEdicaoVisivel(false);
      setIdRegistroEdicao(NaN);

      if (info.precisaAtualizar) {
        handleAtualizarGrid();
      }
    }, []);

    return (
      <>
        <MxpGrid<CodigoTributacaoServicoGridModel>
          dataSource={dataSource}
          id={"codigo-tributacao-servico"}
          ref={gridRef}
          colunas={colunas}
          novoRegistro={handleNovoRegistro}
          editarRegistro={handleEditarRegistro}
          excluirRegistro={handleExcluirRegistro}
          nomeDoArquivoAoExportar={NomesTelas.codigosTributacaoServicos}
        />

        <EditFormCodigoTributacaoServico
          visivel={modalEdicaoVisivel}
          idRegistroEdicao={idRegistroEdicao}
          configuracoesModal={{ largura: "max(50vw, 800px)", altura: "auto" }}
          callBackFecharModal={handleModalCallback}
        />
      </>
    );
  }
);
