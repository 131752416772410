import Button from "devextreme-react/button";
import { Template } from "devextreme-react/core/template";
import Toolbar, { Item } from "devextreme-react/toolbar";

import { Menu } from "devextreme-react/menu";
import UserPanel from "../user-panel/user-panel";
import "./header-menu.scss";

import { TextBox } from "devextreme-react";
import { ItemRenderedEvent } from "devextreme/ui/menu";
import ScrollView from "devextreme/ui/scroll_view";
import { EnterKeyEvent } from "devextreme/ui/text_box";
import { memo, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/img/logo_menu.svg";
import useEffectOnLoad from "../../../hooks/effect.hooks";
import { Notificacao } from "../../../models/api/notificacoes/notificacao";
import { ColecaoAtalhos } from "../../../utils/atalhos/colecao-atalhos";
import { adicionarMeses } from "../../../utils/common/date-utils";
import {
  DadosLink,
  MenuItem,
  abrirModalMxpAntigo,
  navegarParaMxpAntigo,
  navegarParaPaginaExterna,
} from "../../../utils/common/menu-utils";
import AlertaSlide from "../../../utils/dialogos/renderAlerta";
import NotificacoesIcone from "../notificacoes/notificacoes";

interface IProps {
  menuToggleEnabled: boolean;
  userMenuItems?: any;
  title: string;
  irParaPaginaInicial: () => void;
}

const UserPanelMemo = memo(
  UserPanel,
  (prev, next) => prev.menuMode == next.menuMode
);

export default function HeaderMenu({
  menuToggleEnabled,
  userMenuItems,
  title,
  irParaPaginaInicial,
}: IProps) {
  const [notificacaoSlide, setNotificacaoSlide] = useState<
    Notificacao | undefined
  >(undefined);

  const navigate = useNavigate();

  function obterAcaoDeNavegacao(link: DadosLink | undefined): () => void {
    let acao: () => void;

    switch (link!.tipo) {
      case "linkLocal":
        acao = () => navigate(link!.rota);
        break;
      case "linkMxpAntigo":
        acao = () => navegarParaMxpAntigo(link!.rota);
        break;
      case "javaScriptMxpAntigo":
        acao = () => abrirModalMxpAntigo(link!);
        break;
      case "linkPaginaExterna":
        acao = () => navegarParaPaginaExterna(link!.rota);
        break;
    }

    return acao;
  }

  function VincularAtalhos(itens: MenuItem[]) {
    for (const item of itens) {
      if (item.items) {
        VincularAtalhos(item.items);
        continue;
      }

      if (item.link) {
        const acao = obterAcaoDeNavegacao(item.link!);
        if (item.atalho) {
          ColecaoAtalhos.registarAtalhosGlobais({
            ...item.atalho,
            acao: acao,
          });
        }
      }
    }
  }

  useEffectOnLoad(() => {
    VincularAtalhos(userMenuItems);
  });

  function adicionarTextoAtalhoAoMenu(e: ItemRenderedEvent<any>) {
    if (!e) {
      return;
    }

    const dadosItemMenu = e.itemData as MenuItem;

    const divItem = e.itemElement.querySelector(
      ".dx-item-content.dx-menu-item-content"
    ) as HTMLElement;

    if (!divItem) {
      return;
    }

    const link = divItem.querySelector(".dx-item-url") as HTMLElement;

    if (!link) {
      return;
    }
    const textoExistente = link.querySelector(
      ".dx-menu-item-text.dx-menu-item-text-with-url"
    ) as HTMLElement;

    if (!textoExistente) {
      return;
    }

    if (
      dadosItemMenu.criadoEm &&
      adicionarMeses(dadosItemMenu.criadoEm, 1) > new Date()
    ) {
      const etiquetaNovo = document.createElement("span");
      etiquetaNovo.textContent = "novo!";
      etiquetaNovo.classList.add("novo-menu");
      textoExistente.appendChild(etiquetaNovo);
    }

    if (!dadosItemMenu.atalho) {
      return;
    }

    const textoExistenteCopia = textoExistente.cloneNode(true) as HTMLElement;
    textoExistente.remove();

    const textoAtalho = textoExistente.cloneNode(true) as HTMLElement;
    textoAtalho.innerText = ColecaoAtalhos.obterTextoAtalho(
      dadosItemMenu.atalho
    );

    textoExistenteCopia.setAttribute("style", "margin-right: 40px;");

    const novaDiv = document.createElement("div");
    novaDiv.classList.add("containerItemMenu");
    novaDiv.appendChild(textoExistenteCopia);
    novaDiv.appendChild(textoAtalho);

    link.appendChild(novaDiv);
  }

  function realizarAjusteLinks(e: ItemRenderedEvent<any>) {
    if (!e) {
      return;
    }

    const dadosItemMenu = e.itemData as MenuItem;

    if (!dadosItemMenu.url || !dadosItemMenu.link) {
      return;
    }

    // Remove o link gerado pelo devexpress
    const divItem = e.itemElement.querySelector(
      ".dx-item-content.dx-menu-item-content"
    ) as HTMLElement;

    const linkGerado = divItem.querySelector(".dx-item-url") as HTMLElement;
    linkGerado.remove();

    const rota = dadosItemMenu.url;

    const link = document.createElement("a");
    link.classList.add("dx-item-url");
    link.href = rota;
    link.innerHTML = `<span class="dx-menu-item-text dx-menu-item-text-with-url">${e.itemData?.text}</span>`;

    link.addEventListener("click", function (e) {
      if (!e.isTrusted) {
        e.preventDefault();
        return;
      }

      if (!e.ctrlKey) {
        e.preventDefault();
        const acao = obterAcaoDeNavegacao(dadosItemMenu.link);
        acao();
      }
    });

    divItem.appendChild(link);
  }

  // O DevExpress não está gerando os links para o menu no modo adaptativo.
  // Esta função é um workaround para colocar um <a> com a url correta
  // ao redor do item do menu.
  function handleItemRenderedMenuHamburger(
    renderEvent: ItemRenderedEvent<any>
  ) {
    if (!renderEvent) {
      return;
    }

    const dadosItemMenu = renderEvent.itemData as MenuItem;
    if (!dadosItemMenu.link) {
      return;
    }

    const parent = renderEvent.itemElement.parentElement as HTMLElement;

    const divItem = renderEvent.itemElement;

    divItem.remove();

    const link = document.createElement("a");
    link.classList.add("dx-item-url");
    link.appendChild(divItem);

    const acao = obterAcaoDeNavegacao(dadosItemMenu.link);

    const rota = dadosItemMenu.link.rota;
    const tipo = dadosItemMenu.link.tipo;

    link.href =
      (tipo == "linkMxpAntigo"
        ? process.env.REACT_APP_BACKEND_ANTIGO_ENDPOINT
        : "") + rota;

    link.addEventListener("click", function (e) {
      if (!e.isTrusted) {
        e.preventDefault();
        return;
      }

      if (!e.ctrlKey) {
        e.preventDefault();
        acao();
      }
    });

    parent.appendChild(link);
  }

  function handleItemRenderedMenu(e: ItemRenderedEvent<any>) {
    if (menuToggleEnabled) {
      handleItemRenderedMenuHamburger(e);
      return;
    }

    const el = e.itemElement.closest(".dx-submenu") as HTMLElement;
    if (!el) {
      return;
    }

    realizarAjusteLinks(e);
    adicionarTextoAtalhoAoMenu(e);

    if ((ScrollView.getInstance(el) as ScrollView) === undefined) {
      const isSubMenu = el.parentElement?.classList.contains(
        "dx-menu-item-has-submenu"
      );

      el.style.height = `fit-content`;
      el.style.maxHeight = isSubMenu ? "50vh" : "85vh";

      const parentElement = el.closest(".dx-menu-item-has-submenu");

      if (parentElement) {
        el.style.position = "fixed";
      }
      new ScrollView(el, { useNative: true, showScrollbar: "always" });
    }
  }

  function handleEnterPesquisa(e: EnterKeyEvent) {
    const valor = e.component.option("value");

    const valorCodificado = encodeURIComponent(valor ?? "");

    window.open(
      `https://maxiprod.com.br/?s=${valorCodificado}&post_type=ajuda`
    );
  }

  return (
    <>
      <header
        className={`header-component ${
          menuToggleEnabled ? "" : "header-component-desktop"
        }`}
      >
        <Toolbar className={"header-toolbar"}>
          <Item
            location={"before"}
            template={"logoTemplate"}
            visible={!menuToggleEnabled}
          />
          <Item location={"before"} template={"menuTemplate"} />
          <Item
            location={"before"}
            template={"logoTemplate"}
            visible={menuToggleEnabled}
          />
          {!menuToggleEnabled && (
            <Item visible={!menuToggleEnabled} location={"before"}>
              <TextBox
                placeholder="Pesquisar na ajuda"
                mode="search"
                showClearButton={true}
                style={{
                  width: "min(300px, 13vw)",
                  backgroundColor: "rgba(255, 255, 255, .15)",
                  marginLeft: "8px",
                }}
                onEnterKey={handleEnterPesquisa}
              ></TextBox>
            </Item>
          )}
          <Item location={"after"} locateInMenu={"auto"}>
            <NotificacoesIcone
              notificacaoSlide={notificacaoSlide}
              setNotificacaoSlide={setNotificacaoSlide}
            ></NotificacoesIcone>
          </Item>
          <Item
            location={"after"}
            locateInMenu={"auto"}
            menuItemTemplate={"userPanelTemplate"}
          >
            <Button
              className={"user-button authorization"}
              width={"fit-content"}
              height={"100%"}
              stylingMode={"text"}
            >
              <UserPanelMemo
                menuMode={"context"}
                irParaPaginaInicial={irParaPaginaInicial}
              />
            </Button>
          </Item>
          <Template name={"userPanelTemplate"}>
            <UserPanelMemo
              menuMode={"list"}
              irParaPaginaInicial={irParaPaginaInicial}
            />
          </Template>
          <Template name="menuTemplate">
            <Menu
              dataSource={userMenuItems}
              showFirstSubmenuMode="onHover"
              showSubmenuMode="onHover"
              adaptivityEnabled={menuToggleEnabled}
              onItemRendered={handleItemRenderedMenu}
              id="menu-principal"
            />
          </Template>
          <Template name="logoTemplate">
            <a href="#" title={title} onClick={() => irParaPaginaInicial()}>
              <img
                src={logo}
                style={{
                  paddingLeft: menuToggleEnabled ? "0" : "10px",
                  height: "20px",
                  margin: "10px",
                }}
                alt={title}
              />
            </a>
          </Template>
        </Toolbar>
      </header>
      <AlertaSlide
        notificacaoSlide={notificacaoSlide}
        setNotificacaoSlide={setNotificacaoSlide}
      ></AlertaSlide>
    </>
  );
}
