import { PropsWithChildren, Suspense, useEffect, useState } from "react";
import { PainelCarregando } from "../../../components/layout/painel-carregamento";

interface RenderOnDemandProps {
  visivel: boolean;
}

/**
 * Faz com que o elemento seja renderizado apenas depois que ele ficar visível.
 * Util quando o elemento tem algum outro processamento ou animação após ficar invisível.
 */
export default function RenderOnDemand(
  props: PropsWithChildren<RenderOnDemandProps>
) {
  const [seletorVisivel, setSeletorVisivel] = useState(props.visivel);

  useEffect(() => {
    if (props.visivel && !seletorVisivel) {
      setSeletorVisivel(true);
    }
  }, [props.visivel]);

  return <>{seletorVisivel && props.children}</>;
}

interface RenderOnPromisseProps {
  promessa: Promise<void> | undefined | any;
}

const RenderOnPromisseInterno = (
  props: PropsWithChildren<RenderOnPromisseProps>
) => {
  props.promessa.read();
  return <>{props.children}</>;
};

/**
 * Faz com que o elemento seja renderizado apenas depois que a promessa concluir.
 * Util quando precisa fazer chamadas ao back ou processamentos pesados antes de renderizar.
 * Enquanto o processo não terminar vai renderizar o painel de carregamento.
 */
export const RenderOnPromisse = (
  props: PropsWithChildren<RenderOnPromisseProps>
) => {
  const promessa = wrapPromise(props.promessa);
  return (
    <Suspense fallback={<PainelCarregando visivel={true} />}>
      <RenderOnPromisseInterno promessa={promessa}>
        {props.children}
      </RenderOnPromisseInterno>
    </Suspense>
  );
};

interface DelayRenderProps {
  desativarDelay?: boolean;
  timeout?: number;
}

/**
 * Faz com que o elemento seja renderizado apenas depois de um certo tempo.
 * Util quando está renderizando um elemento pesado e quer que ele não afete o elemento pai.
 */
export const DelayRender = (props: PropsWithChildren<DelayRenderProps>) => {
  return (
    <>
      {props.desativarDelay ? (
        props.children
      ) : (
        <RenderOnPromisse
          promessa={
            new Promise((resolve) => setTimeout(resolve, props.timeout ?? 50))
          }
        >
          {props.children}
        </RenderOnPromisse>
      )}
    </>
  );
};

/**
 * Transforma um promessa em um objeto compatível com `Suspense`.
 */
const wrapPromise = (promise: any) => {
  let status = "pending";
  let result: any;
  const suspender = promise.then(
    (r: any) => {
      status = "success";
      result = r;
    },
    (e: any) => {
      status = "error";
      result = e;
    }
  );
  return {
    read() {
      if (status === "pending") {
        throw suspender;
      } else if (status === "error") {
        throw result;
      } else if (status === "success") {
        return result;
      }
    },
  };
};
