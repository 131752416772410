import { Key } from "ts-key-enum";
import { PilhaModais } from "./pilha-modais";
import { ItemContextMenu } from "../context-menu/context-menu-utils";
import { ClickAction } from "../../models/shared/ui/types";

interface ItemAtalho extends Teclas {
  acao: ClickAction;
}

interface ItemAtalhoRegistro extends TeclasOpcionais {
  acao: ClickAction;
}

export interface AtalhoTooltip extends TeclasOpcionais {
  /* Utilize essa opção para que esse atalho não seja processado
   enquanto um input ou textarea estiver em edição.
   Exemplo: Tecla Delete que apaga o caracter a direita do cursor. */
  conflitaComInputs?: boolean;
}

export interface Teclas {
  tecla: string;
  ctrl: boolean;
  shift: boolean;
  alt: boolean;
  conflitaComInputs: boolean;
}

export interface TeclasOpcionais {
  tecla: string;
  ctrl?: boolean;
  shift?: boolean;
  alt?: boolean;
}

interface ItemColecaoAtalhos {
  id: string;
  atalhos: ItemAtalho[];
}

interface AtalhosGrid {
  novo?: () => void;
  contextMenuItens?: ItemContextMenu[];
}

const IntervaloMinimo: number = 100;

export class ColecaoAtalhos {
  static colecao: ItemColecaoAtalhos[] = [];
  static atalhosGlobais: ItemAtalho[] = [];
  static ultimaExecucao: number = 0;

  public static inicializarEventos() {
    document.addEventListener("keydown", (e) => {
      if (this.podeExecutar()) {
        const executado = ColecaoAtalhos.processarAtalhoInterno(
          e.key?.toUpperCase(),
          e.ctrlKey,
          e.shiftKey,
          e.altKey
        );

        if (executado) {
          this.atualizarUltimaExecucao();
          e.preventDefault();
        }
      }
    });
  }

  public static registarAtalhosGlobais(...atalhos: ItemAtalhoRegistro[]) {
    atalhos.forEach((x) => {
      const atalho: ItemAtalho = {
        tecla: x.tecla,
        ctrl: !!x.ctrl,
        shift: !!x.shift,
        alt: !!x.alt,
        acao: x.acao,
        conflitaComInputs: false,
      };
      ColecaoAtalhos.registrarAtalhoInterno(atalho, true, "");
    });
  }

  public static registrarAtalhos(
    modalId: string,
    telaPossuiGrid: boolean,
    ...atalhos: ItemAtalhoRegistro[]
  ) {
    if (!telaPossuiGrid) {
      this.limpaAtalhosAtuaisInterno(modalId);
    }

    atalhos.forEach((x) => {
      const atalho: ItemAtalho = {
        tecla: x.tecla,
        ctrl: !!x.ctrl,
        shift: !!x.shift,
        alt: !!x.alt,
        acao: x.acao,
        conflitaComInputs: false,
      };
      ColecaoAtalhos.registrarAtalhoInterno(atalho, false, modalId);
    });
  }

  public static registrarAtalhosGrid(
    modalId: string,
    { novo, contextMenuItens }: AtalhosGrid
  ) {
    ColecaoAtalhos.limpaAtalhosAtuaisInterno(modalId);

    if (novo) {
      this.registrarAtalhoInterno(
        {
          tecla: Key.Insert,
          ctrl: false,
          shift: false,
          alt: false,
          acao: () => this.invocarSeEstiverForaDeInput(novo),
          conflitaComInputs: false,
        },
        false,
        modalId
      );
    }

    if (contextMenuItens) {
      for (const item of contextMenuItens) {
        if (item.atalho && item.onClick) {
          const click = item.onClick;
          this.registrarAtalhoInterno(
            {
              tecla: item.atalho!.tecla,
              ctrl: item.atalho?.ctrl ?? false,
              shift: item.atalho?.shift ?? false,
              alt: item.atalho?.alt ?? false,
              acao: item.atalho.conflitaComInputs
                ? () => this.invocarSeEstiverForaDeInput(click)
                : click,
              conflitaComInputs: item.atalho.conflitaComInputs ?? false,
            },
            false,
            modalId
          );
        }
      }
    }
  }

  public static estaDentroDeInput() {
    return (
      document.activeElement?.tagName === "INPUT" ||
      document.activeElement?.tagName === "TEXTAREA"
    );
  }

  public static invocarSeEstiverForaDeInput(acao: ClickAction) {
    if (!this.estaDentroDeInput()) {
      acao();
    }
  }

  public static obterTextoAtalho(atalho: TeclasOpcionais): string {
    const texto: string[] = [];

    if (atalho.ctrl) {
      texto.push("Ctrl");
    }

    if (atalho.alt) {
      texto.push("Alt");
    }

    if (atalho.shift) {
      texto.push("Shift");
    }

    texto.push(atalho.tecla);

    return texto.join("+");
  }

  private static deltaTime() {
    return new Date().getTime() - ColecaoAtalhos.ultimaExecucao;
  }

  private static podeExecutar() {
    return this.deltaTime() >= IntervaloMinimo;
  }

  private static atualizarUltimaExecucao() {
    ColecaoAtalhos.ultimaExecucao = new Date().getTime();
  }

  private static processarAtalhoInterno(
    tecla: string,
    ctrl: boolean,
    shift: boolean,
    alt: boolean
  ) {
    const id = PilhaModais.obterIdTopo();
    const index = this.colecao.findIndex((x) => x.id === id);
    let atalho = this.colecao[index]?.atalhos?.find(
      (x) =>
        x.tecla === tecla &&
        x.ctrl === ctrl &&
        x.shift === shift &&
        x.alt === alt
    );

    if (!atalho) {
      atalho = this.atalhosGlobais.find(
        (x) =>
          x.tecla === tecla &&
          x.ctrl === ctrl &&
          x.shift === shift &&
          x.alt === alt
      );
    }

    if (atalho && atalho?.conflitaComInputs && this.estaDentroDeInput()) {
      return false;
    }

    atalho?.acao();

    return !!atalho;
  }

  private static limpaAtalhosAtuaisInterno(modalId: string) {
    const index = this.colecao.findIndex((x) => x.id === modalId);

    if (index > -1) {
      this.colecao.splice(index, 1);
    }
  }

  private static registrarAtalhoInterno(
    item: ItemAtalho,
    global: boolean,
    modalId: string
  ) {
    item.tecla = item.tecla.toUpperCase();

    if (global) {
      this.atalhosGlobais.push(item);
    } else {
      if (!this.colecao.some((x) => x.id === modalId)) {
        this.colecao.push({
          id: modalId,
          atalhos: [],
        });
      }

      const index = this.colecao.findIndex((x) => x.id === modalId);

      const indexAtalho = this.colecao[index].atalhos.findIndex(
        (a) =>
          a.tecla === item.tecla &&
          a.ctrl === item.ctrl &&
          a.shift === item.shift &&
          a.alt === item.alt
      );

      if (indexAtalho < 0) {
        this.colecao[index].atalhos.push(item);
      } else {
        this.colecao[index].atalhos[indexAtalho].acao = item.acao;
      }
    }
  }
}
