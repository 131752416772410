import { useState } from "react";
import Pagina from "../../../components/layout/pagina";
import RequerPermissao from "../../../components/seguranca/requer-permissao";
import { PermissoesOrdemDeProducao } from "../../../models/permissoes/producao/ordem-de-producao/OrdemDeProducaoPermissoes";
import { GridOrdemDeProducao } from "../../../parts/producao/ordem-de-producao/grids/grid-padrao";
import NomesTelas from "../../../utils/common/nomes-telas";
import { ItemContextMenu } from "../../../utils/context-menu/context-menu-utils";
import FiltrosGridOrdemDeProducao from "../../../utils/filtros-grid/producao/ordem-de-producao/filtros-grid-centro-de-trabalho";

export default function OrdemDeProducaoPage() {
  const [menus, setMenus] = useState<ItemContextMenu[]>();

  return (
    <Pagina
      id="ordem-de-producao-page"
      titulo={NomesTelas.ordensProducao}
      area={"Produção"}
      subMenuItems={menus}
    >
      <RequerPermissao codigoPermissoes={[PermissoesOrdemDeProducao.Consultar]}>
        <GridOrdemDeProducao
          valorPadraoDoFiltro={FiltrosGridOrdemDeProducao.ordemDeProducaoEstado}
          definirMenu={setMenus}
        />
      </RequerPermissao>
    </Pagina>
  );
}
