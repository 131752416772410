import { Column } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { MxpGrid } from "../../../../../components/grid";
import { obterTituloTela } from "../../../../../components/layout/form-base2";
import { Modal } from "../../../../../components/layout/modal";
import {
  useEditarRegistroGrid,
  useExcluirRegistroGrid,
  useNovoRegistroGrid,
  usePropagarReferenciaGrid,
} from "../../../../../hooks/grid.hooks";
import { ItemGridModel } from "../../../../../models/api/item/item";
import { CallBackModal } from "../../../../../models/shared/ui/callback-modal";
import { IGridSelecao } from "../../../../../models/shared/ui/formularios";
import { NomesEndpoints } from "../../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../../services/comum/serviceBase";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesModais from "../../../../../utils/common/nomes-modais";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import { GridBaseProps } from "../../../../../utils/grid/grid-utils";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import GetColunasDeAuditoria from "../../../../layout/grid-defaults/colunasDeAuditoria";

interface GridItemProps extends GridBaseProps {
  funcaoGeradoraDeDados?: CustomStore;
}

export const GridItem = forwardRef((props: GridItemProps, ref) => {
  const gridRef = useRef<IGridSelecao>(null);
  const [modalEdicaoVisivel, setModalEdicaoVisivel] = useState(false);
  const [idRegistroEdicao, setIdRegistroEdicao] = useState(NaN);
  const nomeEndpoint = NomesEndpoints.Item;
  const dataSource =
    props.funcaoGeradoraDeDados ??
    new DataSource({
      store: APIBase.getGridSource(nomeEndpoint),
    });
  const nameOfGridHandler = criarNameof<ItemGridModel>();
  const colunas = [
    <Column
      key={nameOfGridHandler("codigo")}
      dataField={nameOfGridHandler("codigo")}
      {...obterConfiguracaoColuna("stringM")}
      caption="Código"
    />,
    <Column
      key={nameOfGridHandler("descricao")}
      dataField={nameOfGridHandler("descricao")}
      {...obterConfiguracaoColuna("stringG")}
      caption="Descrição"
    />,
    <Column
      key={nameOfGridHandler("grupo")}
      dataField={nameOfGridHandler("grupo")}
      {...obterConfiguracaoColuna("stringM")}
      caption="Grupo"
    />,
    <Column
      key={nameOfGridHandler("estado")}
      dataField={nameOfGridHandler("estado")}
      {...obterConfiguracaoColuna("stringM")}
      caption="Estado"
    />,
    <Column
      key={nameOfGridHandler("estocagemPor")}
      dataField={nameOfGridHandler("estocagemPor")}
      {...obterConfiguracaoColuna("stringM")}
      caption="Estocagem por"
      visible={false}
    />,
    ...GetColunasDeAuditoria(),
  ];

  function handleModalCallback(info: CallBackModal) {
    setModalEdicaoVisivel(false);
    setIdRegistroEdicao(NaN);

    if (info.precisaAtualizar) {
      handleAtualizarGrid();
    }
  }

  useImperativeHandle(ref, () => gridRef.current as IGridSelecao);
  usePropagarReferenciaGrid(ref, gridRef);

  function handleAtualizarGrid() {
    if (gridRef.current?.atualizarGrid) {
      gridRef.current?.atualizarGrid();
    }
  }

  const handleNovoRegistro = useNovoRegistroGrid(() => {
    setIdRegistroEdicao(0);
    setModalEdicaoVisivel(true);
  });

  const handleEditarRegistro = useEditarRegistroGrid((id: number) => {
    setIdRegistroEdicao(id);
    setModalEdicaoVisivel(true);
  });

  const handleExcluirRegistro = useExcluirRegistroGrid(() => {
    exibirConfirmacao("Atenção", "Funcionalidade não implementada");
  });

  if (props.filtrosRealizadosNoServidor && dataSource instanceof DataSource) {
    dataSource.filter(
      props.filtrosRealizadosNoServidor.map((x) => [
        x.nomeCampo,
        x.operador,
        x.valor,
      ])
    );
  }

  return (
    <>
      <MxpGrid<ItemGridModel>
        dataSource={dataSource}
        id={"item"}
        ref={gridRef}
        colunas={colunas}
        novoRegistro={props.ocultarBotaoNovo ? undefined : handleNovoRegistro}
        editarRegistro={handleEditarRegistro}
        excluirRegistro={handleExcluirRegistro}
        nomeDoArquivoAoExportar={NomesTelas.item}
        valorPadraoDoFiltro={props.valorPadraoDoFiltro}
        sobreporFiltroSalvoComOFiltroPadrao={
          props.sobreporFiltroSalvoComOFiltroPadrao ?? false
        }
        limparFiltroAoTrocarFiltroPadrao={
          props.limparFiltroAoTrocarFiltroPadrao ?? false
        }
      />
      <Modal
        titulo={obterTituloTela(
          idRegistroEdicao == 0 ? "criar" : "editar",
          NomesModais.item,
          false
        )}
        visivel={modalEdicaoVisivel}
        altura="auto"
        largura={"max(50vw, 800px)"}
        onFechar={() =>
          handleModalCallback({ concluido: false, precisaAtualizar: false })
        }
      >
        <div>Funcionalidade não implementada</div>
      </Modal>
    </>
  );
});
