import { Column } from "devextreme-react/data-grid";
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { renderToString } from "react-dom/server";
import { MxpGrid } from "../../../../../components/grid";
import {
  useEditarRegistroGrid,
  useExcluirRegistroGrid,
  useNovoRegistroGrid,
  usePropagarReferenciaGrid,
} from "../../../../../hooks/grid.hooks";
import { CentroDeTrabalhoGridModel } from "../../../../../models/api/centro-de-trabalho/centro-de-trabalho";
import { decodificaBooleanoEmSimNao } from "../../../../../models/api/comum/decodificadores";
import { CallBackModal } from "../../../../../models/shared/ui/callback-modal";
import { IGridSelecao } from "../../../../../models/shared/ui/formularios";
import EditFormCentroDeTrabalho from "../../../../../pages/producao/centro-de-trabalho/edit-form";
import { NomesEndpoints } from "../../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../../services/comum/serviceBase";
import {
  checarResponseExibeMensagemExclusaoDeSucesso,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import { formatarNumero } from "../../../../../utils/formatadores/formatador-de-numeros";
import { GridBaseProps } from "../../../../../utils/grid/grid-utils";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import GetColunasDeAuditoria from "../../../../layout/grid-defaults/colunasDeAuditoria";

const casasDecimaisParaGrid = 2;
const nomeEndpoint = NomesEndpoints.CentroDeTrabalho;
const dataSource = APIBase.getGridSource(nomeEndpoint);
const nameOfGridHandler = criarNameof<CentroDeTrabalhoGridModel>();
const colunas = [
  <Column
    key={nameOfGridHandler("ativo")}
    dataField={nameOfGridHandler("ativo")}
    {...obterConfiguracaoColuna("boolSimNao")}
    caption="Ativo"
    visible={false}
    cellRender={({ data }) => {
      const dados = data as CentroDeTrabalhoGridModel;
      return decodificaBooleanoEmSimNao(dados.ativo);
    }}
  />,
  <Column
    key={nameOfGridHandler("codigo")}
    dataField={nameOfGridHandler("codigo")}
    {...obterConfiguracaoColuna("stringP")}
    sortIndex={0}
    sortOrder="asc"
    caption="Código"
  />,
  <Column
    key={nameOfGridHandler("descricao")}
    dataField={nameOfGridHandler("descricao")}
    {...obterConfiguracaoColuna("stringGG")}
    caption="Descrição"
  />,
  <Column
    key={nameOfGridHandler("centroDeCustosFormatado")}
    dataField={nameOfGridHandler("centroDeCustosFormatado")}
    {...obterConfiguracaoColuna("stringGG")}
    caption="Centro de custos"
  />,
  <Column
    key={nameOfGridHandler("tipo")}
    dataField={nameOfGridHandler("tipo")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Tipo"
  />,
  <Column
    key={nameOfGridHandler("custoPorHora")}
    dataField={nameOfGridHandler("custoPorHora")}
    {...obterConfiguracaoColuna("custoPorHora")}
    cellRender={({ data }) => {
      const dados = data as CentroDeTrabalhoGridModel;
      return dados.custoPorHora
        ? formatarNumero(dados.custoPorHora, casasDecimaisParaGrid)
        : "";
    }}
  />,
  <Column
    key={nameOfGridHandler("horaPorDia")}
    dataField={nameOfGridHandler("horaPorDia")}
    {...obterConfiguracaoColuna("horasPorDia")}
    cellRender={({ data }) => {
      const dados = data as CentroDeTrabalhoGridModel;
      return dados.horaPorDia
        ? formatarNumero(dados.horaPorDia, casasDecimaisParaGrid)
        : "";
    }}
  />,
  <Column
    key={nameOfGridHandler("unidade")}
    dataField={nameOfGridHandler("unidade")}
    {...obterConfiguracaoColuna("unidade")}
  />,
  <Column
    key={nameOfGridHandler("centroDeTrabalhoGrupo")}
    dataField={nameOfGridHandler("centroDeTrabalhoGrupo")}
    {...obterConfiguracaoColuna("centroDeTrabalhoGrupo")}
  />,
  <Column
    key={nameOfGridHandler("grupoDeCentroDeTrabalhoCalendario")}
    dataField={nameOfGridHandler("grupoDeCentroDeTrabalhoCalendario")}
    {...obterConfiguracaoColuna("grupoDeCentroDeTrabalhoCalendario")}
  />,
  ...GetColunasDeAuditoria(),
];

export const GridCentroDeTrabalho = forwardRef((props: GridBaseProps, ref) => {
  const gridRef = useRef<IGridSelecao>(null);
  useImperativeHandle(ref, () => gridRef.current as IGridSelecao);
  usePropagarReferenciaGrid(ref, gridRef);

  const [modalEdicaoVisivel, setModalEdicaoVisivel] = useState(false);
  const [idRegistroEdicao, setIdRegistroEdicao] = useState(NaN);

  function handleAtualizarGrid() {
    if (gridRef.current?.atualizarGrid) {
      gridRef.current?.atualizarGrid();
    }
  }

  const handleNovoRegistro = useNovoRegistroGrid(() => {
    setIdRegistroEdicao(0);
    setModalEdicaoVisivel(true);
  });

  const handleEditarRegistro = useEditarRegistroGrid((id: number) => {
    setIdRegistroEdicao(id);
    setModalEdicaoVisivel(true);
  });

  const handleExcluirRegistro = useExcluirRegistroGrid(
    async (registro: CentroDeTrabalhoGridModel) => {
      const mensagem = renderToString(<>{obterMensagemExclusao(registro)}</>);
      const excluir = await exibirConfirmacao("Confirmar exclusão", mensagem);

      if (excluir) {
        try {
          const resposta = await APIBase.excluir(registro.id, nomeEndpoint);

          if (resposta) {
            checarResponseExibeMensagemExclusaoDeSucesso(resposta);
            handleAtualizarGrid();
          }
        } catch (erro) {
          tratarErroApi(erro);
        }
      }
    }
  );

  function obterMensagemExclusao(registro: CentroDeTrabalhoGridModel) {
    return `Tem certeza de que deseja excluir o centro de trabalho ${registro.codigo}?`;
  }

  const handleModalCallback = useCallback((info: CallBackModal) => {
    setModalEdicaoVisivel(false);
    setIdRegistroEdicao(NaN);

    if (info.precisaAtualizar) {
      handleAtualizarGrid();
    }
  }, []);

  return (
    <>
      <MxpGrid<CentroDeTrabalhoGridModel>
        dataSource={dataSource}
        id={"centros-de-trabalho"}
        ref={gridRef}
        colunas={colunas}
        novoRegistro={handleNovoRegistro}
        editarRegistro={handleEditarRegistro}
        excluirRegistro={handleExcluirRegistro}
        nomeDoArquivoAoExportar={NomesTelas.centrosDeTrabalho}
        valorPadraoDoFiltro={props.valorPadraoDoFiltro}
        sobreporFiltroSalvoComOFiltroPadrao={
          props.sobreporFiltroSalvoComOFiltroPadrao ?? false
        }
        limparFiltroAoTrocarFiltroPadrao={
          props.limparFiltroAoTrocarFiltroPadrao ?? false
        }
      />
      <EditFormCentroDeTrabalho
        visivel={modalEdicaoVisivel}
        idRegistroEdicao={idRegistroEdicao}
        configuracoesModal={{
          largura: "max(50vw, 800px)",
          altura: "min(86vh, 750px)",
        }}
        callBackFecharModal={handleModalCallback}
      />
    </>
  );
});
