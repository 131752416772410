import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks/store.hooks";
import { validarPayloadOauthAutenticado } from "../../../services/tokens/tokens.service";
import {
  bloquearUI,
  definirEstadoRedirecionando,
  desbloquearUI,
} from "../../../store/ui/ui.slice";
import { checarResponse } from "../../../utils/api/api-utils";
import {
  RemoverSessaoReduxELocalStorage,
  criarUrlCallbackAntigoMaxiprod,
} from "../../../utils/oauth/oauth-utils";

export default function OauthAutenticadoRedirect() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const oAuthData = searchParams.get("payload");
  const returnUrlAntigoMaxiprod = searchParams.get("return");
  const urlPreferencial = useAppSelector(
    (state) => state.sessao.dadosSessao?.usuario?.urlPreferencial
  );
  const msgErro = searchParams.get("erro");

  const getUrlRetorno = () => {
    if (returnUrlAntigoMaxiprod && returnUrlAntigoMaxiprod !== "/") {
      return returnUrlAntigoMaxiprod;
    } else if (urlPreferencial) {
      return urlPreferencial;
    } else {
      return null;
    }
  };

  useEffect(() => {
    dispatch(definirEstadoRedirecionando(true));
    if (msgErro) {
      dispatch(definirEstadoRedirecionando(false));
      RemoverSessaoReduxELocalStorage(true);
      return;
    }

    if (!oAuthData) {
      dispatch(definirEstadoRedirecionando(false));
      navigate("/");
      return;
    }

    validarOauth();
  }, []);

  async function validarOauth() {
    try {
      dispatch(bloquearUI("Carregando..."));
      const resposta = await validarPayloadOauthAutenticado(oAuthData!);
      checarResponse(resposta);
      const retorno = getUrlRetorno();
      const urlCallback = criarUrlCallbackAntigoMaxiprod(
        resposta.model,
        retorno
      );
      window.location.href = urlCallback;
    } catch (erro) {
      dispatch(definirEstadoRedirecionando(false));
      navigate("/");
    } finally {
      dispatch(desbloquearUI());
    }
  }

  return <></>;
}
