import { Column } from "devextreme-react/data-grid";
import { forwardRef, useRef, useState } from "react";
import { MxpGrid } from "../../../../components/grid";
import { obterTituloTela } from "../../../../components/layout/form-base2";
import { Modal } from "../../../../components/layout/modal";
import {
  useEditarRegistroGrid,
  useExcluirRegistroGrid,
  useNovoRegistroGrid,
  usePropagarReferenciaGrid,
} from "../../../../hooks/grid.hooks";
import {
  EmpresaGridModel,
  TipoEmpresasFiltro,
} from "../../../../models/api/empresa/empresa-grid";
import { CallBackModal } from "../../../../models/shared/ui/callback-modal";
import { IGridSelecao } from "../../../../models/shared/ui/formularios";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../services/comum/serviceBase";
import criarNameof from "../../../../utils/common/cria-name-of";
import NomesModais from "../../../../utils/common/nomes-modais";
import NomesTelas from "../../../../utils/common/nomes-telas";
import { exibirConfirmacao } from "../../../../utils/dialogos";
import { GridBaseProps } from "../../../../utils/grid/grid-utils";
import obterConfiguracaoColuna from "../../../../utils/grid/padroes-colunas";

const nomeEndpoint = NomesEndpoints.Empresa;
const dataSource = APIBase.getGridSource(nomeEndpoint);
const dataSourceMinhaEmpresa = APIBase.getGridSourceComParametros(
  nomeEndpoint,
  {
    filtro: TipoEmpresasFiltro.Proprias,
  }
);

const nameOfGridHandler = criarNameof<EmpresaGridModel>();
const colunas = [
  <Column
    key={nameOfGridHandler("id")}
    dataField={nameOfGridHandler("id")}
    {...obterConfiguracaoColuna("id")}
  />,
  <Column
    key={nameOfGridHandler("apelido")}
    dataField={nameOfGridHandler("apelido")}
    {...obterConfiguracaoColuna("apelido")}
  />,
  <Column
    key={nameOfGridHandler("nomeFantasia")}
    dataField={nameOfGridHandler("nomeFantasia")}
    {...obterConfiguracaoColuna("nomeFantasia")}
  />,
  <Column
    key={nameOfGridHandler("razaoSocial")}
    dataField={nameOfGridHandler("razaoSocial")}
    {...obterConfiguracaoColuna("razaoSocial")}
  />,
  <Column
    key={nameOfGridHandler("cnpjCpfCodigo")}
    dataField={nameOfGridHandler("cnpjCpfCodigo")}
    {...obterConfiguracaoColuna("cpfCnpj")}
  />,
  <Column
    key={nameOfGridHandler("municipio")}
    dataField={nameOfGridHandler("municipio")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Município"
  />,
  <Column
    key={nameOfGridHandler("uf")}
    dataField={nameOfGridHandler("uf")}
    {...obterConfiguracaoColuna("stringM")}
  />,
  <Column
    key={nameOfGridHandler("descricaoRegiao")}
    dataField={nameOfGridHandler("descricaoRegiao")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Região"
  />,
  <Column
    key={nameOfGridHandler("codigoEstado")}
    dataField={nameOfGridHandler("codigoEstado")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Estado"
  />,
  <Column
    key={nameOfGridHandler("fornecedor")}
    dataField={nameOfGridHandler("fornecedor")}
    {...obterConfiguracaoColuna("boolSimNao")}
    showInColumnChooser={false}
    visible={false}
  />,
];

export const GridEmpresa = forwardRef((props: GridBaseProps, ref) => {
  const gridRef = useRef<IGridSelecao>(null);
  const [modalEdicaoVisivel, setModalEdicaoVisivel] = useState(false);
  const [idRegistroEdicao, setIdRegistroEdicao] = useState(NaN);

  function handleModalCallback(info: CallBackModal) {
    setModalEdicaoVisivel(false);
    setIdRegistroEdicao(NaN);

    if (info.precisaAtualizar) {
      handleAtualizarGrid();
    }
  }

  usePropagarReferenciaGrid(ref, gridRef);

  function handleAtualizarGrid() {
    if (gridRef.current?.atualizarGrid) {
      gridRef.current?.atualizarGrid();
    }
  }

  const handleNovoRegistro = useNovoRegistroGrid(() => {
    setIdRegistroEdicao(0);
    setModalEdicaoVisivel(true);
  });

  const handleEditarRegistro = useEditarRegistroGrid((id: number) => {
    setIdRegistroEdicao(id);
    setModalEdicaoVisivel(true);
  });

  const handleExcluirRegistro = useExcluirRegistroGrid(() => {
    exibirConfirmacao("Atenção", "Funcionalidade não implementada");
  });

  return (
    <>
      <MxpGrid<EmpresaGridModel>
        id={"empresa"}
        ref={gridRef}
        dataSource={dataSource}
        colunas={colunas}
        nomeDoArquivoAoExportar={NomesTelas.empresas}
        novoRegistro={props.ocultarBotaoNovo ? undefined : handleNovoRegistro}
        editarRegistro={handleEditarRegistro}
        excluirRegistro={handleExcluirRegistro}
        valorPadraoDoFiltro={props.valorPadraoDoFiltro}
        sobreporFiltroSalvoComOFiltroPadrao={
          props.sobreporFiltroSalvoComOFiltroPadrao
        }
      />
      <Modal
        titulo={obterTituloTela(
          idRegistroEdicao == 0 ? "criar" : "editar",
          NomesModais.empresa,
          true
        )}
        visivel={modalEdicaoVisivel}
        altura="auto"
        largura={"max(50vw, 800px)"}
        onFechar={() =>
          handleModalCallback({ concluido: false, precisaAtualizar: false })
        }
      >
        <div>Funcionalidade não implementada</div>
      </Modal>
    </>
  );
});

export const GridMinhaEmpresa = forwardRef((props, ref) => {
  const gridRef = useRef<IGridSelecao>(null);
  const [modalEdicaoVisivel, setModalEdicaoVisivel] = useState(false);
  const [idRegistroEdicao, setIdRegistroEdicao] = useState(NaN);

  function handleModalCallback(info: CallBackModal) {
    setModalEdicaoVisivel(false);
    setIdRegistroEdicao(NaN);

    if (info.precisaAtualizar) {
      handleAtualizarGrid();
    }
  }

  usePropagarReferenciaGrid(ref, gridRef);

  function handleAtualizarGrid() {
    if (gridRef.current?.atualizarGrid) {
      gridRef.current?.atualizarGrid();
    }
  }

  const handleNovoRegistro = useNovoRegistroGrid(() => {
    setIdRegistroEdicao(0);
    setModalEdicaoVisivel(true);
  });

  const handleEditarRegistro = useEditarRegistroGrid((id: number) => {
    setIdRegistroEdicao(id);
    setModalEdicaoVisivel(true);
  });

  const handleExcluirRegistro = useExcluirRegistroGrid(() => {
    exibirConfirmacao("Atenção", "Funcionalidade não implementada");
  });

  return (
    <>
      <MxpGrid<EmpresaGridModel>
        id={"empresa"}
        ref={gridRef}
        dataSource={dataSourceMinhaEmpresa}
        colunas={colunas}
        nomeDoArquivoAoExportar={NomesTelas.empresas}
        novoRegistro={handleNovoRegistro}
        editarRegistro={handleEditarRegistro}
        excluirRegistro={handleExcluirRegistro}
      />
      <Modal
        titulo={obterTituloTela(
          idRegistroEdicao == 0 ? "criar" : "editar",
          NomesModais.empresa,
          true
        )}
        visivel={modalEdicaoVisivel}
        altura="auto"
        largura={"max(50vw, 800px)"}
        onFechar={() =>
          handleModalCallback({ concluido: false, precisaAtualizar: false })
        }
      >
        <div>Funcionalidade não implementada</div>
      </Modal>
    </>
  );
});
