import { Column } from "devextreme-react/data-grid";
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { renderToString } from "react-dom/server";
import { MxpGrid } from "../../../../../components/grid";
import {
  useEditarRegistroGrid,
  useExcluirRegistroGrid,
  useNovoRegistroGrid,
  usePropagarReferenciaGrid,
} from "../../../../../hooks/grid.hooks";
import { VinculoEnderecoEstoqueCentroDeTrabalhoGridModel } from "../../../../../models/api/vinculo-endereco-estoque-centro-de-trabalho/vinculo-endereco-estoque-centro-de-trabalho";
import { CallBackModal } from "../../../../../models/shared/ui/callback-modal";
import { IGridSelecao } from "../../../../../models/shared/ui/formularios";
import EditFormVinculoEnderecoEstoqueCentroDeTrabalho from "../../../../../pages/producao/vinculo-endereco-estoque-centro-de-trabalho/edit-form";
import { NomesEndpoints } from "../../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../../services/comum/serviceBase";
import { checarResponseExibeMensagemExclusaoDeSucesso } from "../../../../../utils/api/api-utils";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import { GridBaseProps } from "../../../../../utils/grid/grid-utils";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import GetColunasDeAuditoria from "../../../../layout/grid-defaults/colunasDeAuditoria";

const nomeEndpoint = NomesEndpoints.VinculoEnderecosDeEstoqueCentroDeTrabalho;
const dataSource = APIBase.getGridSource(nomeEndpoint);
const nameofGridHandler =
  criarNameof<VinculoEnderecoEstoqueCentroDeTrabalhoGridModel>();
const colunas = [
  <Column
    key={nameofGridHandler("centroDeTrabalho")}
    dataField={nameofGridHandler("centroDeTrabalho")}
    caption="Centro de trabalho"
    {...obterConfiguracaoColuna("stringGG")}
  />,
  <Column
    key={nameofGridHandler("enderecoEstoque")}
    dataField={nameofGridHandler("enderecoEstoque")}
    caption="Endereço de estoque"
    {...obterConfiguracaoColuna("stringGG")}
  />,
  <Column
    key={nameofGridHandler("minhaEmpresa")}
    dataField={nameofGridHandler("minhaEmpresa")}
    caption="Minha empresa"
    {...obterConfiguracaoColuna("stringGG")}
  />,
  ...GetColunasDeAuditoria(),
];

export const GridVinculoEnderecoEstoqueCentroDeTrabalho = forwardRef(
  (props: GridBaseProps, ref) => {
    const gridRef = useRef<IGridSelecao>(null);
    useImperativeHandle(ref, () => gridRef.current as IGridSelecao);
    usePropagarReferenciaGrid(ref, gridRef);

    const [modalEdicaoVisivel, setModalEdicaoVisivel] = useState(false);
    const [idRegistroEdicao, setIdRegistroEdicao] = useState(NaN);

    function handleAtualizarGrid() {
      if (gridRef.current?.atualizarGrid) {
        gridRef.current?.atualizarGrid();
      }
    }

    const handleNovoRegistro = useNovoRegistroGrid(() => {
      setIdRegistroEdicao(0);
      setModalEdicaoVisivel(true);
    });

    const handleEditarRegistro = useEditarRegistroGrid((id: number) => {
      setIdRegistroEdicao(id);
      setModalEdicaoVisivel(true);
    });

    const handleExcluirRegistro = useExcluirRegistroGrid(
      async (registro: VinculoEnderecoEstoqueCentroDeTrabalhoGridModel) => {
        const mensagem = renderToString(<>{obterMensagemExclusao(registro)}</>);
        const excluir = await exibirConfirmacao("Confirmar exclusão", mensagem);

        if (excluir) {
          const resposta = await APIBase.excluir(registro.id, nomeEndpoint);

          if (resposta) {
            checarResponseExibeMensagemExclusaoDeSucesso(resposta);
            handleAtualizarGrid();
          }
        }
      }
    );

    function obterMensagemExclusao(
      registro: VinculoEnderecoEstoqueCentroDeTrabalhoGridModel
    ) {
      return `Tem certeza de que deseja excluir o vínculo entre ${registro.centroDeTrabalho} e ${registro.enderecoEstoque}?`;
    }

    const handleModalCallback = useCallback((info: CallBackModal) => {
      setModalEdicaoVisivel(false);
      setIdRegistroEdicao(NaN);

      if (info.precisaAtualizar) {
        handleAtualizarGrid();
      }
    }, []);

    return (
      <>
        <MxpGrid<VinculoEnderecoEstoqueCentroDeTrabalhoGridModel>
          dataSource={dataSource}
          id={"vinculo-endereco-estoque-centro-de-trabalho"}
          ref={gridRef}
          colunas={colunas}
          novoRegistro={handleNovoRegistro}
          editarRegistro={handleEditarRegistro}
          excluirRegistro={handleExcluirRegistro}
          nomeDoArquivoAoExportar={
            NomesTelas.vinculosDoCentroDeTrabalhoComEnderecosDeEstoque
          }
        />
        <EditFormVinculoEnderecoEstoqueCentroDeTrabalho
          visivel={modalEdicaoVisivel}
          idRegistroEdicao={idRegistroEdicao}
          configuracoesModal={{ largura: "max(50vw, 800px)", altura: "auto" }}
          callBackFecharModal={handleModalCallback}
        />
      </>
    );
  }
);
