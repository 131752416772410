import { yupResolver } from "@hookform/resolvers/yup";
import { Button, DataGrid } from "devextreme-react";
import {
  Column,
  ColumnFixing,
  Export,
  ExportTexts,
  Pager,
  Paging,
  Scrolling,
  Selection,
} from "devextreme-react/data-grid";
import ArrayStore from "devextreme/data/array_store";
import { CellPreparedEvent } from "devextreme/ui/data_grid";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ProvedorAjuda from "../../../components/ajuda/provedor-ajuda";
import {
  FormCheckBox,
  FormDateBox,
  FormMultiSelectBox,
  FormSelectBox,
  FormSelectBoxLazy,
} from "../../../components/formularios";
import { Coluna, Linha } from "../../../components/layout/grid-system";
import Pagina from "../../../components/layout/pagina";
import PainelFiltros from "../../../components/layout/painel-filtros";
import TextoEntreCampos from "../../../components/layout/texto-entre-campos";
import RequerPermissao from "../../../components/seguranca/requer-permissao";
import { useAppDispatch, useAppSelector } from "../../../hooks/store.hooks";
import {
  EstadoContaContabil,
  TipoAgrupamentoBalanceteComparativo,
} from "../../../models/api/contabilidade/balancete-comparativo";
import {
  ChavesBalanceteComparativoGridModel,
  PrefixosBalanceteComparativoGridModel,
} from "../../../models/const/contabilidade/balacete-comparativo-chaves";
import { PermissoesBalancete } from "../../../models/permissoes/contabilidade/balancete/permissoes-balancete";
import { DefinicaoColunaBalancete } from "../../../models/viewmodels/contabilidade/balancete-comparativo/balancete-comparativo-grid-view-model";
import {
  ColunasOcultaveisBalanceteComparativo,
  FiltrosBalanceteComparativoViewModel,
  OpcoesAgrupamentoBalanceteComparativo,
  OpcoesColunasOcultaveisBalanceteComparativo,
  OpcoesEstadosContaContabil,
} from "../../../models/viewmodels/contabilidade/balancete-comparativo/filtros-balancete-comparativo-view-model";
import { GridMinhaEmpresa } from "../../../parts/empresas/empresa/grids";
import "../../../parts/layout/grid-defaults/grid-defaults.scss";
import APIConfiguracoes from "../../../services/configuracoes/configuracoes.service";
import Api from "../../../services/contabilidade/balancete-comparativo/balancete-comparativo.service";
import APIEmpresas from "../../../services/empresa/empresa.service";
import { bloquearUI, desbloquearUI } from "../../../store/ui/ui.slice";
import { adaptRegistroBalanceteComparativo_to_BalanceteComparativoGridViewModel } from "../../../utils/adapters/contabilidade/balancete-comparativo-adapter";
import { checarResponse, tratarErroApi } from "../../../utils/api/api-utils";
import { obterDataLimite } from "../../../utils/common/date-utils";
import NomesTelas from "../../../utils/common/nomes-telas";
import { verificaSeUsuarioPossuiPermissoes } from "../../../utils/common/permissoes-utils";
import { getGridDefaultProps } from "../../../utils/grid/grid-utils";
import obterConfiguracaoColuna from "../../../utils/grid/padroes-colunas";
import { ContainerGrid } from "./styles";
import "./styles.css";

const PeriodoMaximoEmAnos = 5;
let dataSource: ArrayStore | undefined;
const dataSourceMinhaEmpresas =
  APIEmpresas.getMinhasEmpresasDataSourceSelectBoxLazy();

export default function BalanceteComparativoPage() {
  const [colunas, setColunas] = useState<DefinicaoColunaBalancete[]>([]);
  const [colunaCreditoVisivel, setColunaCreditoVisivel] = useState(false);
  const [colunaDebitoVisivel, setColunaDebitoVisivel] = useState(false);
  const [colunaLiquidoVisivel, setColunaLiquidoVisivel] = useState(false);
  const [colunaSaldoInicialVisivel, setColunaSaldoInicialVisivel] =
    useState(true);
  const [empresaPadraoFiltro, setEmpresaPadraoFiltro] = useState<number | null>(
    null
  );
  const [colunaSaldoFinalVisivel, setColunaSaldoFinalVisivel] = useState(true);
  const possuiPermissaoExportar = verificaSeUsuarioPossuiPermissoes(
    [PermissoesBalancete.Exportar],
    "todas"
  );

  const dispatch = useAppDispatch();
  const empresaAtual = useAppSelector(
    (state) => state.sessao.dadosSessao?.empresa?.id ?? null
  );
  const filtroPadrao: FiltrosBalanceteComparativoViewModel = {
    dataInicial: null,
    dataFinal: null,
    minhaEmpresaId: empresaPadraoFiltro,
    incluirLancamentosDeEncerramento: true,
    exibirContasZeradas: false,
    agrupamento: TipoAgrupamentoBalanceteComparativo.Mensal,
    colunasExibir: [
      ColunasOcultaveisBalanceteComparativo.SaldoInicial,
      ColunasOcultaveisBalanceteComparativo.SaldoFinal,
    ],
    estadoDaConta: [EstadoContaContabil.Ativas, EstadoContaContabil.Inativas],
  };

  let grid: DataGrid<any, any> | undefined;

  useEffect(() => {
    carregarDados();
    APIConfiguracoes.obterConfiguracoesDeEmpresa().then((response) => {
      setEmpresaPadraoFiltro(response.idEmpresaInicializacao);
    });
  }, [empresaAtual]);

  async function carregarDados() {
    dispatch(bloquearUI("Carregando..."));
    reset(filtroPadrao);
    dataSource = new ArrayStore();
    dispatch(desbloquearUI());
  }

  function setGridRef(ref: DataGrid<any, any> | undefined) {
    grid = ref;
  }

  async function handleBuscar() {
    try {
      dispatch(bloquearUI("Carregando..."));
      const model = getValues();
      alteraVisibilidadeDasColunas(model);

      const response = await Api.obterBalanceteComparativo(model);

      if (checarResponse(response)) {
        const dados =
          adaptRegistroBalanceteComparativo_to_BalanceteComparativoGridViewModel(
            response.model
          );
        dataSource = new ArrayStore({
          data: dados.dados,
        });
        setColunas(dados.competencias);
      }
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      grid?.instance?.refresh();
      dispatch(desbloquearUI());
    }
  }

  const exibeMinhaEmpresa = (c: any) => {
    if (c) {
      return c.CnpjCpfCodigo ? `${c.Apelido} - ${c.CnpjCpfCodigo}` : c.Apelido;
    }

    return "";
  };

  function alteraVisibilidadeDasColunas(
    model: FiltrosBalanceteComparativoViewModel
  ) {
    const creditoVisivel = model.colunasExibir.some(
      (x) => x === ColunasOcultaveisBalanceteComparativo.Creditos
    );
    const debitoVisivel = model.colunasExibir.some(
      (x) => x === ColunasOcultaveisBalanceteComparativo.Debitos
    );
    const liquidoVisivel = model.colunasExibir.some(
      (x) => x === ColunasOcultaveisBalanceteComparativo.Liquido
    );
    const saldoInicialVisivel = model.colunasExibir.some(
      (x) => x === ColunasOcultaveisBalanceteComparativo.SaldoInicial
    );
    const saldofinalVisivel = model.colunasExibir.some(
      (x) => x === ColunasOcultaveisBalanceteComparativo.SaldoFinal
    );

    if (colunaCreditoVisivel !== creditoVisivel) {
      setColunaCreditoVisivel(creditoVisivel);
    }

    if (colunaDebitoVisivel !== debitoVisivel) {
      setColunaDebitoVisivel(debitoVisivel);
    }

    if (colunaLiquidoVisivel !== liquidoVisivel) {
      setColunaLiquidoVisivel(liquidoVisivel);
    }

    if (colunaSaldoInicialVisivel !== saldoInicialVisivel) {
      setColunaSaldoInicialVisivel(saldoInicialVisivel);
    }

    if (colunaSaldoFinalVisivel !== saldofinalVisivel) {
      setColunaSaldoFinalVisivel(saldofinalVisivel);
    }
  }

  function revalidaDataFinal() {
    trigger("dataFinal");
  }

  function revalidaDataInicial() {
    trigger("dataInicial");
  }

  const schema = yup.object().shape({
    dataInicial: yup
      .string()
      .required()
      .test(
        "data_inicial_valida",
        "A data inicial está maior do que a final.",
        function (value) {
          const dtFinStr = getValues().dataFinal as string | null;
          if (value && dtFinStr) {
            const dataInicial = new Date(value);
            const dataFinal = new Date(dtFinStr);
            return dataInicial <= dataFinal;
          }
          return true;
        }
      )
      .test(
        "data_inicial_no_intervalo",
        `Período máximo de ${PeriodoMaximoEmAnos} anos.`,
        function (valor) {
          const dtFinStr = getValues().dataFinal as string | null;
          if (valor && dtFinStr) {
            const dataInicial = new Date(valor);
            const dataFinal = new Date(dtFinStr);
            const limite = obterDataLimite(
              dataInicial,
              PeriodoMaximoEmAnos * 12
            );
            return dataFinal <= limite;
          }
          return true;
        }
      ),
    dataFinal: yup
      .string()
      .required()
      .test(
        "data_final_valida",
        "A data final está menor do que a inicial.",
        function (valor) {
          const dtIniStr = getValues().dataInicial as string | null;
          if (valor && dtIniStr) {
            const dataInicial = new Date(dtIniStr);
            const dataFinal = new Date(valor);
            return dataInicial <= dataFinal;
          }
          return true;
        }
      )
      .test(
        "data_final_no_intervalo",
        `Período máximo de ${PeriodoMaximoEmAnos} anos.`,
        function (valor) {
          const dtIniStr = getValues().dataInicial as string | null;
          if (valor && dtIniStr) {
            const dataInicial = new Date(dtIniStr);
            const dataFinal = new Date(valor);
            const limite = obterDataLimite(
              dataInicial,
              PeriodoMaximoEmAnos * 12
            );
            return dataFinal <= limite;
          }
          return true;
        }
      ),
    minhaEmpresaId: yup.number().optional().nullable().integer().moreThan(0),
    incluirLancamentosDeEncerramento: yup.boolean().required(),
    exibirContasZeradas: yup.boolean().required(),
    estadoDaConta: yup.array(),
    colunasExibir: yup.array(),
    agrupamento: yup.number().required(),
  });

  const { control, handleSubmit, getValues, reset, trigger } =
    useForm<FiltrosBalanceteComparativoViewModel>({
      mode: "onChange",
      reValidateMode: "onChange",
      resolver: yupResolver(schema),
    });

  return (
    <Pagina
      id="balancete-comparativo-page"
      titulo={NomesTelas.balanceteComparativo}
      area="Contabilidade"
    >
      <div>
        <RequerPermissao codigoPermissoes={[PermissoesBalancete.Consultar]}>
          <PainelFiltros>
            <ProvedorAjuda id="balancete-comparativo">
              <form onSubmit={handleSubmit(handleBuscar)}>
                <Linha>
                  <Coluna md={2}>
                    <FormSelectBox
                      control={control}
                      titulo="Tipo de agrupamento"
                      name="agrupamento"
                      dataSource={OpcoesAgrupamentoBalanceteComparativo}
                    />
                  </Coluna>
                  <Coluna md={4}>
                    <div style={{ display: "flex" }}>
                      <div style={{ width: "50%" }}>
                        <FormDateBox
                          control={control}
                          name="dataInicial"
                          titulo="Período"
                          requerido
                          exibirBotaoLimpar
                          tipo="date"
                          aceitaValorCustomizado={true}
                          formatoExibicao="dd/MM/yy"
                          aceitaDigitacaoComMascara={true}
                          onChange={revalidaDataFinal}
                        />
                      </div>
                      <TextoEntreCampos>a</TextoEntreCampos>
                      <div style={{ marginLeft: "0.2em", width: "50%" }}>
                        <FormDateBox
                          control={control}
                          name="dataFinal"
                          exibirBotaoLimpar
                          tipo="date"
                          aceitaValorCustomizado={true}
                          formatoExibicao="dd/MM/yy"
                          aceitaDigitacaoComMascara={true}
                          onChange={revalidaDataInicial}
                        />
                      </div>
                    </div>
                  </Coluna>
                  <Coluna md={4}>
                    <FormSelectBoxLazy
                      name="minhaEmpresaId"
                      titulo="Minha empresa"
                      nomeCampoChave="Id"
                      nomeCampoExibicao={exibeMinhaEmpresa}
                      expressaoDeBusca={["Apelido", "CnpjCpfCodigo"]}
                      control={control}
                      lupaConfig={{
                        modo: "selecaoUnica",
                        titulo: "Selecionar Empresa",
                        componente: (r) => <GridMinhaEmpresa ref={r} />,
                      }}
                      dataSource={dataSourceMinhaEmpresas}
                      labelSemDados="Sem dados"
                    />
                  </Coluna>
                  <Coluna md={2} classe="centralizar-itens">
                    <FormCheckBox
                      control={control}
                      titulo="Incluir lançamentos de encerramento"
                      name="incluirLancamentosDeEncerramento"
                    />
                  </Coluna>
                </Linha>
                <Linha>
                  <Coluna md={2} classe="centralizar-itens">
                    <FormCheckBox
                      control={control}
                      titulo="Exibir contas zeradas"
                      name="exibirContasZeradas"
                    />
                  </Coluna>
                  <Coluna md={2}>
                    <FormMultiSelectBox
                      control={control}
                      titulo="Estado da conta"
                      name="estadoDaConta"
                      dataSource={OpcoesEstadosContaContabil}
                      habilitaBusca
                    />
                  </Coluna>
                  <Coluna md={4}>
                    <FormMultiSelectBox
                      control={control}
                      titulo="Colunas a exibir"
                      name="colunasExibir"
                      dataSource={OpcoesColunasOcultaveisBalanceteComparativo}
                      habilitaBusca
                    />
                  </Coluna>
                  <Coluna md={4}>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <div style={{ marginLeft: "auto", paddingTop: "1em" }}>
                        <Button
                          text="Filtrar"
                          type="success"
                          icon="filter"
                          useSubmitBehavior
                        />
                      </div>
                    </div>
                  </Coluna>
                </Linha>
              </form>
            </ProvedorAjuda>
          </PainelFiltros>
          <ContainerGrid>
            <DataGrid
              ref={setGridRef}
              dataSource={dataSource}
              {...getGridDefaultProps({
                nomeDoArquivoAoExportar: NomesTelas.balanceteComparativo,
                executarOperacoesNoServidor: false,
              })}
              height={"100%"}
              onCellPrepared={(e: CellPreparedEvent) => {
                if (e.data) {
                  if (e.data[ChavesBalanceteComparativoGridModel.Sintetica]) {
                    e.cellElement.classList.add("negrito");
                  }

                  if (
                    e.column.dataField ==
                    ChavesBalanceteComparativoGridModel.Descricao
                  ) {
                    e.cellElement.style.paddingLeft =
                      10 *
                        (e.data[ChavesBalanceteComparativoGridModel.Nivel] +
                          1) +
                      "px";
                  }
                }
              }}
            >
              <Export key="Export" enabled={possuiPermissaoExportar}>
                <ExportTexts
                  key="ExportTexts"
                  exportAll="Exportar todos os dados para o Excel"
                  exportSelectedRows="Exportar os dados selecionados para o Excel"
                />
              </Export>
              <Scrolling
                key="Scrolling"
                useNative={true}
                rowRenderingMode="standard"
              />
              <Paging key="Paging" defaultPageSize={400} />
              <Selection
                key="Selection"
                mode="single"
                selectAllMode="allPages"
              />
              <Pager
                key="Pager"
                allowedPageSizes={[50, 100, 200, 400]}
                displayMode="full"
                showPageSizeSelector
                showInfo
                showNavigationButtons
                visible
              />
              <ColumnFixing key="ColumnFixing" enabled={true} />,
              <Column
                dataField={ChavesBalanceteComparativoGridModel.Codigo}
                caption="Código"
                {...obterConfiguracaoColuna("codigoNumerico")}
                allowReordering={false}
                allowFiltering={false}
                allowSorting={false}
              />
              <Column
                dataField={ChavesBalanceteComparativoGridModel.Classificacao}
                caption="Classificação"
                {...obterConfiguracaoColuna("stringMP")}
                allowReordering={false}
                allowFiltering={false}
                allowSorting={false}
              />
              <Column
                dataField={ChavesBalanceteComparativoGridModel.Descricao}
                caption="Descrição"
                {...obterConfiguracaoColuna("stringGG")}
                allowReordering={false}
                allowFiltering={false}
                allowSorting={false}
              />
              {colunas.map((competencia) => (
                <Column
                  key={competencia.chave}
                  caption={competencia.descricao}
                  allowFixing={false}
                  alignment="center"
                  allowResizing
                >
                  <Column
                    key={`${PrefixosBalanceteComparativoGridModel.SaldoInicial}${competencia.chave}`}
                    dataField={`${PrefixosBalanceteComparativoGridModel.SaldoInicial}${competencia.chave}`}
                    caption="Saldo inicial"
                    {...obterConfiguracaoColuna("monetarioBalanceteCD")}
                    allowReordering={false}
                    allowFiltering={false}
                    allowSorting={false}
                    allowFixing={false}
                    visible={colunaSaldoInicialVisivel}
                  />
                  <Column
                    key={`${PrefixosBalanceteComparativoGridModel.Debitos}${competencia.chave}`}
                    dataField={`${PrefixosBalanceteComparativoGridModel.Debitos}${competencia.chave}`}
                    caption="Débitos"
                    {...obterConfiguracaoColuna("monetarioBalancete")}
                    allowReordering={false}
                    allowFiltering={false}
                    allowSorting={false}
                    allowFixing={false}
                    visible={colunaDebitoVisivel}
                  />
                  <Column
                    key={`${PrefixosBalanceteComparativoGridModel.Creditos}${competencia.chave}`}
                    dataField={`${PrefixosBalanceteComparativoGridModel.Creditos}${competencia.chave}`}
                    caption="Créditos"
                    {...obterConfiguracaoColuna("monetarioBalancete")}
                    allowReordering={false}
                    allowFiltering={false}
                    allowSorting={false}
                    allowFixing={false}
                    visible={colunaCreditoVisivel}
                  />
                  <Column
                    key={`${PrefixosBalanceteComparativoGridModel.Liquido}${competencia.chave}`}
                    dataField={`${PrefixosBalanceteComparativoGridModel.Liquido}${competencia.chave}`}
                    caption="Líquido"
                    {...obterConfiguracaoColuna("monetarioBalanceteCD")}
                    allowReordering={false}
                    allowFiltering={false}
                    allowSorting={false}
                    allowFixing={false}
                    visible={colunaLiquidoVisivel}
                  />
                  <Column
                    key={`${PrefixosBalanceteComparativoGridModel.SaldoFinal}${competencia.chave}`}
                    dataField={`${PrefixosBalanceteComparativoGridModel.SaldoFinal}${competencia.chave}`}
                    caption="Saldo final"
                    {...obterConfiguracaoColuna("monetarioBalanceteCD")}
                    allowReordering={false}
                    allowFiltering={false}
                    allowSorting={false}
                    allowFixing={false}
                    visible={colunaSaldoFinalVisivel}
                  />
                </Column>
              ))}
              <Column
                key="ColunaDeEspaco"
                width="100%"
                name="colunaDeEspaco"
                cssClass="coluna-de-espaco"
                visibleIndex={1000}
                allowReordering={false}
                showInColumnChooser={false}
                allowHiding={false}
                allowResizing={false}
                allowSorting={false}
                allowSearch={false}
                allowFixing={false}
                fixedPosition="right"
              />
            </DataGrid>
          </ContainerGrid>
        </RequerPermissao>
      </div>
    </Pagina>
  );
}
