import { DataGrid } from "devextreme-react";
import { Column, DataGridTypes } from "devextreme-react/cjs/data-grid";
import { RowPreparedEvent } from "devextreme/ui/data_grid";
import { useCallback, useState } from "react";
import { useEditarRegistroGrid } from "../../../../../hooks/grid.hooks";
import { ContaDeCustosDaEcfOperacao } from "../../../../../models/api/conta-de-custos-ecf/conta-de-custos-ecf";
import { LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcfGridModel } from "../../../../../models/api/informativo-composicao-custos-lucro-real/linha-apuracao-informativo-composicao-custos-ecf";
import { CallBackModal } from "../../../../../models/shared/ui/callback-modal";
import LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcfService from "../../../../../services/informativo-composicao-custos-lucro-real/linha-apuracao-informativo-composicao-custos-ecf";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import {
  criarItensMenuContextoPadrao,
  tratarDadosContextMenu,
} from "../../../../../utils/context-menu/context-menu-utils";
import { formatarNumero } from "../../../../../utils/formatadores/formatador-de-numeros";
import { getGridDefaultProps } from "../../../../../utils/grid/grid-utils";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import GridDefaults from "../../../../layout/grid-defaults";
import GetColunasDeAuditoria from "../../../../layout/grid-defaults/colunasDeAuditoria";
import ModalEdicaoValorLinhaApuracaoInformativoComposicaoCustos from "../modal-edicao-valor-linha-apuracao";

const nameOfGridHandler =
  criarNameof<LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcfGridModel>();
const linhaApuracaoDoInformativoDaComposicaoDeCustosDaEcfService =
  new LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcfService();

interface GridInternaLinhaApuracaoInformativoComposicaoCustosEcfProps {
  idApuracaoDoDoInformativoDaComposicaoDeCustosDaEcf: number;
  modalPaiVisivel: boolean;
}

function formatarLinhasGrid(e: RowPreparedEvent<any, any>) {
  if (e.rowType != "data") {
    return;
  }

  const dados: LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcfGridModel =
    e.data;

  if (
    dados.operacao == ContaDeCustosDaEcfOperacao.Incremento ||
    dados.operacao == ContaDeCustosDaEcfOperacao.Decremento
  ) {
    return;
  }

  if (dados.operacao == ContaDeCustosDaEcfOperacao.Separador) {
    e.rowElement.setAttribute("style", "background-color: #dcdcdc;");
  } else if (dados.operacao == ContaDeCustosDaEcfOperacao.Totalizador) {
    e.rowElement.setAttribute("style", "font-weight: bold;");
  }
}

export default function GridInternaGridInternaLinhaApuracaoInformativoComposicaoCustosEcf(
  props: GridInternaLinhaApuracaoInformativoComposicaoCustosEcfProps
) {
  const [modalEdicaoVisivel, setModalEdicaoVisivel] = useState(false);
  const [idRegistroEdicao, setIdRegistroEdicao] = useState(NaN);

  let gridRef: DataGrid<any, any> = new DataGrid<any, any>({});
  function setGridRef(ref: any) {
    gridRef = ref;
  }

  function getGridRef() {
    return gridRef;
  }

  function atualizarGrid() {
    gridRef.instance.refresh();
  }

  const handleEditarRegistro = useEditarRegistroGrid((id) => {
    setIdRegistroEdicao(id);
    setModalEdicaoVisivel(true);
  });

  const handleModalCallback = useCallback((info: CallBackModal) => {
    setModalEdicaoVisivel(false);
    setIdRegistroEdicao(NaN);

    if (info.precisaAtualizar) {
      atualizarGrid();
    }
  }, []);

  function gerarContextMenu(data: any) {
    const [getData] =
      tratarDadosContextMenu<LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcfGridModel>(
        data,
        gridRef,
        getGridRef
      );

    return criarItensMenuContextoPadrao({
      editar: () => {
        const data = getData();
        handleEditarRegistro(data!.id);
      },
      exibirIconeEditarMenuAcoes:
        getData()?.operacao == ContaDeCustosDaEcfOperacao.Incremento ||
        getData()?.operacao == ContaDeCustosDaEcfOperacao.Decremento,
    });
  }

  return (
    <>
      <DataGrid
        ref={setGridRef}
        dataSource={
          props.modalPaiVisivel &&
          linhaApuracaoDoInformativoDaComposicaoDeCustosDaEcfService.GetGridSourceDaApuracao(
            props.idApuracaoDoDoInformativoDaComposicaoDeCustosDaEcf
          )
        }
        {...getGridDefaultProps({
          nomeDoArquivoAoExportar:
            NomesTelas.informativoDaComposicaoDeCustosDoLucroReal,
          getGridRef: getGridRef,
          gerarOpcoesDoRegistro: gerarContextMenu,
          style: {
            height: "100%",
          },
        })}
        onRowPrepared={formatarLinhasGrid}
      >
        {GridDefaults({
          gridId:
            "grid-linha-apuracao-informativo-composicao-custos-ecf-editform-apuracao",
          atualizarGrid,
          exportavel: false,
          filtravel: false,
          paginavel: false,
          selecionavel: false,
          ordenavel: false,
          getGridRef,
          gerarContextMenu,
        })}

        <Column
          key={nameOfGridHandler("codigo")}
          dataField={nameOfGridHandler("codigo")}
          {...obterConfiguracaoColuna("stringP")}
          caption="Código"
          width={70}
        />
        <Column
          key={nameOfGridHandler("descricao")}
          dataField={nameOfGridHandler("descricao")}
          {...obterConfiguracaoColuna("stringXXG")}
          caption="Descrição"
          width={600}
        />
        <Column
          key={nameOfGridHandler("valor")}
          dataField={nameOfGridHandler("valor")}
          {...obterConfiguracaoColuna("monetario")}
          caption="Valor"
          cellRender={(data: DataGridTypes.ColumnCellTemplateData) => {
            const dadosLinha: LinhaApuracaoDoInformativoDaComposicaoDeCustosDaEcfGridModel =
              data.row.data;

            const valorFormatado =
              dadosLinha.valor != undefined
                ? formatarNumero(dadosLinha.valor, 2)
                : "";

            if (dadosLinha.valorEstaFixo) {
              return (
                <>
                  {valorFormatado}
                  <span
                    style={{ fontSize: "16px", marginLeft: "2px" }}
                    className={`ic-material-symbols-outlined ic-keep`}
                    title="O valor está fixo"
                  ></span>
                </>
              );
            }

            return <>{valorFormatado}</>;
          }}
        />
        {GetColunasDeAuditoria()}
      </DataGrid>

      <ModalEdicaoValorLinhaApuracaoInformativoComposicaoCustos
        idRegistroEdicao={idRegistroEdicao}
        visivel={modalEdicaoVisivel}
        callBackFecharModal={handleModalCallback}
        configuracoesModal={{ largura: "max(30vw, 600px)" }}
      />
    </>
  );
}
