import DataSource from "devextreme/data/data_source";
import { SelectBoxLazyOpcoes } from "../../../models/api/comum/selectboxlazy-options";
import { criarDataSourceSelectBoxLazy } from "../../../utils/api/api-utils";
import criarDatasourceGrid from "../../../utils/grid/back-end-grid-utils";
import { NomesEndpoints } from "../../comum/nomesEndpoints";

export class ContaContabilService {
  private _nomeEndpoint: NomesEndpoints;

  constructor() {
    this._nomeEndpoint = NomesEndpoints.ContaContabil;
  }

  public GetSelectBoxLazyDataSource(opcoes: SelectBoxLazyOpcoes) {
    const camposSelect = JSON.stringify(opcoes.camposRetorno);

    const ds = criarDatasourceGrid(
      `${this._nomeEndpoint}/grid?select=${camposSelect}`
    );

    return criarDataSourceSelectBoxLazy(ds, opcoes);
  }

  public GetDataSourceGrid() {
    return new DataSource({
      store: criarDatasourceGrid(`${this._nomeEndpoint}/grid`),
    });
  }
}
