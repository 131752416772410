import { useEffect, useState } from "react";
import * as yup from "yup";
import { FormGrupo, FormTextBox } from "../../../../components/formularios";
import {
  FormBase2,
  FormularioEdicaoBaseProps,
} from "../../../../components/layout/form-base2";
import { Coluna, Linha } from "../../../../components/layout/grid-system";
import {
  useHookForms,
  useLimparFormSeIdForNaN,
} from "../../../../hooks/form.hooks";
import { RecuperarSenha } from "../../../../models/api/controle-de-acesso/controle-de-acesso";
import { CallBackModal } from "../../../../models/shared/ui/callback-modal";
import API from "../../../../services/controle-acesso/controle-acesso.service";
import { checarResponse, tratarErroApi } from "../../../../utils/api/api-utils";
import criarNameof from "../../../../utils/common/cria-name-of";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../utils/common/notificacoes-utils";

interface EditFormRecuperacaoSenhaProps extends FormularioEdicaoBaseProps {
  email: string;
}
const novoRegistro: RecuperarSenha = {
  usuarioEmail: "",
};

export const EditFormRecuperacaoSenha = (
  props: EditFormRecuperacaoSenhaProps
) => {
  const nameOfHandler = criarNameof<RecuperarSenha>();

  const [carregando, setCarregando] = useState(false);

  const schema = yup.object().shape({
    usuarioEmail: yup.string().email().required("Campo obrigatório"),
  });

  const hookForm = useHookForms<RecuperarSenha>(schema);
  useLimparFormSeIdForNaN(hookForm, novoRegistro, props.idRegistroEdicao);

  const { getValues, control, handleSubmit, setValue } = hookForm;

  useEffect(() => {
    setValue(nameOfHandler("usuarioEmail"), props.email);
  }, [props.email]);

  function fechar(info: CallBackModal) {
    if (props.callBackFecharModal) {
      props.callBackFecharModal(info);
    }
  }

  function handleCancelar() {
    fechar({ concluido: false, precisaAtualizar: false });
  }

  async function handleRecuperar() {
    setCarregando(true);
    const model = getValues();
    try {
      const resposta = await API.EnviarEmailRecuperacaoSenha(
        model.usuarioEmail
      );
      checarResponse(resposta);
      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar({
          concluido: true,
          precisaAtualizar: true,
        });
      }
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  function handleEnter() {
    document.activeElement?.dispatchEvent(new Event("blur"));
    form?.requestSubmit();
  }

  let form: HTMLFormElement | null;

  return (
    <FormBase2
      visivel={props.visivel}
      carregando={carregando}
      titulo={"Esqueci minha senha"}
      onClickSalvar={handleSubmit(handleRecuperar)}
      onClickCancelar={handleCancelar}
      configuracoesModal={props.configuracoesModal}
      modoEdicao="não aplicável"
      botaoSalvar={{ texto: "Recuperar minha senha", icon: "" }}
    >
      <FormGrupo>
        <Linha>
          <Coluna md={12}>
            <FormTextBox
              name={nameOfHandler("usuarioEmail")}
              titulo="E-mail"
              control={control}
              requerido
              tamanhoMaximo={120}
              onEnter={handleEnter}
              tipo="email"
            />
          </Coluna>
        </Linha>
        <Linha style={{ marginTop: "5px" }}>
          <Coluna md={12}>
            <span>
              Um e-mail com instruções será enviado para o seu e-mail.
            </span>
          </Coluna>
        </Linha>
      </FormGrupo>
    </FormBase2>
  );
};
