import getApi from "../../configs/api";
import {
  ResponseBase,
  ResponseModel,
} from "../../models/api/comum/response-base";
import {
  RecuperarSenhaViaEmailRequest,
  VerificacaoLinkRecuperacaoSenhaRequest,
} from "../../models/api/controle-de-acesso/controle-de-acesso";
import criarDatasourceGrid from "../../utils/grid/back-end-grid-utils";

const nomeEndpoint: string = "ControleDeAcesso";

async function EnviarEmailRecuperacaoSenha(usuarioEmail: string) {
  const api = getApi();
  const response = await api.post<ResponseBase>(
    `${nomeEndpoint}/EnviarEmailRecuperacaoSenha?email=${usuarioEmail}`
  );

  return response.data;
}

async function VerificarSeHashDeRecuperacaoDeSenhaEstaValido(
  request: VerificacaoLinkRecuperacaoSenhaRequest
) {
  const api = getApi();

  const response = await api.post<ResponseModel<boolean>>(
    `${nomeEndpoint}/VerificarHashEmailRecuperacaoSenha`,
    request
  );
  return response.data;
}

async function RecuperarSenha(request: RecuperarSenhaViaEmailRequest) {
  const api = getApi();
  const response = await api.post<ResponseBase>(
    `${nomeEndpoint}/RecuperarSenha`,
    request
  );
  return response.data;
}

function GetGridSource() {
  return criarDatasourceGrid(`${nomeEndpoint}/UsuariosLogadosGrid`);
}

async function ForcarLogoff(idUsuario: number) {
  const api = getApi();

  const response = await api.post<ResponseBase>(
    `${nomeEndpoint}/ForcarLogoff?idUsuario=${idUsuario}`
  );
  return response.data;
}

export default {
  EnviarEmailRecuperacaoSenha,
  VerificarSeHashDeRecuperacaoDeSenhaEstaValido,
  RecuperarSenha,
  GetGridSource,
  ForcarLogoff,
};
