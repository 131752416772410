import { CentroDeTrabalhoGridModel } from "../../../../models/api/centro-de-trabalho/centro-de-trabalho";
import criarNameof from "../../../common/cria-name-of";

export default class FiltrosGridCentroDeTrabalho {
  static readonly centroDeTrabalhoAtivo: any[] = [
    criarNameof<CentroDeTrabalhoGridModel>()("ativo"),
    "=",
    true,
  ];
}
