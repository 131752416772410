import { TabPanel } from "devextreme-react";

export function definirIndiceSelecionadoTabPanel(
  tabPanel: TabPanel | null | undefined,
  indice: number
) {
  tabPanel?.instance?.option("selectedIndex", indice);
}

export function obterIndiceSelecionadoTabPanel(
  tabPanel: TabPanel | null | undefined
) {
  return tabPanel?.instance?.option("selectedIndex") ?? -1;
}
