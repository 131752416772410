import { useState } from "react";
import * as yup from "yup";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import { FormDateBox } from "../../../../../components/formularios";
import {
  FormBase2,
  FormularioEdicaoBaseProps,
} from "../../../../../components/layout/form-base2";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import {
  useCarregarRegistro,
  useHookForms,
  useLimparFormSeIdForNaN,
} from "../../../../../hooks/form.hooks";
import AuditavelDTO from "../../../../../models/api/comum/auditavel-dto";
import {
  EfdReinfApuracaoRequestDTO,
  EfdReinfApuracaoResponseDTO,
} from "../../../../../models/api/efd-reinf/apuracao/apuracao";
import { CallBackModal } from "../../../../../models/shared/ui/callback-modal";
import { NomesEndpoints } from "../../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../../services/comum/serviceBase";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import NomesModais from "../../../../../utils/common/nomes-modais";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";

const nomeEndpoint = NomesEndpoints.EfdReinfApuracao;
const novoRegistro: EfdReinfApuracaoRequestDTO = {
  dataInicial: "",
  dataFinal: "",
};

export const EditFormEfdReinfApuracao = (props: FormularioEdicaoBaseProps) => {
  const [carregando, setCarregando] = useState(false);
  const [dadosAuditoria, setDadosAuditoria] = useState<AuditavelDTO>();

  const schema = yup.object().shape({
    dataInicial: yup
      .string()
      .required()
      .test(
        "data_inicial_valida",
        "A data inicial está maior do que a final.",
        function (value, contexto) {
          if (value && contexto.parent.dataFinal) {
            const dataInicial = new Date(value);
            const dataFinal = new Date(contexto.parent.dataFinal);
            return dataInicial <= dataFinal;
          }
          return true;
        }
      ),
    dataFinal: yup
      .string()
      .required()
      .test(
        "data_final_valida",
        "A data final está menor do que a inicial.",
        function (valor, contexto) {
          if (valor && contexto.parent.dataInicial) {
            const dataInicial = new Date(contexto.parent.dataInicial);
            const dataFinal = new Date(valor);
            return dataInicial <= dataFinal;
          }
          return true;
        }
      ),
  });

  const hookForm = useHookForms(schema);
  useCarregarRegistro(props.idRegistroEdicao, carregarTela);
  useLimparFormSeIdForNaN(hookForm, novoRegistro, props.idRegistroEdicao);

  const { control, handleSubmit, getValues, reset } = hookForm;

  async function carregarTela() {
    try {
      setCarregando(true);
      if (props.idRegistroEdicao > 0) {
        await carregarModel();
      } else {
        reset(novoRegistro);
        setDadosAuditoria(undefined);
      }
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  async function carregarModel() {
    try {
      const resposta =
        await APIBase.obterPorIdComDadosAuditoria<EfdReinfApuracaoResponseDTO>(
          props.idRegistroEdicao,
          nomeEndpoint
        );
      checarResponse(resposta);
      reset(resposta.model);
      setDadosAuditoria(resposta.model);
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    }
  }

  function fechar(info: CallBackModal) {
    if (props.callBackFecharModal) {
      setDadosAuditoria(undefined);
      props.callBackFecharModal(info);
    }
  }

  function handleCancelar() {
    fechar({ concluido: false, precisaAtualizar: false });
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues();
    try {
      const resposta =
        props.idRegistroEdicao > 0
          ? await APIBase.atualizar(model, nomeEndpoint)
          : await APIBase.cadastrar(model, nomeEndpoint);

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar({
          concluido: true,
          precisaAtualizar: true,
        });
      }
      fechar({
        concluido: true,
        precisaAtualizar: false,
      });
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  function callBackUnprocessableEntity() {
    fechar({
      concluido: false,
      precisaAtualizar: true,
    });
  }

  return (
    <>
      <div id="edit-form-efd-reinf-apuracao">
        <FormBase2
          visivel={props.visivel}
          carregando={carregando}
          onClickSalvar={handleSubmit(handleSalvar)}
          onClickCancelar={handleCancelar}
          configuracoesModal={props.configuracoesModal}
          modoEdicao={props.idRegistroEdicao == 0 ? "criar" : "editar"}
          titulo={NomesModais.efdReinfApuracao}
          isNomeTelaFeminino={true}
          auditoria={dadosAuditoria}
        >
          <ProvedorAjuda id="edit-form-efd-reinf-apuracao">
            <Linha>
              <Coluna md={3}>
                <FormDateBox
                  name="dataInicial"
                  titulo="Período de"
                  control={control}
                  requerido
                  exibirBotaoLimpar
                  tipo="date"
                  aceitaValorCustomizado={true}
                  formatoExibicao="dd/MM/yy"
                  aceitaDigitacaoComMascara={true}
                  somenteLeitura={props.idRegistroEdicao > 0}
                />
              </Coluna>
              <Coluna md={3}>
                <FormDateBox
                  name="dataFinal"
                  titulo="a"
                  control={control}
                  requerido
                  exibirBotaoLimpar
                  tipo="date"
                  aceitaValorCustomizado={true}
                  formatoExibicao="dd/MM/yy"
                  aceitaDigitacaoComMascara={true}
                  somenteLeitura={props.idRegistroEdicao > 0}
                />
              </Coluna>
            </Linha>
          </ProvedorAjuda>
        </FormBase2>
      </div>
    </>
  );
};
