import { OrdemDeProducaoGridModel } from "../../../../models/api/producao/ordem-de-producao/ordem-de-producao";
import { EstadoDecodificado } from "../../../../models/api/producao/ordem-de-producao/ordem-de-producao-enums";
import criarNameof from "../../../common/cria-name-of";

export default class FiltrosGridOrdemDeProducao {
  static readonly ordemDeProducaoEstado: any[] = [
    criarNameof<OrdemDeProducaoGridModel>()("estadoDecodificado"),
    "anyof",
    [EstadoDecodificado.AProduzir, EstadoDecodificado.Sugerida],
  ];
}
