import DataSource from "devextreme/data/data_source";
import { AdicaoExclusaoCompensacaoApuracaoLalurIrpjGrid } from "../../models/api/adicao-exclusao-compensacao-apuracao/adicao-exclusao-compensacao-apuracao-lalur-irpj/adicao-exclusao-compensacao-apuracao-lalur-irpj";
import { AdicaoOuExclusaoOuCompensacao } from "../../models/api/adicao-exclusao-compensacao/adicao-exclusao-compensacao-enums";
import { SelectBoxLazyOpcoes } from "../../models/api/comum/selectboxlazy-options";
import { criarDataSourceSelectBoxLazy } from "../../utils/api/api-utils";
import criarNameof from "../../utils/common/cria-name-of";
import criarDatasourceGrid from "../../utils/grid/back-end-grid-utils";
import { NomesEndpoints } from "../comum/nomesEndpoints";

export default class AdicaoExclusaoCompensacaoApuracaoLalurIrpjService {
  private _nomeEndpoint: NomesEndpoints;

  constructor() {
    this._nomeEndpoint =
      NomesEndpoints.AdicaoExclusaoCompensacaoApuracaoLalurIrpj;
  }

  public ObterGridSource(
    apuracaoDoLalurEIrpjId: number,
    adicaoOuExclusaoOuCompensacao: AdicaoOuExclusaoOuCompensacao
  ) {
    const dataSource = criarDatasourceGrid(`${this._nomeEndpoint}/grid`);

    const nameof =
      criarNameof<AdicaoExclusaoCompensacaoApuracaoLalurIrpjGrid>();

    const ds = new DataSource({
      store: dataSource,
      paginate: true,
      filter: [
        [nameof("apuracaoDoLalurEIrpjId"), "=", apuracaoDoLalurEIrpjId],
        "and",
        [
          nameof("adicaoOuExclusaoOuCompensacao"),
          "=",
          adicaoOuExclusaoOuCompensacao,
        ],
      ],
    });

    return ds;
  }

  public ObterDataSourceSelectBoxLazy(opcoes: SelectBoxLazyOpcoes) {
    const camposSelect = JSON.stringify(opcoes.camposRetorno);

    const dataSource = criarDatasourceGrid(
      `${this._nomeEndpoint}/grid?select=${camposSelect}`
    );

    return criarDataSourceSelectBoxLazy(dataSource, opcoes);
  }
}
