import { Column } from "devextreme-react/data-grid";
import { forwardRef, useCallback, useRef, useState } from "react";
import { renderToString } from "react-dom/server";
import ImportadorDeRegistros, {
  MxpSchemaImportar,
  colunaUtcParaData,
} from "../../../../../components/formularios/importador";
import { MxpGrid } from "../../../../../components/grid";
import {
  useEditarRegistroGrid,
  useExcluirRegistroComPermissaoGrid,
  useGerarItensAdicionais,
  useNovoRegistroGrid,
  usePropagarReferenciaGrid,
} from "../../../../../hooks/grid.hooks";
import {
  LoteGridModel,
  loteDoFabricanteOuNumeroDeSerieMaxLength,
} from "../../../../../models/api/lote/lote";
import { PermissoesLote } from "../../../../../models/direitos/estoque/lote/permissoes-lote";
import { CallBackModal } from "../../../../../models/shared/ui/callback-modal";
import { IGridSelecao } from "../../../../../models/shared/ui/formularios";
import { EditFormLote } from "../../../../../pages/estoque/lote/edit-form";
import { NomesEndpoints } from "../../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../../services/comum/serviceBase";
import ApiLote from "../../../../../services/lote/lote.service";
import {
  checarResponseExibeMensagemExclusaoDeSucesso,
  checarResponseExibeMensagemExecutadoComSucesso,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";

import { exibirConfirmacao } from "../../../../../utils/dialogos";

import { GridBaseProps } from "../../../../../utils/grid/grid-utils";

import { ItemContextMenu } from "../../../../../utils/context-menu/context-menu-utils";
import {
  abrirModalDetalhesDoLoteMxp1,
  criarBoletimDeInspecao,
} from "../../../../../utils/especifico/lote/lote-utils";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import yupPt from "../../../../../utils/validacao/validacao";
import GetColunasDeAuditoria from "../../../../layout/grid-defaults/colunasDeAuditoria";

const nomeEndpoint = NomesEndpoints.Lote;

const dataSource = APIBase.getGridSource(nomeEndpoint);

const nameOfGridHandler = criarNameof<LoteGridModel>();

const schema: MxpSchemaImportar = {
  "Lote do fabricante/número de série*": {
    prop: "loteDoFabricanteOuNumeroDeSerie",
    type: String,
    dataType: "string",
  },
  "Item*": {
    // JSON object property name.
    prop: "codigoItem",
    type: String,
    dataType: "string",
  },
  "Fabricante*": {
    prop: "codigoFabricante",
    type: String,
    dataType: "string",
  },
  "Data de fabricação": {
    prop: "fabricacaoData",
    type: colunaUtcParaData,
    dataType: "date",
  },
  "Data de vencimento": {
    prop: "vencimentoData",
    type: colunaUtcParaData,
    dataType: "date",
  },
  "Data de reanálise": {
    prop: "reanaliseData",
    type: colunaUtcParaData,
    dataType: "date",
  },
  Observações: {
    prop: "observacoes",
    type: String,
    dataType: "string",
  },
  "Observações internas": {
    prop: "observacoesInternas",
    type: String,
    dataType: "number",
  },
};

const schemaYup = yupPt.object().shape({
  codigoItem: yupPt.string().label("Item*").required(),
  codigoFabricante: yupPt.string().label("Fabricante*").required(),
  loteDoFabricanteOuNumeroDeSerie: yupPt
    .string()
    .label("Lote do fabricante/número de serie*")
    .required()
    .max(loteDoFabricanteOuNumeroDeSerieMaxLength),
});

const colunas = [
  <Column
    key={nameOfGridHandler("item")}
    dataField={nameOfGridHandler("item")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Item"
  />,
  <Column
    key={nameOfGridHandler("descricao")}
    dataField={nameOfGridHandler("descricao")}
    {...obterConfiguracaoColuna("stringGG")}
    caption="Descrição"
  />,
  <Column
    key={nameOfGridHandler("fabricante")}
    dataField={nameOfGridHandler("fabricante")}
    {...obterConfiguracaoColuna("stringGG")}
    caption="Fabricante"
  />,
  <Column
    key={nameOfGridHandler("loteDoFabricanteOuNumeroDeSerie")}
    dataField={nameOfGridHandler("loteDoFabricanteOuNumeroDeSerie")}
    {...obterConfiguracaoColuna("stringGG")}
    caption="Lote/número de série do fabricante"
    alignment="right"
  />,
  <Column
    key={nameOfGridHandler("estocagem")}
    dataField={nameOfGridHandler("estocagem")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Estocagem"
    visible={false}
  />,
  <Column
    key={nameOfGridHandler("dono")}
    dataField={nameOfGridHandler("dono")}
    {...obterConfiguracaoColuna("stringGG")}
    caption="Dono"
  />,
  <Column
    key={nameOfGridHandler("reanalise")}
    dataField={nameOfGridHandler("reanalise")}
    {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
    caption="Reanálise"
    visible={false}
    width={115}
  />,
  <Column
    key={nameOfGridHandler("vencimento")}
    dataField={nameOfGridHandler("vencimento")}
    {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
    caption="Vencimento"
    width={115}
  />,
  <Column
    key={nameOfGridHandler("fabricacao")}
    dataField={nameOfGridHandler("fabricacao")}
    {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
    caption="Fabricação"
    visible={false}
    width={115}
  />,
  <Column
    key={nameOfGridHandler("observacoes")}
    dataField={nameOfGridHandler("observacoes")}
    {...obterConfiguracaoColuna("stringGG")}
    caption="Observações"
  />,
  <Column
    key={nameOfGridHandler("observacoesInternas")}
    dataField={nameOfGridHandler("observacoesInternas")}
    {...obterConfiguracaoColuna("stringGG")}
    caption="Observações internas"
    visible={false}
  />,
  <Column
    key={nameOfGridHandler("estadoDecodificado")}
    dataField={nameOfGridHandler("estadoDecodificado")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Estado"
    visible={false}
  />,
  ...GetColunasDeAuditoria(true, false, [
    { ordenarPor: "criacaoData", ordem: "desc" },
  ]),
];

export const GridLote = forwardRef((props: GridBaseProps, ref) => {
  const gridRef = useRef<IGridSelecao>(null);
  const [modalEdicaoVisivel, setModalEdicaoVisivel] = useState(false);
  const [importarVisivel, setImportarVisivel] = useState(false);
  const [idRegistroEdicao, setIdRegistroEdicao] = useState(NaN);

  const handleModalCallback = useCallback((info: CallBackModal) => {
    setModalEdicaoVisivel(false);
    setIdRegistroEdicao(NaN);

    if (info.precisaAtualizar) {
      handleAtualizarGrid();
    }
  }, []);

  usePropagarReferenciaGrid(ref, gridRef);

  function handleAtualizarGrid() {
    if (gridRef.current?.atualizarGrid) {
      gridRef.current?.atualizarGrid();
    }
  }

  const handleNovoRegistro = useNovoRegistroGrid(() => {
    if (
      verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesLote.InserirEditar,
      ])
    ) {
      setIdRegistroEdicao(0);
      setModalEdicaoVisivel(true);
    }
  });

  const handleEditarRegistro = useEditarRegistroGrid((id: number) => {
    if (
      verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesLote.InserirEditar,
      ])
    ) {
      setIdRegistroEdicao(id);
      setModalEdicaoVisivel(true);
    }
  });

  function handleCancelarLote(loteId: number | undefined) {
    if (!loteId) return;

    const mensagem = renderToString(<>{obterMensagemCancelamento()}</>);
    exibirConfirmacao("Confirmar cancelamento", mensagem).then((confirmado) => {
      if (confirmado) {
        ApiLote.cancelar(loteId)
          .then((resposta) => {
            if (resposta) {
              checarResponseExibeMensagemExecutadoComSucesso(resposta);
              handleAtualizarGrid();
            }
          })
          .catch((erro) => tratarErroApi(erro));
      }
    });
  }

  const handleExcluirRegistro = useExcluirRegistroComPermissaoGrid(
    obterMensagemExclusao,
    excluirRegistro,
    PermissoesLote.Excluir
  );

  async function excluirRegistro(registro: LoteGridModel) {
    try {
      const resposta = await APIBase.excluir(registro.id, nomeEndpoint);

      if (resposta) {
        checarResponseExibeMensagemExclusaoDeSucesso(resposta);
        handleAtualizarGrid();
      }
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  function obterMensagemExclusao(registro: LoteGridModel) {
    return `Tem certeza de que deseja excluir o lote ${registro.loteDoFabricanteOuNumeroDeSerie}?`;
  }

  function obterMensagemCancelamento() {
    return "Tem certeza de que deseja cancelar o lote selecionado?";
  }

  const acaoCancelaLote = (
    getData: () => LoteGridModel | undefined,
    ocultarNoMaisOpcoes: boolean
  ) => {
    return {
      text: "Cancelar lote",
      icon: "ic-material-symbols-outlined ic-cancel",
      ocultarNoMaisOpcoes: ocultarNoMaisOpcoes,
      ocultarNoMenuPrincipal: true,
      onClick: () => handleCancelarLote(getData()?.id),
    };
  };

  const handleGerarItensAdicionais = useGerarItensAdicionais(
    (getData: () => LoteGridModel | undefined): ItemContextMenu[] => {
      const itensAdicionais: ItemContextMenu[] = [
        {
          text: "Imprimir",
          icon: "ic-material-symbols-outlined ic-print",
          onClick: () => {
            const data = getData();
            abrirModalDetalhesDoLoteMxp1(data?.id);
          },
          mostraNaColunaDeAcoes: true,
          ocultarNoMenuPrincipal: true,
          hint: "Imprimir lote",
        },
        acaoCancelaLote(getData, false),
        {
          text: "Ações",
          icon: "ic-material-symbols-outlined ic-vertical",
          hint: "Menu com opções de ação",
          ocultarNoMaisOpcoes: true,
          items: [
            {
              text: "Importar lotes de arquivo Excel",
              icon: "ic-material-symbols-outlined ic-file-upload",
              ocultarNoMaisOpcoes: true,
              onClick: () => setImportarVisivel(true),
            },
            acaoCancelaLote(getData, true),
            {
              text: "Criar boletim de inspeção",
              ocultarNoMaisOpcoes: true,
              onClick: () => {
                const data = getData();
                criarBoletimDeInspecao(
                  data?.id,
                  data?.loteDoFabricanteOuNumeroDeSerie
                );
              },
            },
          ],
        },
      ];

      return itensAdicionais;
    }
  );

  return (
    <>
      <MxpGrid<LoteGridModel>
        id={"lote"}
        ref={gridRef}
        definirMenuSuperior={props.definirMenu}
        dataSource={dataSource}
        colunas={colunas}
        novoRegistro={handleNovoRegistro}
        gerarItensAdicionaisDeContexto={handleGerarItensAdicionais}
        editarRegistro={handleEditarRegistro}
        excluirRegistro={handleExcluirRegistro}
        nomeDoArquivoAoExportar={NomesTelas.lotes}
      />

      <EditFormLote
        visivel={modalEdicaoVisivel}
        idRegistroEdicao={idRegistroEdicao}
        configuracoesModal={{
          altura: "max(60vh, 625px)",
          largura: "max(60vw, 800px)",
        }}
        callBackFecharModal={handleModalCallback}
      />

      <ImportadorDeRegistros
        titulo="Importar lotes de arquivo Excel"
        onFechar={() => setImportarVisivel(false)}
        nomeDaPlanilha="Lotes"
        schema={schema}
        visivel={importarVisivel}
        onImportar={ApiLote.cadastrarEmMassaExcel}
        modeloUrl={"../modelosXlsx/Planilha_Modelo_Lotes_MAXIPROD.xlsx"}
        schemaYup={schemaYup}
      />
    </>
  );
});
