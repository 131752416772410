import getApi from "../../../configs/api";
import { ResponseBase } from "../../../models/api/comum/response-base";
import { NomesEndpoints } from "../../comum/nomesEndpoints";

const nomeEndpoint = NomesEndpoints.EfdReinfApuracao;

async function RecalcularApuracao(id: number) {
  const api = getApi();
  const response = await api.post<ResponseBase>(
    `${nomeEndpoint}/Recalcular?id=${id}`
  );

  return response.data;
}

export default {
  RecalcularApuracao,
};
