import { ItemGridModel } from "../../../../models/api/item/item";
import { Estado, EstocagemTipo } from "../../../../models/api/item/item-enums";
import criarNameof from "../../../common/cria-name-of";

export default class FiltrosGridItem {
  static readonly itemLoteOuSerieAtivo: any[] = [
    [
      [
        criarNameof<ItemGridModel>()("estocagemPor"),
        "=",
        EstocagemTipo.LoteFabricante,
      ],
      "or",
      [criarNameof<ItemGridModel>()("estocagemPor"), "=", EstocagemTipo.Serie],
    ],
    "and",
    [
      [criarNameof<ItemGridModel>()("estado"), "=", Estado.Ativo],
      "or",
      [criarNameof<ItemGridModel>()("estado"), "=", Estado.Digitacao],
    ],
  ];
}
