import { Column } from "devextreme-react/data-grid";
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { renderToString } from "react-dom/server";
import { MxpGrid } from "../../../../../components/grid";
import {
  useEditarRegistroGrid,
  useExcluirRegistroGrid,
  useNovoRegistroGrid,
  usePropagarReferenciaGrid,
} from "../../../../../hooks/grid.hooks";
import { useAppSelector } from "../../../../../hooks/store.hooks";
import { CfopGridModel } from "../../../../../models/api/cfop/cfop";
import { CallBackModal } from "../../../../../models/shared/ui/callback-modal";
import { IGridSelecao } from "../../../../../models/shared/ui/formularios";
import { EditFormCfop } from "../../../../../pages/fiscal/cfop/edit-form";
import { NomesEndpoints } from "../../../../../services/comum/nomesEndpoints";
import API from "../../../../../services/comum/serviceBase";
import store from "../../../../../store";
import { checarResponseExibeMensagemExclusaoDeSucesso } from "../../../../../utils/api/api-utils";
import { AssinanteDefaultId } from "../../../../../utils/common/constantes";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import { GridBaseProps } from "../../../../../utils/grid/grid-utils";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import GetColunasDeAuditoria from "../../../../layout/grid-defaults/colunasDeAuditoria";

const nomeEndpoint = NomesEndpoints.Cfop;
const dataSource = API.getGridSource(nomeEndpoint);
const nameOfGridHandler = criarNameof<CfopGridModel>();
const colunas = [
  <Column
    key={nameOfGridHandler("codigo")}
    dataField={nameOfGridHandler("codigo")}
    {...obterConfiguracaoColuna("stringP")}
    caption="Código"
  />,
  <Column
    key={nameOfGridHandler("descricao")}
    dataField={nameOfGridHandler("descricao")}
    {...obterConfiguracaoColuna("stringXG")}
    caption="Descrição"
  />,
  <Column
    key={nameOfGridHandler("interstadual")}
    dataField={nameOfGridHandler("interstadual")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Interestadual"
  />,
  <Column
    key={nameOfGridHandler("estadoFormatado")}
    dataField={nameOfGridHandler("estadoFormatado")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Estado"
  />,
  <Column
    key={nameOfGridHandler("entradaSaidaFormatado")}
    dataField={nameOfGridHandler("entradaSaidaFormatado")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Entrada/Saída"
  />,
  <Column
    key={nameOfGridHandler("devolucaoFormatado")}
    dataField={nameOfGridHandler("devolucaoFormatado")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Devolução"
  />,
  <Column
    key={nameOfGridHandler("tipoFormatado")}
    dataField={nameOfGridHandler("tipoFormatado")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Nacional/Municipal"
    visible={false}
  />,
  ...GetColunasDeAuditoria(),
];

export const GridCFOP = forwardRef((props: GridBaseProps, ref) => {
  const gridRef = useRef<IGridSelecao>(null);
  useImperativeHandle(ref, () => gridRef.current as IGridSelecao);
  usePropagarReferenciaGrid(ref, gridRef);

  const [modalEdicaoVisivel, setModalEdicaoVisivel] = useState(false);
  const [idRegistroEdicao, setIdRegistroEdicao] = useState(NaN);

  const isUsuarioMaster = useAppSelector(
    (state) => state.sessao.dadosSessao?.usuario.isUsuarioMaster
  );

  function handleAtualizarGrid() {
    if (gridRef.current?.atualizarGrid) {
      gridRef.current?.atualizarGrid();
    }
  }

  const handleExcluirRegistro = useExcluirRegistroGrid(
    async (registro: CfopGridModel) => {
      if (!validarAcessoDefaultMaster()) {
        return;
      }

      const mensagem = renderToString(<>{obterMensagemExclusao(registro)}</>);
      const excluir = await exibirConfirmacao("Confirmar exclusão", mensagem);

      if (excluir) {
        const resposta = await API.excluir(registro.id, nomeEndpoint);

        if (resposta) {
          checarResponseExibeMensagemExclusaoDeSucesso(resposta);
          handleAtualizarGrid();
        }
      }
    }
  );

  function obterMensagemExclusao(registro: CfopGridModel) {
    return `Tem certeza de que deseja excluir o CFOP ${registro.codigo}?`;
  }

  const handleNovoRegistro = useNovoRegistroGrid(() => {
    if (!validarAcessoDefaultMaster()) {
      return;
    }

    setIdRegistroEdicao(0);
    setModalEdicaoVisivel(true);
  });

  const handleEditarRegistro = useEditarRegistroGrid((id: number) => {
    if (!validarAcessoDefaultMaster()) {
      return;
    }

    setIdRegistroEdicao(id);
    setModalEdicaoVisivel(true);
  });

  function validarAcessoDefaultMaster(): boolean {
    if (!isUsuarioMaster) {
      return false;
    }

    const isUsuarioDefault =
      store.getState().sessao.dadosSessao?.empresa.assinanteId ==
      AssinanteDefaultId;

    if (!isUsuarioDefault) {
      exibeMensagemInsercaoEdicaoUsuarioMaster(false);
      return false;
    }

    return true;
  }

  function exibeMensagemInsercaoEdicaoUsuarioMaster(isEdicao: boolean) {
    const acao = isEdicao ? "editado" : "realizado";

    exibirNotificacaoToast({
      mensagem: `O cadastro de CFOP somente pode ser ${acao} no assinante DEFAULT (2)`,
      tipo: TipoNotificacao.Advertencia,
    });
  }

  const handleModalCallback = useCallback((info: CallBackModal) => {
    setModalEdicaoVisivel(false);
    setIdRegistroEdicao(NaN);

    if (info.precisaAtualizar) {
      handleAtualizarGrid();
    }
  }, []);

  return (
    <>
      <MxpGrid<CfopGridModel>
        dataSource={dataSource}
        id={"cfop"}
        ref={gridRef}
        colunas={colunas}
        permiteSomenteMaster={true}
        isUsuarioMaster={isUsuarioMaster}
        exibirIconeExcluirMenuAcoes={isUsuarioMaster}
        exibirIconeEditarMenuAcoes={isUsuarioMaster}
        valorPadraoDoFiltro={props.valorPadraoDoFiltro}
        novoRegistro={handleNovoRegistro}
        editarRegistro={handleEditarRegistro}
        excluirRegistro={handleExcluirRegistro}
        nomeDoArquivoAoExportar={NomesTelas.cfop}
        exibirMenuDeContexto={isUsuarioMaster}
      />
      <EditFormCfop
        visivel={modalEdicaoVisivel}
        idRegistroEdicao={idRegistroEdicao}
        configuracoesModal={{ largura: "max(50vw, 800px)", altura: "auto" }}
        callBackFecharModal={handleModalCallback}
      />
    </>
  );
});
