import { LoadIndicator } from "devextreme-react";
import { Overlay } from "./styles";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
export default function PainelCarregamento() {
  return (
    <Overlay>
      <LoadIndicator visible={true} />
    </Overlay>
  );
}

interface PainelCarregandoProps {
  visivel: boolean;
}

export interface IPainelCarregando {
  setCarregando: (carregando: boolean) => void;
}

export const PainelCarregando = forwardRef(
  (props: PainelCarregandoProps, ref) => {
    const [visivel, setVisivel] = useState(props.visivel);

    useEffect(() => {
      setVisivel(props.visivel);
    }, [props.visivel]);

    useImperativeHandle(
      ref,
      (): IPainelCarregando => ({
        setCarregando: (carregando) => {
          setVisivel(carregando);
        },
      })
    );

    return (
      <>
        {visivel && (
          <Overlay>
            <LoadIndicator visible={true} />
          </Overlay>
        )}
      </>
    );
  }
);
