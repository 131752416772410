import { HttpStatusCode } from "axios";
import { SortDescriptor } from "devextreme/data";
import CustomStore from "devextreme/data/custom_store";
import DataSource from "devextreme/data/data_source";
import fileDownload from "js-file-download";
import {} from "react-router-dom";
import {
  NecessitaOpcionaisResponse,
  NotificationsResponse,
  ResponseBase,
} from "../../models/api/comum/response-base";
import { SelectBoxLazyOpcoes } from "../../models/api/comum/selectboxlazy-options";
import store from "../../store";
import {
  exibirAccordionNotifications,
  exibirNecessitaOpcionais,
} from "../../store/ui/ui.slice";
import exibirNotificacaoToast, {
  JanelasDeNotificacaoTitulos,
  MensagensParaNotificacao,
  TipoNotificacao,
} from "../common/notificacoes-utils";
import { exibirAlerta } from "../dialogos";

export function tratarErroApi(
  error: any,
  callBackUnprocessableEntity?: () => void
) {
  tratarErroApiInterno(error, false, callBackUnprocessableEntity);
}

export function tratarErroApiComoModal(
  error: any,
  callBackUnprocessableEntity?: () => void
) {
  tratarErroApiInterno(error, true, callBackUnprocessableEntity);
}

function tratarErroApiInterno(
  error: any,
  exibirErroComoModal: boolean,
  callBackUnprocessableEntity?: () => void
) {
  if (error.response) {
    if (error.response.status === HttpStatusCode.Forbidden) {
      exibirAlerta(
        "Atenção",
        `<span style="white-space: pre-line">${
          error.response.data.mensagem || "Erro ao verificar as permissões."
        }</span>`
      );
      return;
    }

    // Requisição feita com resposta do servidor com status code diferente de 2xx

    if (
      error.response.status == HttpStatusCode.PaymentRequired &&
      !(error.response.data instanceof Blob)
    ) {
      const model = error.response.data as NecessitaOpcionaisResponse;
      store.dispatch(exibirNecessitaOpcionais(model.model));
      return;
    }

    if (error.response.data instanceof Blob) {
      const blob = error.response.data as Blob;
      blob.text().then((str) => {
        const obj = JSON.parse(str);
        checarResponse(obj, exibirErroComoModal);
      });
    } else {
      checarResponse(error.response.data, exibirErroComoModal);
    }

    if (
      error.response.status == HttpStatusCode.UnprocessableEntity &&
      callBackUnprocessableEntity
    ) {
      callBackUnprocessableEntity();
    }
  } else if (error.request) {
    // Requisição feita sem resposta do servidor
    console.log(error.request);
  } else {
    // Outros erros
    console.log("Error", error.message);
  }
}

export function params(obj: any) {
  const params = new URLSearchParams();
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== undefined && obj[key] !== null) {
      params.append(key, obj[key]);
    }
  });
  return params;
}

export function checarResponse<T extends ResponseBase>(
  response: T,
  exibirErroComoModal?: boolean
): boolean {
  const notificacoes = response as unknown as NotificationsResponse;
  let sucesso = true;
  if (!response.sucesso) {
    if (!notificacoes?.model || notificacoes?.model?.length == 0) {
      if (exibirErroComoModal) {
        exibirAlerta("Atenção", quebrarLinhasMensagem(response.mensagem));
      } else {
        exibirNotificacaoToast({
          mensagem: response.mensagem,
          tipo: TipoNotificacao.Erro,
        });
      }
    } else if (
      notificacoes.model &&
      notificacoes?.model.length != 0 &&
      !ehItemOpcionalAssinaturaModel(notificacoes)
    ) {
      if (store) {
        notificacoes.titulo = JanelasDeNotificacaoTitulos.Atencao;
        store.dispatch(exibirAccordionNotifications(notificacoes));
      }
    } else if (
      notificacoes.model &&
      notificacoes?.model.length != 0 &&
      ehItemOpcionalAssinaturaModel(notificacoes)
    ) {
      if (store) {
        const dados = notificacoes as unknown as NecessitaOpcionaisResponse;
        store.dispatch(exibirNecessitaOpcionais(dados.model));
      }
    }

    sucesso = false;
  }

  return sucesso;
}

function ehItemOpcionalAssinaturaModel(response: any) {
  const objeto = response?.model[0];

  return "codigo" in objeto && "descricao" in objeto && "resumo" in objeto;
}

export function checarResponseExibeMensagemExclusaoDeSucesso<
  T extends ResponseBase
>(response: T): boolean {
  const isSucesso = checarResponse(response);

  if (isSucesso) {
    exibirNotificacaoToast({
      mensagem: MensagensParaNotificacao.ExcluidoComSucesso,
      tipo: TipoNotificacao.Sucesso,
    });
  }

  return isSucesso;
}

export function checarResponseExibeMensagemExecutadoComSucesso<
  T extends ResponseBase
>(response: T): boolean {
  const isSucesso = checarResponse(response);

  if (isSucesso) {
    exibirNotificacaoToast({
      mensagem: response.mensagem,
      tipo: TipoNotificacao.Sucesso,
    });
  }

  return isSucesso;
}

export function checarResponseBaixarArquivo(
  response: Blob | ResponseBase,
  nomeArquivo: string,
  extensaoArquivo?: string
): boolean {
  const notificacoes = response as unknown as NotificationsResponse;
  const nomeCompletoArquivo = [nomeArquivo, extensaoArquivo].join(".");
  let sucesso = true;

  if (notificacoes.model && notificacoes?.model.length != 0) {
    if (store) {
      notificacoes.titulo = JanelasDeNotificacaoTitulos.Atencao;
      store.dispatch(exibirAccordionNotifications(notificacoes));
    }
    sucesso = false;
  } else if (notificacoes.sucesso === false && notificacoes.mensagem) {
    exibirNotificacaoToast({
      mensagem: notificacoes.mensagem,
      tipo: TipoNotificacao.Erro,
    });

    sucesso = false;
  } else {
    fileDownload(response as Blob, nomeCompletoArquivo);
  }

  return sucesso;
}

export function criarDataSourceSelectBoxLazy(
  dataSource: CustomStore,
  opcoes: SelectBoxLazyOpcoes
) {
  const ds = new DataSource({
    store: dataSource,
    paginate: true,
    pageSize: opcoes.quantidadeRegistros ?? 50,
  });

  if (opcoes.filtros) {
    ds.filter(opcoes.filtros.map((x) => [x.nomeCampo, x.operador, x.valor]));
  }

  const camposOrdenacao: SortDescriptor<any>[] = [];

  opcoes.camposOrdenacao.map((x) => {
    camposOrdenacao.push({ selector: x.nomeCampo, desc: x.desc });
  });

  ds.sort(camposOrdenacao);

  return ds;
}

function quebrarLinhasMensagem(conteudo: string) {
  const linhas = conteudo.split("\n");
  const elemento = document.createElement("span");

  for (let i = 0; i < linhas.length; i++) {
    if (i != 0) {
      elemento.appendChild(document.createElement("br"));
    }

    elemento.appendChild(document.createTextNode(linhas[i]));
  }

  return elemento.innerHTML;
}
