//import "devextreme/dist/css/dx.material.blue.light.compact.css";
import { LoadPanel } from "devextreme-react";
import "devextreme/dist/css/dx.common.css";
import { loadMessages, locale } from "devextreme/localization";
import ptMessages from "devextreme/localization/messages/pt.json";
import "primeflex/primeflex.min.css";
import { useEffect } from "react";
import { setLocale } from "yup";
import "./App.css";
import ModalSobre from "./components/sobre/versao/versao";
import Rotas from "./configs/routes";
import "./dx-styles.scss";
import { useAppSelector } from "./hooks/store.hooks";
import "./parts/layout/scrollbar/scrollbar.scss";
import ChecarVersao from "./parts/utils/checar-versao/intex";
import "./themes/generated/theme.additional.css";
import "./themes/generated/theme.base.css";
import "./themes/google-icons.css";
import { ColecaoAtalhos } from "./utils/atalhos/colecao-atalhos";
import NotificacaoPopup from "./utils/dialogos/renderNotifications";
import { useScreenSizeClass } from "./utils/template-layout/media-query";
import { traducoesYup } from "./yup.locale.pt-br";

function App() {
  loadMessages(ptMessages);
  locale(navigator.language);
  setLocale(traducoesYup);

  const bloqueado = useAppSelector((state) => state.estadoUI.blockUI.bloqueado);
  const textoBloqueio = useAppSelector((state) => state.estadoUI.blockUI.texto);

  useEffect(() => {
    ColecaoAtalhos.inicializarEventos();
  }, []);

  return (
    <div className={`app ${useScreenSizeClass}`}>
      <ChecarVersao />
      <LoadPanel
        visible={bloqueado}
        shadingColor="rgba(0,0,0,0.4)"
        message={textoBloqueio}
        hint={textoBloqueio}
      />
      <NotificacaoPopup />
      <ModalSobre />
      <Rotas />
    </div>
  );
}

export default App;
