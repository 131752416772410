import { useState } from "react";
import * as yup from "yup";
import ProvedorAjuda from "../../../../components/ajuda/provedor-ajuda";
import {
  FormCheckBox,
  FormDateBox,
  FormSelectBox,
  FormTextBox,
} from "../../../../components/formularios";
import {
  FormBase2,
  FormularioEdicaoBaseProps,
} from "../../../../components/layout/form-base2";
import { Coluna, Linha } from "../../../../components/layout/grid-system";
import {
  useCarregarCombos,
  useCarregarRegistro,
  useHookForms,
  useLimparFormSeIdForNaN,
} from "../../../../hooks/form.hooks";
import AuditavelDTO from "../../../../models/api/comum/auditavel-dto";
import { TipoTributoContaPadraoParteB } from "../../../../models/api/conta-padrao-parte-b/conta-padrao-parte-b";
import {
  ContaDaParteBRequest,
  ContaDaParteBResponse,
  TipoTributoContaParteB,
} from "../../../../models/api/conta-parte-b/conta-parte-b";
import { SelectItemEnumTipoTributoContaParteB } from "../../../../models/const/dicionario-combos/conta-parte-b";
import { PermissoesContaParteB } from "../../../../models/permissoes/contabilidade/conta-parte-b/permissoes-conta-parte-b";
import { CallBackModal } from "../../../../models/shared/ui/callback-modal";
import SelectItem from "../../../../models/shared/ui/select-item";
import { ContaPadraoParteBService } from "../../../../services/conta-padrao-parte-b/conta-padrao-parte-b.service";
import { ContaParteBService } from "../../../../services/conta-parte-b/conta-parte-b.service";
import { checarResponse, tratarErroApi } from "../../../../utils/api/api-utils";
import NomesModais from "../../../../utils/common/nomes-modais";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../utils/common/permissoes-utils";

const novoRegistro: ContaDaParteBRequest = {
  id: 0,
  codigo: "",
  descricao: "",
  ativo: true,
  tipoTributo: TipoTributoContaParteB.IRPJ,
  contaPadraoDaParteBId: 0,
  dataCriacaoDaConta: undefined,
};

const contaParteBService = new ContaParteBService();
const contaPadraoParteBService = new ContaPadraoParteBService();

export default function EditFormContaParteB(props: FormularioEdicaoBaseProps) {
  const [carregando, setCarregando] = useState(false);
  const [contasPadroesParteB, setContasPadroesParteB] = useState<SelectItem[]>(
    []
  );
  const [dadosAuditoria, setDadosAuditoria] = useState<AuditavelDTO>();

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    codigo: yup.string().required().max(50),
    descricao: yup.string().required().max(300),
    tipoTributo: yup
      .mixed<TipoTributoContaParteB>()
      .transform((v) => (v ? v : null))
      .oneOf(
        Object.values(TipoTributoContaParteB).map((x) => x as number),
        "Valor inválido"
      )
      .required(),
    dataCriacaoDaConta: yup
      .date()
      .required()
      .transform((valor) => (valor ? valor : null)),
    contaPadraoDaParteBId: yup
      .number()
      .required()
      .positive()
      .integer()
      .transform((v) => (v ? v : null))
      .oneOf(
        contasPadroesParteB.map((x) => x.valor as number),
        "Valor inválido"
      ),
    ativo: yup.boolean().required(),
  });

  function fechar(info: CallBackModal) {
    if (props.callBackFecharModal) {
      props.callBackFecharModal(info);
    }
  }

  const hookForm = useHookForms<ContaDaParteBRequest>(schema);
  useCarregarRegistro(props.idRegistroEdicao, CarregarRegistro);
  useCarregarCombos(props.idRegistroEdicao, carregarCombo);
  useLimparFormSeIdForNaN(hookForm, novoRegistro, props.idRegistroEdicao);

  const { register, control, handleSubmit, getValues, reset, watch } = hookForm;

  async function CarregarRegistro() {
    try {
      setCarregando(true);
      const resposta =
        await contaParteBService.ObterPorIdComDadosAuditoria<ContaDaParteBResponse>(
          props.idRegistroEdicao
        );
      reset(resposta?.model);
      setDadosAuditoria(resposta.model);
    } catch (error) {
      tratarErroApi(error);
    } finally {
      setCarregando(false);
    }
  }

  function handleCancelar() {
    fechar({ concluido: false, precisaAtualizar: false });
  }

  async function carregarCombo() {
    await carregarComboContaPadraoDaParteB(watch("tipoTributo"));
  }

  async function carregarComboContaPadraoDaParteB(
    tipoTributo: TipoTributoContaParteB
  ) {
    const tiposConsulta: TipoTributoContaPadraoParteB[] = [
      TipoTributoContaPadraoParteB.Ambos,
    ];

    tiposConsulta.push(
      tipoTributo == TipoTributoContaParteB.CSLL
        ? TipoTributoContaPadraoParteB.CSLL
        : TipoTributoContaPadraoParteB.IRPJ
    );

    try {
      const resposta = await contaPadraoParteBService.ObterListaSimplesDosTipos(
        tiposConsulta
      );
      checarResponse(resposta);
      setContasPadroesParteB(
        resposta.model.map((x) => ({
          valor: x.valor,
          descricao: x.descricao,
        }))
      );
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  async function handleSalvar() {
    try {
      setCarregando(true);
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesContaParteB.InserirEditar,
        ])
      ) {
        return;
      }
      const model = getValues();
      const resposta =
        props.idRegistroEdicao > 0
          ? await contaParteBService.Atualizar(model)
          : await contaParteBService.Cadastrar(model);

      checarResponse(resposta);

      if (resposta?.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar({ concluido: true, precisaAtualizar: true });
      }
    } catch (error: any) {
      tratarErroApi(error);
    } finally {
      setCarregando(false);
    }
  }

  async function onChangeTipoTributo(e: TipoTributoContaParteB) {
    await carregarComboContaPadraoDaParteB(e);
  }

  return (
    <FormBase2
      visivel={props.visivel}
      carregando={carregando}
      onClickSalvar={handleSubmit(handleSalvar)}
      onClickCancelar={handleCancelar}
      configuracoesModal={props.configuracoesModal}
      modoEdicao={props.idRegistroEdicao == 0 ? "criar" : "editar"}
      isNomeTelaFeminino
      titulo={NomesModais.contaParteB}
      auditoria={dadosAuditoria}
    >
      <input type="hidden" {...register("id")} defaultValue={0} />
      <ProvedorAjuda id="edit-form-conta-parte-b">
        <Linha>
          <Coluna md={3}>
            <FormTextBox
              name="codigo"
              titulo="Código"
              control={control}
              requerido
              tamanhoMaximo={50}
            />
          </Coluna>
          <Coluna md={3}>
            <FormSelectBox
              name="tipoTributo"
              titulo="Tipo do tributo"
              control={control}
              requerido
              dataSource={SelectItemEnumTipoTributoContaParteB}
              onValueChange={onChangeTipoTributo}
            />
          </Coluna>
          <Coluna md={6} classe="float-right">
            <FormCheckBox name="ativo" titulo="Ativo" control={control} />
          </Coluna>
        </Linha>
        <Linha>
          <Coluna md={12}>
            <FormTextBox
              name="descricao"
              titulo="Descrição"
              control={control}
              requerido
              tamanhoMaximo={300}
            />
          </Coluna>
        </Linha>
        <Linha>
          <Coluna md={6}>
            <FormDateBox
              name="dataCriacaoDaConta"
              titulo="Data de criação"
              control={control}
              requerido
              exibirBotaoLimpar
              tipo="date"
              aceitaValorCustomizado
              formatoExibicao="dd/MM/yy"
              aceitaDigitacaoComMascara
            />
          </Coluna>
        </Linha>
        <Linha>
          <Coluna md={12}>
            <FormSelectBox
              name="contaPadraoDaParteBId"
              titulo="Código padrão da parte B"
              control={control}
              requerido
              dataSource={contasPadroesParteB}
              habilitaBusca
              tipoBusca="contains"
            />
          </Coluna>
        </Linha>
      </ProvedorAjuda>
    </FormBase2>
  );
}
