import { useState } from "react";
import * as yup from "yup";
import {
  FormGrupo,
  FormSelectBoxLazy,
} from "../../../../components/formularios";
import {
  FormBase2,
  FormularioEdicaoBaseProps,
} from "../../../../components/layout/form-base2";
import { Coluna, Linha } from "../../../../components/layout/grid-system";
import {
  useCarregarRegistro,
  useHookForms,
  useLimparFormSeIdForNaN,
} from "../../../../hooks/form.hooks";
import AuditavelDTO from "../../../../models/api/comum/auditavel-dto";
import {
  VinculoEnderecoEstoqueCentroDeTrabalhoRequestDTO,
  VinculoEnderecoEstoqueCentroDeTrabalhoResponseDTO,
} from "../../../../models/api/vinculo-endereco-estoque-centro-de-trabalho/vinculo-endereco-estoque-centro-de-trabalho";
import { CallBackModal } from "../../../../models/shared/ui/callback-modal";
import { GridEnderecoEstoque } from "../../../../parts/estoque/grids/grid-padrao";
import { GridCentroDeTrabalho } from "../../../../parts/producao/centro-de-trabalho/grids/grid-padrao";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../services/comum/serviceBase";
import APIEnderecoEstoque from "../../../../services/estoque/endereco-estoque/endereco-estoque.service";
import APICentrosTrabalho from "../../../../services/producao/centro-de-trabalho/centro-de-trabalho.service";
import { checarResponse, tratarErroApi } from "../../../../utils/api/api-utils";
import NomesModais from "../../../../utils/common/nomes-modais";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../utils/common/notificacoes-utils";

const novoRegistro: VinculoEnderecoEstoqueCentroDeTrabalhoRequestDTO = {
  id: 0,
  idCentroDeTrabalho: 0,
  idEnderecoEstoque: 0,
};

const nomeEndpoint = NomesEndpoints.VinculoEnderecosDeEstoqueCentroDeTrabalho;

const enderecosEstoqueCentroDeTrabalho =
  APIEnderecoEstoque.getDataSourceSelectBoxLazy({
    camposRetorno: ["Id", "Codigo", "Descricao"],
    camposOrdenacao: [
      {
        nomeCampo: "Codigo",
        desc: false,
      },
    ],
  });

const centrosTrabalho = APICentrosTrabalho.getDataSourceSelectBoxLazy({
  camposRetorno: ["Id", "Codigo", "Descricao"],
  camposOrdenacao: [
    {
      nomeCampo: "Codigo",
      desc: false,
    },
  ],
});

const exibeCentroDeTrabalho = (c: any) => {
  if (c) {
    return `${c.Codigo} - ${c.Descricao}`;
  }

  return "";
};

const centroDeTrabalhoExpressaoDeBusca = ["Codigo", "Descricao"];

const exibeEnderecoDeEstoque = (c: any) => {
  if (c) {
    return `${c.Codigo} - ${c.Descricao}`;
  }

  return "";
};

const enderecoDeEstoqueExpressaoDeBusca = ["Codigo", "Descricao"];

export const EditFormVinculoEnderecoEstoqueCentroDeTrabalho = (
  props: FormularioEdicaoBaseProps
) => {
  const [carregando, setCarregando] = useState(false);
  const [dadosAuditoria, setDadosAuditoria] = useState<AuditavelDTO>();

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    idCentroDeTrabalho: yup.number().required().moreThan(0).integer(),
    idEnderecoEstoque: yup.number().required().moreThan(0).integer(),
  });

  const hookForm = useHookForms(schema);
  useCarregarRegistro(props.idRegistroEdicao, carregarTela);
  useLimparFormSeIdForNaN(hookForm, novoRegistro, props.idRegistroEdicao);

  const { register, control, handleSubmit, getValues, reset } = hookForm;

  async function carregarTela() {
    try {
      setCarregando(true);
      if (props.idRegistroEdicao > 0) {
        await carregarModel();
      } else {
        reset(novoRegistro);
        setDadosAuditoria(undefined);
      }
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  async function carregarModel() {
    try {
      const resposta =
        await APIBase.obterPorIdComDadosAuditoria<VinculoEnderecoEstoqueCentroDeTrabalhoResponseDTO>(
          props.idRegistroEdicao,
          nomeEndpoint
        );
      checarResponse(resposta);
      reset(resposta.model);
      setDadosAuditoria(resposta.model);
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues();
    try {
      const resposta =
        props.idRegistroEdicao > 0
          ? await APIBase.atualizar(model, nomeEndpoint)
          : await APIBase.cadastrar(model, nomeEndpoint);

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar({
          concluido: true,
          precisaAtualizar: true,
        });
      }
      fechar({
        concluido: true,
        precisaAtualizar: false,
      });
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  function fechar(info: CallBackModal) {
    if (props.callBackFecharModal) {
      props.callBackFecharModal(info);
    }
  }

  function handleCancelar() {
    setDadosAuditoria(undefined);
    fechar({ concluido: false, precisaAtualizar: false });
  }

  function callBackUnprocessableEntity() {
    fechar({
      concluido: false,
      precisaAtualizar: true,
    });
  }

  return (
    <>
      <FormBase2
        visivel={props.visivel}
        carregando={carregando}
        onClickSalvar={handleSubmit(handleSalvar)}
        onClickCancelar={handleCancelar}
        configuracoesModal={props.configuracoesModal}
        modoEdicao={props.idRegistroEdicao == 0 ? "criar" : "editar"}
        titulo={NomesModais.vinculoDoCentroDeTrabalhoComEnderecoDeEstoque}
        auditoria={dadosAuditoria}
      >
        <input type="hidden" {...register("id")} defaultValue={0} />
        <FormGrupo>
          <Linha>
            <Coluna md={12}>
              <FormSelectBoxLazy
                name="idCentroDeTrabalho"
                titulo="Centro de trabalho"
                control={control}
                requerido
                nomeCampoChave="Id"
                nomeCampoExibicao={exibeCentroDeTrabalho}
                expressaoDeBusca={centroDeTrabalhoExpressaoDeBusca}
                dataSource={centrosTrabalho}
                lupaConfig={{
                  modo: "selecaoUnica",
                  titulo: "Selecionar centro de trabalho",
                  componente: (r) => <GridCentroDeTrabalho ref={r} />,
                }}
                labelSemDados="Sem dados"
              />
            </Coluna>
          </Linha>
          <Linha>
            <Coluna md={12}>
              <FormSelectBoxLazy
                name="idEnderecoEstoque"
                titulo="Endereço de estoque"
                control={control}
                requerido
                nomeCampoChave="Id"
                nomeCampoExibicao={exibeEnderecoDeEstoque}
                expressaoDeBusca={enderecoDeEstoqueExpressaoDeBusca}
                dataSource={enderecosEstoqueCentroDeTrabalho}
                lupaConfig={{
                  modo: "selecaoUnica",
                  titulo: "Selecionar o endereço estoque",
                  componente: (r) => <GridEnderecoEstoque ref={r} />,
                }}
                labelSemDados="Sem dados"
              />
            </Coluna>
          </Linha>
        </FormGrupo>
      </FormBase2>
    </>
  );
};

export default EditFormVinculoEnderecoEstoqueCentroDeTrabalho;
