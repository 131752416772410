import { useState } from "react";
import Pagina from "../../../../components/layout/pagina";
import RequerPermissao from "../../../../components/seguranca/requer-permissao";
import { PermissoesEfdReinf } from "../../../../models/permissoes/fiscal/efd-reinf/permissoes-efd-reinf";
import { GridEfdReinf4010 } from "../../../../parts/fiscal/efd-reinf/efd-reinf-4010/grid";
import NomesTelas from "../../../../utils/common/nomes-telas";
import { ItemContextMenu } from "../../../../utils/context-menu/context-menu-utils";

export default function EfdReinf4010Page() {
  const [menus, setMenus] = useState<ItemContextMenu[]>();

  return (
    <Pagina
      id="efd-reinf-4010-page"
      titulo={NomesTelas.efdReinf4010}
      area={"Fiscal"}
      subMenuItems={menus}
    >
      <RequerPermissao codigoPermissoes={[PermissoesEfdReinf.Consultar]}>
        <GridEfdReinf4010 definirMenu={setMenus} />
      </RequerPermissao>
    </Pagina>
  );
}
