import React, { CSSProperties } from "react";

export interface LinhaProps {
  children: React.ReactNode;
  style?: CSSProperties;
}

export function Linha(props: LinhaProps) {
  return (
    <div className="grid" style={props.style}>
      {props.children}
    </div>
  );
}

type ValoresAceitosColuna = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export interface ColunaProps {
  children?: React.ReactNode;
  mobile?: ValoresAceitosColuna;
  sm?: ValoresAceitosColuna;
  md?: ValoresAceitosColuna;
  lg?: ValoresAceitosColuna;
  xl?: ValoresAceitosColuna;
  classe?: string;
  offset?: ValoresAceitosColuna;
}

export function Coluna(props: ColunaProps) {
  let configuracaoTamanho = props.mobile ? `col-${props.mobile}` : "col-12";
  const configuracaoOffset = props.offset ? ` col-offset-${props.offset}` : "";

  if (props.sm) configuracaoTamanho += ` sm:col-${props.sm}`;
  if (props.md) configuracaoTamanho += ` md:col-${props.md}`;
  if (props.lg) configuracaoTamanho += ` lg:col-${props.lg}`;
  if (props.xl) configuracaoTamanho += ` xl:col-${props.xl}`;

  return (
    <div
      className={`${configuracaoTamanho} ${configuracaoOffset} ${props.classe}`}
    >
      {props.children}
    </div>
  );
}
