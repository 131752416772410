import { useState } from "react";
import Pagina from "../../../components/layout/pagina";
import RequerPermissao from "../../../components/seguranca/requer-permissao";
import { PermissoesCentroDeTrabalhoGrupo } from "../../../models/permissoes/producao/grupo-centro-de-trabalho/permissoes-grupo-centro-de-trabalho";
import { GridCentroDeTrabalhoGrupo } from "../../../parts/producao/grupo-centro-de-trabalho/grids/grid-padrao";
import NomesTelas from "../../../utils/common/nomes-telas";
import { ItemContextMenu } from "../../../utils/context-menu/context-menu-utils";

export default function CentroDeTrabalhoGrupoPage() {
  const [menus, setMenus] = useState<ItemContextMenu[]>();

  return (
    <Pagina
      id="grupos-centros-trabalho-page"
      titulo={NomesTelas.gruposCentrosTrabalho}
      area={"Produção"}
      subMenuItems={menus}
    >
      <RequerPermissao
        codigoPermissoes={[PermissoesCentroDeTrabalhoGrupo.Consultar]}
      >
        <GridCentroDeTrabalhoGrupo definirMenu={setMenus} />
      </RequerPermissao>
    </Pagina>
  );
}
