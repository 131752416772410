import { SelectBoxLazyOpcoes } from "../../models/api/comum/selectboxlazy-options";
import { criarDataSourceSelectBoxLazy } from "../../utils/api/api-utils";
import criarDatasourceGrid from "../../utils/grid/back-end-grid-utils";
import { NomesEndpoints } from "../comum/nomesEndpoints";

export default class AdicaoExclusaoCompensacaoLalurIrpjService {
  private _nomeEndpoint: NomesEndpoints;

  constructor() {
    this._nomeEndpoint = NomesEndpoints.AdicaoExclusaoCompensacaoLalurIrpj;
  }

  public ObterGridSource() {
    return criarDatasourceGrid(`${this._nomeEndpoint}/grid`);
  }

  public ObterDataSourceSelectBoxLazy(opcoes: SelectBoxLazyOpcoes) {
    const camposSelect = JSON.stringify(opcoes.camposRetorno);

    const dataSource = criarDatasourceGrid(
      `${this._nomeEndpoint}/grid?select=${camposSelect}`
    );

    return criarDataSourceSelectBoxLazy(dataSource, opcoes);
  }
}
