import { ArquivoBaseModel } from "../arquivos/arquivo";
import AuditavelDTO from "../comum/auditavel-dto";
import AuditavelGridModel from "../comum/auditavel-grid";
import { CadastrarEmMassaExcelBase } from "../comum/cadastrar-varios";

export const loteDoFabricanteOuNumeroDeSerieMaxLength: number = 25;

export interface LoteGridModel extends AuditavelGridModel {
  id: number;
  item: string | null;
  descricao: string | null;
  fabricante: string | null;
  loteDoFabricanteOuNumeroDeSerie: string | null;
  estocagem: string | null;
  dono: string;
  reanalise: Date | null;
  vencimento: Date | null;
  fabricacao: Date | null;
  observacoes: string | null;
  observacoesInternas: string | null;
  estadoDecodificado: string | null;
}

export interface LoteRequestDTO {
  id: number;
  itemId: number | null;
  fabricanteId: number | null;
  loteDoFabricanteOuNumeroDeSerie: string | null;
  historico: string | null;
  fabricacaoData: Date | null;
  vencimentoData: Date | null;
  reanaliseData: Date | null;
  observacoes: string | null;
  observacoesInternas: string | null;
  arquivos: Array<ArquivoBaseModel>;
  utilizaPrefixo: boolean;
}

export interface LoteCadastrarVariosExcelRequest
  extends CadastrarEmMassaExcelBase {
  loteDoFabricanteOuNumeroDeSerie: string;
  codigoItem: string;
  codigoFabricante: string;
  fabricacaoData: Date | null;
  vencimentoData: Date | null;
  reanaliseData: Date | null;
  observacoes: string | null;
  observacoesInternas: string | null;
  utilizaPrefixo: boolean;
}

export interface VerificacaoDeDadosEncontradosNaTranscricaoDoPrefixoESufixoResponse {
  algumDadoNaoFoiEncontrado: boolean;
  mensagemDeDadosNaoEncontrados: string;
}

export interface LoteResponseDTO extends LoteRequestDTO, AuditavelDTO {}
