import { AxiosResponse } from "axios";
import getApi from "../../../configs/api";
import { ArquivoBaseModel } from "../../../models/api/arquivos/arquivo";
import {
  ResponseBase,
  ResponseBaseArquivo,
  ResponseModel,
} from "../../../models/api/comum/response-base";
import { OrdemDeProducaoResponse } from "../../../models/api/producao/ordem-de-producao/ordem-de-producao";
import APIAnexos, { IAnexosService } from "../../comum/anexos/anexos.service";
import { NomesEndpoints } from "../../comum/nomesEndpoints";
import { ServiceBase } from "../../comum/serviceBase";

export class OrdemDeProducaoService
  extends ServiceBase
  implements IAnexosService
{
  constructor() {
    super(NomesEndpoints.OrdemDeProducao);
  }

  public async obterPorIdComItem(id: number) {
    const api = getApi();

    const response = await api.get<ResponseModel<OrdemDeProducaoResponse>>(
      `${this._nomeEndpoint}/obterPorIdComItem?id=${id}`
    );

    return response.data;
  }

  public async obterAnexos(id: number): Promise<ArquivoBaseModel[]> {
    return await APIAnexos.obterAnexos(id, this._nomeEndpoint);
  }

  public async anexoAlterarLista(
    id: number,
    anexos: ArquivoBaseModel[]
  ): Promise<ResponseBase> {
    return await APIAnexos.anexoAlterarLista(id, anexos, this._nomeEndpoint);
  }

  public async anexoObterDadosParaDownload(
    id: number,
    idAnexo: number
  ): Promise<AxiosResponse<ResponseBaseArquivo, any>> {
    return await APIAnexos.anexoObterDadosParaDownload(
      id,
      idAnexo,
      this._nomeEndpoint
    );
  }
}
