import { Column } from "devextreme-react/data-grid";
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { renderToString } from "react-dom/server";
import { MxpGrid } from "../../../../../components/grid";
import {
  useEditarRegistroGrid,
  useExcluirRegistroGrid,
  useNovoRegistroGrid,
  usePropagarReferenciaGrid,
} from "../../../../../hooks/grid.hooks";
import { NaturezaDoRendimentoGridModel } from "../../../../../models/api/efd-reinf/natureza-do-rendimento/natureza-do-rendimento";
import { CallBackModal } from "../../../../../models/shared/ui/callback-modal";
import { IGridSelecao } from "../../../../../models/shared/ui/formularios";
import { EditFormNaturezaDoRendimento } from "../../../../../pages/fiscal/efd-reinf/natureza-do-rendimento/edit-form";
import { NomesEndpoints } from "../../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../../services/comum/serviceBase";
import { checarResponseExibeMensagemExclusaoDeSucesso } from "../../../../../utils/api/api-utils";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import { GridBaseProps } from "../../../../../utils/grid/grid-utils";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import GetColunasDeAuditoria from "../../../../layout/grid-defaults/colunasDeAuditoria";

const nomeEndpoint = NomesEndpoints.NaturezaDoRendimento;
const dataSource = APIBase.getGridSource(nomeEndpoint);
const nameOfGridHandler = criarNameof<NaturezaDoRendimentoGridModel>();
const colunas = [
  <Column
    key={nameOfGridHandler("codigo")}
    dataField={nameOfGridHandler("codigo")}
    {...obterConfiguracaoColuna("stringP")}
    sortIndex={0}
    sortOrder="asc"
    caption="Código"
  />,
  <Column
    key={nameOfGridHandler("descricao")}
    dataField={nameOfGridHandler("descricao")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Descrição"
  />,
  ...GetColunasDeAuditoria(),
];

export const GridNaturezaDoRendimento = forwardRef(
  (props: GridBaseProps, ref) => {
    const gridRef = useRef<IGridSelecao>(null);
    useImperativeHandle(ref, () => gridRef.current as IGridSelecao);
    usePropagarReferenciaGrid(ref, gridRef);

    const [modalEdicaoVisivel, setModalEdicaoVisivel] = useState(false);
    const [idRegistroEdicao, setIdRegistroEdicao] = useState(NaN);

    function handleAtualizarGrid() {
      if (gridRef.current?.atualizarGrid) {
        gridRef.current?.atualizarGrid();
      }
    }

    const handleNovoRegistro = useNovoRegistroGrid(() => {
      setIdRegistroEdicao(0);
      setModalEdicaoVisivel(true);
    });

    const handleEditarRegistro = useEditarRegistroGrid((id: number) => {
      setIdRegistroEdicao(id);
      setModalEdicaoVisivel(true);
    });

    const handleExcluirRegistro = useExcluirRegistroGrid(
      async (registro: NaturezaDoRendimentoGridModel) => {
        const mensagem = renderToString(<>{obterMensagemExclusao(registro)}</>);
        const excluir = await exibirConfirmacao("Confirmar exclusão", mensagem);

        if (excluir) {
          const resposta = await APIBase.excluir(registro.id, nomeEndpoint);

          if (resposta) {
            checarResponseExibeMensagemExclusaoDeSucesso(resposta);
            handleAtualizarGrid();
          }
        }
      }
    );

    function obterMensagemExclusao(registro: NaturezaDoRendimentoGridModel) {
      return `Tem certeza que deseja excluir o registro ${registro.descricao}?`;
    }

    const handleModalCallback = useCallback((info: CallBackModal) => {
      setModalEdicaoVisivel(false);
      setIdRegistroEdicao(NaN);

      if (info.precisaAtualizar) {
        handleAtualizarGrid();
      }
    }, []);

    return (
      <>
        <MxpGrid<NaturezaDoRendimentoGridModel>
          dataSource={dataSource}
          id={"natureza-do-rendimento"}
          ref={gridRef}
          colunas={colunas}
          novoRegistro={handleNovoRegistro}
          editarRegistro={handleEditarRegistro}
          excluirRegistro={handleExcluirRegistro}
          definirMenuSuperior={props.definirMenu}
          nomeDoArquivoAoExportar={NomesTelas.naturezaDoRendimento}
        />
        <EditFormNaturezaDoRendimento
          visivel={modalEdicaoVisivel}
          idRegistroEdicao={idRegistroEdicao}
          configuracoesModal={{ largura: "max(30vw, 600px)", altura: "auto" }}
          callBackFecharModal={handleModalCallback}
        />
      </>
    );
  }
);
