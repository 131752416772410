import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Sessao from "../../../models/dto/sessao/sessao";
import {
  getDadosSessao,
  getTokenAutoLogin,
} from "../../../services/tokens/tokens.service";
import { tratarErroApi } from "../../../utils/api/api-utils";
import { GravarSessaoReduxELocalStorage } from "../../../utils/oauth/oauth-utils";

export default function Autologin() {
  const [searchParams] = useSearchParams();
  const payload = searchParams.get("payload");
  const navigate = useNavigate();

  useEffect(() => {
    autoLogin();
  });

  async function autoLogin() {
    if (payload) {
      try {
        const resposta = await getTokenAutoLogin(payload);

        if (resposta) {
          const dadosSessaoResposta = await getDadosSessao(resposta);
          const sessao: Sessao = {
            dadosSessao: dadosSessaoResposta,
          };
          GravarSessaoReduxELocalStorage(sessao, resposta);
        }
      } catch (erro) {
        tratarErroApi(erro);
      }
    }
    if (process.env.REACT_APP_IR_PARA_ANTIGO_AO_LOGAR == "true") {
      const urlMxp1 = process.env.REACT_APP_BACKEND_ANTIGO_ENDPOINT as string;
      window.location.href = urlMxp1;
    } else {
      navigate("/");
    }
  }

  return <span>Aguarde...</span>;
}
