import { Button } from "devextreme-react";
import { ContainerLogo } from "../../../pages/autenticacao/login/styles";
import "./index.scss";
import {
  ContainerBotao,
  ContainerCardLogin,
  ContainerRodape,
  HeaderCardLogin,
} from "../card-login/styles";

interface CardRedefinirSenhaViaEmailProps {
  titulo: string;
  children: React.ReactNode;
  onClickRecuperar: () => void;
}

export default function CardRedefinirSenhaViaEmail(
  props: CardRedefinirSenhaViaEmailProps
) {
  return (
    <>
      <ContainerCardLogin className="dx-card content">
        <HeaderCardLogin>
          <ContainerLogo>
            <img src="/img/Logo_ERP_MAXIPROD.svg" alt="Logotipo da Maxiprod" />
          </ContainerLogo>
        </HeaderCardLogin>
        <div className="tituloRecuperacaoSenha">{props.titulo}</div>

        {props.children}

        <ContainerRodape>
          <ContainerBotao>
            <Button
              text="Redefinir senha"
              onClick={props.onClickRecuperar}
              type="default"
              width={"10rem"}
              height={30}
            />
          </ContainerBotao>
        </ContainerRodape>
      </ContainerCardLogin>
    </>
  );
}
