import { useState } from "react";
import Pagina from "../../../components/layout/pagina";
import RequerPermissao from "../../../components/seguranca/requer-permissao";
import { PermissoesLote } from "../../../models/direitos/estoque/lote/permissoes-lote";
import { GridLote } from "../../../parts/estoque/lote/grids/grid-padrao";
import NomesTelas from "../../../utils/common/nomes-telas";
import { ItemContextMenu } from "../../../utils/context-menu/context-menu-utils";

export default function LotePage() {
  const [menus, setMenus] = useState<ItemContextMenu[]>();

  return (
    <Pagina
      id="lote-page"
      titulo={NomesTelas.lotes}
      area="Estoque"
      subMenuItems={menus}
    >
      <RequerPermissao codigoPermissoes={[PermissoesLote.Consultar]}>
        <GridLote definirMenu={setMenus} />
      </RequerPermissao>
    </Pagina>
  );
}
