import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ProvedorAjuda from "../../../../../../components/ajuda/provedor-ajuda";
import BotaoLadoDireitoInput from "../../../../../../components/botoes/botao-lado-direito-input";
import { FormNumberBox } from "../../../../../../components/formularios";
import {
  FormBase2,
  FormularioEdicaoBaseProps,
} from "../../../../../../components/layout/form-base2";
import { Coluna, Linha } from "../../../../../../components/layout/grid-system";
import { useCarregarRegistro } from "../../../../../../hooks/form.hooks";
import AuditavelDTO from "../../../../../../models/api/comum/auditavel-dto";
import {
  DemonstrativoSaldosParteBLalurRequest,
  DemonstrativoSaldosParteBLalurResponse,
} from "../../../../../../models/api/demonstrativo-saldos/demonstrativo-saldos-parte-b-lalur/demonstrativo-saldos-parte-b-lalur";
import { CallBackModal } from "../../../../../../models/shared/ui/callback-modal";
import { DemonstrativoSaldosParteBLalurService } from "../../../../../../services/demonstrativo-saldos-parte-b-lalur/demonstrativo-saldos-parte-b-lalur";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../../utils/common/notificacoes-utils";
import { exibirConfirmacao } from "../../../../../../utils/dialogos";
import { obterFormatStringNumero } from "../../../../../../utils/formatadores/formatador-de-numeros";

const service = new DemonstrativoSaldosParteBLalurService();

const novoRegistro: DemonstrativoSaldosParteBLalurRequest = {
  id: 0,
  contaDaParteBId: 0,
  apuracaoLalurIrpjId: 0,
  saldoInicial: 0,
  saldoInicialEstaFixo: false,
  valorDaParteA: 0,
  valorDaParteB: 0,
  saldoFinal: 0,
};

export default function ModalEdicaoDemonstrativosSaldosLalur(
  props: FormularioEdicaoBaseProps
) {
  const [dadosAuditoria, setDadosAuditoria] = useState<AuditavelDTO>();
  const [carregando, setCarregando] = useState(false);

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    contaDaParteBId: yup.number().required().moreThan(-1),
    apuracaoLalurIrpjId: yup.number().required().moreThan(-1).integer(),
    saldoInicialEstaFixo: yup.boolean().required(),
    valorDaParteA: yup.number(),
    valorDaParteB: yup.number(),
    saldoFinal: yup.number(),
    saldoInicial: yup.number(),
  });

  const { register, control, handleSubmit, getValues, reset, setValue, watch } =
    useForm<DemonstrativoSaldosParteBLalurRequest>({
      mode: "onChange",
      reValidateMode: "onChange",
      resolver: yupResolver(schema),
    });

  useCarregarRegistro(props.idRegistroEdicao, carregarTela);

  // Usado para limpar o formulário se for NaN
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEdicao)) {
      reset(novoRegistro);
    }
  }, [props.idRegistroEdicao]);

  function fechar(info: CallBackModal) {
    if (props.callBackFecharModal) {
      setDadosAuditoria(undefined);
      props.callBackFecharModal(info);
    }
  }

  function handleCancelar() {
    fechar({ concluido: false, precisaAtualizar: false });
  }

  async function carregarTela() {
    try {
      setCarregando(true);
      if (props.idRegistroEdicao > 0) {
        await carregarModel();
      } else {
        reset(novoRegistro);
        setDadosAuditoria(undefined);
      }
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  async function carregarModel() {
    try {
      const resposta =
        await service.ObterPorIdComDadosAuditoria<DemonstrativoSaldosParteBLalurResponse>(
          props.idRegistroEdicao
        );
      checarResponse(resposta);
      reset(resposta.model);
      setDadosAuditoria(resposta.model);
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues();

    try {
      const resposta = await service.Atualizar({
        id: model.id,
        saldoInicial: model.saldoInicial ?? 0,
      });

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar({
          concluido: true,
          precisaAtualizar: true,
        });
      }
      fechar({
        concluido: true,
        precisaAtualizar: false,
      });
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  function callBackUnprocessableEntity() {
    fechar({
      concluido: false,
      precisaAtualizar: true,
    });
  }

  async function handleTornarSaldoInicialLivre() {
    try {
      {
        const resposta = await exibirConfirmacao(
          "Confirmação",
          "Deseja tornar livre o saldo inicial?"
        );

        if (resposta) {
          setCarregando(true);
          const retorno = await service.TornarSaldoInicialLivre(
            props.idRegistroEdicao
          );
          checarResponse(retorno);

          if (retorno.sucesso) {
            setValue("saldoInicialEstaFixo", false);
          }
        }
      }
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  function onChangeSaldoInicial(valor: any) {
    if (valor != getValues("saldoInicial")) {
      setValue("saldoInicialEstaFixo", true);
    }
  }

  return (
    <>
      <FormBase2
        carregando={carregando}
        titulo={"demonstrativo de saldos da parte B do LALUR"}
        onClickSalvar={handleSubmit(handleSalvar)}
        onClickCancelar={handleCancelar}
        visivel={props.visivel}
        modoEdicao={"editar"}
        auditoria={dadosAuditoria}
        configuracoesModal={props.configuracoesModal}
      >
        <ProvedorAjuda id="modal-edicao-demonstrativo-custos-lalur">
          <Coluna md={3}>
            <input
              type="hidden"
              {...register("contaDaParteBId")}
              defaultValue={0}
            />
            <input
              type="hidden"
              {...register("apuracaoLalurIrpjId")}
              defaultValue={0}
            />
            <input
              type="hidden"
              {...register("valorDaParteA")}
              defaultValue={0}
            />
            <input
              type="hidden"
              {...register("valorDaParteB")}
              defaultValue={0}
            />
            <input type="hidden" {...register("saldoFinal")} defaultValue={0} />
            <input
              type="hidden"
              {...register("saldoInicialEstaFixo")}
              defaultValue={0}
            />
          </Coluna>
          <Linha>
            <Coluna md={6}>
              <BotaoLadoDireitoInput
                textoTooltip="Fixar saldo inicial"
                icone="ic-keep"
                onClick={() => handleTornarSaldoInicialLivre()}
                visivel={watch("saldoInicialEstaFixo")}
              >
                <FormNumberBox
                  name="saldoInicial"
                  titulo="Saldo inicial"
                  formato={obterFormatStringNumero(2)}
                  control={control}
                  requerido
                  onChange={(e) => onChangeSaldoInicial(e)}
                />
              </BotaoLadoDireitoInput>
            </Coluna>
          </Linha>
        </ProvedorAjuda>
      </FormBase2>
    </>
  );
}
