import Pagina from "../../../../components/layout/pagina";
import RequerPermissao from "../../../../components/seguranca/requer-permissao";
import { PermissoesNaturezaDoRendimento } from "../../../../models/permissoes/fiscal/efd-reinf/permissoes-natureza-do-rendimento";
import { GridNaturezaDoRendimento } from "../../../../parts/fiscal/efd-reinf/natureza-do-rendimento/grid";
import NomesTelas from "../../../../utils/common/nomes-telas";

export default function NaturezaDoRendimentoPage() {
  return (
    <Pagina
      id="natureza-do-rendimento-page"
      titulo={NomesTelas.naturezaDoRendimento}
      area={"Fiscal"}
    >
      <RequerPermissao
        codigoPermissoes={[PermissoesNaturezaDoRendimento.Consultar]}
      >
        <GridNaturezaDoRendimento />
      </RequerPermissao>
    </Pagina>
  );
}
