import { Column } from "devextreme-react/data-grid";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { renderToString } from "react-dom/server";
import ModalAccordion from "../../../../../components/dialogos/modal-accordion";
import {
  AccordionDataSource,
  AccordionModel,
} from "../../../../../components/dialogos/modal-accordion/modal-accordion";
import { MxpGrid } from "../../../../../components/grid";
import {
  useExcluirRegistroGrid,
  useGerarItensAdicionais,
  usePropagarReferenciaGrid,
} from "../../../../../hooks/grid.hooks";
import { useAppDispatch } from "../../../../../hooks/store.hooks";
import { EfdReinfNumeracaoTipo } from "../../../../../models/api/efd-reinf/efd-reinf";
import {
  EfdReinf2020GridModel,
  InfosR2020EnvioReinf,
} from "../../../../../models/api/efd-reinf/efd-reinf-2020/efd-reinf-2020";
import { PermissoesEfdReinf } from "../../../../../models/permissoes/fiscal/efd-reinf/permissoes-efd-reinf";
import { IGridSelecao } from "../../../../../models/shared/ui/formularios";
import API from "../../../../../services/efd-reinf/efd-reinf.service";
import { bloquearUI, desbloquearUI } from "../../../../../store/ui/ui.slice";
import {
  checarResponse,
  checarResponseExibeMensagemExclusaoDeSucesso,
  checarResponseExibeMensagemExecutadoComSucesso,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { ItemContextMenu } from "../../../../../utils/context-menu/context-menu-utils";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import { verificaStatusDoRegistroParaConsulta as verificaStatusDoRegistroEfdReinfParaConsulta } from "../../../../../utils/especifico/efd-reinf/efd-reinf-utils";
import { formatarData } from "../../../../../utils/formatadores/formatador-de-datas";
import { formatarNumeroMonetario } from "../../../../../utils/formatadores/formatador-de-numeros";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import GetColunasDeAuditoria from "../../../../layout/grid-defaults/colunasDeAuditoria";

const dataSource = API.getGridSource(EfdReinfNumeracaoTipo.R2020);
const nameOfGridHandler = criarNameof<EfdReinf2020GridModel>();
const colunas = [
  <Column
    key={nameOfGridHandler("tipoDeArquivo")}
    dataField={nameOfGridHandler("tipoDeArquivo")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Tipo de arquivo"
    visible={false}
  />,
  <Column
    key={nameOfGridHandler("apuracaoDataInicial")}
    dataField={nameOfGridHandler("apuracaoDataInicial")}
    {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
    caption="Data inicial da apuração"
    sortIndex={0}
    sortOrder="desc"
    width={200}
  />,
  <Column
    key={nameOfGridHandler("apuracaoDataFinal")}
    dataField={nameOfGridHandler("apuracaoDataFinal")}
    {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
    caption="Data final da apuração"
    sortIndex={0}
    sortOrder="desc"
    width={200}
  />,
  <Column
    key={nameOfGridHandler("clienteRazaoSocial")}
    dataField={nameOfGridHandler("clienteRazaoSocial")}
    {...obterConfiguracaoColuna("stringGG")}
    caption="Razão social do cliente"
  />,
  <Column
    key={nameOfGridHandler("clienteCnpj")}
    dataField={nameOfGridHandler("clienteCnpj")}
    {...obterConfiguracaoColuna("cnpjFormatado")}
    caption="CNPJ do cliente"
  />,
  <Column
    key={nameOfGridHandler("baseDeCalculo")}
    dataField={nameOfGridHandler("baseDeCalculo")}
    {...obterConfiguracaoColuna("monetario")}
    caption="Base de cálculo"
  />,
  <Column
    key={nameOfGridHandler("valorRetido")}
    dataField={nameOfGridHandler("valorRetido")}
    {...obterConfiguracaoColuna("monetario")}
    caption="Valor retido"
  />,
  <Column
    key={nameOfGridHandler("dataRecibo")}
    dataField={nameOfGridHandler("dataRecibo")}
    {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
    caption="Data do recibo"
  />,
  <Column
    key={nameOfGridHandler("recibo")}
    dataField={nameOfGridHandler("recibo")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Recibo"
  />,
  <Column
    key={nameOfGridHandler("situacaoDecodificada")}
    dataField={nameOfGridHandler("situacaoDecodificada")}
    {...obterConfiguracaoColuna("stringP")}
    caption="Situação"
  />,
  <Column
    key={nameOfGridHandler("codigoDoErro")}
    dataField={nameOfGridHandler("codigoDoErro")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Código do erro"
  />,
  <Column
    key={nameOfGridHandler("motivoDoErro")}
    dataField={nameOfGridHandler("motivoDoErro")}
    {...obterConfiguracaoColuna("stringGG")}
    caption="Motivo do erro"
  />,
  ...GetColunasDeAuditoria(true),
];

interface GridEfdReinf2020Props {
  definirMenu?: (data: ItemContextMenu[]) => void;
}

function obterDadosAccordionEnvioReinf(
  info: InfosR2020EnvioReinf
): AccordionModel[] {
  const accordionModel: AccordionModel[] = [];

  const obterMensagemBase = (apelido: string, valorRetido: number) => {
    return `Cliente: ${apelido}. Valor retido: ${formatarNumeroMonetario(
      valorRetido
    )}`;
  };

  if (info.registrosJaEnviados.length > 0) {
    accordionModel.push({
      grupoDeMensagensTitulo: `Não foi possível enviar ${info.registrosJaEnviados.length} eventos R-2020. Pois já foram enviados com sucesso:`,
      mensagens: info.registrosJaEnviados.map((x) => {
        return `${obterMensagemBase(
          x.apelidoCliente,
          x.valorRetido
        )}. Data do recibo: ${formatarData(x.reciboData)}. Recibo: ${x.recibo}`;
      }),
    });
  }

  if (info.registrosComDivergenciaNaApuracao.length > 0) {
    accordionModel.push({
      grupoDeMensagensTitulo: `Não foi possível enviar ${info.registrosComDivergenciaNaApuracao.length} eventos R-2020. Pois a data inicial e data final da apuração não pertencem ao mesmo período (mês/ano), o que geraria rejeição por parte da SEFAZ:`,
      mensagens: info.registrosComDivergenciaNaApuracao.map((x) => {
        return `${obterMensagemBase(
          x.apelidoCliente,
          x.valorRetido
        )}. Apuração: ${formatarData(x.dataInicial)} a ${formatarData(
          x.dataFinal
        )}`;
      }),
    });
  }

  if (info.registrosEnviadosComSucesso.length > 0) {
    accordionModel.push({
      grupoDeMensagensTitulo: `${info.registrosEnviadosComSucesso.length} envios foram realizados com sucesso. Clique aqui para ver os eventos enviados:`,
      mensagens: info.registrosEnviadosComSucesso.map((x) => {
        return `${obterMensagemBase(
          x.apelidoCliente,
          x.valorRetido
        )}. Data do recibo: ${formatarData(x.reciboData)}. Recibo: ${x.recibo}`;
      }),
    });
  }

  if (info.registrosComErros.length > 0) {
    accordionModel.push({
      grupoDeMensagensTitulo: `${info.registrosComErros.length} envios não foram realizados com sucesso. Clique aqui para ver o motivo dos erros:`,
      mensagens: info.registrosComErros.map((x) => {
        return `${obterMensagemBase(x.apelidoCliente, x.valorRetido)}. Erro: ${
          x.codigoErro
        } - ${x.motivoErro}`;
      }),
    });
  }

  if (info.registrosEmProcessamento.length > 0) {
    accordionModel.push({
      grupoDeMensagensTitulo: `${info.registrosEmProcessamento.length} envios estão com o lote em processamento na SEFAZ. Clique aqui para ver os eventos:`,
      mensagens: info.registrosEmProcessamento.map((x) => {
        return `${obterMensagemBase(x.apelidoCliente, x.valorRetido)}`;
      }),
    });
  }

  return accordionModel;
}

export const GridEfdReinf2020 = forwardRef(
  (props: GridEfdReinf2020Props, ref) => {
    const gridRef = useRef<IGridSelecao>(null);
    useImperativeHandle(ref, () => gridRef.current as IGridSelecao);

    const [modalAccordionVisivel, setModalAccordionVisivel] = useState(false);
    const [
      detalhamentoEnvioReinfMensagensAccordion,
      setDetalhamentoEnvioReinfMensagensAccordion,
    ] = useState<AccordionDataSource>();

    const dispatch = useAppDispatch();

    usePropagarReferenciaGrid(ref, gridRef);

    function handleAtualizarGrid() {
      if (gridRef.current?.atualizarGrid) {
        gridRef.current?.atualizarGrid();
      }
    }

    const handleExcluirRegistro = useExcluirRegistroGrid(
      async (registro: EfdReinf2020GridModel) => {
        if (
          !verificaComNotificacaoSeUsuarioPossuiPermissoes([
            PermissoesEfdReinf.Excluir,
          ])
        ) {
          return;
        }

        const mensagem = renderToString(<>{obterMensagemExclusao(registro)}</>);
        const excluir = await exibirConfirmacao("Confirmar exclusão", mensagem);

        if (excluir) {
          const resposta = await API.excluir(
            registro.id,
            EfdReinfNumeracaoTipo.R2020
          );

          if (resposta) {
            checarResponseExibeMensagemExclusaoDeSucesso(resposta);
            handleAtualizarGrid();
          }
        }
      }
    );

    function obterMensagemExclusao(registro: EfdReinf2020GridModel) {
      return `Tem certeza que deseja excluir o registro com o valor retido ${formatarNumeroMonetario(
        Number(registro.valorRetido)
      )}?`;
    }

    const handleGerarItensAdicionais = useGerarItensAdicionais(
      (
        getData: () => EfdReinf2020GridModel | undefined,
        data?: EfdReinf2020GridModel | undefined,
        getSelecionados?: () => EfdReinf2020GridModel[] | undefined
      ) => {
        const itensAdicionais = [
          {
            text: "Ações",
            icon: "ic-material-symbols-outlined ic-vertical",
            hint: "Menu com opções de ações",
            items: [
              {
                text: "Enviar evento R-2020 - Retenção contribuição previdenciária - Prestadores de serviços",
                mostraNaColunaDeAcoes: false,
                onClick: () => {
                  if (getSelecionados) {
                    const ids = getSelecionados()?.map((x) => x.id);
                    enviarEvento(ids);
                  } else {
                    const id = getData()?.id;
                    enviarEvento(id ? [id] : undefined);
                  }
                },
                hint: "Enviar evento",
              },
              {
                text: "Consultar evento R-2020 - Retenção contribuição previdenciária - Prestadores de serviços",
                onClick: () => {
                  const data = getData();
                  consultarEvento(data?.id);
                },
                disabledIcon: !verificaStatusDoRegistroEfdReinfParaConsulta(
                  data?.situacao
                ),
                mostraNaColunaDeAcoes: false,
                hint: "Consultar evento",
              },
            ],
          },
        ];

        return itensAdicionais;
      }
    );

    async function enviarEvento(ids?: number[]) {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesEfdReinf.Enviar,
        ])
      ) {
        return;
      }

      if (ids) {
        try {
          dispatch(bloquearUI("Enviando..."));
          const resposta = await API.enviar<InfosR2020EnvioReinf>(
            ids,
            EfdReinfNumeracaoTipo.R2020
          );
          checarResponse(resposta);
          if (resposta.sucesso) {
            if (resposta.model) {
              setModalAccordionVisivel(true);
              setDetalhamentoEnvioReinfMensagensAccordion({
                model: obterDadosAccordionEnvioReinf(resposta.model),
              });
            }
          }
        } catch (erro) {
          tratarErroApi(erro);
        } finally {
          dispatch(desbloquearUI());
        }
      }
    }

    async function consultarEvento(id?: number) {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesEfdReinf.Consultar,
        ])
      ) {
        return;
      }
      if (id) {
        try {
          const resposta = await API.consultar(id, EfdReinfNumeracaoTipo.R2020);

          if (resposta.sucesso) {
            checarResponseExibeMensagemExecutadoComSucesso(resposta);
          }
        } catch (erro) {
          tratarErroApi(erro);
        }
      }
    }

    function handlerFecharModalAccordion() {
      setModalAccordionVisivel(false);
      setDetalhamentoEnvioReinfMensagensAccordion(undefined);
    }

    return (
      <>
        <MxpGrid<EfdReinf2020GridModel>
          id={"efd-reinf-2020"}
          dataSource={dataSource}
          ref={gridRef}
          colunas={colunas}
          exibirIconeEditarMenuAcoes={false}
          excluirRegistro={handleExcluirRegistro}
          gerarItensAdicionaisDeContexto={handleGerarItensAdicionais}
          definirMenuSuperior={props.definirMenu}
          nomeDoArquivoAoExportar={NomesTelas.efdReinf2020}
        />
        <ModalAccordion
          modalAccordionVisivel={modalAccordionVisivel}
          handlerFecharModalAccordion={handlerFecharModalAccordion}
          dataSource={detalhamentoEnvioReinfMensagensAccordion?.model}
          modalTitulo={
            detalhamentoEnvioReinfMensagensAccordion?.accordionTitulo ??
            "Atenção"
          }
          accordionId="accordion-detalhamento-envio-reinf-R2020"
          itemDoAccordionAltura="auto"
        />
      </>
    );
  }
);
