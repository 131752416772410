import { useState } from "react";
import * as yup from "yup";
import ProvedorAjuda from "../../../../components/ajuda/provedor-ajuda";
import {
  FormGrupo,
  FormNumberBox,
  FormSelectBox,
  FormTextBox,
} from "../../../../components/formularios";
import {
  FormBase2,
  FormularioEdicaoBaseProps,
} from "../../../../components/layout/form-base2";
import { Coluna, Linha } from "../../../../components/layout/grid-system";
import useEffectOnLoad from "../../../../hooks/effect.hooks";
import {
  useCarregarRegistro,
  useHookForms,
  useLimparFormSeIdForNaN,
} from "../../../../hooks/form.hooks";
import AuditavelDTO from "../../../../models/api/comum/auditavel-dto";
import {
  CodigoTributacaoServicoRequestDTO,
  CodigoTributacaoServicoResponseDTO,
} from "../../../../models/api/tributacao/codigo-tributacao-servico";
import { CallBackModal } from "../../../../models/shared/ui/callback-modal";
import SelectItem from "../../../../models/shared/ui/select-item";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../services/comum/serviceBase";
import APICodigoDeTributacao from "../../../../services/tributacao/codigo-tributacao-servico.service";
import { checarResponse, tratarErroApi } from "../../../../utils/api/api-utils";
import NomesModais from "../../../../utils/common/nomes-modais";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../utils/common/notificacoes-utils";
import { obterFormatStringNumero } from "../../../../utils/formatadores/formatador-de-numeros";

const novoRegistro: CodigoTributacaoServicoRequestDTO = {
  id: 0,
  aliquotaIss: null,
  codigo: "",
  descricao: "",
  idCodigoDoServico: 0,
};

const nomeEndpoint = NomesEndpoints.TributacaoDeServicoCodigo;

const CodigosServicoSelectBox = (props: { control: any }) => {
  const [codigosServico, serCodigosServico] = useState<SelectItem[]>([]);

  useEffectOnLoad(() => {
    carregarCodigosServico();
  });

  async function carregarCodigosServico() {
    try {
      const resposta = await APICodigoDeTributacao.obterCodigosServico();
      checarResponse(resposta);
      serCodigosServico(
        resposta.model.map((x) => ({
          valor: x.valor,
          descricao: x.descricao,
        }))
      );
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  return (
    <FormSelectBox
      name="idCodigoDoServico"
      titulo="Código do serviço"
      toolTip="Código do serviço"
      control={props.control}
      requerido
      dataSource={codigosServico}
      habilitaBusca
      tipoBusca="contains"
    />
  );
};

export const EditFormCodigoTributacaoServico = (
  props: FormularioEdicaoBaseProps
) => {
  const [carregando, setCarregando] = useState(false);
  const [dadosAuditoria, setDadosAuditoria] = useState<AuditavelDTO>();

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    codigo: yup.string().required().max(20),
    descricao: yup.string().required().max(255),
    idCodigoDoServico: yup.number().required().positive().integer(),
    aliquotaIss: yup
      .number()
      .nullable()
      .positive()
      .max(10000000000)
      .transform((valor) => (valor ? valor : null)),
  });

  const hookForm = useHookForms(schema);
  useCarregarRegistro(props.idRegistroEdicao, carregarTela);
  useLimparFormSeIdForNaN(hookForm, novoRegistro, props.idRegistroEdicao);

  const { register, control, handleSubmit, getValues, reset } = hookForm;

  async function carregarTela() {
    try {
      setCarregando(true);
      if (props.idRegistroEdicao > 0) {
        await carregarModel();
      } else {
        reset(novoRegistro);
        setDadosAuditoria(undefined);
      }
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  async function carregarModel() {
    try {
      const resposta =
        await APIBase.obterPorIdComDadosAuditoria<CodigoTributacaoServicoResponseDTO>(
          props.idRegistroEdicao,
          nomeEndpoint
        );
      checarResponse(resposta);
      reset(resposta.model);
      setDadosAuditoria(resposta.model);
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues();
    try {
      const resposta =
        props.idRegistroEdicao > 0
          ? await APIBase.atualizar(model, nomeEndpoint)
          : await APIBase.cadastrar(model, nomeEndpoint);

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar({
          concluido: true,
          precisaAtualizar: true,
        });
      }
      fechar({
        concluido: true,
        precisaAtualizar: false,
      });
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  function fechar(info: CallBackModal) {
    if (props.callBackFecharModal) {
      props.callBackFecharModal(info);
    }
  }

  function handleCancelar() {
    setDadosAuditoria(undefined);
    fechar({ concluido: false, precisaAtualizar: false });
  }

  function callBackUnprocessableEntity() {
    fechar({
      concluido: false,
      precisaAtualizar: true,
    });
  }

  return (
    <>
      <FormBase2
        visivel={props.visivel}
        carregando={carregando}
        onClickSalvar={handleSubmit(handleSalvar)}
        onClickCancelar={handleCancelar}
        configuracoesModal={props.configuracoesModal}
        modoEdicao={props.idRegistroEdicao == 0 ? "criar" : "editar"}
        titulo={NomesModais.codigoDeTributacaoDeServicos}
        auditoria={dadosAuditoria}
      >
        <ProvedorAjuda id="edit-form-codigo-tributacao-servico">
          <input type="hidden" {...register("id")} defaultValue={0} />
          <FormGrupo>
            <Linha>
              <Coluna md={4}>
                <FormTextBox
                  name="codigo"
                  titulo="Código"
                  toolTip="Código"
                  control={control}
                  requerido
                  tamanhoMaximo={20}
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={12}>
                <FormTextBox
                  name="descricao"
                  titulo="Descrição"
                  toolTip="Descrição"
                  control={control}
                  requerido
                  tamanhoMaximo={255}
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={12}>
                <CodigosServicoSelectBox control={control} />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={5}>
                <FormNumberBox
                  name="aliquotaIss"
                  titulo="Alíquota de ISS"
                  toolTip="Alíquota de ISS"
                  control={control}
                  minimo={0}
                  maximo={10000000000}
                  formato={obterFormatStringNumero(4)}
                  exibirBotaoLimpar
                />
              </Coluna>
            </Linha>
          </FormGrupo>
        </ProvedorAjuda>
      </FormBase2>
    </>
  );
};

export default EditFormCodigoTributacaoServico;
