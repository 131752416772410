import { useState } from "react";
import Pagina from "../../../components/layout/pagina";
import RequerPermissao from "../../../components/seguranca/requer-permissao";
import { PermissoesInsumosDasOrdensDeProducao } from "../../../models/permissoes/producao/insumo-da-ordem-de-producao/InsumoDaOrdemDeProducaoPermissoes";
import { GridInsumoDaOrdemDeProducao } from "../../../parts/producao/insumo-da-ordem-de-producao/grids/grid-padrao";
import NomesTelas from "../../../utils/common/nomes-telas";
import { ItemContextMenu } from "../../../utils/context-menu/context-menu-utils";

export default function InsumosDasOrdensDeProducaoPage() {
  const [menus, setMenus] = useState<ItemContextMenu[]>();

  return (
    <Pagina
      id="insumos-das-ordens-de-producao-page"
      titulo={NomesTelas.insumosDasOrdensDeProducao}
      area={"Produção"}
      subMenuItems={menus}
    >
      <RequerPermissao
        codigoPermissoes={[PermissoesInsumosDasOrdensDeProducao.Consultar]}
      >
        <GridInsumoDaOrdemDeProducao definirMenu={setMenus} />
      </RequerPermissao>
    </Pagina>
  );
}
