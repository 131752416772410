import { SelectBoxLazyOpcoes } from "../../../models/api/comum/selectboxlazy-options";
import { EnderecoEstoqueGridModel } from "../../../models/api/endereco-estoque/endereco-estoque";
import { criarDataSourceSelectBoxLazy } from "../../../utils/api/api-utils";
import criarNameof from "../../../utils/common/cria-name-of";
import criarDatasourceGrid from "../../../utils/grid/back-end-grid-utils";
import { NomesEndpoints } from "../../comum/nomesEndpoints";

const nomeEndpoint: string = NomesEndpoints.EnderecoEstoque;

function getDataSourceSelectBoxLazy(opcoes: SelectBoxLazyOpcoes) {
  const nameOfGridHandler = criarNameof<EnderecoEstoqueGridModel>();
  const camposSelect = JSON.stringify(opcoes.camposRetorno);
  const dataSource = criarDatasourceGrid(
    `${nomeEndpoint}/grid?select=${camposSelect}`
  );
  opcoes.camposOrdenacao = [
    {
      nomeCampo: nameOfGridHandler("codigo"),
      desc: false,
    },
  ];

  return criarDataSourceSelectBoxLazy(dataSource, opcoes);
}

export default {
  getDataSourceSelectBoxLazy,
};
