import { useState } from "react";
import * as yup from "yup";
import ProvedorAjuda from "../../../../components/ajuda/provedor-ajuda";
import {
  FormGrupo,
  FormSelectBox,
  FormTextBox,
} from "../../../../components/formularios";
import {
  FormBase2,
  FormularioEdicaoBaseProps,
} from "../../../../components/layout/form-base2";
import { Coluna, Linha } from "../../../../components/layout/grid-system";
import {
  useCarregarCombos,
  useCarregarRegistro,
  useHookForms,
  useLimparFormSeIdForNaN,
} from "../../../../hooks/form.hooks";
import AuditavelDTO from "../../../../models/api/comum/auditavel-dto";
import { ConjuntoVeiculoRequestDTO } from "../../../../models/api/conjunto-veiculo/conjunto-veiculo";
import { TipoUnidadeTransporte } from "../../../../models/api/veiculo/veiculo";
import { CallBackModal } from "../../../../models/shared/ui/callback-modal";
import SelectItem from "../../../../models/shared/ui/select-item";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../services/comum/serviceBase";
import API from "../../../../services/conjunto-veiculo/conjunto-veiculo.service";
import APIVeiculo from "../../../../services/veiculo/veiculo.service";
import { checarResponse, tratarErroApi } from "../../../../utils/api/api-utils";
import NomesModais from "../../../../utils/common/nomes-modais";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../utils/common/notificacoes-utils";

const novoRegistro: ConjuntoVeiculoRequestDTO = {
  id: 0,
  descricao: "",
  idVeiculoTracao: 0,
  idVeiculoReboque1: 0,
  idVeiculoReboque2: null,
  idVeiculoReboque3: null,
};

export const EditFormConjuntoVeiculo = (props: FormularioEdicaoBaseProps) => {
  const [carregando, setCarregando] = useState(false);
  const [veiculosTracao, setVeiculoTracao] = useState<SelectItem[]>([]);
  const [veiculosReboque, setVeiculoReboque] = useState<SelectItem[]>([]);
  const [dadosAuditoria, setDadosAuditoria] = useState<AuditavelDTO>();

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    descricao: yup.string().required().max(120),
    idVeiculoTracao: yup
      .number()
      .required()
      .positive()
      .integer()
      .transform((v) => (v ? v : null))
      .oneOf(
        veiculosTracao.map((x) => x.valor as number),
        "Campo inválido"
      ),
    idVeiculoReboque1: yup
      .number()
      .required()
      .positive()
      .integer()
      .transform((v) => (v ? v : null))
      .oneOf(
        veiculosReboque.map((x) => x.valor as number),
        "Campo inválido"
      )
      .test(
        "reboque1IgualAo2Tests",
        "Campo igual ao reboque 2",
        function (value) {
          return this.parent.idVeiculoReboque2 != value;
        }
      )
      .test(
        "reboque1IgualAo3Tests",
        "Campo igual ao reboque 3",
        function (value) {
          return this.parent.idVeiculoReboque3 != value;
        }
      ),
    idVeiculoReboque2: yup
      .number()
      .positive()
      .integer()
      .transform((v) => (v ? v : null))
      .nullable()
      .oneOf(
        veiculosReboque.map((x) => x.valor as number),
        "Campo inválido"
      )
      .test(
        "reboque2IgualAo1Tests",
        "Campo igual ao reboque 1",
        function (value) {
          if (!value) {
            return true;
          }
          return this.parent.idVeiculoReboque1 != value;
        }
      )
      .test(
        "reboque2IgualAo3Tests",
        "Campo igual ao reboque 3",
        function (value) {
          if (!value) {
            return true;
          }
          return this.parent.idVeiculoReboque3 != value;
        }
      ),
    idVeiculoReboque3: yup
      .number()
      .positive()
      .integer()
      .transform((v) => (v ? v : null))
      .nullable()
      .oneOf(
        veiculosReboque.map((x) => x.valor as number),
        "Campo inválido"
      )
      .test(
        "reboque3IgualAo1Tests",
        "Campo igual ao reboque 1",
        function (value) {
          if (!value) {
            return true;
          }

          return this.parent.idVeiculoReboque1 != value;
        }
      )
      .test(
        "reboque3IgualAo2Tests",
        "Campo igual ao reboque 2",
        function (value) {
          if (!value) {
            return true;
          }
          return this.parent.idVeiculoReboque2 != value;
        }
      ),
  });

  function fechar(info: CallBackModal) {
    if (props.callBackFecharModal) {
      props.callBackFecharModal(info);
    }
  }

  const hookForm = useHookForms<ConjuntoVeiculoRequestDTO>(schema);
  useCarregarRegistro(props.idRegistroEdicao, CarregarRegistro);
  useCarregarCombos(props.idRegistroEdicao, carregarCombos);
  useLimparFormSeIdForNaN(hookForm, novoRegistro, props.idRegistroEdicao);

  const { register, control, handleSubmit, getValues, reset } = hookForm;

  async function CarregarRegistro() {
    try {
      setCarregando(true);
      const resposta = await API.obterPorIdComDadosAuditoria(
        props.idRegistroEdicao
      );
      reset(resposta?.model);
      setDadosAuditoria(resposta.model);
    } catch (error) {
      tratarErroApi(error);
    } finally {
      setCarregando(false);
    }
  }

  function handleCancelar() {
    fechar({ concluido: false, precisaAtualizar: false });
  }

  async function carregarCombos() {
    await carregarVeiculosTracao();
    await carregarVeiculosReboque();
  }

  async function carregarVeiculosTracao() {
    try {
      const resposta = await APIVeiculo.obterListaSimples([
        TipoUnidadeTransporte.RodoviarioTracao,
      ]);
      checarResponse(resposta);
      setVeiculoTracao(
        resposta.model.map((x) => ({
          valor: x.valor,
          descricao: x.descricao,
        }))
      );
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  async function carregarVeiculosReboque() {
    try {
      const resposta = await APIVeiculo.obterListaSimples([
        TipoUnidadeTransporte.RodoviarioReboque,
        TipoUnidadeTransporte.Outros,
      ]);
      checarResponse(resposta);
      setVeiculoReboque(
        resposta.model.map((x) => ({
          valor: x.valor,
          descricao: x.descricao,
        }))
      );
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues();

    try {
      const resposta =
        props.idRegistroEdicao > 0
          ? await APIBase.atualizar(model, NomesEndpoints.VeiculoConjunto)
          : await APIBase.cadastrar(model, NomesEndpoints.VeiculoConjunto);

      checarResponse(resposta);

      if (resposta?.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar({ concluido: true, precisaAtualizar: true });
      }
    } catch (error: any) {
      tratarErroApi(error);
    } finally {
      setCarregando(false);
    }
  }

  return (
    <FormBase2
      visivel={props.visivel}
      carregando={carregando}
      onClickSalvar={handleSubmit(handleSalvar)}
      onClickCancelar={handleCancelar}
      configuracoesModal={props.configuracoesModal}
      modoEdicao={props.idRegistroEdicao == 0 ? "criar" : "editar"}
      titulo={NomesModais.conjuntoVeiculo}
      auditoria={dadosAuditoria}
    >
      <input type="hidden" {...register("id")} defaultValue={0} />
      <ProvedorAjuda id="edit-form-veiculo">
        <FormGrupo>
          <Linha>
            <Coluna md={12}>
              <FormTextBox
                name="descricao"
                titulo="Descrição"
                control={control}
                requerido
                tamanhoMaximo={120}
              />
            </Coluna>
          </Linha>
          <Linha>
            <Coluna md={12}>
              <FormSelectBox
                name="idVeiculoTracao"
                titulo="Veículo tração"
                control={control}
                requerido
                dataSource={veiculosTracao}
                habilitaBusca
                tipoBusca="contains"
              />
            </Coluna>
          </Linha>
          <Linha>
            <Coluna md={12}>
              <FormSelectBox
                name="idVeiculoReboque1"
                titulo="Veículo reboque 1"
                control={control}
                requerido
                dataSource={veiculosReboque}
                habilitaBusca
                tipoBusca="contains"
              />
            </Coluna>
          </Linha>
          <Linha>
            <Coluna md={12}>
              <FormSelectBox
                name="idVeiculoReboque2"
                titulo="Veículo reboque 2"
                control={control}
                dataSource={veiculosReboque}
                habilitaBusca
                tipoBusca="contains"
              />
            </Coluna>
          </Linha>
          <Linha>
            <Coluna md={12}>
              <FormSelectBox
                name="idVeiculoReboque3"
                titulo="Veículo reboque 3"
                control={control}
                dataSource={veiculosReboque}
                habilitaBusca
                tipoBusca="contains"
              />
            </Coluna>
          </Linha>
        </FormGrupo>
      </ProvedorAjuda>
    </FormBase2>
  );
};
