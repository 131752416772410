import { Column } from "devextreme-react/data-grid";
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { MxpGrid } from "../../../../../components/grid";
import {
  useEditarRegistroGrid,
  useExcluirRegistroComPermissaoGrid,
  useGerarItensAdicionais,
  useNovoRegistroGrid,
  usePropagarReferenciaGrid,
} from "../../../../../hooks/grid.hooks";
import { OrdemDeProducaoGridModel } from "../../../../../models/api/producao/ordem-de-producao/ordem-de-producao";
import { PermissoesOrdemDeProducao } from "../../../../../models/permissoes/producao/ordem-de-producao/OrdemDeProducaoPermissoes";
import { CallBackModal } from "../../../../../models/shared/ui/callback-modal";
import { IGridSelecao } from "../../../../../models/shared/ui/formularios";
import EditFormOrdemDeProducao from "../../../../../pages/producao/ordem-de-producao/edit-form";
import { NomesEndpoints } from "../../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../../services/comum/serviceBase";
import {
  checarResponseExibeMensagemExclusaoDeSucesso,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import criarNameof from "../../../../../utils/common/cria-name-of";
import { navegarParaMxpAntigo } from "../../../../../utils/common/menu-utils";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { GridBaseProps } from "../../../../../utils/grid/grid-utils";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import GetColunasDeAuditoria from "../../../../layout/grid-defaults/colunasDeAuditoria";

const nomeEndpoint = NomesEndpoints.OrdemDeProducao;
const dataSource = APIBase.getGridSource(nomeEndpoint);

const nameOfGridHandler = criarNameof<OrdemDeProducaoGridModel>();
const colunas = [
  <Column
    key={nameOfGridHandler("numero")}
    dataField={nameOfGridHandler("numero")}
    {...obterConfiguracaoColuna("codigo")}
    caption="Nº"
    alignment="right"
    sortOrder="desc"
  />,
  <Column
    key={nameOfGridHandler("itemCodigo")}
    dataField={nameOfGridHandler("itemCodigo")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Item"
  />,
  <Column
    key={nameOfGridHandler("itemDescricao")}
    dataField={nameOfGridHandler("itemDescricao")}
    {...obterConfiguracaoColuna("stringXG")}
    caption="Descrição"
  />,
  <Column
    key={nameOfGridHandler("itemDescricaoComplementar")}
    dataField={nameOfGridHandler("itemDescricaoComplementar")}
    {...obterConfiguracaoColuna("stringXG")}
    caption="Descrição complementar"
    visible={false}
  />,
  <Column
    key={nameOfGridHandler("grupoCodigo")}
    dataField={nameOfGridHandler("grupoCodigo")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Grupo"
    visible={false}
  />,
  <Column
    key={nameOfGridHandler("grupoDescricao")}
    dataField={nameOfGridHandler("grupoDescricao")}
    {...obterConfiguracaoColuna("stringXG")}
    caption="Descrição do grupo"
    visible={false}
  />,
  <Column
    key={nameOfGridHandler("itemDesenho")}
    dataField={nameOfGridHandler("itemDesenho")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Desenho"
    visible={false}
  />,
  <Column
    key={nameOfGridHandler("itemDesenhoRevisao")}
    dataField={nameOfGridHandler("itemDesenhoRevisao")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Revisão do desenho"
    visible={false}
  />,
  <Column
    key={nameOfGridHandler("quantidade")}
    dataField={nameOfGridHandler("quantidade")}
    {...obterConfiguracaoColuna("quantidade")}
  />,
  <Column
    key={nameOfGridHandler("quantidadeDePerdaPrevista")}
    dataField={nameOfGridHandler("quantidadeDePerdaPrevista")}
    {...obterConfiguracaoColuna("quantidade")}
    caption="Qt de perda prevista"
    visible={false}
    width={180}
  />,
  <Column
    key={nameOfGridHandler("quantidadeBruta")}
    dataField={nameOfGridHandler("quantidadeBruta")}
    {...obterConfiguracaoColuna("quantidade")}
    caption="Qt bruta"
    visible={false}
  />,
  <Column
    key={nameOfGridHandler("itemUnidadePrincipalCodigo")}
    dataField={nameOfGridHandler("itemUnidadePrincipalCodigo")}
    {...obterConfiguracaoColuna("stringPP")}
    caption="Un"
    width={60}
  />,
  <Column
    key={nameOfGridHandler("quantidadeNaUnidadeDeVenda")}
    dataField={nameOfGridHandler("quantidadeNaUnidadeDeVenda")}
    {...obterConfiguracaoColuna("quantidade")}
    caption="Qt na un de venda"
    visible={false}
    width={150}
  />,
  <Column
    key={nameOfGridHandler("itemUnidadeDeVendaCodigo")}
    dataField={nameOfGridHandler("itemUnidadeDeVendaCodigo")}
    {...obterConfiguracaoColuna("stringP")}
    caption="Un de venda"
    visible={false}
    width={120}
  />,
  <Column
    key={nameOfGridHandler("necessidadeData")}
    dataField={nameOfGridHandler("necessidadeData")}
    {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
    caption="Necessidade"
  />,
  <Column
    key={nameOfGridHandler("estadoDecodificado")}
    dataField={nameOfGridHandler("estadoDecodificado")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Estado"
  />,
  <Column
    key={nameOfGridHandler("quantidadeConcluida")}
    dataField={nameOfGridHandler("quantidadeConcluida")}
    {...obterConfiguracaoColuna("quantidade")}
    caption="Qt concluída"
  />,
  <Column
    key={nameOfGridHandler("itemPesoLiquidoUnitario")}
    dataField={nameOfGridHandler("itemPesoLiquidoUnitario")}
    {...obterConfiguracaoColuna("peso")}
    caption="Peso líq unitário (kg)"
    visible={false}
    width={180}
  />,
  <Column
    key={nameOfGridHandler("pesoLiquidoTotal")}
    dataField={nameOfGridHandler("pesoLiquidoTotal")}
    {...obterConfiguracaoColuna("peso")}
    caption="Peso líq total (kg)"
    visible={false}
    width={180}
  />,
  <Column
    key={nameOfGridHandler("itemPesoBrutoUnitario")}
    dataField={nameOfGridHandler("itemPesoBrutoUnitario")}
    {...obterConfiguracaoColuna("peso")}
    caption="Peso bruto unitário (kg)"
    visible={false}
    width={180}
  />,
  <Column
    key={nameOfGridHandler("pesoBrutoTotal")}
    dataField={nameOfGridHandler("pesoBrutoTotal")}
    {...obterConfiguracaoColuna("peso")}
    caption="Peso bruto total (kg)"
    visible={false}
    width={180}
  />,
  <Column
    key={nameOfGridHandler("quantidadeAConcluir")}
    dataField={nameOfGridHandler("quantidadeAConcluir")}
    {...obterConfiguracaoColuna("quantidade")}
    caption="Qt a concluir"
    visible={false}
  />,
  <Column
    key={nameOfGridHandler("emissaoData")}
    dataField={nameOfGridHandler("emissaoData")}
    {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
    caption="Emissão"
    visible={false}
    width={100}
  />,
  <Column
    key={nameOfGridHandler("emitidoPorApelido")}
    dataField={nameOfGridHandler("emitidoPorApelido")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Emitido por"
    visible={false}
  />,
  <Column
    key={nameOfGridHandler("quantidadeSobra")}
    dataField={nameOfGridHandler("quantidadeSobra")}
    {...obterConfiguracaoColuna("quantidade")}
    caption="Qt sobra"
    visible={false}
  />,
  <Column
    key={nameOfGridHandler("origemDecodificada")}
    dataField={nameOfGridHandler("origemDecodificada")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Origem"
    visible={false}
  />,
  <Column
    key={nameOfGridHandler("inicioPrevistoOuRealizadoData")}
    dataField={nameOfGridHandler("inicioPrevistoOuRealizadoData")}
    {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
    caption="Início"
    visible={false}
  />,
  <Column
    key={nameOfGridHandler("fimPrevistoOuRealizadoData")}
    dataField={nameOfGridHandler("fimPrevistoOuRealizadoData")}
    {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
    caption="Fim"
    visible={false}
  />,
  <Column
    key={nameOfGridHandler("tempoRealizadoEmHoras")}
    dataField={nameOfGridHandler("tempoRealizadoEmHoras")}
    {...obterConfiguracaoColuna("quantidade")}
    caption="Tempo realizado em horas"
    visible={false}
    width={220}
  />,
  <Column
    key={nameOfGridHandler("tempoPrevistoEmHoras")}
    dataField={nameOfGridHandler("tempoPrevistoEmHoras")}
    {...obterConfiguracaoColuna("quantidade")}
    caption="Tempo previsto em horas"
    visible={false}
    width={220}
  />,
  <Column
    key={nameOfGridHandler("quantidadeOuTempoRealizadoEmPercentual")}
    dataField={nameOfGridHandler("quantidadeOuTempoRealizadoEmPercentual")}
    {...obterConfiguracaoColuna("percentual")}
    caption="Qt ou tempo realizado %"
    visible={false}
    width={200}
  />,
  <Column
    key={nameOfGridHandler("quantidadeConcluidaEmPercentual")}
    dataField={nameOfGridHandler("quantidadeConcluidaEmPercentual")}
    {...obterConfiguracaoColuna("percentual")}
    caption="Qt concluída em %"
    visible={false}
    width={150}
  />,
  <Column
    key={nameOfGridHandler("maoDeObraCusto")}
    dataField={nameOfGridHandler("maoDeObraCusto")}
    {...obterConfiguracaoColuna("monetario")}
    caption="Custo da mão de obra"
    visible={false}
    width={180}
  />,
  <Column
    key={nameOfGridHandler("observacoes")}
    dataField={nameOfGridHandler("observacoes")}
    {...obterConfiguracaoColuna("stringXXG")}
    caption="Observações"
    visible={false}
  />,
  <Column
    key={nameOfGridHandler("insumosDisponibilidadeDecodificada")}
    dataField={nameOfGridHandler("insumosDisponibilidadeDecodificada")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Disponibilidade dos insumos"
    visible={false}
    width={230}
  />,
  <Column
    key={nameOfGridHandler("insumosBaixasEstadoDecodificado")}
    dataField={nameOfGridHandler("insumosBaixasEstadoDecodificado")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Estado das baixas dos insumos"
    visible={false}
    width={230}
  />,
  <Column
    key={nameOfGridHandler("itemPontoDeRessuprimento")}
    dataField={nameOfGridHandler("itemPontoDeRessuprimento")}
    {...obterConfiguracaoColuna("quantidade")}
    caption="Ponto de ressuprimento"
    visible={false}
    width={180}
  />,
  ...GetColunasDeAuditoria(),
];
export const GridOrdemDeProducao = forwardRef((props: GridBaseProps, ref) => {
  const gridRef = useRef<IGridSelecao>(null);
  useImperativeHandle(ref, () => gridRef.current as IGridSelecao);
  usePropagarReferenciaGrid(ref, gridRef);

  const [modalEdicaoVisivel, setModalEdicaoVisivel] = useState(false);
  const [idRegistroEdicao, setIdRegistroEdicao] = useState(NaN);

  function handleAtualizarGrid() {
    if (gridRef.current?.atualizarGrid) {
      gridRef.current?.atualizarGrid();
    }
  }

  const handleNovoRegistro = useNovoRegistroGrid(() => {
    if (
      verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesOrdemDeProducao.InserirEditar,
      ])
    ) {
      setIdRegistroEdicao(0);
      setModalEdicaoVisivel(true);
    }
  });

  const handleEditarRegistro = useEditarRegistroGrid((id: number) => {
    if (
      verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesOrdemDeProducao.InserirEditar,
      ])
    ) {
      setIdRegistroEdicao(id);
      setModalEdicaoVisivel(true);
    }
  });

  const handleExcluirRegistro = useExcluirRegistroComPermissaoGrid(
    obterMensagemExclusao,
    excluirRegistro,
    PermissoesOrdemDeProducao.Excluir
  );

  async function excluirRegistro(registro: OrdemDeProducaoGridModel) {
    try {
      const resposta = await APIBase.excluir(registro.id, nomeEndpoint);

      if (resposta) {
        checarResponseExibeMensagemExclusaoDeSucesso(resposta);
        handleAtualizarGrid();
      }
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  function obterMensagemExclusao(registro: OrdemDeProducaoGridModel) {
    return `Tem certeza de que deseja excluir a ordem de producão ${registro.numero}?`;
  }

  const handleGerarItensAdicionais = useGerarItensAdicionais(
    (getData: () => OrdemDeProducaoGridModel | undefined) => {
      const itensAdicionais = [
        {
          text: "Consultar",
          icon: "ic-material-symbols-outlined ic-manage-search",
          hint: "Menu com opções de consulta",
          items: [
            {
              text: "Operações: roteiro/gantt",
              icon: "dx-icon-bulletlist",
              onClick: () => consultarOperacoesRoteiroGantt(getData()?.id),
            },
            {
              text: "Apontamento de operações",
              icon: "ic-material-symbols-outlined ic-pending-actions",
              onClick: () => consultarApontamentosDeOperacoes(getData()?.id),
            },
            {
              text: "Item",
              icon: "ic-material-symbols-outlined ic-app-registration",
              onClick: () => consultarItem(getData()?.itemCodigo),
            },
          ],
        },
      ];

      return itensAdicionais;
    }
  );

  function consultarOperacoesRoteiroGantt(idOrdemDeProducao?: number) {
    if (idOrdemDeProducao) {
      const rota = `/OperacaoOrdemProducao/Index?of=${idOrdemDeProducao}`;
      navegarParaMxpAntigo(rota);
    }
  }

  function consultarApontamentosDeOperacoes(idOrdemDeProducao?: number) {
    if (idOrdemDeProducao) {
      const rota = `/ApontamentoHora/Index?IdOp=${idOrdemDeProducao}`;
      navegarParaMxpAntigo(rota);
    }
  }

  function consultarItem(itemCodigo?: string | null) {
    if (itemCodigo) {
      const rota = `/Item/PorCodigo?cod=${itemCodigo}`;
      navegarParaMxpAntigo(rota);
    }
  }

  const handleModalCallback = useCallback((info: CallBackModal) => {
    setModalEdicaoVisivel(false);
    setIdRegistroEdicao(NaN);

    if (info.precisaAtualizar) {
      handleAtualizarGrid();
    }
  }, []);

  return (
    <>
      <MxpGrid<OrdemDeProducaoGridModel>
        dataSource={dataSource}
        id={"ordem-de-producao"}
        ref={gridRef}
        colunas={colunas}
        novoRegistro={handleNovoRegistro}
        editarRegistro={handleEditarRegistro}
        excluirRegistro={handleExcluirRegistro}
        gerarItensAdicionaisDeContexto={handleGerarItensAdicionais}
        definirMenuSuperior={props.definirMenu}
        nomeDoArquivoAoExportar={NomesTelas.ordensProducao}
        valorPadraoDoFiltro={props.valorPadraoDoFiltro}
      />
      <EditFormOrdemDeProducao
        visivel={modalEdicaoVisivel}
        idRegistroEdicao={idRegistroEdicao}
        configuracoesModal={{ largura: "max(90vw, 1800px)", altura: "auto" }}
        callBackFecharModal={handleModalCallback}
      />
    </>
  );
});
