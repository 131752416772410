import { Column } from "devextreme-react/data-grid";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { renderToString } from "react-dom/server";
import ModalAccordion from "../../../../../components/dialogos/modal-accordion";
import {
  AccordionDataSource,
  AccordionModel,
} from "../../../../../components/dialogos/modal-accordion/modal-accordion";
import { MxpGrid } from "../../../../../components/grid";
import {
  useExcluirRegistroGrid,
  useGerarItensAdicionais,
  usePropagarReferenciaGrid,
} from "../../../../../hooks/grid.hooks";
import { useAppDispatch } from "../../../../../hooks/store.hooks";
import { EfdReinfNumeracaoTipo } from "../../../../../models/api/efd-reinf/efd-reinf";
import {
  EfdReinf4010GridModel,
  InfosR4010EnvioReinf,
} from "../../../../../models/api/efd-reinf/efd-reinf-4010/efd-reinf-4010";
import { PermissoesEfdReinf } from "../../../../../models/permissoes/fiscal/efd-reinf/permissoes-efd-reinf";
import { IGridSelecao } from "../../../../../models/shared/ui/formularios";
import API from "../../../../../services/efd-reinf/efd-reinf.service";
import { bloquearUI, desbloquearUI } from "../../../../../store/ui/ui.slice";
import {
  checarResponse,
  checarResponseExibeMensagemExclusaoDeSucesso,
  checarResponseExibeMensagemExecutadoComSucesso,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { ItemContextMenu } from "../../../../../utils/context-menu/context-menu-utils";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import { verificaStatusDoRegistroParaConsulta } from "../../../../../utils/especifico/efd-reinf/efd-reinf-utils";
import { formatarData } from "../../../../../utils/formatadores/formatador-de-datas";
import { formatarCPF } from "../../../../../utils/formatadores/formatador-de-strings";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import GetColunasDeAuditoria from "../../../../layout/grid-defaults/colunasDeAuditoria";

const dataSource = API.getGridSource(EfdReinfNumeracaoTipo.R4010);
const nameOfGridHandler = criarNameof<EfdReinf4010GridModel>();

const colunas = [
  <Column
    key={nameOfGridHandler("tipoDeArquivo")}
    dataField={nameOfGridHandler("tipoDeArquivo")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Tipo de arquivo"
    visible={false}
    width={150}
  />,
  <Column
    key={nameOfGridHandler("apuracaoDataInicial")}
    dataField={nameOfGridHandler("apuracaoDataInicial")}
    {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
    caption="Data inicial da apuração"
    sortIndex={0}
    sortOrder="desc"
    width={200}
  />,
  <Column
    key={nameOfGridHandler("apuracaoDataFinal")}
    dataField={nameOfGridHandler("apuracaoDataFinal")}
    {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
    caption="Data final da apuração"
    sortIndex={0}
    sortOrder="desc"
    width={200}
  />,
  <Column
    key={nameOfGridHandler("fornecedorRazaoSocial")}
    dataField={nameOfGridHandler("fornecedorRazaoSocial")}
    {...obterConfiguracaoColuna("stringGG")}
    caption="Nome do fornecedor"
    width={300}
  />,
  <Column
    key={nameOfGridHandler("fornecedorCpf")}
    dataField={nameOfGridHandler("fornecedorCpf")}
    {...obterConfiguracaoColuna("cpfFormatado")}
    caption="CPF do fornecedor"
    width={190}
  />,
  <Column
    key={nameOfGridHandler("dataRecibo")}
    dataField={nameOfGridHandler("dataRecibo")}
    {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
    caption="Data do recibo"
    width={150}
  />,
  <Column
    key={nameOfGridHandler("recibo")}
    dataField={nameOfGridHandler("recibo")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Recibo"
    width={205}
  />,
  <Column
    key={nameOfGridHandler("situacaoDecodificada")}
    dataField={nameOfGridHandler("situacaoDecodificada")}
    {...obterConfiguracaoColuna("stringP")}
    caption="Situação"
    width={100}
  />,
  <Column
    key={nameOfGridHandler("codigoDoErro")}
    dataField={nameOfGridHandler("codigoDoErro")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Código do erro"
    width={130}
  />,
  <Column
    key={nameOfGridHandler("motivoDoErro")}
    dataField={nameOfGridHandler("motivoDoErro")}
    {...obterConfiguracaoColuna("stringGG")}
    caption="Motivo do erro"
    width={250}
  />,
  <Column
    key={nameOfGridHandler("minhaEmpresaApelido")}
    dataField={nameOfGridHandler("minhaEmpresaApelido")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Minha empresa"
    visible={false}
    width={200}
  />,
  ...GetColunasDeAuditoria(true),
];

interface GridEfdReinf4010Props {
  definirMenu?: (data: ItemContextMenu[]) => void;
}

function obterDadosAccordionEnvioReinf(
  info: InfosR4010EnvioReinf
): AccordionModel[] {
  const accordionModel: AccordionModel[] = [];

  const obterMensagemBase = (apelido: string, cpf: string) => {
    return `Fornecedor: ${apelido}. CPF: ${formatarCPF(cpf)}`;
  };

  if (info.registrosJaEnviados.length > 0) {
    accordionModel.push({
      grupoDeMensagensTitulo: `Não foi possível enviar ${info.registrosJaEnviados.length} eventos R-4010. Pois já foram enviados com sucesso:`,
      mensagens: info.registrosJaEnviados.map((x) => {
        return `${obterMensagemBase(
          x.apelidoFornecedor,
          x.cpf
        )}. Data do recibo: ${formatarData(x.reciboData)}. Recibo: ${x.recibo}`;
      }),
    });
  }

  if (info.registrosComDivergenciaNaApuracao.length > 0) {
    accordionModel.push({
      grupoDeMensagensTitulo: `Não foi possível enviar ${info.registrosComDivergenciaNaApuracao.length} eventos R-4010. Pois a data inicial e data final da apuração não pertencem ao mesmo período (mês/ano), o que geraria rejeição por parte da SEFAZ:`,
      mensagens: info.registrosComDivergenciaNaApuracao.map((x) => {
        return `${obterMensagemBase(
          x.apelidoFornecedor,
          x.cpf
        )}. Apuração: ${formatarData(x.dataInicial)} a ${formatarData(
          x.dataFinal
        )}`;
      }),
    });
  }

  if (info.registrosEnviadosComSucesso.length > 0) {
    accordionModel.push({
      grupoDeMensagensTitulo: `${info.registrosEnviadosComSucesso.length} envios foram realizados com sucesso. Clique aqui para ver os eventos enviados:`,
      mensagens: info.registrosEnviadosComSucesso.map((x) => {
        return `${obterMensagemBase(
          x.apelidoFornecedor,
          x.cpf
        )}. Data do recibo: ${formatarData(x.reciboData)}. Recibo: ${x.recibo}`;
      }),
    });
  }

  if (info.registrosComErros.length > 0) {
    accordionModel.push({
      grupoDeMensagensTitulo: `${info.registrosComErros.length} envios não foram realizados com sucesso. Clique aqui para ver o motivo dos erros:`,
      mensagens: info.registrosComErros.map((x) => {
        return `${obterMensagemBase(x.apelidoFornecedor, x.cpf)}. Erro: ${
          x.codigoErro
        } - ${x.motivoErro}`;
      }),
    });
  }

  if (info.registrosEmProcessamento.length > 0) {
    accordionModel.push({
      grupoDeMensagensTitulo: `${info.registrosEmProcessamento.length} envios estão com o lote em processamento na SEFAZ. Clique aqui para ver os eventos:`,
      mensagens: info.registrosEmProcessamento.map((x) => {
        return `${obterMensagemBase(x.apelidoFornecedor, x.cpf)}`;
      }),
    });
  }

  return accordionModel;
}

export const GridEfdReinf4010 = forwardRef(
  (props: GridEfdReinf4010Props, ref) => {
    const gridRef = useRef<IGridSelecao>(null);
    useImperativeHandle(ref, () => gridRef.current as IGridSelecao);

    usePropagarReferenciaGrid(ref, gridRef);

    const [modalAccordionVisivel, setModalAccordionVisivel] = useState(false);
    const [dadosAccordion, setDadosAccordion] = useState<AccordionDataSource>();

    const dispatch = useAppDispatch();

    function handleAtualizarGrid() {
      if (gridRef.current?.atualizarGrid) {
        gridRef.current?.atualizarGrid();
      }
    }

    const handleExcluirRegistro = useExcluirRegistroGrid(
      async (registro: EfdReinf4010GridModel) => {
        try {
          dispatch(bloquearUI());
          if (
            !verificaComNotificacaoSeUsuarioPossuiPermissoes([
              PermissoesEfdReinf.Excluir,
            ])
          ) {
            return;
          }

          const mensagem = renderToString(
            <>{obterMensagemExclusao(registro)}</>
          );
          const excluir = await exibirConfirmacao(
            "Confirmar exclusão",
            mensagem
          );

          if (excluir) {
            const resposta = await API.excluir(
              registro.id,
              EfdReinfNumeracaoTipo.R4010
            );

            if (resposta) {
              checarResponseExibeMensagemExclusaoDeSucesso(resposta);
              handleAtualizarGrid();
            }
          }
        } catch (erro) {
          tratarErroApi(erro);
        } finally {
          dispatch(desbloquearUI());
        }
      }
    );

    function obterMensagemExclusao(registro: EfdReinf4010GridModel) {
      return `Tem certeza que deseja excluir o registro do fornecedor ${registro.fornecedorRazaoSocial}?`;
    }

    const handleGerarItensAdicionais = useGerarItensAdicionais(
      (
        getData: () => EfdReinf4010GridModel | undefined,
        data?: EfdReinf4010GridModel | undefined,
        getSelecionados?: () => EfdReinf4010GridModel[] | undefined
      ) => {
        const itensAdicionais = [
          {
            text: "Ações",
            icon: "ic-material-symbols-outlined ic-vertical",
            hint: "Menu com opções de ações",
            items: [
              {
                text: "Enviar evento R-4010 - Pagamentos/créditos a beneficiário pessoa física",
                mostraNaColunaDeAcoes: true,
                onClick: () => {
                  if (getSelecionados) {
                    const ids = getSelecionados()?.map((x) => x.id);
                    enviarEvento(ids);
                  } else {
                    const id = getData()?.id;
                    enviarEvento(id ? [id] : undefined);
                  }
                },
                hint: "Enviar evento",
              },
              {
                text: "Consultar evento R-4010 - Pagamentos/créditos a beneficiário pessoa física",
                onClick: () => {
                  consultarEvento(getData()?.id);
                },
                disabledIcon: !verificaStatusDoRegistroParaConsulta(
                  data?.situacao
                ),
                mostraNaColunaDeAcoes: true,
                hint: "Consultar evento",
              },
            ],
          },
        ];

        return itensAdicionais;
      }
    );

    async function enviarEvento(ids?: number[]) {
      if (ids) {
        try {
          dispatch(bloquearUI());
          if (
            !verificaComNotificacaoSeUsuarioPossuiPermissoes([
              PermissoesEfdReinf.Enviar,
            ])
          ) {
            return;
          }
          const resposta = await API.enviar<InfosR4010EnvioReinf>(
            ids,
            EfdReinfNumeracaoTipo.R4010
          );

          if (resposta.sucesso) {
            checarResponse(resposta);
            if (resposta.model) {
              setDadosAccordion({
                model: obterDadosAccordionEnvioReinf(resposta.model),
              });
              setModalAccordionVisivel(true);
            }
          }
        } catch (erro) {
          tratarErroApi(erro);
        } finally {
          dispatch(desbloquearUI());
        }
      }
    }

    async function consultarEvento(id?: number) {
      if (id) {
        dispatch(bloquearUI());
        if (
          !verificaComNotificacaoSeUsuarioPossuiPermissoes([
            PermissoesEfdReinf.Consultar,
          ])
        ) {
          return;
        }
        try {
          const resposta = await API.consultar(id, EfdReinfNumeracaoTipo.R4010);

          if (resposta.sucesso) {
            checarResponseExibeMensagemExecutadoComSucesso(resposta);
          }
        } catch (erro) {
          tratarErroApi(erro);
        } finally {
          dispatch(desbloquearUI());
        }
      }
    }

    function handleFecharModalAccordion() {
      setModalAccordionVisivel(false);
      setDadosAccordion(undefined);
    }

    return (
      <>
        <MxpGrid<EfdReinf4010GridModel>
          id={"efd-reinf-4010"}
          dataSource={dataSource}
          ref={gridRef}
          colunas={colunas}
          exibirIconeEditarMenuAcoes={false}
          excluirRegistro={handleExcluirRegistro}
          gerarItensAdicionaisDeContexto={handleGerarItensAdicionais}
          definirMenuSuperior={props.definirMenu}
          nomeDoArquivoAoExportar={NomesTelas.efdReinf4010}
        />

        <ModalAccordion
          modalAccordionVisivel={modalAccordionVisivel}
          handlerFecharModalAccordion={handleFecharModalAccordion}
          dataSource={dadosAccordion?.model}
          accordionId="accordion-detalhamento-envio-reinf-R4010"
          itemDoAccordionAltura="auto"
        />
      </>
    );
  }
);
