import getApi from "../../configs/api";
import { CertificadoDigitalRequestDTO } from "../../models/api/certificado-digital/certificado-digital";
import { ResponseBase } from "../../models/api/comum/response-base";

const nomeEndpoint: string = "CertificadoDigital";

async function uploadAsync(model: CertificadoDigitalRequestDTO) {
  const api = getApi();
  const response = await api.post<ResponseBase>(
    `${nomeEndpoint}/upload`,
    model,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return response.data;
}

export default {
  uploadAsync,
};
