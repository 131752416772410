export enum OrigemInsumo {
  EstruturaDoItem = 1,
  Manual = 2,
}

export enum TipoDoEstoqueInsumo {
  ConsumirEstoque = 1,
  ManterInsumo = 2,
}

export enum EstadoDaTerceirizacao {
  FornecidoPorNos = 1,
  FornecidoPeloCliente = 2,
  SemTerceirizacao = 3,
}
