import { useState } from "react";
import * as yup from "yup";
import ProvedorAjuda from "../../../../components/ajuda/provedor-ajuda";
import {
  FormCheckBox,
  FormNumberBox,
  FormSelectBox,
  FormSelectBoxLazy,
} from "../../../../components/formularios";
import FormTextArea from "../../../../components/formularios/textarea";

import {
  FormBase2,
  FormularioEdicaoBaseProps,
} from "../../../../components/layout/form-base2";
import { Coluna, Linha } from "../../../../components/layout/grid-system";
import {
  useCarregarRegistro,
  useHookForms,
  useLimparFormSeIdForNaN,
} from "../../../../hooks/form.hooks";
import { AdicaoExclusaoCompensacaoLacsGridModel } from "../../../../models/api/adicao-exclusao-compensacao/adicao-exclusao-compensacao-lacs-csll/adicao-exclusao-compensacao-lacs-csll";
import AuditavelDTO from "../../../../models/api/comum/auditavel-dto";
import {
  ContaDaParteBGridModel,
  TipoTributoContaParteB,
} from "../../../../models/api/conta-parte-b/conta-parte-b";
import { IndicadorDoLancamento } from "../../../../models/api/lancamento-parte-b/lancamento-parte-b-comum";
import {
  LancamentoNaParteBLacsRequest,
  LancamentoNaParteBLacsResponse,
} from "../../../../models/api/lancamento-parte-b/lancamento-parte-b-lacs/lancamento-parte-b-lacs";
import { SelectItemEnumIndicadorDoLancamentoLancamentoNaParteB } from "../../../../models/const/dicionario-combos/lancamento-parte-b";
import { PermissoesApuracaoLacsCsll } from "../../../../models/permissoes/fiscal/apuracao-lacs-csll/permissoes-apuracao-lacs-csll";
import { CallBackModal } from "../../../../models/shared/ui/callback-modal";
import { GridContaDaParteB } from "../../../../parts/contabilidade/conta-parte-b";
import { GridAdicaoExclusaoCompensacaoLacs } from "../../../../parts/fiscal/adicao-exclusao-compensacao-lacs-csll/grid";
import AdicaoExclusaoCompensacaoLacsCsllService from "../../../../services/adicao-exclusao-compensacao-lacs-csll/adicao-exclusao-compensacao-lacs-csll.service";
import { ContaParteBService } from "../../../../services/conta-parte-b/conta-parte-b.service";
import { LancamentoNaParteBDoLacsService } from "../../../../services/lancamento-parte-b-lacs/lancamento-parte-b-lacs";
import { checarResponse, tratarErroApi } from "../../../../utils/api/api-utils";
import criarNameof from "../../../../utils/common/cria-name-of";
import NomesModais from "../../../../utils/common/nomes-modais";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../utils/common/permissoes-utils";
import { obterFormatStringNumero } from "../../../../utils/formatadores/formatador-de-numeros";

const LancamentoNaParteBLacsService = new LancamentoNaParteBDoLacsService();
const contaParteBService = new ContaParteBService();
const adicaoExclusaoCompensacaoLacsService =
  new AdicaoExclusaoCompensacaoLacsCsllService();
const nameofContaParteBGrid = criarNameof<ContaDaParteBGridModel>();
const nameofAdicaoExclusaoCompensacaoGrid =
  criarNameof<AdicaoExclusaoCompensacaoLacsGridModel>();

function carregaContaParteB() {
  return contaParteBService.GetDataSourceSelectBoxLazy({
    camposRetorno: [
      nameofContaParteBGrid("id"),
      nameofContaParteBGrid("codigo"),
      nameofContaParteBGrid("descricao"),
    ],
    filtros: [
      { nomeCampo: nameofContaParteBGrid("ativo"), operador: "=", valor: true },
      {
        nomeCampo: nameofContaParteBGrid("tipoTributo"),
        operador: "=",
        valor: TipoTributoContaParteB.CSLL,
      },
    ],
    camposOrdenacao: [
      {
        nomeCampo: nameofContaParteBGrid("codigo"),
        desc: false,
      },
    ],
  });
}

function carregaAdicoesExclusoesCompensacoesLacs() {
  return adicaoExclusaoCompensacaoLacsService.ObterDataSourceSelectBoxLazy({
    camposRetorno: [
      nameofAdicaoExclusaoCompensacaoGrid("id"),
      nameofAdicaoExclusaoCompensacaoGrid("codigo"),
      nameofAdicaoExclusaoCompensacaoGrid("descricao"),
    ],
    camposOrdenacao: [
      {
        nomeCampo: nameofAdicaoExclusaoCompensacaoGrid("codigo"),
        desc: false,
      },
    ],
  });
}

interface EditFormLancamentoNaParteBLacsProps
  extends FormularioEdicaoBaseProps {
  idApuracaoDoLacsEDaCsll: number;
}

const contaParteBExpressaoBusca = [
  nameofContaParteBGrid("codigo"),
  nameofContaParteBGrid("descricao"),
];
const exibeContaParteB = (c: ContaDaParteBGridModel | null) => {
  if (c) {
    return c.descricao == null ? `${c.codigo}` : `${c.codigo} (${c.descricao})`;
  }

  return "";
};

const adicaoExclusaoCompensacaoExpressaoBusca = [
  nameofAdicaoExclusaoCompensacaoGrid("codigo"),
  nameofAdicaoExclusaoCompensacaoGrid("descricao"),
];
const exibeAdicaoExclusaoCompensacao = (
  c: AdicaoExclusaoCompensacaoLacsGridModel | null
) => {
  if (c) {
    return c.descricao == null ? `${c.codigo}` : `${c.codigo} (${c.descricao})`;
  }

  return "";
};

export default function EditFormLancamentoNaParteBLacs(
  props: EditFormLancamentoNaParteBLacsProps
) {
  const novoRegistro: LancamentoNaParteBLacsRequest = {
    id: 0,
    apuracaoDoLacsEDaCsllId: props.idApuracaoDoLacsEDaCsll,
    contaDaParteBDoLalurOuDoLacsId: 0,
    incluirNaParteA: false,
    adicaoOuExclusaoOuCompensacaoDoLacsId: undefined,
    indicadorDoLancamento: undefined,
    historico: "",
    tributacaoDiferida: false,
    valor: 0,
  };
  const [carregando, setCarregando] = useState(false);
  const [contaParteB] = useState(carregaContaParteB);
  const [adicoesExclusoesCompensacoes] = useState(
    carregaAdicoesExclusoesCompensacoesLacs
  );
  const [dadosAuditoria, setDadosAuditoria] = useState<AuditavelDTO>();
  const [incluirNaParteA, setIncluirNaParteA] = useState(false);

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    contaDaParteBDoLalurOuDoLacsId: yup
      .number()
      .required("Campo obrigatório.")
      .moreThan(0, "Campo obrigatório.")
      .integer(),
    valor: yup.number().moreThan(0).required(),
    indicadorDoLancamento: yup
      .mixed<IndicadorDoLancamento>()
      .transform((v) => (v ? v : null))
      .oneOf(
        Object.values(IndicadorDoLancamento).map((x) => x as number),
        "Valor inválido"
      )
      .test("valida-indicador-do-lancamento", "Indicador inválido", (v) => {
        return incluirNaParteA
          ? v == IndicadorDoLancamento.CR || v == IndicadorDoLancamento.DB
          : true;
      })
      .required(),
    historico: yup.string().required().max(500),
    tributacaoDiferida: yup.bool().oneOf([true, false]),
    incluirNaParteA: yup.bool().required(),
    adicaoOuExclusaoOuCompensacaoDoLacsId: yup
      .number()
      .integer()
      .nullable()
      .test(
        "valida-adicaoOuExclusaoOuCompensacaoDoLacsId",
        "Campo obrigatório.",
        (v) => {
          return incluirNaParteA ? v != null : true;
        }
      ),
    apuracaoDoLacsEDaCsllId: yup.number().moreThan(-1).integer().required(),
  });

  function fechar(info: CallBackModal) {
    if (props.callBackFecharModal) {
      props.callBackFecharModal(info);
    }
  }

  const hookForm = useHookForms<LancamentoNaParteBLacsRequest>(schema);
  useCarregarRegistro(props.idRegistroEdicao, CarregarRegistro);
  useLimparFormSeIdForNaN(hookForm, novoRegistro, props.idRegistroEdicao);

  const { register, control, handleSubmit, getValues, reset } = hookForm;

  async function CarregarRegistro() {
    try {
      setCarregando(true);
      const resposta =
        await LancamentoNaParteBLacsService.ObterPorIdComDadosAuditoria<LancamentoNaParteBLacsResponse>(
          props.idRegistroEdicao
        );
      reset(resposta?.model);
      setDadosAuditoria(resposta.model);
    } catch (error) {
      tratarErroApi(error);
    } finally {
      setCarregando(false);
    }
  }

  function handleCancelar() {
    fechar({ concluido: false, precisaAtualizar: false });
  }

  async function handleSalvar() {
    try {
      setCarregando(true);
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesApuracaoLacsCsll.InserirEditar,
        ])
      ) {
        return;
      }
      const model = getValues();
      const resposta =
        props.idRegistroEdicao > 0
          ? await LancamentoNaParteBLacsService.Atualizar(model)
          : await LancamentoNaParteBLacsService.Cadastrar(model);

      checarResponse(resposta);

      if (resposta?.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar({ concluido: true, precisaAtualizar: true });
      }
    } catch (error: any) {
      tratarErroApi(error);
    } finally {
      setCarregando(false);
    }
  }

  async function onChangeIncluirNaParteA(e: any) {
    setIncluirNaParteA(e.value);
  }

  return (
    <FormBase2
      visivel={props.visivel}
      carregando={carregando}
      onClickSalvar={handleSubmit(handleSalvar)}
      onClickCancelar={handleCancelar}
      configuracoesModal={props.configuracoesModal}
      modoEdicao={props.idRegistroEdicao == 0 ? "criar" : "editar"}
      isNomeTelaFeminino
      titulo={NomesModais.lancamentoNaParteB}
      auditoria={dadosAuditoria}
    >
      <input type="hidden" {...register("id")} defaultValue={0} />
      <ProvedorAjuda id="edit-form-lancamento-parte-b-lacs">
        <Linha>
          <Coluna md={12}>
            <FormSelectBoxLazy
              name="contaDaParteBDoLalurOuDoLacsId"
              titulo="Conta da parte B"
              control={control}
              requerido
              nomeCampoChave={nameofContaParteBGrid("id")}
              nomeCampoExibicao={exibeContaParteB}
              expressaoDeBusca={contaParteBExpressaoBusca}
              dataSource={contaParteB}
              lupaConfig={{
                modo: "selecaoUnica",
                titulo: "Selecionar conta da parte B",
                componente: (r) => <GridContaDaParteB ref={r} />,
              }}
              labelSemDados="Sem dados"
            />
          </Coluna>
        </Linha>
        <Linha>
          <Coluna md={3}>
            <FormNumberBox
              name="valor"
              titulo="Valor"
              minimo={0}
              formato={obterFormatStringNumero(2)}
              control={control}
              requerido
            />
          </Coluna>
          <Coluna md={5}>
            <FormSelectBox
              name="indicadorDoLancamento"
              titulo="Indicador do lançamento"
              control={control}
              requerido
              dataSource={SelectItemEnumIndicadorDoLancamentoLancamentoNaParteB}
              defaultValue={null}
            />
          </Coluna>
          <Coluna md={4} classe="centralizar-itens">
            <FormCheckBox
              name="tributacaoDiferida"
              titulo="Realização de valores cuja tributação tenha sido diferida"
              control={control}
            />
          </Coluna>
        </Linha>
        <Linha>
          <Coluna md={12}>
            <FormTextArea
              name="historico"
              titulo="Histórico"
              control={control}
              requerido
              tamanhoMaximo={500}
              valor={""}
              height={10}
            />
          </Coluna>
        </Linha>
        <Linha>
          <Coluna md={3} classe="centralizar-itens">
            <FormCheckBox
              name="incluirNaParteA"
              titulo="Tem reflexo na parte A"
              control={control}
              onValueChanged={onChangeIncluirNaParteA}
            />
          </Coluna>
          <Coluna md={9}>
            <FormSelectBoxLazy
              name="adicaoOuExclusaoOuCompensacaoDoLacsId"
              titulo="Adição, exclusão ou compensação de prejuizo"
              control={control}
              nomeCampoChave={nameofAdicaoExclusaoCompensacaoGrid("id")}
              nomeCampoExibicao={exibeAdicaoExclusaoCompensacao}
              expressaoDeBusca={adicaoExclusaoCompensacaoExpressaoBusca}
              dataSource={adicoesExclusoesCompensacoes}
              lupaConfig={{
                modo: "selecaoUnica",
                titulo: "Selecionar adição, exclusão ou compensação do LACS",
                componente: (r) => (
                  <GridAdicaoExclusaoCompensacaoLacs ref={r} />
                ),
              }}
              labelSemDados="Sem dados"
              desabilitado={!incluirNaParteA}
              requerido={incluirNaParteA}
            />
          </Coluna>
        </Linha>
      </ProvedorAjuda>
    </FormBase2>
  );
}
