import getApi from "../../configs/api";
import { ListaSimplesResponse } from "../../models/api/comum/response-base";
import { TipoUnidadeTransporte } from "../../models/api/veiculo/veiculo";
import qs from "qs";

async function obterListaSimples(
  unidadesTransportes?: TipoUnidadeTransporte[]
) {
  const api = getApi();

  const response = await api.get<ListaSimplesResponse>(
    "Veiculo/lista-simples",
    {
      params: {
        unidadesTransporte: unidadesTransportes,
      },
      paramsSerializer: {
        serialize: (x) => {
          return qs.stringify(x);
        },
      },
    }
  );

  return response.data;
}

export default {
  obterListaSimples,
};
