import { TabPanel } from "devextreme-react";
import { Item } from "devextreme-react/tab-panel";
import { useState } from "react";
import * as yup from "yup";
import ProvedorAjuda from "../../../../components/ajuda/provedor-ajuda";
import {
  FormCheckBox,
  FormGrupo,
  FormNumberBox,
  FormSelectBox,
  FormSelectBoxLazy,
  FormTextBox,
} from "../../../../components/formularios";
import {
  FormBase2,
  FormularioEdicaoBaseProps,
} from "../../../../components/layout/form-base2";
import { Coluna, Linha } from "../../../../components/layout/grid-system";
import RequerPermissao from "../../../../components/seguranca/requer-permissao";
import useEffectOnLoad from "../../../../hooks/effect.hooks";
import {
  useCarregarRegistro,
  useHookForms,
  useLimparFormSeIdForNaN,
} from "../../../../hooks/form.hooks";
import {
  CentroDeTrabalhoRequestDTO,
  CentroDeTrabalhoResponseDTO,
} from "../../../../models/api/centro-de-trabalho/centro-de-trabalho";
import {
  TipoCentroDeTrabalho,
  TipoSequenciamento,
  tipoCentroDeTrabalhoAsSelectItem,
} from "../../../../models/api/centro-de-trabalho/centro-de-trabalho-enums";
import AuditavelDTO from "../../../../models/api/comum/auditavel-dto";
import { PermissoesVinculoEnderecoEstoqueCentroDeTrabalho } from "../../../../models/permissoes/producao/vinculo-endereco-estoque-centro-de-trabalho/permissoes-vinculo-endereco-estoque-centro-de-trabalho";
import { CallBackModal } from "../../../../models/shared/ui/callback-modal";
import SelectItem from "../../../../models/shared/ui/select-item";
import { GridCentroDeCustos } from "../../../../parts/contabilidade/centro-de-custos/grids/grid-padrao";
import CentroDeTrabalhoAbaCapacidadeFinita from "../../../../parts/producao/centro-de-trabalho/abas/capacidade-finita";
import CentroDeTrabalhoAbaVinculoEnderecoEstoque from "../../../../parts/producao/centro-de-trabalho/abas/enderecoEstoque";
import CentroDeTrabalhoAbaRoteiroItem from "../../../../parts/producao/centro-de-trabalho/abas/roteiro-item";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../services/comum/serviceBase";
import APICentroDeTrabalho from "../../../../services/producao/centro-de-trabalho/centro-de-trabalho.service";
import { checarResponse, tratarErroApi } from "../../../../utils/api/api-utils";
import { previneDigitacaoDeCaracteres } from "../../../../utils/common/common-utils";
import NomesModais from "../../../../utils/common/nomes-modais";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../utils/common/notificacoes-utils";
import {
  letrasApenasMaiusculasDigitosSimbolosSemEspacos,
  letrasDigitosSimbolosSemEspacos,
} from "../../../../utils/common/regex-padrao";
import { obterFormatStringNumero } from "../../../../utils/formatadores/formatador-de-numeros";
import {
  codigoTamanhoMaximo,
  descricaoTamanhoMaximo,
  operacaoDescricaoComplementarPreferencialTamanhoMaximo,
  operacaoDescricaoPreferencialTamanhoMaximo,
} from "./constantes";

const nomeEndpoint = NomesEndpoints.CentroDeTrabalho;
const nomeEndpointGrupoCentroDeTrabalho = NomesEndpoints.CentroDeTrabalhoGrupo;
const nomeEndpointCentroDeCustos = NomesEndpoints.CentroDeCustos;

const UnidadesMedidaSelectBox = (props: { control: any }) => {
  const [unidadesMedida, setUnidadesMedida] = useState<SelectItem[]>([]);

  useEffectOnLoad(() => {
    carregarUnidadesMedida();
  });

  async function carregarUnidadesMedida() {
    try {
      const resposta = await APICentroDeTrabalho.obterUnidadesMedida();
      checarResponse(resposta);
      setUnidadesMedida(
        resposta.model.map((x) => ({
          valor: x.valor,
          descricao: x.descricao,
        }))
      );
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  return (
    <FormSelectBox
      name="unidadeMedidaId"
      titulo="Unidade"
      control={props.control}
      dataSource={unidadesMedida}
      habilitaBusca
      tipoBusca="contains"
    />
  );
};

const novoRegistro: CentroDeTrabalhoRequestDTO = {
  id: 0,
  codigo: "",
  descricao: null,
  ativo: true,
  centroDeCustosId: null,
  tipo: null,
  custoPorHora: null,
  horasPorDia: null,
  unidadeMedidaId: null,
  cargaFabricaConsiderar: false,
  operacaoDescricaoPreferencial: null,
  operacaoDescricaoComplementarPreferencial: null,
  centroDeTrabalhoGrupoId: null,
  sequenciamentoEstaFixo: TipoSequenciamento.Livre,
  baixasLimitarPorEnderecoDeEstoque: false,
};

const centrosCusto = APIBase.getDataSourceSelectBoxLazy(
  {
    camposRetorno: ["Id", "Codigo", "Descricao", "Classificacao"],
    camposOrdenacao: [
      {
        nomeCampo: "Classificacao",
        desc: false,
      },
    ],
  },
  nomeEndpointCentroDeCustos
);
const gruposCentroDeTrabalho = APIBase.getDataSourceSelectBoxLazy(
  {
    camposRetorno: ["Id", "Codigo", "Descricao"],
    camposOrdenacao: [
      {
        nomeCampo: "Codigo",
        desc: false,
      },
    ],
  },
  nomeEndpointGrupoCentroDeTrabalho
);

const previneDigitacaoDeCaracteresHandler = (e: any) =>
  previneDigitacaoDeCaracteres(e, letrasDigitosSimbolosSemEspacos);

const exibeCentroDeCustos = (c: any) => {
  if (c) {
    return c.Descricao == null
      ? `${c.Codigo}`
      : `${c.Classificacao} ${c.Descricao} (${c.Codigo})`;
  }

  return "";
};

const centroDeCustosExpressaoDeBusca = ["Codigo", "Descricao", "Classificacao"];

export const EditFormCentroDeTrabalho = (props: FormularioEdicaoBaseProps) => {
  const [carregando, setCarregando] = useState(false);
  const [dadosAuditoria, setDadosAuditoria] = useState<AuditavelDTO>();

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    codigo: yup
      .string()
      .required()
      .max(codigoTamanhoMaximo)
      .matches(
        letrasApenasMaiusculasDigitosSimbolosSemEspacos,
        "Deve conter apenas letras maiúsculas, letras sem acento, números e símbolos"
      ),
    descricao: yup.string().nullable().max(descricaoTamanhoMaximo),
    ativo: yup.boolean().nonNullable(),
    centroDeCustosId: yup.number().required().positive().integer(),
    tipo: yup
      .mixed<TipoCentroDeTrabalho>()
      .nullable()
      .transform((v) => (v >= 0 ? v : null))
      .oneOf(
        Object.values(TipoCentroDeTrabalho).map((x) => x as number),
        "Valor inválido"
      ),
    custoPorHora: yup.number().nullable(),
    horasPorDia: yup.number().nullable(),
    unidadeMedidaId: yup.number().notRequired().nullable().positive().integer(),
    cargaFabricaConsiderar: yup.boolean().notRequired(),
    operacaoDescricaoPreferencial: yup
      .string()
      .nullable()
      .max(operacaoDescricaoPreferencialTamanhoMaximo),
    operacaoDescricaoComplementarPreferencial: yup
      .string()
      .nullable()
      .max(operacaoDescricaoComplementarPreferencialTamanhoMaximo),
    centroDeTrabalhoGrupoId: yup
      .number()
      .notRequired()
      .nullable()
      .positive()
      .integer(),
    sequenciamentoEstaFixo: yup
      .mixed<TipoSequenciamento>()
      .nullable()
      .transform((v) => (v >= 0 ? v : null))
      .oneOf(
        Object.values(TipoSequenciamento).map((x) => x as number),
        "Valor inválido"
      ),
    baixasLimitarPorEnderecoDeEstoque: yup.boolean().nonNullable(),
  });

  const hookForm = useHookForms(schema);
  useCarregarRegistro(props.idRegistroEdicao, carregarTela);
  useLimparFormSeIdForNaN(hookForm, novoRegistro, props.idRegistroEdicao);

  const { register, getValues, setValue, reset, control, handleSubmit } =
    hookForm;

  async function carregarTela() {
    try {
      setCarregando(true);
      if (props.idRegistroEdicao > 0) {
        await carregarModel();
      } else {
        reset(novoRegistro);
        setDadosAuditoria(undefined);
      }
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  async function carregarModel() {
    try {
      const resposta =
        await APIBase.obterPorIdComDadosAuditoria<CentroDeTrabalhoResponseDTO>(
          props.idRegistroEdicao,
          nomeEndpoint
        );
      checarResponse(resposta);
      reset(resposta.model);
      setDadosAuditoria(resposta.model);
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    }
  }

  function fechar(info: CallBackModal) {
    if (props.callBackFecharModal) {
      setDadosAuditoria(undefined);
      props.callBackFecharModal(info);
    }
  }

  function handleCancelar() {
    fechar({ concluido: false, precisaAtualizar: false });
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues();
    try {
      const resposta =
        props.idRegistroEdicao > 0
          ? await APIBase.atualizar(model, nomeEndpoint)
          : await APIBase.cadastrar(model, nomeEndpoint);

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar({
          concluido: true,
          precisaAtualizar: true,
        });
      }
      fechar({
        concluido: true,
        precisaAtualizar: false,
      });
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  function callBackUnprocessableEntity() {
    fechar({
      concluido: false,
      precisaAtualizar: true,
    });
  }

  return (
    <>
      <FormBase2
        titulo={NomesModais.centroDeTrabalho}
        visivel={props.visivel}
        carregando={carregando}
        auditoria={dadosAuditoria}
        modoEdicao={props.idRegistroEdicao == 0 ? "criar" : "editar"}
        onClickSalvar={handleSubmit(handleSalvar)}
        onClickCancelar={handleCancelar}
        configuracoesModal={props.configuracoesModal}
      >
        <ProvedorAjuda id="edit-form-centro-de-trabalho">
          <input type="hidden" {...register("id")} defaultValue={0} />
          <FormGrupo>
            <Linha>
              <Coluna md={3}>
                <FormTextBox
                  name="codigo"
                  titulo="Código"
                  control={control}
                  requerido
                  tamanhoMaximo={codigoTamanhoMaximo}
                  transform="uppercase"
                  onKeyDown={previneDigitacaoDeCaracteresHandler}
                />
              </Coluna>
              <Coluna md={9}>
                <Linha>
                  <Coluna md={10}>
                    <FormTextBox
                      name="descricao"
                      titulo="Descrição"
                      control={control}
                      tamanhoMaximo={descricaoTamanhoMaximo}
                    />
                  </Coluna>
                  <Coluna md={2} classe="centralizar-itens">
                    <FormCheckBox
                      name="ativo"
                      titulo="Ativo"
                      defaultValue={true}
                      control={control}
                    />
                  </Coluna>
                </Linha>
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={6}>
                <FormSelectBoxLazy
                  name="centroDeCustosId"
                  titulo="Centro de custos"
                  control={control}
                  requerido
                  nomeCampoChave="Id"
                  nomeCampoExibicao={exibeCentroDeCustos}
                  expressaoDeBusca={centroDeCustosExpressaoDeBusca}
                  dataSource={centrosCusto}
                  lupaConfig={{
                    modo: "selecaoUnica",
                    titulo: "Selecionar centro de custos",
                    componente: (r) => <GridCentroDeCustos ref={r} />,
                  }}
                  labelSemDados="Sem dados"
                />
              </Coluna>
              <Coluna md={6}>
                <FormSelectBox
                  name="tipo"
                  titulo="Tipo"
                  control={control}
                  dataSource={tipoCentroDeTrabalhoAsSelectItem}
                  habilitaBusca
                  tipoBusca="contains"
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={2}>
                <FormNumberBox
                  name="custoPorHora"
                  titulo="Custo/h (R$)"
                  control={control}
                  minimo={0}
                  maximo={9999999999}
                  requerido={false}
                  formato={obterFormatStringNumero(2)}
                  quantidadeIncrementarDecrementar={1}
                />
              </Coluna>
              <Coluna md={2}>
                <FormNumberBox
                  name="horasPorDia"
                  titulo="h/dia"
                  control={control}
                  minimo={0}
                  maximo={9999999999}
                  requerido={false}
                  formato={obterFormatStringNumero(2)}
                  quantidadeIncrementarDecrementar={1}
                />
              </Coluna>
              <Coluna md={2}>
                <UnidadesMedidaSelectBox control={control} />
              </Coluna>
              <Coluna md={6} classe="centralizar-itens">
                <FormCheckBox
                  name="cargaFabricaConsiderar"
                  titulo="Considera na carga fábrica"
                  defaultValue={false}
                  control={control}
                />
              </Coluna>
            </Linha>
          </FormGrupo>
          <TabPanel
            deferRendering={false}
            showNavButtons
            swipeEnabled={false}
            itemTitleRender={(item) => item.text}
            height={"max(40vh, 320px)"}
          >
            <Item text="Inicialização do roteiro do item">
              <CentroDeTrabalhoAbaRoteiroItem
                idRegistro={props.idRegistroEdicao}
                abaSomenteLeitura={false}
                control={control}
              />
            </Item>
            <Item text="Capacidade finita">
              <CentroDeTrabalhoAbaCapacidadeFinita
                gruposCentroDeTrabalho={gruposCentroDeTrabalho}
                abaSomenteLeitura={false}
                control={control}
              />
            </Item>
            <Item text="Vínculos com endereços de estoque">
              <RequerPermissao
                codigoPermissoes={[
                  PermissoesVinculoEnderecoEstoqueCentroDeTrabalho.Consultar,
                ]}
              >
                <CentroDeTrabalhoAbaVinculoEnderecoEstoque
                  idCentroDeTrabalhoVinculado={props.idRegistroEdicao}
                  control={control}
                  getValues={getValues}
                  setValue={setValue}
                />
              </RequerPermissao>
            </Item>
          </TabPanel>
        </ProvedorAjuda>
      </FormBase2>
    </>
  );
};

export default EditFormCentroDeTrabalho;
