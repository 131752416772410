import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { FieldValues, UseFormReturn, useForm } from "react-hook-form";
import * as yup from "yup";

export function useLimparFormSeIdForNaN<T extends FieldValues>(
  form: UseFormReturn,
  novoRegistro: T,
  idEmEdicao: number
) {
  useEffect(() => {
    if (Number.isNaN(idEmEdicao)) {
      form.reset(novoRegistro);
    }
  }, [idEmEdicao]);
}

export function useCarregarCombos(idEmEdicao: number, callback: () => void) {
  useEffect(() => {
    if (!Number.isNaN(idEmEdicao)) {
      callback();
    }
  }, [idEmEdicao]);
}

export function useCarregarDadosDoModelo(
  idEmEdicao: number,
  callback: () => void
) {
  useEffect(() => {
    if (!Number.isNaN(idEmEdicao)) {
      callback();
    }
  }, [idEmEdicao]);
}

export function useCarregarRegistro(
  idEmEdicao: number,
  handleCarregarRegistro: () => void
) {
  useEffect(() => {
    if (idEmEdicao > 0) {
      handleCarregarRegistro();
    }
  }, [idEmEdicao]);
}

export function useHookForms<T extends FieldValues>(
  schemaYup: yup.ObjectSchema<T>
) {
  return useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    shouldUseNativeValidation: true,
    resolver: yupResolver(schemaYup),
  });
}
