import { Column } from "devextreme-react/data-grid";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { MxpGrid } from "../../../../../components/grid";
import { usePropagarReferenciaGrid } from "../../../../../hooks/grid.hooks";
import { decodificaBooleanoEmSimNao } from "../../../../../models/api/comum/decodificadores";
import { InsumoDaOrdemDeProducaoGridModel } from "../../../../../models/api/insumo-do-centro-de-trabalho/insumo-da-ordem-de-producao";
import { IGridSelecao } from "../../../../../models/shared/ui/formularios";
import { InsumoDaOrdemDeProducaoService } from "../../../../../services/insumo-da-ordem-de-producao/insumo-da-ordem-de-producao";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import { GridBaseProps } from "../../../../../utils/grid/grid-utils";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import GetColunasDeAuditoria from "../../../../layout/grid-defaults/colunasDeAuditoria";

const APIInsumos = new InsumoDaOrdemDeProducaoService();
const dataSource = APIInsumos.GetGridSource();
const nameOfGridHandler = criarNameof<InsumoDaOrdemDeProducaoGridModel>();

const colunas = [
  <Column
    key={nameOfGridHandler("ordemDeProducao")}
    dataField={nameOfGridHandler("ordemDeProducao")}
    {...obterConfiguracaoColuna("stringPP")}
    caption="Ordem de produção"
    width={160}
    sortIndex={0}
    sortOrder="desc"
  />,
  <Column
    key={nameOfGridHandler("operacao")}
    dataField={nameOfGridHandler("operacao")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Operação"
    width={160}
    sortIndex={1}
    sortOrder="asc"
  />,
  <Column
    key={nameOfGridHandler("codigo")}
    dataField={nameOfGridHandler("codigo")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Código"
    width={160}
  />,
  <Column
    key={nameOfGridHandler("descricao")}
    dataField={nameOfGridHandler("descricao")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Descrição"
    width={400}
  />,
  <Column
    key={nameOfGridHandler("descricaoComplementar")}
    dataField={nameOfGridHandler("descricaoComplementar")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Descrição complementar"
    visible={false}
    width={400}
  />,
  <Column
    key={nameOfGridHandler("quantidadeSemPercentualDePerda")}
    dataField={nameOfGridHandler("quantidadeSemPercentualDePerda")}
    {...obterConfiguracaoColuna("quantidade")}
    caption="Qt sem percentual de perda"
    width={230}
  />,
  <Column
    key={nameOfGridHandler("perdaEmPercentual")}
    dataField={nameOfGridHandler("perdaEmPercentual")}
    {...obterConfiguracaoColuna("quantidade")}
    caption="Perda em percentual"
    width={165}
  />,
  <Column
    key={nameOfGridHandler("quantidadeTotal")}
    dataField={nameOfGridHandler("quantidadeTotal")}
    {...obterConfiguracaoColuna("quantidade")}
    caption="Qt total"
    width={150}
  />,
  <Column
    key={nameOfGridHandler("unidade")}
    dataField={nameOfGridHandler("unidade")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Unidade"
    visible={false}
    width={100}
  />,
  <Column
    key={nameOfGridHandler("quantidadeParaUmaUnidade")}
    dataField={nameOfGridHandler("quantidadeParaUmaUnidade")}
    {...obterConfiguracaoColuna("quantidade")}
    caption="Qt para uma unidade"
    visible={false}
    width={150}
  />,
  <Column
    key={nameOfGridHandler("quantidadeBaixada")}
    dataField={nameOfGridHandler("quantidadeBaixada")}
    {...obterConfiguracaoColuna("quantidade")}
    caption="Qt baixada"
    width={150}
  />,
  <Column
    key={nameOfGridHandler("quantidadeABaixar")}
    dataField={nameOfGridHandler("quantidadeABaixar")}
    {...obterConfiguracaoColuna("quantidade")}
    caption="Qt a baixar"
    width={150}
  />,
  <Column
    key={nameOfGridHandler("loteInterno")}
    dataField={nameOfGridHandler("loteInterno")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Lote interno"
    width={150}
  />,
  <Column
    key={nameOfGridHandler("loteDoFabricante")}
    dataField={nameOfGridHandler("loteDoFabricante")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Lote do fabricante"
    width={150}
  />,
  <Column
    key={nameOfGridHandler("enderecoDeEstoque")}
    dataField={nameOfGridHandler("enderecoDeEstoque")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Endereço de estoque"
    width={150}
  />,
  <Column
    key={nameOfGridHandler("estoqueDisponivel")}
    dataField={nameOfGridHandler("estoqueDisponivel")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Estoque disponível"
    width={150}
  />,
  <Column
    key={nameOfGridHandler("estoqueDisponivelExplosao")}
    dataField={nameOfGridHandler("estoqueDisponivelExplosao")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Estoque disponível para explosão"
    visible={false}
    width={150}
  />,
  <Column
    key={nameOfGridHandler("reservado")}
    dataField={nameOfGridHandler("reservado")}
    {...obterConfiguracaoColuna("boolSimNao")}
    caption="Reservado"
    cellRender={({ data }) => {
      const dados = data as InsumoDaOrdemDeProducaoGridModel;
      return decodificaBooleanoEmSimNao(dados.reservado);
    }}
    width={150}
  />,
  <Column
    key={nameOfGridHandler("dono")}
    dataField={nameOfGridHandler("dono")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Dono"
    width={200}
  />,
  <Column
    key={nameOfGridHandler("tipoDoEstoque")}
    dataField={nameOfGridHandler("tipoDoEstoque")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Tipo do estoque"
    visible={false}
    width={150}
  />,
  <Column
    key={nameOfGridHandler("itemPai")}
    dataField={nameOfGridHandler("itemPai")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Item pai"
    visible={false}
    width={160}
  />,
  <Column
    key={nameOfGridHandler("descricaoDoItemPai")}
    dataField={nameOfGridHandler("descricaoDoItemPai")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Descrição do item pai"
    visible={false}
    width={400}
  />,
  ...GetColunasDeAuditoria(),
];
export const GridInsumoDaOrdemDeProducao = forwardRef(
  (props: GridBaseProps, ref) => {
    const gridRef = useRef<IGridSelecao>(null);
    useImperativeHandle(ref, () => gridRef.current as IGridSelecao);
    usePropagarReferenciaGrid(ref, gridRef);

    return (
      <>
        <MxpGrid<InsumoDaOrdemDeProducaoGridModel>
          dataSource={dataSource}
          id={"insumo-da-ordem-de-producao"}
          ref={gridRef}
          colunas={colunas}
          exibirIconeEditarMenuAcoes={false}
          exibirIconeExcluirMenuAcoes={false}
          definirMenuSuperior={props.definirMenu}
          nomeDoArquivoAoExportar={NomesTelas.insumosDasOrdensDeProducao}
        />
      </>
    );
  }
);
