import { Item } from "devextreme-react/tab-panel";
import { ReactNode, useRef, useState } from "react";
import * as yup from "yup";
import ProvedorAjuda from "../../../../components/ajuda/provedor-ajuda";
import {
  FormCheckBox,
  FormDateBox,
  FormGrupo,
  FormNumberBox,
  FormSelectBox,
  FormSelectBoxLazy,
} from "../../../../components/formularios";
import {
  FormBase2,
  FormularioEdicaoBaseProps,
} from "../../../../components/layout/form-base2";
import { Coluna, Linha } from "../../../../components/layout/grid-system";
import {
  useCarregarCombos,
  useCarregarRegistro,
  useHookForms,
  useLimparFormSeIdForNaN,
} from "../../../../hooks/form.hooks";
import AuditavelDTO from "../../../../models/api/comum/auditavel-dto";
import {
  OrdemDeProducaoRequest,
  OrdemDeProducaoResponse,
  quantidadeDePerdaPrevistaMaxima,
  quantidadeDePerdaPrevistaMinima,
  quantidadeMaxima,
  quantidadeMinima,
} from "../../../../models/api/producao/ordem-de-producao/ordem-de-producao";
import {
  OrdemDeProducaoEstado,
  ordemDeProducaoEstadoDicionario,
} from "../../../../models/api/producao/ordem-de-producao/ordem-de-producao-enums";
import { CallBackModal } from "../../../../models/shared/ui/callback-modal";
import { GridItem } from "../../../../parts/itens/item/grids/grid-padrao";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import { checarResponse, tratarErroApi } from "../../../../utils/api/api-utils";
import criarNameof from "../../../../utils/common/cria-name-of";
import NomesModais from "../../../../utils/common/nomes-modais";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../utils/common/notificacoes-utils";

import { Button, TabPanel } from "devextreme-react";
import { MenuEdicaoOrdemDeProducao } from "../../../../components/ordem-de-producao/menu-edicao-op";
import { ProvedorMenuEdicaoOrdemDeProducao } from "../../../../components/ordem-de-producao/provedor-menu-op";
import { ResponseBase } from "../../../../models/api/comum/response-base";
import { SelectBoxLazyFiltros } from "../../../../models/api/comum/selectboxlazy-options";
import { ItemResponse } from "../../../../models/api/item/item";
import { Estado, EstocagemTipo } from "../../../../models/api/item/item-enums";
import SelectItem from "../../../../models/shared/ui/select-item";
import OrdemDeProducaoAbaAnexos from "../../../../parts/producao/ordem-de-producao/abas/anexos";
import OrdemDeProducaoAbaInsumos from "../../../../parts/producao/ordem-de-producao/abas/insumos";
import {
  dicionarioDosIndicesDasAbasPorCampo,
  OrdemDeProducaoAbasIndices,
} from "../../../../parts/producao/ordem-de-producao/abas/ordem-de-producao-abas";
import OrdemDeProducaoAbaOutrosDados from "../../../../parts/producao/ordem-de-producao/abas/outros-dados";
import APIBase from "../../../../services/comum/serviceBase";
import { ItemService } from "../../../../services/item/item.service";
import { OrdemDeProducaoService } from "../../../../services/producao/ordem-de-producao/ordem-de-producao.service";
import ValorOpcaoService from "../../../../services/valor-opcao/valor-opcao.service";
import { verificarValidacaoDasAbasComRedirecionamento } from "../../../../utils/common/form-utils";
import { exibirConfirmacao } from "../../../../utils/dialogos";
import { FixarContainer } from "../../centro-de-trabalho/edit-form/styles";
import { EstadoConfiguravelContainer, EstadoContainer } from "./style";

const novoRegistro: OrdemDeProducaoRequest = {
  id: 0,
  numero: NaN,
  itemId: NaN,
  quantidade: NaN,
  fixarQuantidade: true,
  quantidadeDePerdaPrevista: NaN,
  necessidadeData: new Date(),
  fixarNecessidadeData: true,
  estado: OrdemDeProducaoEstado.AProduzir,
  estadoConfiguravelId: NaN,
  observacoes: "",
  arquivos: [],
};

const filtrosPadroesParaItem: SelectBoxLazyFiltros[] = [
  { nomeCampo: "Estado", operador: "<>", valor: Estado.Inativo },
];

const ordemDeProducaoService = new OrdemDeProducaoService();
const valorOpcaoService = new ValorOpcaoService();
const itemService = new ItemService();
const nameOfOrdemDeProducao = criarNameof<OrdemDeProducaoRequest>();

function carregaItens(filtrosAdicionais: SelectBoxLazyFiltros[] = []) {
  const filtros: SelectBoxLazyFiltros[] = [
    { nomeCampo: "EstocagemPor", operador: "<>", valor: EstocagemTipo.Codigo },
    { nomeCampo: "EstocagemPor", operador: "<>", valor: EstocagemTipo.Interno },
    { nomeCampo: "Estado", operador: "<>", valor: Estado.Inativo },
  ];

  for (const filtro of filtrosAdicionais) {
    filtros.push(filtro);
  }

  return APIBase.getDataSourceSelectBoxLazy(
    {
      camposRetorno: ["Id", "Codigo", "Descricao", "EstocagemPor"],
      filtros: filtros,
      camposOrdenacao: [
        {
          nomeCampo: "Codigo",
          desc: false,
        },
      ],
    },
    NomesEndpoints.Item
  );
}

const itensExpressaoBusca = ["Codigo", "Descricao"];
const exibeItem = (item: any) => {
  if (item) {
    return item.Descricao == null
      ? `${item.Codigo}`
      : `${item.Codigo} (${item.Descricao})`;
  }

  return "";
};

export const estadoOrdemDeProducaoAsSelectItem: SelectItem[] = Object.keys(
  OrdemDeProducaoEstado
)
  .map((x) => parseInt(x))
  .filter((x) => !isNaN(x))
  .map((valor) => ({
    valor,
    descricao: ordemDeProducaoEstadoDicionario[valor as OrdemDeProducaoEstado],
  }));

export const EditFormOrdemDeProducao = (props: FormularioEdicaoBaseProps) => {
  const [carregando, setCarregando] = useState(false);
  const [dadosAuditoria, setDadosAuditoria] = useState<AuditavelDTO>();
  const [itens] = useState(carregaItens);
  const [estadosConfiguraveis, setEstadosConfiguraveis] = useState<
    SelectItem[]
  >([]);

  const tabPanelRef = useRef<TabPanel>(null);
  ("");

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    itemId: yup.number().required().moreThan(-1).integer(),
    necessidadeData: yup.date().required(),
    quantidade: yup
      .number()
      .required()
      .moreThan(quantidadeMinima.toNumber())
      .lessThan(quantidadeMaxima.toNumber()),
    quantidadeDePerdaPrevista: yup
      .number()
      .notRequired()
      .moreThan(quantidadeDePerdaPrevistaMinima.toNumber())
      .lessThan(quantidadeDePerdaPrevistaMaxima.toNumber()),
    fixarQuantidade: yup.bool().required(),
    fixarNecessidadeData: yup.bool().required(),
  });

  const isRegistroEmEdicao = props.idRegistroEdicao > 0;

  const hookForm = useHookForms(schema);
  useCarregarRegistro(props.idRegistroEdicao, carregarTela);
  useLimparFormSeIdForNaN(hookForm, novoRegistro, props.idRegistroEdicao);

  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    reset,
    watch,
    formState,
  } = hookForm;

  const botoesAdicionais: ReactNode[] = [
    <Button
      key="btn-limpar"
      type="normal"
      text="Concluir"
      visible={
        watch(nameOfOrdemDeProducao("estado")) ==
        OrdemDeProducaoEstado.AProduzir
      }
      onClick={() =>
        exibirNotificacaoToast({
          mensagem: "Função ainda não foi implementada.",
          tipo: TipoNotificacao.Informacao,
        })
      }
    />,
  ];

  useCarregarCombos(props.idRegistroEdicao, carregarCombos);

  async function carregarCombos() {
    await carregarEstadosConfiguraveis();
  }

  async function carregarTela() {
    try {
      setCarregando(true);
      if (isRegistroEmEdicao) {
        await carregarModel();
      } else {
        reset(novoRegistro);
        setDadosAuditoria(undefined);
      }
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  async function carregarModel() {
    try {
      const [resposta, anexos] = await Promise.all([
        await ordemDeProducaoService.ObterPorIdComDadosAuditoria<OrdemDeProducaoResponse>(
          props.idRegistroEdicao
        ),
        await ordemDeProducaoService.obterAnexos(props.idRegistroEdicao),
      ]);

      checarResponse(resposta);
      resposta.model.arquivos = anexos;

      reset(resposta.model);
      setDadosAuditoria(resposta.model);
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    }
  }

  async function handleSubmitError(errors: object) {
    verificarValidacaoDasAbasComRedirecionamento<OrdemDeProducaoRequest>(
      tabPanelRef,
      errors,
      dicionarioDosIndicesDasAbasPorCampo,
      OrdemDeProducaoAbasIndices.Insumos
    );
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues() as OrdemDeProducaoRequest;
    try {
      let resposta: ResponseBase;

      if (isRegistroEmEdicao) {
        const [respostaApi, respostaAnexo] = await Promise.all([
          ordemDeProducaoService.Atualizar(model),
          ordemDeProducaoService.anexoAlterarLista(
            model.id,
            model.arquivos ?? []
          ),
        ]);

        resposta = respostaApi;
        checarResponse(respostaAnexo);
      } else {
        const respostaInicial =
          await ordemDeProducaoService.CadastrarComRetorno<
            OrdemDeProducaoRequest,
            OrdemDeProducaoResponse
          >(model as OrdemDeProducaoRequest);

        if (respostaInicial.sucesso) {
          const id = respostaInicial.model.id;
          const respostaAnexo = await ordemDeProducaoService.anexoAlterarLista(
            id,
            model.arquivos ?? []
          );
          checarResponse(respostaAnexo);
        }

        resposta = {
          sucesso: respostaInicial.sucesso,
          mensagem: respostaInicial.mensagem,
        };
      }

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar({
          concluido: true,
          precisaAtualizar: true,
        });
      }
      fechar({
        concluido: true,
        precisaAtualizar: false,
      });
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  function fechar(info: CallBackModal) {
    if (props.callBackFecharModal) {
      props.callBackFecharModal(info);
    }
  }

  async function handleCancelar() {
    if (formState.isDirty) {
      const confirmacao = await exibirConfirmacao(
        "Aviso",
        "Há dados não salvos. Deseja cancelar?"
      );

      if (!confirmacao) {
        return;
      }
    }

    setDadosAuditoria(undefined);
    fechar({ concluido: false, precisaAtualizar: false });
  }

  function callBackUnprocessableEntity() {
    fechar({
      concluido: false,
      precisaAtualizar: true,
    });
  }

  function funcaoParaBaixarAnexo(idAnexo: number) {
    return ordemDeProducaoService.anexoObterDadosParaDownload(
      getValues().id,
      idAnexo
    );
  }

  async function carregarEstadosConfiguraveis() {
    try {
      const resposta =
        await valorOpcaoService.ObterEstadosConfiguraveisDaOrdemDeProducao();
      checarResponse(resposta);
      setEstadosConfiguraveis(
        resposta.model.map((x) => ({
          valor: x.valor,
          descricao: x.descricao,
        }))
      );
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  async function onItemChanged() {
    if (!props.visivel) {
      return;
    }

    try {
      const resposta = await itemService.ObterPorId(
        getValues(nameOfOrdemDeProducao("itemId"))
      );
      checarResponse(resposta);

      const item = resposta.model as ItemResponse;
      const quantidadeDePerdaPrevistaDoItem = item.perdaPrevista ?? NaN;

      setValue(
        nameOfOrdemDeProducao("quantidadeDePerdaPrevista"),
        quantidadeDePerdaPrevistaDoItem
      );
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    }
  }

  return (
    <>
      <ProvedorMenuEdicaoOrdemDeProducao>
        <FormBase2
          visivel={props.visivel}
          carregando={carregando}
          onClickSalvar={handleSubmit(handleSalvar, handleSubmitError)}
          onClickCancelar={handleCancelar}
          configuracoesModal={props.configuracoesModal}
          modoEdicao={props.idRegistroEdicao == 0 ? "criar" : "editar"}
          titulo={NomesModais.ordemDeProducao}
          auditoria={dadosAuditoria}
          isNomeTelaFeminino={true}
          botoesAdicionais={botoesAdicionais}
          componentesAdicionais={MenuEdicaoOrdemDeProducao}
        >
          <ProvedorAjuda id={"edit-form-ordem-de-producao"}>
            <input type="hidden" {...register("id")} defaultValue={0} />
            <input
              type="hidden"
              {...register("arquivos")}
              defaultValue={JSON.stringify([])}
            />
            <FormGrupo>
              <Linha>
                <Coluna md={2}>
                  <FormNumberBox
                    name={nameOfOrdemDeProducao("numero")}
                    titulo="Numero"
                    control={control}
                    somenteLeitura
                  />
                </Coluna>
                <Coluna md={6}>
                  <FormSelectBoxLazy
                    name={nameOfOrdemDeProducao("itemId")}
                    titulo="Item"
                    control={control}
                    requerido
                    nomeCampoChave="Id"
                    nomeCampoExibicao={exibeItem}
                    somenteLeitura={isRegistroEmEdicao}
                    expressaoDeBusca={itensExpressaoBusca}
                    onSelectionChanged={onItemChanged}
                    dataSource={itens}
                    lupaConfig={{
                      modo: "selecaoUnica",
                      titulo: "Selecionar item",
                      componente: (r) => (
                        <GridItem
                          sobreporFiltroSalvoComOFiltroPadrao
                          ocultarBotaoNovo
                          filtrosRealizadosNoServidor={filtrosPadroesParaItem}
                          ref={r}
                        />
                      ),
                    }}
                    labelSemDados="Sem dados"
                  />
                </Coluna>
                <Coluna md={2} offset={2}>
                  <EstadoContainer>
                    <FormSelectBox
                      name={nameOfOrdemDeProducao("estado")}
                      titulo="Estado"
                      control={control}
                      somenteLeitura
                      dataSource={estadoOrdemDeProducaoAsSelectItem}
                      habilitaBusca
                      tipoBusca="contains"
                    />
                  </EstadoContainer>
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={2}>
                  <FormNumberBox
                    name={nameOfOrdemDeProducao("quantidade")}
                    titulo="Quantidade"
                    control={control}
                    minimo={quantidadeMinima.toNumber()}
                    maximo={quantidadeMaxima.toNumber()}
                    requerido
                  />
                </Coluna>
                <Coluna md={1}>
                  <FixarContainer>
                    <FormCheckBox
                      name={nameOfOrdemDeProducao("fixarQuantidade")}
                      titulo="Fixar"
                      control={control}
                    />
                  </FixarContainer>
                </Coluna>
                <Coluna md={2}>
                  <FormDateBox
                    name={nameOfOrdemDeProducao("necessidadeData")}
                    titulo="Necessidade"
                    control={control}
                    exibirBotaoLimpar
                    tipo="date"
                    aceitaValorCustomizado={true}
                    formatoExibicao="dd/MM/yyyy"
                    aceitaDigitacaoComMascara={true}
                    requerido
                  />
                </Coluna>
                <Coluna md={1}>
                  <FixarContainer>
                    <FormCheckBox
                      name={nameOfOrdemDeProducao("fixarNecessidadeData")}
                      titulo="Fixar"
                      control={control}
                    />
                  </FixarContainer>
                </Coluna>
                <Coluna md={2} offset={4}>
                  <EstadoConfiguravelContainer>
                    <FormSelectBox
                      name={nameOfOrdemDeProducao("estadoConfiguravelId")}
                      titulo="Estado configurável"
                      control={control}
                      dataSource={estadosConfiguraveis}
                      habilitaBusca
                      tipoBusca="contains"
                    />
                  </EstadoConfiguravelContainer>
                </Coluna>
              </Linha>
            </FormGrupo>
            <TabPanel
              key={props.idRegistroEdicao}
              deferRendering={false}
              ref={tabPanelRef}
              showNavButtons
              swipeEnabled={false}
              itemTitleRender={(item) => item.text}
              height={"max(60vh, 420px)"}
            >
              <Item text="Insumos">
                {isRegistroEmEdicao ? (
                  <OrdemDeProducaoAbaInsumos
                    idRegistro={props.idRegistroEdicao}
                    control={control}
                  />
                ) : (
                  <p>
                    <b>Para visualizar os insumos, é necessário criar a OP.</b>
                  </p>
                )}
              </Item>
              <Item text="Reservas e vínculos">
                <p>Ainda será implementado</p>
              </Item>
              <Item text="Aparas">
                <p>Ainda será implementado</p>
              </Item>
              <Item text="Retirada de materiais">
                <p>Ainda será implementado</p>
              </Item>
              <Item text="Conclusões">
                <p>Ainda será implementado</p>
              </Item>
              <Item text="Outros dados">
                <OrdemDeProducaoAbaOutrosDados
                  idRegistro={props.idRegistroEdicao}
                  control={control}
                  watch={watch}
                />
              </Item>
              <Item text="Anexos">
                <OrdemDeProducaoAbaAnexos
                  idRegistro={props.idRegistroEdicao}
                  getValues={getValues}
                  funcaoParaBaixarAnexo={funcaoParaBaixarAnexo}
                />
              </Item>
              <Item text="Anotações">
                <p>Ainda será implementado</p>
              </Item>
            </TabPanel>
          </ProvedorAjuda>
        </FormBase2>
      </ProvedorMenuEdicaoOrdemDeProducao>
    </>
  );
};

export default EditFormOrdemDeProducao;
