import getApi from "../../configs/api";
import { ResponseModel } from "../../models/api/comum/response-base";
import {
  TiposTokenAcesso,
  TokenAcessoRequestDTO,
  TokenAcessoResponseDTO,
} from "../../models/api/tokens/acesso-token";

const nomeEndpoint: string = "TokenDeAcesso";

async function Obter(idUsuario: number, tipo: TiposTokenAcesso) {
  const api = getApi();
  const response = await api.get<ResponseModel<TokenAcessoResponseDTO>>(
    `${nomeEndpoint}?idUsuario=${idUsuario}&tipo=${tipo}`
  );

  return response.data;
}

async function Gerar(model: TokenAcessoRequestDTO) {
  const api = getApi();
  const response = await api.post<ResponseModel<TokenAcessoResponseDTO>>(
    nomeEndpoint,
    model
  );

  return response.data;
}

async function Revogar(idUsuario: number, tipo: TiposTokenAcesso) {
  const api = getApi();
  const response = await api.delete<ResponseModel<boolean>>(
    `${nomeEndpoint}?idUsuario=${idUsuario}&tipo=${tipo}`
  );

  return response.data;
}

export default {
  Gerar,
  Obter,
  Revogar,
};
