export interface RegistroBalanceteComparativo {
  contaContabilId: number;
  contaContabilDeNivelSuperiorId: number | null;
  nivel: number;
  tipo: TipoContaContabil;
  codigo: string;
  classificacao: string;
  descricao: string;
  contaAtiva: boolean;
  competencias: CompetenciaRegistroBalanceteComparativo[];
}

export interface CompetenciaRegistroBalanceteComparativo {
  competencia: string;
  agrupamento: TipoAgrupamentoBalanceteComparativo;
  saldoBalancete: number;
  saldoIntermediario: number;
  debito: number;
  credito: number;
  liquido: number;
  saldoInicial: number;
  saldoFinal: number;
}

export enum TipoContaContabil {
  Analitica = 65,
  Sintetica = 83,
}

export enum EstadoContaContabil {
  Ativas = 0,
  Inativas = 1,
  Ambas = 2,
}

export enum TipoAgrupamentoBalanceteComparativo {
  Mensal = 0,
  Trimestral = 1,
  Anual = 2,
}
