import getApi from "../../../configs/api";
import { CentroDeTrabalhoGridModel } from "../../../models/api/centro-de-trabalho/centro-de-trabalho";
import {
  ListaSimplesResponse,
  ResponseModel,
} from "../../../models/api/comum/response-base";
import { SelectBoxLazyOpcoes } from "../../../models/api/comum/selectboxlazy-options";
import { criarDataSourceSelectBoxLazy } from "../../../utils/api/api-utils";
import criarNameof from "../../../utils/common/cria-name-of";
import criarDatasourceGrid from "../../../utils/grid/back-end-grid-utils";
import { NomesEndpoints } from "../../comum/nomesEndpoints";

export const nomeEndpointCentroDeTrabalho = NomesEndpoints.CentroDeTrabalho;

async function obterUnidadesMedida() {
  const api = getApi();
  const response = await api.get<ListaSimplesResponse>(
    `${nomeEndpointCentroDeTrabalho}/unidades-medidas`
  );

  return response.data;
}

async function existeVinculosEnderecoEstoqueCentroDeTrabalho(id: number) {
  const api = getApi();
  const response = await api.get<ResponseModel<boolean>>(
    `${nomeEndpointCentroDeTrabalho}/existeVinculosEnderecoEstoqueCentroDeTrabalho?id=${id}`
  );

  return response.data;
}

function getDataSourceSelectBoxLazy(opcoes: SelectBoxLazyOpcoes) {
  const nameOfGridHandler = criarNameof<CentroDeTrabalhoGridModel>();
  const camposSelect = JSON.stringify(opcoes.camposRetorno);
  const dataSource = criarDatasourceGrid(
    `${nomeEndpointCentroDeTrabalho}/grid?select=${camposSelect}`
  );

  opcoes.filtros = [
    {
      nomeCampo: nameOfGridHandler("ativo"),
      operador: "=",
      valor: true,
    },
  ];

  opcoes.camposOrdenacao = [
    { nomeCampo: nameOfGridHandler("codigo"), desc: false },
  ];

  return criarDataSourceSelectBoxLazy(dataSource, opcoes);
}

export default {
  obterUnidadesMedida,
  getDataSourceSelectBoxLazy,
  existeVinculosEnderecoEstoqueCentroDeTrabalho,
};
