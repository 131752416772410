import { Column } from "devextreme-react/cjs/data-grid";
import { EstoqueMovimentacaoGridModel } from "../../../models/api/estoque-movimentacao/estoque-movimentacao";
import criarNameof from "../../../utils/common/cria-name-of";
import obterConfiguracaoColuna from "../../../utils/grid/padroes-colunas";
import GetColunasDeAuditoria from "../../layout/grid-defaults/colunasDeAuditoria";

const nameOfGridHandler = criarNameof<EstoqueMovimentacaoGridModel>();

export const colunasEstoqueMovimentacao = [
  <Column
    key={nameOfGridHandler("dataDaMovimentacao")}
    dataField={nameOfGridHandler("dataDaMovimentacao")}
    {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
    allowEditing={false}
    caption="Data da movimentação"
  />,
  <Column
    key={nameOfGridHandler("itemCodigo")}
    dataField={nameOfGridHandler("itemCodigo")}
    {...obterConfiguracaoColuna("stringM")}
    allowEditing={false}
    caption="Item"
  />,
  <Column
    key={nameOfGridHandler("itemDescricao")}
    dataField={nameOfGridHandler("itemDescricao")}
    {...obterConfiguracaoColuna("stringGG")}
    allowEditing={false}
    caption="Descrição"
  />,
  <Column
    key={nameOfGridHandler("quantidade")}
    dataField={nameOfGridHandler("quantidade")}
    {...obterConfiguracaoColuna("quantidade")}
    allowEditing={false}
    caption="Quantidade"
  />,
  <Column
    key={nameOfGridHandler("pesoBruto")}
    dataField={nameOfGridHandler("pesoBruto")}
    {...obterConfiguracaoColuna("peso")}
    allowEditing={false}
    caption="Peso bruto"
  />,
  <Column
    key={nameOfGridHandler("pesoLiquido")}
    dataField={nameOfGridHandler("pesoLiquido")}
    {...obterConfiguracaoColuna("peso")}
    allowEditing={false}
    caption="Peso líquido"
  />,
  ...GetColunasDeAuditoria(),
];
