import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ProvedorAjuda from "../../../../components/ajuda/provedor-ajuda";
import {
  FormDateBox,
  FormGrupo,
  FormTextBoxSimples,
} from "../../../../components/formularios";
import {
  FormBase2,
  FormularioEdicaoBaseProps,
} from "../../../../components/layout/form-base2";
import { Coluna, Linha } from "../../../../components/layout/grid-system";
import { useAppSelector } from "../../../../hooks/store.hooks";
import AuditavelDTO from "../../../../models/api/comum/auditavel-dto";
import {
  ApuracaoDoInformativoDaComposicaoDeCustosDaEcfCadastrarRequest,
  ApuracaoDoInformativoDaComposicaoDeCustosDaEcfDTO,
} from "../../../../models/api/informativo-composicao-custos-lucro-real/apuracao-informativo-composicao-custos-ecf";
import { CallBackModal } from "../../../../models/shared/ui/callback-modal";
import { ApuracaoDoInformativoDaComposicaoDeCustosDaEcfService } from "../../../../services/informativo-composicao-custos-lucro-real/apuracao-informativo-composicao-custos-ecf";
import { checarResponse, tratarErroApi } from "../../../../utils/api/api-utils";
import NomesModais from "../../../../utils/common/nomes-modais";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../utils/common/notificacoes-utils";
import { formatarCpfCnpj } from "../../../../utils/formatadores/formatador-de-strings";

const service = new ApuracaoDoInformativoDaComposicaoDeCustosDaEcfService();

const novoRegistro: ApuracaoDoInformativoDaComposicaoDeCustosDaEcfDTO = {
  id: 0,
  dataInicial: "",
  dataFinal: "",
};

export const EditFormInsercaoApuracaoDoInformativoDaComposicaoDeCustosDaEcf = (
  props: FormularioEdicaoBaseProps
) => {
  const [carregando, setCarregando] = useState(false);
  const [dadosAuditoria, setDadosAuditoria] = useState<AuditavelDTO>();

  const empresaLogada = useAppSelector((state) => {
    return {
      Apelido: state.sessao.dadosSessao?.empresa.apelido,
      CpfCpj: state.sessao.dadosSessao?.empresa.cnpjCpfCodigo,
    };
  });

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    dataInicial: yup
      .string()
      .required()
      .test(
        "data_inicial_valida",
        "A data inicial está maior do que a final.",
        function (value, contexto) {
          if (value && contexto.parent.dataFinal) {
            const dataInicial = new Date(value);
            const dataFinal = new Date(contexto.parent.dataFinal);
            return dataInicial <= dataFinal;
          }
          return true;
        }
      ),
    dataFinal: yup
      .string()
      .required()
      .test(
        "data_final_valida",
        "A data final está menor do que a inicial.",
        function (valor, contexto) {
          if (valor && contexto.parent.dataInicial) {
            const dataInicial = new Date(contexto.parent.dataInicial);
            const dataFinal = new Date(valor);
            return dataInicial <= dataFinal;
          }
          return true;
        }
      ),
  });

  const { control, handleSubmit, getValues, reset } =
    useForm<ApuracaoDoInformativoDaComposicaoDeCustosDaEcfCadastrarRequest>({
      mode: "onChange",
      reValidateMode: "onChange",
      resolver: yupResolver(schema),
    });

  // Usado para limpar o formulário se for NaN
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEdicao)) {
      reset(novoRegistro);
    }
  }, [props.idRegistroEdicao]);

  function fechar(info: CallBackModal) {
    if (props.callBackFecharModal) {
      setDadosAuditoria(undefined);
      props.callBackFecharModal(info);
    }
  }

  function handleCancelar() {
    fechar({ concluido: false, precisaAtualizar: false });
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues();
    try {
      const resposta = await service.Calcular(model);

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar({
          concluido: true,
          precisaAtualizar: true,
        });
      }
      fechar({
        concluido: true,
        precisaAtualizar: false,
      });
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  function callBackUnprocessableEntity() {
    fechar({
      concluido: false,
      precisaAtualizar: true,
    });
  }

  return (
    <>
      <div>
        <FormBase2
          visivel={props.visivel}
          carregando={carregando}
          onClickSalvar={handleSubmit(handleSalvar)}
          onClickCancelar={handleCancelar}
          configuracoesModal={props.configuracoesModal}
          modoEdicao={props.idRegistroEdicao == 0 ? "criar" : "editar"}
          titulo={NomesModais.informativoComposicaoCustosLucroReal}
          auditoria={dadosAuditoria}
        >
          <ProvedorAjuda id="edit-form-informativo-composicao-custos-lucro-real">
            <FormGrupo>
              <Linha>
                <Coluna md={8}>
                  <FormTextBoxSimples
                    titulo={"Minha empresa"}
                    valor={`${empresaLogada.Apelido} - ${formatarCpfCnpj(
                      empresaLogada.CpfCpj!
                    )}`}
                    somenteLeitura
                  />
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={3}>
                  <FormDateBox
                    name="dataInicial"
                    titulo="Período de"
                    requerido
                    control={control}
                    exibirBotaoLimpar
                    tipo="date"
                    aceitaValorCustomizado={true}
                    formatoExibicao="dd/MM/yy"
                    aceitaDigitacaoComMascara={true}
                  />
                </Coluna>
                <Coluna md={3}>
                  <FormDateBox
                    name="dataFinal"
                    titulo="a"
                    control={control}
                    requerido
                    exibirBotaoLimpar
                    tipo="date"
                    aceitaValorCustomizado={true}
                    formatoExibicao="dd/MM/yy"
                    aceitaDigitacaoComMascara={true}
                  />
                </Coluna>
              </Linha>
            </FormGrupo>
          </ProvedorAjuda>
        </FormBase2>
      </div>
    </>
  );
};

export default EditFormInsercaoApuracaoDoInformativoDaComposicaoDeCustosDaEcf;
