import { DataGrid } from "devextreme-react";
import { Column } from "devextreme-react/data-grid";
import { SingleMultipleOrNone } from "devextreme/common";
import { forwardRef, useImperativeHandle, useState } from "react";
import { renderToString } from "react-dom/server";
import { Link } from "react-router-dom";
import LinkButton from "../../../../components/botoes/link-button";
import { atualizarToken } from "../../../../configs/api";
import { useAppDispatch, useAppSelector } from "../../../../hooks/store.hooks";
import { UsuariosLogadosGrid } from "../../../../models/api/controle-de-acesso/controle-de-acesso";
import { TokenResponse } from "../../../../models/api/tokens/token-responses";
import { decodificarTipoDeAcesso } from "../../../../models/api/usuario/usuario";
import { PermissoesUsuario } from "../../../../models/permissoes/seguranca/usuario/permissoes-usuario";
import APIControleAcesso from "../../../../services/controle-acesso/controle-acesso.service";
import { getDadosSessao } from "../../../../services/tokens/tokens.service";
import {
  bloquearUI,
  definirEstadoAtualizacaoToken,
  desbloquearUI,
} from "../../../../store/ui/ui.slice";
import { checarResponse, tratarErroApi } from "../../../../utils/api/api-utils";
import criarNameof from "../../../../utils/common/cria-name-of";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../utils/common/permissoes-utils";
import { exibirConfirmacao } from "../../../../utils/dialogos";
import {
  GridBaseProps,
  gerarGridId,
  getGridDefaultProps,
} from "../../../../utils/grid/grid-utils";
import obterConfiguracaoColuna from "../../../../utils/grid/padroes-colunas";
import {
  getGridSelecaoProps,
  gridSelecaoConstructor,
} from "../../../../utils/grid/selecao-utils";
import { GravarSessaoReduxELocalStorage } from "../../../../utils/oauth/oauth-utils";
import GestorLocalStorage, {
  ChavesLocalstorage,
} from "../../../../utils/storage/gestor-storage";
import GridDefaults from "../../../layout/grid-defaults";
import { MensagemSuperiorGridUsuariosLogados } from "./styles";

export const GridUsuariosLogados = forwardRef((props: GridBaseProps, ref) => {
  const [modoSelecao, setModoSelecao] = useState<SingleMultipleOrNone>("none");
  const [acaoDoubleClick, setAcaoDoubleClick] = useState<() => void>();

  const limiteAtingido: boolean = useAppSelector(
    (state) => !!state.sessao.dadosSessao?.simplificado
  );

  const gridId = gerarGridId(props, "usuarios-logados");
  let gridRef: DataGrid<any, any> = new DataGrid<any, any>({});

  function setGridRef(ref: any) {
    gridRef = ref;
  }

  function getGridRef() {
    return gridRef;
  }

  useImperativeHandle(ref, () =>
    gridSelecaoConstructor(
      gridRef,
      () => modoSelecao,
      setModoSelecao,
      setAcaoDoubleClick
    )
  );

  const dispatch = useAppDispatch();
  const dataSource = APIControleAcesso.GetGridSource();

  function atualizarGrid() {
    gridRef.instance.refresh();
  }

  async function forcarLogoff(
    idUsuario: number,
    nome: string,
    protegerLogoffForcado: boolean
  ) {
    try {
      if (
        protegerLogoffForcado &&
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesUsuario.ForcarLogoffMesmoComProtecao,
        ])
      ) {
        return;
      }
      dispatch(bloquearUI());

      const mensagem = renderToString(
        <>
          Tem certeza de que deseja forçar o logoff do usuário{" "}
          <strong>
            <i>{nome}</i>
          </strong>
          ?
        </>
      );

      const excluir = await exibirConfirmacao("Confirmar", mensagem);

      if (excluir) {
        const resposta = await APIControleAcesso.ForcarLogoff(idUsuario);
        checarResponse(resposta);

        if (resposta.sucesso) {
          exibirNotificacaoToast({
            mensagem: resposta.mensagem,
            tipo: TipoNotificacao.Sucesso,
          });

          /* Força atualizar a sessão para que caso haja sessões disponíveis,
           após derrubar, libera o usuário para acessar outras telas. */
          dispatch(definirEstadoAtualizacaoToken("ocioso"));
          await atualizarToken("atualizando", {}, true);
          const dadosToken = GestorLocalStorage.LerItem<TokenResponse>(
            ChavesLocalstorage.DadosSessao
          );
          const dados = await getDadosSessao(dadosToken!);
          GravarSessaoReduxELocalStorage({ dadosSessao: dados }, dadosToken!);

          atualizarGrid();
        }
      }
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      dispatch(desbloquearUI());
    }
  }

  const nameOfGridHandler = criarNameof<UsuariosLogadosGrid>();

  const linkForcarLogoff = (dados: UsuariosLogadosGrid) => {
    return (
      <LinkButton
        sublinhado
        onClick={async () =>
          await forcarLogoff(
            dados.idUsuario,
            dados.agrupamentoUsuario,
            dados.protegerLogoffForcado
          )
        }
      >
        Forçar logoff
      </LinkButton>
    );
  };

  const urlMxp1 = process.env.REACT_APP_BACKEND_ANTIGO_ENDPOINT as string;

  const tipoAcessoUsuario = useAppSelector(
    (state) => state.sessao.dadosSessao?.usuario.tipoDeAcesso
  );

  return (
    <>
      {limiteAtingido && (
        <MensagemSuperiorGridUsuariosLogados>
          Limite de usuários simultâneos do tipo:{" "}
          {decodificarTipoDeAcesso(tipoAcessoUsuario!)} excedido. Você pode
          desconectar um usuário da lista ou, se preferir,
          <a href={`${urlMxp1}/Assinatura`}> alterar a sua assinatura</a>.
        </MensagemSuperiorGridUsuariosLogados>
      )}
      {!limiteAtingido && (
        <MensagemSuperiorGridUsuariosLogados>
          Você pode usar o sistema normalmente.
          <Link to="/">Clique aqui</Link> para ir para a tela inicial.
        </MensagemSuperiorGridUsuariosLogados>
      )}
      <DataGrid
        ref={setGridRef}
        dataSource={dataSource}
        {...getGridDefaultProps({
          getGridRef: getGridRef,
          isModal: props.isModal,
          style: props.style,
        })}
        {...getGridSelecaoProps(
          () => modoSelecao,
          () => acaoDoubleClick
        )}
      >
        {GridDefaults({
          gridId,
          atualizarGrid,
          getGridRef,
        })}
        <Column
          dataField={nameOfGridHandler("agrupamentoUsuario")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Usuário"
          allowSorting={false}
        />
        <Column
          dataField={nameOfGridHandler("idUsuario")}
          {...obterConfiguracaoColuna("stringP")}
          caption=""
          allowSorting={false}
          allowFiltering={false}
          cellRender={({ data }) =>
            linkForcarLogoff(data as UsuariosLogadosGrid)
          }
          alignment="left"
          showInColumnChooser={false}
        />
        <Column
          dataField={nameOfGridHandler("tipoDeAcesso")}
          {...obterConfiguracaoColuna("stringM")}
          caption="Tipo de acesso"
        />
      </DataGrid>
    </>
  );
});
