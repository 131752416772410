import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HomeBackground } from "../../../../components/layout/login-bg/styles";
import RequerPermissao from "../../../../components/seguranca/requer-permissao";
import VerificacaoBackend from "../../../../components/seguranca/verificacao-backend";
import { PermissoesLucroPresumido } from "../../../../models/permissoes/contabilidade/ecf/permissoes-lucro-presumido";
import { EcfService } from "../../../../services/contabilidade/ecf/ecf.service";
import { EditFormEcfLucroPresumido } from "./edit-form";

const service = new EcfService();

export const ModalEcf = () => {
  const [visivel, setVisivel] = useState(true);
  const navigate = useNavigate();

  const handleModalCallback = useCallback(() => {
    setVisivel(false);
    navigate("/", {
      replace: true,
    });
  }, []);

  const checkarGeracaoEcf = async () => {
    const resposta = await service.PodeGerarEcf();
    return resposta;
  };

  return (
    <VerificacaoBackend checagem={checkarGeracaoEcf}>
      <RequerPermissao codigoPermissoes={[PermissoesLucroPresumido.Gerar]}>
        <HomeBackground>
          <EditFormEcfLucroPresumido
            visivel={visivel}
            idRegistroEdicao={0}
            configuracoesModal={{ largura: "max(30vw, 600px)", altura: "auto" }}
            callBackFecharModal={handleModalCallback}
          />
        </HomeBackground>
      </RequerPermissao>
    </VerificacaoBackend>
  );
};
