import { useState } from "react";
import * as yup from "yup";
import ProvedorAjuda from "../../../../components/ajuda/provedor-ajuda";
import {
  FormGrupo,
  FormNumberBox,
  FormTextBox,
  FormTextBoxSimples,
} from "../../../../components/formularios";
import {
  FormBase2,
  FormularioEdicaoBaseProps,
} from "../../../../components/layout/form-base2";
import { Coluna, Linha } from "../../../../components/layout/grid-system";
import {
  useCarregarRegistro,
  useHookForms,
  useLimparFormSeIdForNaN,
} from "../../../../hooks/form.hooks";
import AuditavelDTO from "../../../../models/api/comum/auditavel-dto";
import {
  NumeroDeSeriePrefixoAtualizarRequest,
  NumeroDeSeriePrefixoResponse,
} from "../../../../models/api/producao/numero-de-serie-prefixo/numero-de-serie-prefixo";
import { CallBackModal } from "../../../../models/shared/ui/callback-modal";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../services/comum/serviceBase";
import { checarResponse, tratarErroApi } from "../../../../utils/api/api-utils";
import { previneDigitacaoDeCaracteres } from "../../../../utils/common/common-utils";
import NomesModais from "../../../../utils/common/nomes-modais";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../utils/common/notificacoes-utils";
import {
  letrasDigitosSimbolosSemEspacosComColchetes,
  letrasDigitosSimbolosSemEspacosComColchetesPermiteStringVazia,
} from "../../../../utils/common/regex-padrao";
import { prefixoTamanhoMaximo, sufixoTamanhoMaximo } from "./constantes";

const novoRegistro: NumeroDeSeriePrefixoAtualizarRequest = {
  id: 0,
  prefixo: "",
  proximoSequencial: 1,
  digitosNumericos: 6,
  sufixo: "",
};

const nomeEndpoint = NomesEndpoints.NumeroDeSeriePrefixo;

const previneDigitacaoDeCaracteresHandler = (e: any) =>
  previneDigitacaoDeCaracteres(e, letrasDigitosSimbolosSemEspacosComColchetes);

export const EditFormNumeroDeSeriePrefixo = (
  props: FormularioEdicaoBaseProps
) => {
  const [carregando, setCarregando] = useState(false);
  const [dadosAuditoria, setDadosAuditoria] = useState<AuditavelDTO>();

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    prefixo: yup
      .string()
      .notRequired()
      .max(prefixoTamanhoMaximo)
      .matches(
        letrasDigitosSimbolosSemEspacosComColchetesPermiteStringVazia,
        "Deve conter apenas letras maiúsculas, letras sem acento, números e símbolos"
      ),
    digitosNumericos: yup.number().required().moreThan(0).integer(),
    proximoSequencial: yup.number().required().moreThan(0).integer(),
    sufixo: yup
      .string()
      .notRequired()
      .max(sufixoTamanhoMaximo)
      .matches(
        letrasDigitosSimbolosSemEspacosComColchetesPermiteStringVazia,
        "Deve conter apenas letras maiúsculas, letras sem acento, números e símbolos"
      ),
  });

  const hookForm = useHookForms(schema);
  useCarregarRegistro(props.idRegistroEdicao, carregarTela);
  useLimparFormSeIdForNaN(hookForm, novoRegistro, props.idRegistroEdicao);

  const { register, control, handleSubmit, getValues, reset, watch } = hookForm;

  async function carregarTela() {
    try {
      setCarregando(true);
      if (props.idRegistroEdicao > 0) {
        await carregarModel();
      } else {
        reset(novoRegistro);
        setDadosAuditoria(undefined);
      }
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  async function carregarModel() {
    try {
      const resposta =
        await APIBase.obterPorIdComDadosAuditoria<NumeroDeSeriePrefixoResponse>(
          props.idRegistroEdicao,
          nomeEndpoint
        );
      checarResponse(resposta);
      reset(resposta.model);
      setDadosAuditoria(resposta.model);
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues();

    if (!model.prefixo && !model.sufixo) {
      setCarregando(false);
      exibirNotificacaoToast({
        mensagem: 'É obrigatório a informação do campo "Prefixo" ou "Sufixo".',
        tipo: TipoNotificacao.Erro,
      });

      return;
    }

    try {
      const resposta =
        props.idRegistroEdicao > 0
          ? await APIBase.atualizar(model, nomeEndpoint)
          : await APIBase.cadastrar(model, nomeEndpoint);

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar({
          concluido: true,
          precisaAtualizar: true,
        });
      }
      fechar({
        concluido: true,
        precisaAtualizar: false,
      });
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  function fechar(info: CallBackModal) {
    if (props.callBackFecharModal) {
      props.callBackFecharModal(info);
    }
  }

  function handleCancelar() {
    setDadosAuditoria(undefined);
    fechar({ concluido: false, precisaAtualizar: false });
  }

  function callBackUnprocessableEntity() {
    fechar({
      concluido: false,
      precisaAtualizar: true,
    });
  }

  const proximoNumeroDeSerie = `${watch("prefixo") ?? ""}${(
    watch("proximoSequencial") ?? 1
  )
    .toString()
    .padStart(watch("digitosNumericos") ?? 8, "0")}${watch("sufixo") ?? ""}`;

  return (
    <>
      <FormBase2
        visivel={props.visivel}
        carregando={carregando}
        onClickSalvar={handleSubmit(handleSalvar)}
        onClickCancelar={handleCancelar}
        configuracoesModal={props.configuracoesModal}
        modoEdicao={props.idRegistroEdicao == 0 ? "criar" : "editar"}
        titulo={NomesModais.numeroDeSeriePrefixoESufixo}
        auditoria={dadosAuditoria}
      >
        <ProvedorAjuda id={"edit-form-numero-de-serie-prefixo"}>
          <input type="hidden" {...register("id")} defaultValue={0} />
          <FormGrupo>
            <Linha>
              <Coluna md={4}>
                <FormTextBox
                  name="prefixo"
                  titulo="Prefixo"
                  control={control}
                  tamanhoMaximo={prefixoTamanhoMaximo}
                  somenteLeitura={props.idRegistroEdicao > 0}
                  transform="uppercase"
                  onKeyDown={previneDigitacaoDeCaracteresHandler}
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={6}>
                <FormNumberBox
                  name="digitosNumericos"
                  titulo="Dígitos numéricos"
                  tipo="number"
                  control={control}
                  quantidadeIncrementarDecrementar={1}
                  exibirSetasIncrementarDecrementar
                  requerido
                  minimo={1}
                  maximo={15}
                  valueChangeEvent={"keyup blur"}
                />
              </Coluna>
              <Coluna md={6}>
                <FormNumberBox
                  name="proximoSequencial"
                  titulo="Próximo sequencial"
                  control={control}
                  quantidadeIncrementarDecrementar={1}
                  exibirSetasIncrementarDecrementar
                  requerido
                  minimo={1}
                  maximo={9_999_999_999}
                  valueChangeEvent={"keyup blur"}
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={4}>
                <FormTextBox
                  name="sufixo"
                  titulo="Sufixo"
                  control={control}
                  tamanhoMaximo={sufixoTamanhoMaximo}
                  somenteLeitura={props.idRegistroEdicao > 0}
                  transform="uppercase"
                  onKeyDown={previneDigitacaoDeCaracteresHandler}
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={12}>
                <FormTextBoxSimples
                  titulo="Próximo lote ou número de série"
                  somenteLeitura
                  valor={proximoNumeroDeSerie}
                />
              </Coluna>
            </Linha>
          </FormGrupo>
        </ProvedorAjuda>
      </FormBase2>
    </>
  );
};

export default EditFormNumeroDeSeriePrefixo;
