import { yupResolver } from "@hookform/resolvers/yup";
import DataSource from "devextreme/data/data_source";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ProvedorAjuda from "../../../../components/ajuda/provedor-ajuda";
import {
  FormNumberBox,
  FormSelectBoxLazy,
  FormTextBoxSimples,
} from "../../../../components/formularios";
import FormTextArea from "../../../../components/formularios/textarea";
import {
  FormBase2,
  FormularioEdicaoBaseProps,
} from "../../../../components/layout/form-base2";
import { Coluna, Linha } from "../../../../components/layout/grid-system";
import useEffectOnLoad from "../../../../hooks/effect.hooks";
import { useCarregarRegistro } from "../../../../hooks/form.hooks";
import AuditavelDTO from "../../../../models/api/comum/auditavel-dto";
import { SelectBoxLazyFiltros } from "../../../../models/api/comum/selectboxlazy-options";
import {
  InsumoDaOrdemDeProducaoAlterarRequest,
  InsumoDaOrdemDeProducaoResponseDTO,
} from "../../../../models/api/insumo-do-centro-de-trabalho/insumo-da-ordem-de-producao";
import {
  EstadoDaTerceirizacao,
  OrigemInsumo,
} from "../../../../models/api/insumo-do-centro-de-trabalho/insumo-da-ordem-de-producao-enums";
import { OrdemDeProducaoResponse } from "../../../../models/api/producao/ordem-de-producao/ordem-de-producao";
import { CallBackModal } from "../../../../models/shared/ui/callback-modal";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../services/comum/serviceBase";
import { EstoqueMovimentacaoService } from "../../../../services/estoque-movimentacoes/estoque-movimentacoes-service";
import { InsumoDaOrdemDeProducaoService } from "../../../../services/insumo-da-ordem-de-producao/insumo-da-ordem-de-producao";
import { ItemService } from "../../../../services/item/item.service";
import { OrdemDeProducaoService } from "../../../../services/producao/ordem-de-producao/ordem-de-producao.service";
import { checarResponse, tratarErroApi } from "../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../utils/common/notificacoes-utils";
import { obterFormatStringNumero } from "../../../../utils/formatadores/formatador-de-numeros";
import { GridItem } from "../../../itens/item/grids/grid-padrao";

const service = new InsumoDaOrdemDeProducaoService();
const serviceOrdemDeProducao = new OrdemDeProducaoService();
const serviceEstoqueMovimentacao = new EstoqueMovimentacaoService();
const serviceItem = new ItemService();

const exibeOperacaoDeOrdemDeProducao = (operacaoDeOrdemDeProducao: any) => {
  if (operacaoDeOrdemDeProducao) {
    return operacaoDeOrdemDeProducao.Descricao == null
      ? `${operacaoDeOrdemDeProducao.Numero}`
      : `${operacaoDeOrdemDeProducao.Numero} - ${operacaoDeOrdemDeProducao.Descricao}`;
  }

  return "";
};

const operacoesOrdemDeProducaoExpressaoBusca = ["Codigo", "Descricao"];

const exibeItem = (item: any) => {
  if (item) {
    return item.Descricao == null
      ? `${item.Codigo}`
      : `${item.Codigo} (${item.Descricao})`;
  }

  return "";
};

const itemExpressaoBusca = ["Codigo", "Descricao"];

interface FormularioInsercaoInsumosProps extends FormularioEdicaoBaseProps {
  ordemDeProducaoId: number;
}

export default function EditFormEdicaoInsumoDaOrdemDeProducao(
  props: FormularioInsercaoInsumosProps
) {
  const novoRegistro: InsumoDaOrdemDeProducaoAlterarRequest = {
    id: 0,
    ordemDeProducaoId: props.ordemDeProducaoId,
    operacaoId: NaN,
    itemId: NaN,
    quantidadeLiquida: 0,
    observacoes: null,
    ativo: true,
    origem: OrigemInsumo.Manual,
    itemPaiId: undefined,
    ordem: 0,
    perdaEmPercentual: 0,
    estoqueId: null,
    numeroDeSerieId: null,
    tipoDoEstoque: null,
    estadoDaTerceirizacao: EstadoDaTerceirizacao.SemTerceirizacao,
  };

  const [dadosAuditoria, setDadosAuditoria] = useState<AuditavelDTO>();
  const [carregando, setCarregando] = useState(false);
  const [itens, setItens] = useState<DataSource>();
  const [operacoesOrdemDeProducao, setOperacoesOrdemDeProducao] =
    useState<DataSource>();
  const [ordemDeProducao, setOrdemDeProducao] =
    useState<OrdemDeProducaoResponse>();
  const [insumoPossuiMovimentacoes, setInsumoPossuiMovimentacoes] =
    useState<boolean>();

  useEffectOnLoad(() => {
    carregarOrdemDeProducao();
    carregaItens();
    carregaOperacoesOrdemDeProducao();
  });

  function carregaItens() {
    setItens(
      serviceItem.GetDataSourceSelectBoxLazyItensInsumos({
        camposRetorno: ["Id", "Codigo", "Descricao"],
        camposOrdenacao: [
          {
            nomeCampo: "Codigo",
            desc: false,
          },
        ],
      })
    );
  }

  async function carregarOrdemDeProducao() {
    try {
      const resposta = await serviceOrdemDeProducao.obterPorIdComItem(
        props.ordemDeProducaoId
      );
      checarResponse(resposta);
      setOrdemDeProducao(resposta.model);
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  function carregaOperacoesOrdemDeProducao() {
    const filtros: SelectBoxLazyFiltros[] = [
      {
        nomeCampo: "OrdemDeProducaoId",
        operador: "=",
        valor: props.ordemDeProducaoId,
      },
    ];

    setOperacoesOrdemDeProducao(
      APIBase.getDataSourceSelectBoxLazy(
        {
          camposRetorno: ["Id", "OrdemDeProducaoId", "Numero", "Descricao"],
          filtros: filtros,
          camposOrdenacao: [
            {
              nomeCampo: "Numero",
              desc: false,
            },
          ],
        },
        NomesEndpoints.OperacaoDeOrdemDeProducao
      )
    );
  }

  const schema = yup.object().shape({});

  const { register, control, handleSubmit, getValues, reset } =
    useForm<InsumoDaOrdemDeProducaoResponseDTO>({
      mode: "onChange",
      reValidateMode: "onChange",
      resolver: yupResolver(schema),
    });

  useCarregarRegistro(props.idRegistroEdicao, carregarTela);

  // Usado para limpar o formulário se for NaN
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEdicao)) {
      reset(novoRegistro);
    }
  }, [props.idRegistroEdicao]);

  function fechar(info: CallBackModal) {
    if (props.callBackFecharModal) {
      setDadosAuditoria(undefined);
      props.callBackFecharModal(info);
    }
  }

  function handleCancelar() {
    fechar({ concluido: false, precisaAtualizar: false });
  }

  async function carregarTela() {
    try {
      setCarregando(true);
      if (props.idRegistroEdicao > 0) {
        await carregarModel();
      } else {
        reset(novoRegistro);
        setDadosAuditoria(undefined);
      }
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  async function carregarModel() {
    try {
      const resposta =
        await service.ObterPorIdComDadosAuditoria<InsumoDaOrdemDeProducaoResponseDTO>(
          props.idRegistroEdicao
        );
      checarResponse(resposta);
      reset(resposta.model);
      setDadosAuditoria(resposta.model);

      const insumoPossuiMovimentacao =
        await serviceEstoqueMovimentacao.VerificaSeInsumoPossuiMovimentacoes(
          props.idRegistroEdicao
        );
      setInsumoPossuiMovimentacoes(insumoPossuiMovimentacao);
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues();
    try {
      const resposta = await service.Atualizar(model);

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar({
          concluido: true,
          precisaAtualizar: true,
        });
      }
      fechar({
        concluido: true,
        precisaAtualizar: false,
      });
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  function callBackUnprocessableEntity() {
    fechar({
      concluido: false,
      precisaAtualizar: true,
    });
  }

  function formataExibicaoOrdemProducao() {
    return ordemDeProducao?.numero == null
      ? `${ordemDeProducao?.itemDescricao}`
      : `${ordemDeProducao?.numero} - ${ordemDeProducao?.itemDescricao}`;
  }

  function gerarDadosGridItens() {
    return serviceItem.GetGridSourceItensInsumos();
  }

  return (
    <>
      <FormBase2
        carregando={carregando}
        titulo={"Editar insumo"}
        onClickSalvar={handleSubmit(handleSalvar)}
        onClickCancelar={handleCancelar}
        visivel={props.visivel}
        modoEdicao={"não aplicável"}
        configuracoesModal={props.configuracoesModal}
        botaoSalvar={{ texto: "Salvar" }}
        auditoria={dadosAuditoria}
      >
        <ProvedorAjuda id="edit-form-insumo-da-ordem-de-producao">
          <input type="hidden" {...register("itemPaiId")} />
          <input type="hidden" {...register("ordem")} />
          <input type="hidden" {...register("ordemDeProducaoId")} />

          <Linha>
            <Coluna md={5}>
              <FormTextBoxSimples
                titulo="Ordem de produção"
                valor={formataExibicaoOrdemProducao()}
                somenteLeitura
              />
            </Coluna>
            <Coluna md={4}>
              <FormSelectBoxLazy
                name="operacaoId"
                titulo="Operação"
                control={control}
                requerido
                nomeCampoChave="Id"
                expressaoDeBusca={operacoesOrdemDeProducaoExpressaoBusca}
                nomeCampoExibicao={exibeOperacaoDeOrdemDeProducao}
                dataSource={operacoesOrdemDeProducao}
                labelSemDados="Sem dados"
              />
            </Coluna>
          </Linha>
          <Linha>
            <Coluna md={6}>
              <FormSelectBoxLazy
                name="itemId"
                titulo="Item"
                control={control}
                requerido
                nomeCampoChave="Id"
                expressaoDeBusca={itemExpressaoBusca}
                nomeCampoExibicao={exibeItem}
                dataSource={itens}
                lupaConfig={{
                  modo: "selecaoUnica",
                  titulo: "Selecionar item",
                  componente: (r) => (
                    <GridItem
                      funcaoGeradoraDeDados={gerarDadosGridItens()}
                      ref={r}
                    />
                  ),
                }}
                labelSemDados="Sem dados"
                desabilitado={insumoPossuiMovimentacoes}
              />
            </Coluna>
            <Coluna md={2}>
              <FormNumberBox
                name="quantidadeLiquida"
                titulo="Quantidade"
                formato={obterFormatStringNumero(5)}
                control={control}
                requerido
              />
            </Coluna>
          </Linha>
          <Linha>
            <Coluna md={12}>
              <FormTextArea
                name="observacoes"
                titulo={"Observações"}
                valor={""}
                control={control}
                height={15}
              />
            </Coluna>
          </Linha>
        </ProvedorAjuda>
      </FormBase2>
    </>
  );
}
