import getApi from "../../configs/api";
import { NomesEndpoints } from "../comum/nomesEndpoints";
import { ServiceBase } from "../comum/serviceBase";

export class EstoqueMovimentacaoService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.EstoqueMovimentacao);
  }

  public async VerificaSeInsumoPossuiMovimentacoes(idInsumo: number) {
    const api = getApi();
    const response = await api.get<boolean>(
      `${this._nomeEndpoint}/VerificaSeInsumoPossuiMovimentacoes?id=${idInsumo}`
    );

    return response.data;
  }
}
