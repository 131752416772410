import Pagina from "../../../components/layout/pagina";
import RequerPermissao from "../../../components/seguranca/requer-permissao";
import { PermissoesConjuntoVeiculo } from "../../../models/permissoes/vendas/conjunto-veiculo/permissoes-conjunto-veiculo";
import { GridConjuntoVeiculo } from "../../../parts/vendas/conjunto-veiculo/grid";
import NomesTelas from "../../../utils/common/nomes-telas";

export default function ConjuntoVeiculoPage() {
  return (
    <Pagina
      id="conjunto-veiculo-page"
      titulo={NomesTelas.conjuntoVeiculos}
      area={"Fiscal > Cadastros"}
    >
      <RequerPermissao codigoPermissoes={[PermissoesConjuntoVeiculo.Consultar]}>
        <GridConjuntoVeiculo />
      </RequerPermissao>
    </Pagina>
  );
}
