import { Column } from "devextreme-react/cjs/data-grid";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { MxpGrid } from "../../../../components/grid";
import { usePropagarReferenciaGrid } from "../../../../hooks/grid.hooks";
import { AdicaoExclusaoCompensacaoLalurGridModel } from "../../../../models/api/adicao-exclusao-compensacao/adicao-exclusao-compensacao-lalur-irpj/adicao-exclusao-compensacao-lalur-irpj";
import { IGridSelecao } from "../../../../models/shared/ui/formularios";
import AdicaoExclusaoCompensacaoLalurIrpjService from "../../../../services/adicao-exclusao-compensacao-lalur-irpj/adicao-exclusao-compensacao-lalur-irpj.service";
import criarNameof from "../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../utils/common/nomes-telas";
import { GridBaseProps } from "../../../../utils/grid/grid-utils";
import obterConfiguracaoColuna from "../../../../utils/grid/padroes-colunas";
import GetColunasDeAuditoria from "../../../layout/grid-defaults/colunasDeAuditoria";

const service = new AdicaoExclusaoCompensacaoLalurIrpjService();
const dataSource = service.ObterGridSource();

const nameOfGridHandler =
  criarNameof<AdicaoExclusaoCompensacaoLalurGridModel>();
const colunas = [
  <Column
    key={nameOfGridHandler("codigo")}
    dataField={nameOfGridHandler("codigo")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Código"
  />,
  <Column
    key={nameOfGridHandler("descricao")}
    dataField={nameOfGridHandler("descricao")}
    {...obterConfiguracaoColuna("stringGG")}
    caption="Descrição"
  />,
  <Column
    key={nameOfGridHandler("tipoDecodificado")}
    dataField={nameOfGridHandler("tipoDecodificado")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Tipo"
  />,
  ...GetColunasDeAuditoria(),
];

export const GridAdicaoExclusaoCompensacaoLalur = forwardRef(
  (props: GridBaseProps, ref) => {
    const gridRef = useRef<IGridSelecao>(null);
    useImperativeHandle(ref, () => gridRef.current as IGridSelecao);
    usePropagarReferenciaGrid(ref, gridRef);

    return (
      <MxpGrid<AdicaoExclusaoCompensacaoLalurGridModel>
        dataSource={dataSource}
        id={"veiculo"}
        ref={gridRef}
        colunas={colunas}
        nomeDoArquivoAoExportar={NomesTelas.adicaoExclusaoCompensacaoLalurIrpj}
        exibirIconeEditarMenuAcoes={false}
        exibirIconeExcluirMenuAcoes={false}
      />
    );
  }
);
