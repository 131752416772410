import { TagBox } from "devextreme-react";
import { LabelMode, SimplifiedSearchMode } from "devextreme/common";
import { Controller, FieldValues, UseControllerProps } from "react-hook-form";
import SelectItem from "../../../models/shared/ui/select-item";
import IconeAjuda from "../../ajuda/icone-ajuda";
import LinkAjuda from "../../ajuda/link-ajuda";
import { LabelErro } from "../label-erro";

interface FormMulltiSelectBoxProps<T extends FieldValues>
  extends UseControllerProps<T> {
  tabIndex?: number;
  titulo?: string;
  toolTip?: string;
  placeholder?: string;
  tipoPlaceholder?: LabelMode;
  exibirBotaoLimpar?: boolean;
  somenteLeitura?: boolean;
  desabilitado?: boolean;
  requerido?: boolean;
  labelSemDados?: string;
  dataSource?: SelectItem[];
  habilitaBusca?: boolean;
  tipoBusca?: SimplifiedSearchMode;
  onValueChange?: (value: any) => void;
  exibirLinkAjuda?: boolean;
  onEnter?: (e: any) => void;
  modoSelecao?: "lista" | "umPorUm" | "listaComBotaoOk";
}

export default function <T extends FieldValues>(
  props: FormMulltiSelectBoxProps<T>
) {
  return (
    <Controller
      {...props}
      render={({ field, fieldState }) => (
        <>
          <label htmlFor={field.name}>
            <span className="dx-field-item-label-content">
              <span className="dx-field-item-label-text">
                {props.titulo ? props.titulo : <br />}
              </span>
              {props.requerido && (
                <span className="dx-field-item-required-mark">&nbsp;*</span>
              )}
              {(props.exibirLinkAjuda ?? true) && (
                <LinkAjuda keyAjuda={field.name}>
                  <IconeAjuda />
                </LinkAjuda>
              )}
            </span>
          </label>
          <TagBox
            tabIndex={props.tabIndex}
            dataSource={props.dataSource}
            valueExpr="valor"
            displayExpr="descricao"
            noDataText={props.labelSemDados}
            hint={props.toolTip}
            label={props.placeholder}
            labelMode={props.tipoPlaceholder}
            readOnly={props.somenteLeitura}
            disabled={props.desabilitado}
            showClearButton={props.exibirBotaoLimpar}
            onEnterKey={props.onEnter}
            onValueChange={(value) => {
              props.onValueChange?.(value);
              field.onChange(value);
            }}
            onFocusOut={() => {
              field.onBlur();
            }}
            onSelectionChanged={(e) => {
              /* Serve para que o texto da pesquisa seja limpo após inserir um item. 
              A própria dev-express sugeriu isso em seus fóruns de suporte.*/
              if (e.addedItems.length > 0) {
                const textbox = e.component.field() as HTMLInputElement;
                textbox.value = "";

                if (document.activeElement?.id == textbox.id) {
                  e.component.getDataSource().searchValue("");
                  textbox.blur();
                  textbox.focus();
                }
              }
            }}
            value={field.value}
            validationStatus={fieldState.invalid ? "invalid" : "valid"}
            searchEnabled={props.habilitaBusca}
            searchMode={props.tipoBusca ?? "startswith"}
            inputAttr={{ id: field.name }}
            showSelectionControls={props.modoSelecao != "umPorUm"}
            applyValueMode={
              props.modoSelecao != "listaComBotaoOk"
                ? "instantly"
                : "useButtons"
            }
            showDropDownButton
          />
          <LabelErro>{fieldState.error?.message}</LabelErro>
        </>
      )}
    />
  );
}
