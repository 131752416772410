import { HttpStatusCode } from "axios";
import getApi from "../../configs/api";
import {
  ResponseBase,
  ResponseModel,
} from "../../models/api/comum/response-base";
import {
  EstoqueMovimentacaoGridModel,
  EstoqueMovimentacaoSelecionarGridModel,
} from "../../models/api/estoque-movimentacao/estoque-movimentacao";
import { EstoqueSelecionadoParaBaixaDeInsumo } from "../../models/api/estoque/estoque";
import {
  EstornarBaixasDoInsumoResponse,
  InsumoDaOrdemDeProducaoBaixarResponse,
} from "../../models/api/insumo-do-centro-de-trabalho/insumo-da-ordem-de-producao";
import { checarResponse, tratarErroApi } from "../../utils/api/api-utils";
import { criarDatasourceGridComParametros } from "../../utils/grid/back-end-grid-utils";
import { NomesEndpoints } from "../comum/nomesEndpoints";
import { ServiceBase } from "../comum/serviceBase";

export class InsumoDaOrdemDeProducaoService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.InsumoDaOrdemDeProducao);
  }

  public GetGridSourceFiltradoPorOp(parametros: object) {
    return criarDatasourceGridComParametros(
      `${this._nomeEndpoint}/grid-filtrado-por-op`,
      parametros
    );
  }

  public async BaixaAutomatica(
    insumoId: number,
    quantidadeParaBaixar: number,
    forcar: boolean
  ) {
    const api = getApi();
    const response = await api.post<
      ResponseModel<InsumoDaOrdemDeProducaoBaixarResponse>
    >(`${this._nomeEndpoint}/baixarAutomaticamente`, {
      insumoId: insumoId,
      quantidadeParaBaixar: quantidadeParaBaixar,
      forcarConclusao: forcar,
    });

    checarResponse(response.data);
    return response.data;
  }

  public async BaixarEstoquesSelecionadosParaInsumo(
    idInsumo: number,
    estoques: EstoqueSelecionadoParaBaixaDeInsumo[],
    forcarConclusao: boolean
  ) {
    const api = getApi();
    const response = await api.post<
      ResponseModel<InsumoDaOrdemDeProducaoBaixarResponse>
    >(`${this._nomeEndpoint}/baixarEstoquesSelecionadosParaInsumo`, {
      insumoId: idInsumo,
      estoquesSelecionadosParaBaixar: estoques,
      forcarConclusao: forcarConclusao,
    });

    checarResponse(response.data);
    return response.data;
  }

  public async EstornarBaixasDoInsumo(
    movimentacoes: EstoqueMovimentacaoSelecionarGridModel[]
  ) {
    const api = getApi();
    const response = await api.post<ResponseBase>(
      `${this._nomeEndpoint}/estornarBaixasDoInsumo`,
      {
        movimentacoes: movimentacoes.map((mov) => {
          return {
            movimentacaoId: mov.id,
            quantidade: mov.quantidadeParaEstornar,
          };
        }),
      }
    );

    checarResponse(response.data);
    return response.data;
  }

  public async ForcarConclusaoDeInsumo(insumoId: number) {
    const api = getApi();
    const response = await api.put<ResponseBase>(
      `${this._nomeEndpoint}/forcarConclusaoDeInsumo?insumoId=${insumoId}`
    );

    checarResponse(response.data);
    return response.data;
  }

  public async EstornarInsumo(insumoId: number, quantidade: number) {
    const api = getApi();
    try {
      const response = await api.post<ResponseBase>(
        `${this._nomeEndpoint}/estornarInsumo`,
        {
          insumoId: insumoId,
          quantidade: quantidade,
        }
      );

      checarResponse(response.data);
      return {
        sucesso: response.data.sucesso,
        mensagem: response.data.mensagem,
        precisaSelecionarMovs: false,
        movimentacoes: [],
      } as EstornarBaixasDoInsumoResponse;
    } catch (error) {
      const erro = error as any;
      // Tem mais de um insumo para estornar parcialmente
      if (erro?.response?.status == HttpStatusCode.PreconditionRequired) {
        const response = await api.get<
          ResponseModel<EstoqueMovimentacaoGridModel[]>
        >(
          `${this._nomeEndpoint}/gridMovimentacoesDeInsumo?insumoId=${insumoId}`
        );

        checarResponse(response.data);

        const movimentacoes = response.data.model
          .map((x) => x as EstoqueMovimentacaoSelecionarGridModel)
          .sort((a, b) => {
            return (
              new Date(b.dataDaMovimentacao).getTime() -
              new Date(a.dataDaMovimentacao).getTime()
            );
          });
        let quantidadeParaEstornar = quantidade;
        movimentacoes.forEach((x) => {
          x.quantidadeParaEstornar = Math.min(
            x.quantidade,
            quantidadeParaEstornar
          );
          quantidadeParaEstornar -= x.quantidadeParaEstornar;
        });

        return {
          sucesso: response.data.sucesso,
          mensagem: response.data.mensagem,
          precisaSelecionarMovs: true,
          movimentacoes: movimentacoes,
        } as EstornarBaixasDoInsumoResponse;
      }

      tratarErroApi(error);

      return {
        sucesso: false,
        mensagem:
          (error as any)?.response?.data?.mensagem ?? "Erro ao estornar insumo",
        precisaSelecionarMovs: false,
        movimentacoes: [],
      } as EstornarBaixasDoInsumoResponse;
    }
  }

  public async EstornarInsumos(insumosIds: number[]) {
    const api = getApi();
    try {
      const response = await api.post<ResponseBase>(
        `${this._nomeEndpoint}/estornarInsumos`,
        {
          insumosIds: insumosIds,
        }
      );

      checarResponse(response.data);
      return response.data;
    } catch (error) {
      tratarErroApi(error);

      return {
        sucesso: false,
        mensagem:
          (error as any)?.response?.data?.mensagem ??
          "Erro ao estornar insumos",
      } as ResponseBase;
    }
  }
}
