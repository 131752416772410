import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks/store.hooks";
import {
  efetuarLogoff,
  efetuarLogoffPayload,
} from "../../../services/tokens/tokens.service";
import {
  bloquearUI,
  desabilitarToasts,
  desbloquearUI,
  habilitarToasts,
} from "../../../store/ui/ui.slice";
import { checarResponse, tratarErroApi } from "../../../utils/api/api-utils";
import { aguardar } from "../../../utils/common/common-utils";
import { RemoverSessaoReduxELocalStorage } from "../../../utils/oauth/oauth-utils";

export default function LogoutRedirect() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let payload = searchParams.get("payload");

  if (!payload) {
    payload =
      useAppSelector((state) => state.sessao.dadosSessao?.chaveSessao) ?? null;
  }

  useEffect(() => {
    dispatch(desabilitarToasts());
    executarLogout();
  }, []);

  async function executarLogout() {
    dispatch(bloquearUI("Saindo..."));

    try {
      const resposta = payload
        ? await efetuarLogoffPayload(payload)
        : await efetuarLogoff();
      checarResponse(resposta);
      RemoverSessaoReduxELocalStorage();
      navigate("/", { replace: true });
    } catch (erro) {
      dispatch(habilitarToasts());
      tratarErroApi(erro);
    } finally {
      dispatch(desbloquearUI());
      await aguardar(2000);
      dispatch(habilitarToasts());
    }
  }

  return <></>;
}
