import { yupResolver } from "@hookform/resolvers/yup";
import DataSource from "devextreme/data/data_source";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
  FormDateBox,
  FormNumberBox,
  FormSelectBox,
  FormSelectBoxLazy,
} from "../../../../../components/formularios";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import TabContainer from "../../../../../components/layout/tab-container";
import { useCarregarCombos } from "../../../../../hooks/form.hooks";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/store.hooks";
import {
  ModalidadeTransporteMDFe,
  TipoEmitenteMDFe,
  TipoTransportadorMDFe,
} from "../../../../../models/api/mdfe/mdfe-enums";
import { TipoDocumentoEletronico } from "../../../../../models/api/serie-fiscal/serie-fiscal";
import {
  SelectItemEnumModalidadeTransporteMDFe,
  SelectItemEnumTipoEmitenteMDFe,
  SelectItemEnumTipoTransportadorMDFe,
} from "../../../../../models/const/dicionario-combos/mdfe";
import { IFormulario } from "../../../../../models/shared/ui/formularios";
import SelectItem from "../../../../../models/shared/ui/select-item";
import { MDFeAbaGeralViewModel } from "../../../../../models/viewmodels/vendas/mdfe/mdfe-edit-form-view-model";
import { NomesEndpoints } from "../../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../../services/comum/serviceBase";
import APISerieFiscal from "../../../../../services/serie-fiscal/serie-fiscal.service";
import {
  definirDadosGerais,
  definirTipoTransportador,
} from "../../../../../store/mdfe/mdfe.slice";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import { checarSeFormFoiModificado } from "../../../../../utils/common/form-utils";
import { obterFormatStringNumero } from "../../../../../utils/formatadores/formatador-de-numeros";
import { GridEmpresa } from "../../../../empresas/empresa/grids";

interface DadosGeraisProps {
  idRegistro: number;
  abaSomenteLeitura: boolean;
}

const contratantesExprecaoBusca = ["Apelido", "CnpjCpfCodigo"];

const exibeContratante = (c: any) => {
  if (c) {
    return c.CnpjCpfCodigo ? `${c.Apelido} - ${c.CnpjCpfCodigo}` : c.Apelido;
  }

  return "";
};

const schema = yup.object().shape({
  id: yup.number().required().moreThan(-1).integer(),
  serie: yup.string().required("Campo obrigatório.").max(3),
  numero: yup
    .number()
    .nullable()
    .positive()
    .integer()
    .max(9999999999)
    .transform((valor) => (valor ? valor : null)),
  dataHoraEmissao: yup
    .string() //Tratado como string pois o redux não lida com Date
    .required()
    .transform((valor) => (valor ? valor : null)),
  modalidadeTransporte: yup
    .mixed<ModalidadeTransporteMDFe>()
    .transform((v) => (v ? v : null))
    .oneOf(
      Object.values(ModalidadeTransporteMDFe).map((x) => x as number),
      "Valor inválido"
    )
    .required(),
  idContratante: yup
    .number()
    .required("Campo obrigatório.")
    .moreThan(0, "Campo obrigatório.")
    .integer(),
  tipoTransportador: yup
    .mixed<TipoTransportadorMDFe>()
    .nullable()
    .oneOf(
      Object.values(TipoTransportadorMDFe).map((x) => x as number),
      "Valor inválido"
    ),
  tipoEmitente: yup
    .mixed<TipoEmitenteMDFe>()
    .transform((v) => (v ? v : null))
    .oneOf(
      Object.values(TipoEmitenteMDFe).map((x) => x as number),
      "Valor inválido"
    )
    .required(),
  dataHoraInicioViagem: yup
    .string() //Tratado como string pois o redux não lida com Date
    .notRequired()
    .nullable()
    .transform((valor) => (valor ? valor : null)),
});

// Cria um componente referenciável
export const MDFeAbaDadosGerais = forwardRef(
  ({ idRegistro, abaSomenteLeitura }: DadosGeraisProps, ref) => {
    const model: MDFeAbaGeralViewModel = useAppSelector((state) => {
      const doc = state.mdfe.documentoAtual;
      return {
        id: doc.id,
        serie: doc.serie,
        numero: doc.numero,
        dataHoraEmissao: doc.dataHoraEmissao,
        modalidadeTransporte: doc.modalidadeTransporte,
        tipoTransportador: doc.tipoTransportador,
        tipoEmitente: doc.tipoEmitente,
        dataHoraInicioViagem: doc.dataHoraInicioViagem,
        idUfInicio: doc.idUfInicio,
        idUfFim: doc.idUfFim,
        idContratante: doc.modalRodoviario.contratante?.idContratante,
        cepLocalCarregamento: doc.cepLocalCarregamento,
        cepLocalDescarregamento: doc.cepLocalDescarregamento,
      };
    });

    const [seriesFiscais, setSerieFiscal] = useState<SelectItem[]>([]);
    const [contratantes, setContratantes] = useState<DataSource | undefined>();

    useCarregarCombos(idRegistro, carregarTela);

    useEffect(() => {
      // Adicionado pois o form não é atualizado conforme mudança no redux.
      setValue("numero", model.numero);
    }, [model.numero]);

    useEffect(() => {
      setValue("dataHoraEmissao", model.dataHoraEmissao);
    }, [model.dataHoraEmissao]);

    async function carregarTela() {
      reset(model);
      carregarSeriesFiscais();
      carregarContratantes();
    }

    async function carregarSeriesFiscais() {
      try {
        const resposta = await APISerieFiscal.obterListaSimples(
          TipoDocumentoEletronico.MDFe
        );
        checarResponse(resposta);
        setSerieFiscal(
          resposta.model.map((x) => ({
            valor: x.descricao,
            descricao: x.descricao,
          }))
        );
      } catch (erro) {
        tratarErroApi(erro);
      }
    }

    async function carregarContratantes() {
      setContratantes(
        APIBase.getDataSourceSelectBoxLazy(
          {
            camposRetorno: ["Id", "Apelido", "CnpjCpfCodigo"],
            camposOrdenacao: [
              {
                nomeCampo: "Apelido",
                desc: false,
              },
            ],
          },
          NomesEndpoints.Empresa
        )
      );
    }

    const dispatch = useAppDispatch();

    const { setValue, reset, control, formState, handleSubmit } =
      useForm<MDFeAbaGeralViewModel>({
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: yupResolver(schema),
      });

    let form: HTMLFormElement | null;

    function handleSubmitInterno(data: MDFeAbaGeralViewModel) {
      dispatch(definirDadosGerais(data));
    }

    // Repassar referências para componente pai
    useImperativeHandle(
      ref,
      (): IFormulario => ({
        requestSubmit() {
          form?.requestSubmit();
        },
        valido() {
          form?.requestSubmit();
          return Object.keys(formState.errors).length == 0;
        },
        isDirty() {
          return checarSeFormFoiModificado(formState);
        },
      })
    );

    function onChangeTipoTransportador(e: TipoTransportadorMDFe) {
      dispatch(definirTipoTransportador(e));
    }

    return (
      <TabContainer>
        <form
          ref={(ref) => (form = ref)}
          onSubmit={handleSubmit(handleSubmitInterno)}
        >
          <Linha>
            <Coluna lg={3} md={6}>
              <FormNumberBox
                name="numero"
                titulo="Número"
                control={control}
                minimo={0}
                tipo="number"
                maximo={9999999999}
                formato={obterFormatStringNumero(0)}
                exibirBotaoLimpar
                somenteLeitura={true}
              />
            </Coluna>
            <Coluna lg={2} md={6}>
              <FormSelectBox
                name="serie"
                titulo="Série"
                toolTip="Série"
                control={control}
                requerido
                dataSource={seriesFiscais}
                habilitaBusca
                tipoBusca="contains"
                somenteLeitura={abaSomenteLeitura}
              />
            </Coluna>
            <Coluna lg={4} md={6}>
              <FormDateBox
                name="dataHoraEmissao"
                titulo="Emissão"
                toolTip="Emissão"
                control={control}
                requerido
                exibirBotaoLimpar
                tipo="date"
                somenteLeitura={abaSomenteLeitura}
                aceitaValorCustomizado={true}
                formatoExibicao="dd/MM/yy"
                aceitaDigitacaoComMascara={true}
              />
            </Coluna>
            <Coluna lg={3} md={6}>
              <FormSelectBox
                name="modalidadeTransporte"
                titulo="Modalidade"
                toolTip="Modalidade"
                control={control}
                requerido
                dataSource={SelectItemEnumModalidadeTransporteMDFe}
                habilitaBusca
                tipoBusca="contains"
                somenteLeitura
              />
            </Coluna>
          </Linha>
          <Linha>
            <Coluna lg={6} md={8}>
              <FormSelectBoxLazy
                name="idContratante"
                titulo="Contratante"
                nomeCampoChave="Id"
                nomeCampoExibicao={exibeContratante}
                expressaoDeBusca={contratantesExprecaoBusca}
                control={control}
                requerido
                lupaConfig={{
                  modo: "selecaoUnica",
                  titulo: "Selecionar Empresa",
                  componente: (r) => <GridEmpresa ref={r} />,
                }}
                dataSource={contratantes}
                labelSemDados="Sem dados"
                somenteLeitura={abaSomenteLeitura}
              />
            </Coluna>
            <Coluna lg={3} md={4}>
              <FormSelectBox
                name="tipoTransportador"
                titulo="Tipo do transportador"
                toolTip="Tipo do transportador"
                control={control}
                dataSource={SelectItemEnumTipoTransportadorMDFe}
                habilitaBusca
                tipoBusca="contains"
                somenteLeitura={abaSomenteLeitura}
                onValueChange={onChangeTipoTransportador}
              />
            </Coluna>
            <Coluna lg={3} md={6}>
              <FormSelectBox
                name="tipoEmitente"
                titulo="Tipo do emitente"
                toolTip="Tipo do emitente"
                control={control}
                requerido
                dataSource={SelectItemEnumTipoEmitenteMDFe}
                habilitaBusca
                tipoBusca="contains"
                somenteLeitura={true}
              />
            </Coluna>
            <Coluna lg={3} md={6}>
              <FormDateBox
                name="dataHoraInicioViagem"
                titulo="Início da viagem"
                toolTip="Início da viagem"
                control={control}
                exibirBotaoLimpar
                tipo="date"
                somenteLeitura={abaSomenteLeitura}
                formatoExibicao="dd/MM/yy"
                aceitaDigitacaoComMascara={true}
              />
            </Coluna>
          </Linha>
        </form>
      </TabContainer>
    );
  }
);

export default MDFeAbaDadosGerais;
