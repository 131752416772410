import { Column } from "devextreme-react/data-grid";
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { renderToString } from "react-dom/server";
import { MxpGrid } from "../../../../../components/grid";
import {
  useEditarRegistroGrid,
  useExcluirRegistroGrid,
  useNovoRegistroGrid,
  usePropagarReferenciaGrid,
} from "../../../../../hooks/grid.hooks";
import { SerieFiscalGridModel } from "../../../../../models/api/serie-fiscal/serie-fiscal";
import { CallBackModal } from "../../../../../models/shared/ui/callback-modal";
import { IGridSelecao } from "../../../../../models/shared/ui/formularios";
import { EditFormSerieFiscal } from "../../../../../pages/fiscal/serie-fiscal/edit-form";
import { NomesEndpoints } from "../../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../../services/comum/serviceBase";
import {
  checarResponseExibeMensagemExclusaoDeSucesso,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import { GridBaseProps } from "../../../../../utils/grid/grid-utils";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import GetColunasDeAuditoria from "../../../../layout/grid-defaults/colunasDeAuditoria";

const nomeEndpoint = NomesEndpoints.SerieFiscal;
const dataSource = APIBase.getGridSource(nomeEndpoint);
const nameOfGridHandler = criarNameof<SerieFiscalGridModel>();
const colunas = [
  <Column
    key={nameOfGridHandler("serie")}
    dataField={nameOfGridHandler("serie")}
    {...obterConfiguracaoColuna("stringP")}
    sortOrder="asc"
    caption="Série"
  />,
  <Column
    key={nameOfGridHandler("proximoNumero")}
    dataField={nameOfGridHandler("proximoNumero")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Próximo número"
  />,
  <Column
    key={nameOfGridHandler("tipoDocumentoEletronicoDecodificado")}
    dataField={nameOfGridHandler("tipoDocumentoEletronicoDecodificado")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Tipo de documento fiscal"
  />,
  <Column
    key={nameOfGridHandler("observacoes")}
    dataField={nameOfGridHandler("observacoes")}
    {...obterConfiguracaoColuna("stringGG")}
    caption="Observações"
    allowFiltering={false}
    allowSorting={false}
    visible={false}
  />,
  ...GetColunasDeAuditoria(),
];

export const GridSerieFiscal = forwardRef((props: GridBaseProps, ref) => {
  const gridRef = useRef<IGridSelecao>(null);
  useImperativeHandle(ref, () => gridRef.current as IGridSelecao);
  usePropagarReferenciaGrid(ref, gridRef);

  const [modalEdicaoVisivel, setModalEdicaoVisivel] = useState(false);
  const [idRegistroEdicao, setIdRegistroEdicao] = useState(NaN);

  function handleAtualizarGrid() {
    if (gridRef.current?.atualizarGrid) {
      gridRef.current?.atualizarGrid();
    }
  }

  const handleNovoRegistro = useNovoRegistroGrid(() => {
    setIdRegistroEdicao(0);
    setModalEdicaoVisivel(true);
  });

  const handleEditarRegistro = useEditarRegistroGrid((id: number) => {
    setIdRegistroEdicao(id);
    setModalEdicaoVisivel(true);
  });

  const handleExcluirRegistro = useExcluirRegistroGrid(
    async (registro: SerieFiscalGridModel) => {
      const mensagem = renderToString(<>{obterMensagemExclusao(registro)}</>);
      const excluir = await exibirConfirmacao("Confirmar exclusão", mensagem);

      if (excluir) {
        try {
          const resposta = await APIBase.excluir(registro.id, nomeEndpoint);

          if (resposta) {
            checarResponseExibeMensagemExclusaoDeSucesso(resposta);
            handleAtualizarGrid();
          }
        } catch (erro) {
          tratarErroApi(erro);
        }
      }
    }
  );

  function obterMensagemExclusao(registro: SerieFiscalGridModel) {
    return `Tem certeza de que deseja excluir a série fiscal ${registro.serie}?`;
  }

  const handleModalCallback = useCallback((info: CallBackModal) => {
    setModalEdicaoVisivel(false);
    setIdRegistroEdicao(NaN);

    if (info.precisaAtualizar) {
      handleAtualizarGrid();
    }
  }, []);

  return (
    <>
      <MxpGrid<SerieFiscalGridModel>
        dataSource={dataSource}
        id={"natureza-do-rendimento"}
        ref={gridRef}
        colunas={colunas}
        novoRegistro={handleNovoRegistro}
        editarRegistro={handleEditarRegistro}
        excluirRegistro={handleExcluirRegistro}
        nomeDoArquivoAoExportar={NomesTelas.serieDeDocumentosFiscais}
      />
      <EditFormSerieFiscal
        visivel={modalEdicaoVisivel}
        idRegistroEdicao={idRegistroEdicao}
        configuracoesModal={{ largura: "max(30vw, 600px)", altura: "auto" }}
        callBackFecharModal={handleModalCallback}
      />
    </>
  );
});
