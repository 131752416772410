import { useState } from "react";
import Pagina from "../../../components/layout/pagina";
import RequerPermissao from "../../../components/seguranca/requer-permissao";
import { PermissoesApuracaoLalurIrpj } from "../../../models/permissoes/fiscal/apuracao-lalur-irpj/permissoes-apuracao-lalur-irpj";
import { GridApuracaoLalurIrpj } from "../../../parts/fiscal/apuracao-lalur-irpj/grid";
import NomesTelas from "../../../utils/common/nomes-telas";
import { ItemContextMenu } from "../../../utils/context-menu/context-menu-utils";

export default function ApuracaoLalurIrpjPage() {
  const [menus, setMenus] = useState<ItemContextMenu[]>();

  return (
    <Pagina
      id="apuracao-lalur-irpj-page"
      area={"Fiscal > Apurações"}
      titulo={NomesTelas.apuracaoLalurIrpj}
      subMenuItems={menus}
    >
      <RequerPermissao
        codigoPermissoes={[PermissoesApuracaoLalurIrpj.Consultar]}
      >
        <GridApuracaoLalurIrpj definirMenu={setMenus} />
      </RequerPermissao>
    </Pagina>
  );
}
