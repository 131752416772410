export interface EmpresaGridModel {
  id: number;
  inscricaoEstadual: string;
  assinanteId: number;
  idAssinanteCliente: number | null;
  razaoSocial: string;
  nomeFantasia: string;
  representante: boolean;
  parceiro: boolean;
  concorrente: boolean;
  transportadora: boolean;
  propria: boolean;
  cadastro: string;
  inscricaoNaSUFRAMA: string;
  emailNFe: string;
  observacoes: string;
  homepage: string;
  formaPagamento: number | null;
  formaCobranca: number | null;
  codigoCondPagamento: string;
  condicaoDePagamentoPadrao: string;
  classeCliente: string;
  cliente: boolean;
  crmProdutos: string;
  crmFornecedor: string;
  crmIdEstado: number | null;
  crmUltimoTelefonema: string | null;
  crmUltimaVisita: string | null;
  crmDataEstado: string | null;
  crmIdResponsavel: number | null;
  estadoCadastro: string;
  dataInsercao: string;
  idRepresentanteVendedor: number | null;
  idRepresentanteVendedor2: number | null;
  idRepresentanteVendedor3: number | null;
  limiteCredito: number;
  ultimaAlteracaoData: string | null;
  prospectivo: string;
  apelido: string;
  fornecedor: boolean;
  _btnImprimir: string;
  selected: boolean;
  endereco: string;
  numero: string;
  complemento: string;
  bairro: string;
  telefone1: string;
  telefone2: string;
  telefone3: string;
  email: string;
  municipio: string;
  uf: string;
  pais: string;
  codigoEndereco: string;
  codigoProbabilidadeNegocio: string;
  descricaoSegmento: string;
  descricaoRegiao: string;
  descricaoPerfil: string;
  descricaoFormaPedido: string;
  codigoAtencao: string;
  codigoTamanho: string;
  codigoEstado: string;
  codigoResponsavelCrm: string;
  apelidoRepresentante: string;
  apelidoRepresentante2: string;
  apelidoRepresentante3: string;
  titulosVencidos: number;
  dataEmissaoUltimoPedidoVenda: string | null;
  dataEmissaoUltimaNotaFiscalVenda: string | null;
  situacaoFiscalEspecial: string;
  crmUltimoAcessoValido: string | null;
  crmQuantidadeDeAcessosValidos: number | null;
  cnpjCpfCodigo: string;
  _regimeTributario: number;
  nomeUsuarioCriador: string;
  apelidoUsuarioUltimaAlteracao: string;
  idsCotacoesFornecedor: string;
  numerosCotacoesFornecedor: string;
  creditoUtilizado: number;
}

export enum TipoEmpresasFiltro {
  SemEspecificao = 0,
  Proprias = 1,
}
