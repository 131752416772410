import { useState } from "react";
import * as yup from "yup";
import ProvedorAjuda from "../../../../../components/ajuda/provedor-ajuda";
import { FormNumberBox } from "../../../../../components/formularios";
import FormTextArea from "../../../../../components/formularios/textarea";
import {
  FormBase2,
  FormularioEdicaoBaseProps,
} from "../../../../../components/layout/form-base2";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import {
  useCarregarRegistro,
  useHookForms,
  useLimparFormSeIdForNaN,
} from "../../../../../hooks/form.hooks";
import AuditavelDTO from "../../../../../models/api/comum/auditavel-dto";
import {
  NaturezaDoRendimentoRequestDTO,
  NaturezaDoRendimentoResponseDTO,
} from "../../../../../models/api/efd-reinf/natureza-do-rendimento/natureza-do-rendimento";
import { CallBackModal } from "../../../../../models/shared/ui/callback-modal";
import { NomesEndpoints } from "../../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../../services/comum/serviceBase";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import NomesModais from "../../../../../utils/common/nomes-modais";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { NUMERO_INTEIRO_FORMATADOR } from "../../../../../utils/formatadores/formatador-de-numeros";

const novoRegistro: NaturezaDoRendimentoRequestDTO = {
  id: 0,
  codigo: "",
  descricao: null,
};

const nomeEndpoint = NomesEndpoints.NaturezaDoRendimento;

export const EditFormNaturezaDoRendimento = (
  props: FormularioEdicaoBaseProps
) => {
  const [carregando, setCarregando] = useState(false);
  const [dadosAuditoria, setDadosAuditoria] = useState<AuditavelDTO>();

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    codigo: yup.number().required().moreThan(-1).lessThan(100_000).integer(),
    descricao: yup.string().nullable().max(430),
  });

  const hookForm = useHookForms(schema);
  useCarregarRegistro(props.idRegistroEdicao, carregarTela);
  useLimparFormSeIdForNaN(hookForm, novoRegistro, props.idRegistroEdicao);

  const { register, control, handleSubmit, getValues, reset } = hookForm;

  async function carregarTela() {
    try {
      setCarregando(true);
      if (props.idRegistroEdicao > 0) {
        await carregarModel();
      } else {
        reset(novoRegistro);
        setDadosAuditoria(undefined);
      }
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  async function carregarModel() {
    try {
      const resposta =
        await APIBase.obterPorIdComDadosAuditoria<NaturezaDoRendimentoResponseDTO>(
          props.idRegistroEdicao,
          nomeEndpoint
        );
      checarResponse(resposta);
      reset(resposta.model);
      setDadosAuditoria(resposta.model);
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    }
  }

  function handleCancelar() {
    fechar({ concluido: false, precisaAtualizar: false });
  }

  function fechar(info: CallBackModal) {
    if (props.callBackFecharModal) {
      props.callBackFecharModal(info);
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues();
    try {
      const resposta =
        props.idRegistroEdicao > 0
          ? await APIBase.atualizar(model, nomeEndpoint)
          : await APIBase.cadastrar(model, nomeEndpoint);

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar({
          concluido: true,
          precisaAtualizar: true,
        });
      }
      fechar({
        concluido: true,
        precisaAtualizar: false,
      });
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  function callBackUnprocessableEntity() {
    fechar({
      concluido: false,
      precisaAtualizar: true,
    });
  }

  return (
    <>
      <div id="edit-form-natureza-do-rendimento">
        <FormBase2
          visivel={props.visivel}
          carregando={carregando}
          onClickSalvar={handleSubmit(handleSalvar)}
          onClickCancelar={handleCancelar}
          configuracoesModal={props.configuracoesModal}
          modoEdicao={props.idRegistroEdicao == 0 ? "criar" : "editar"}
          titulo={NomesModais.naturezaDoRendimento}
          auditoria={dadosAuditoria}
          isNomeTelaFeminino={true}
        >
          <ProvedorAjuda id="edit-form-natureza-do-rendimento">
            <input type="hidden" {...register("id")} defaultValue={0} />
            <Linha>
              <Coluna md={3}>
                <FormNumberBox
                  name="codigo"
                  titulo={"Código"}
                  requerido
                  control={control}
                  formato={NUMERO_INTEIRO_FORMATADOR}
                  minimo={0}
                  maximo={99_999}
                />
              </Coluna>
            </Linha>
            <Linha>
              <Coluna md={12}>
                <FormTextArea
                  name="descricao"
                  titulo="Descrição"
                  valor={""}
                  control={control}
                  tamanhoMaximo={430}
                  height={20}
                />
              </Coluna>
            </Linha>
          </ProvedorAjuda>
        </FormBase2>
      </div>
    </>
  );
};
