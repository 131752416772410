import { Column } from "devextreme-react/cjs/data-grid";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { renderToString } from "react-dom/server";
import { MxpGrid } from "../../../../components/grid";
import {
  useEditarRegistroGrid,
  useExcluirRegistroGrid,
  useGerarItensAdicionais,
  useNovoRegistroGrid,
  usePropagarReferenciaGrid,
} from "../../../../hooks/grid.hooks";
import { ApuracaoLacsCsllGridModel } from "../../../../models/api/apuracao-lacs-csll/apuracao-lacs-csll";
import { PermissoesApuracaoLacsCsll } from "../../../../models/permissoes/fiscal/apuracao-lacs-csll/permissoes-apuracao-lacs-csll";
import { CallBackModal } from "../../../../models/shared/ui/callback-modal";
import { IGridSelecao } from "../../../../models/shared/ui/formularios";
import { ApuracaoLacsCsllService } from "../../../../services/apuracao-lacs-csll/apuracao-lacs-csll.service";
import {
  checarResponseExibeMensagemExclusaoDeSucesso,
  checarResponseExibeMensagemExecutadoComSucesso,
} from "../../../../utils/api/api-utils";
import criarNameof from "../../../../utils/common/cria-name-of";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../utils/common/permissoes-utils";
import { exibirConfirmacao } from "../../../../utils/dialogos";
import { formatarData } from "../../../../utils/formatadores/formatador-de-datas";
import { GridBaseProps } from "../../../../utils/grid/grid-utils";
import obterConfiguracaoColuna from "../../../../utils/grid/padroes-colunas";
import GetColunasDeAuditoria from "../../../layout/grid-defaults/colunasDeAuditoria";
import EditFormAlteracaoApuracaoLacsCsll from "../form-edicao";
import EditFormInsercaoApuracaoLacsCsll from "../form-insercao";

const apuracaoLacsCsllService = new ApuracaoLacsCsllService();
const dataSource = apuracaoLacsCsllService.GetGridSource();
const nameOfGridHandler = criarNameof<ApuracaoLacsCsllGridModel>();

const colunas = [
  <Column
    key={nameOfGridHandler("dataInicial")}
    dataField={nameOfGridHandler("dataInicial")}
    {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
    caption="Data inicial"
    sortIndex={0}
    sortOrder="desc"
    width={140}
  />,
  <Column
    key={nameOfGridHandler("dataFinal")}
    dataField={nameOfGridHandler("dataFinal")}
    {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
    caption="Data final"
    sortIndex={1}
    sortOrder="desc"
    width={140}
  />,
  <Column
    key={nameOfGridHandler("lucroOuPrejuizo")}
    dataField={nameOfGridHandler("lucroOuPrejuizo")}
    {...obterConfiguracaoColuna("monetario")}
    caption="Lucro do período antes do CSLL"
    width={230}
  />,
  <Column
    key={nameOfGridHandler("adicoes")}
    dataField={nameOfGridHandler("adicoes")}
    {...obterConfiguracaoColuna("monetario")}
    caption="Adições (+)"
    width={120}
  />,
  <Column
    key={nameOfGridHandler("exclusoes")}
    dataField={nameOfGridHandler("exclusoes")}
    {...obterConfiguracaoColuna("monetario")}
    caption="Exclusões (-)"
    width={120}
  />,
  <Column
    key={nameOfGridHandler("baseCalculoAntesCompensacao")}
    dataField={nameOfGridHandler("baseCalculoAntesCompensacao")}
    {...obterConfiguracaoColuna("monetario")}
    caption="Base de cálculo antes das compensações de prejuízos anteriores"
    width={430}
  />,
  <Column
    key={nameOfGridHandler("compensacoes")}
    dataField={nameOfGridHandler("compensacoes")}
    {...obterConfiguracaoColuna("monetario")}
    caption="Compensações"
    width={140}
  />,
  <Column
    key={nameOfGridHandler("baseCalculo")}
    dataField={nameOfGridHandler("baseCalculo")}
    {...obterConfiguracaoColuna("monetario")}
    caption="Base de cálculo do CSLL"
    width={190}
  />,
  <Column
    key={nameOfGridHandler("contribuicaoSocialApurada")}
    dataField={nameOfGridHandler("contribuicaoSocialApurada")}
    {...obterConfiguracaoColuna("monetario")}
    caption="Contribuição social apurada à alíquota de 9%"
    width={310}
  />,
  <Column
    key={nameOfGridHandler("contribuicaoSocialAPagar")}
    dataField={nameOfGridHandler("contribuicaoSocialAPagar")}
    {...obterConfiguracaoColuna("monetario")}
    caption="Contribuição social a pagar"
    width={200}
  />,
  ...GetColunasDeAuditoria(),
];

export const GridApuracaoLacsCsll = forwardRef((props: GridBaseProps, ref) => {
  const gridRef = useRef<IGridSelecao>(null);
  useImperativeHandle(ref, () => gridRef.current as IGridSelecao);
  usePropagarReferenciaGrid(ref, gridRef);

  const [modalInsercaoVisivel, setModalInsercaoVisivel] = useState(false);
  const [idRegistroEdicao, setIdRegistroEdicao] = useState(NaN);
  const [modalEdicaoVisivel, setModalEdicaoVisivel] = useState(false);

  function handleAtualizarGrid() {
    if (gridRef.current?.atualizarGrid) {
      gridRef.current?.atualizarGrid();
    }
  }

  const handleNovoRegistro = useNovoRegistroGrid(() => {
    if (
      !verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesApuracaoLacsCsll.InserirEditar,
      ])
    ) {
      return;
    }
    setIdRegistroEdicao(0);
    setModalInsercaoVisivel(true);
  });

  const handleEditarRegistro = useEditarRegistroGrid((id) => {
    if (
      !verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesApuracaoLacsCsll.InserirEditar,
      ])
    ) {
      return;
    }
    setIdRegistroEdicao(id);
    setModalEdicaoVisivel(true);
  });

  const handleExcluirRegistro = useExcluirRegistroGrid(
    async (registro: ApuracaoLacsCsllGridModel) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesApuracaoLacsCsll.Excluir,
        ])
      ) {
        return;
      }
      const mensagem = renderToString(<>{obterMensagemExclusao(registro)}</>);
      const excluir = await exibirConfirmacao("Confirmar exclusão", mensagem);

      if (excluir) {
        const resposta = await apuracaoLacsCsllService.Excluir(registro.id);

        if (resposta) {
          checarResponseExibeMensagemExclusaoDeSucesso(resposta);
          handleAtualizarGrid();
        }
      }
    }
  );

  function obterMensagemExclusao(registro: ApuracaoLacsCsllGridModel) {
    return `Tem certeza que deseja excluir a apuração de ${formatarData(
      registro.dataInicial
    )} a ${formatarData(registro.dataFinal)}?`;
  }

  function handleModalInsercaoCallback(info: CallBackModal) {
    setModalInsercaoVisivel(false);
    setIdRegistroEdicao(NaN);

    if (info.precisaAtualizar) {
      handleAtualizarGrid();
    }
  }

  function handleModalEdicaoCallback(info: CallBackModal) {
    setModalEdicaoVisivel(false);
    setIdRegistroEdicao(NaN);

    if (info.precisaAtualizar) {
      handleAtualizarGrid();
    }
  }

  async function handleRecalcular(id: number) {
    if (
      !verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesApuracaoLacsCsll.InserirEditar,
      ])
    ) {
      return;
    }

    const recalcular = await exibirConfirmacao(
      "Confirmar recálculo",
      "Tem certeza de que deseja recalcular a apuração? As informações serão apagadas e recalculadas."
    );

    if (recalcular) {
      const resultado = await apuracaoLacsCsllService.Recalcular(id);
      checarResponseExibeMensagemExecutadoComSucesso(resultado);
      if (resultado.sucesso) {
        handleAtualizarGrid();
      }
    }
  }

  const handleGerarItensAdicionais = useGerarItensAdicionais(
    (getData: () => ApuracaoLacsCsllGridModel | undefined) => {
      const itensAdicionais = [
        {
          text: "Ações",
          icon: "ic-material-symbols-outlined ic-vertical",
          hint: "Menu com opções de ações",
          items: [
            {
              text: "Recalcular apuração",
              onClick: async () => await handleRecalcular(getData()!.id),
              mostraNaColunaDeAcoes: true,
            },
          ],
        },
      ];

      return itensAdicionais;
    }
  );

  return (
    <>
      <MxpGrid<ApuracaoLacsCsllGridModel>
        id={"grid-apuracao-lacs-csll"}
        colunas={colunas}
        dataSource={dataSource}
        ref={gridRef}
        excluirRegistro={handleExcluirRegistro}
        novoRegistro={handleNovoRegistro}
        editarRegistro={handleEditarRegistro}
        gerarItensAdicionaisDeContexto={handleGerarItensAdicionais}
        definirMenuSuperior={props.definirMenu}
      />

      <EditFormInsercaoApuracaoLacsCsll
        idRegistroEdicao={idRegistroEdicao}
        visivel={modalInsercaoVisivel}
        configuracoesModal={{ largura: "max(30vw, 600px)", altura: "auto" }}
        callBackFecharModal={handleModalInsercaoCallback}
      />

      <EditFormAlteracaoApuracaoLacsCsll
        idRegistroEdicao={idRegistroEdicao}
        visivel={modalEdicaoVisivel}
        configuracoesModal={{ largura: "max(30vw, 700px)", altura: "auto" }}
        callBackFecharModal={handleModalEdicaoCallback}
      />
    </>
  );
});
