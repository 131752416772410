import { Column } from "devextreme-react/data-grid";
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { renderToString } from "react-dom/server";
import { MxpGrid } from "../../../../components/grid";
import {
  useEditarRegistroGrid,
  useExcluirRegistroGrid,
  useNovoRegistroGrid,
  usePropagarReferenciaGrid,
} from "../../../../hooks/grid.hooks";
import { VeiculoGridModel } from "../../../../models/api/veiculo/veiculo";
import { CallBackModal } from "../../../../models/shared/ui/callback-modal";
import { IGridSelecao } from "../../../../models/shared/ui/formularios";
import EditFormVeiculo from "../../../../pages/vendas/veiculo/edit-form";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../services/comum/serviceBase";
import {
  checarResponseExibeMensagemExclusaoDeSucesso,
  tratarErroApi,
} from "../../../../utils/api/api-utils";
import criarNameof from "../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../utils/common/nomes-telas";
import { exibirConfirmacao } from "../../../../utils/dialogos";
import { GridBaseProps } from "../../../../utils/grid/grid-utils";
import obterConfiguracaoColuna from "../../../../utils/grid/padroes-colunas";
import GetColunasDeAuditoria from "../../../layout/grid-defaults/colunasDeAuditoria";

const nomeEndpoint = NomesEndpoints.Veiculo;
const dataSource = APIBase.getGridSource(nomeEndpoint);

const nameOfGridHandler = criarNameof<VeiculoGridModel>();
const colunas = [
  <Column
    key={nameOfGridHandler("descricao")}
    dataField={nameOfGridHandler("descricao")}
    {...obterConfiguracaoColuna("stringGG")}
    sortIndex={0}
    sortOrder="asc"
    caption="Descrição"
  />,
  <Column
    key={nameOfGridHandler("tipoRodado")}
    dataField={nameOfGridHandler("tipoRodado")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Rodado"
  />,
  <Column
    key={nameOfGridHandler("tipoCarroceria")}
    dataField={nameOfGridHandler("tipoCarroceria")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Carroceria"
  />,
  ...GetColunasDeAuditoria(),
];

export const GridVeiculo = forwardRef((props: GridBaseProps, ref) => {
  const gridRef = useRef<IGridSelecao>(null);
  useImperativeHandle(ref, () => gridRef.current as IGridSelecao);
  usePropagarReferenciaGrid(ref, gridRef);

  const [modalEdicaoVisivel, setModalEdicaoVisivel] = useState(false);
  const [idRegistroEdicao, setIdRegistroEdicao] = useState(NaN);

  function handleAtualizarGrid() {
    if (gridRef.current?.atualizarGrid) {
      gridRef.current?.atualizarGrid();
    }
  }

  const handleNovoRegistro = useNovoRegistroGrid(() => {
    setIdRegistroEdicao(0);
    setModalEdicaoVisivel(true);
  });

  const handleEditarRegistro = useEditarRegistroGrid((id: number) => {
    setIdRegistroEdicao(id);
    setModalEdicaoVisivel(true);
  });

  const handleExcluirRegistro = useExcluirRegistroGrid(
    async (registro: VeiculoGridModel) => {
      const mensagem = renderToString(<>{obterMensagemExclusao(registro)}</>);
      const excluir = await exibirConfirmacao("Confirmar exclusão", mensagem);

      if (excluir) {
        try {
          const resposta = await APIBase.excluir(registro.id, nomeEndpoint);

          if (resposta) {
            checarResponseExibeMensagemExclusaoDeSucesso(resposta);
            handleAtualizarGrid();
          }
        } catch (erro) {
          tratarErroApi(erro);
        }
      }
    }
  );

  function obterMensagemExclusao(registro: VeiculoGridModel) {
    return `Tem certeza que deseja excluir o registro ${registro.descricao}?`;
  }

  const handleModalCallback = useCallback((info: CallBackModal) => {
    setModalEdicaoVisivel(false);
    setIdRegistroEdicao(NaN);

    if (info.precisaAtualizar) {
      handleAtualizarGrid();
    }
  }, []);

  return (
    <>
      <MxpGrid<VeiculoGridModel>
        dataSource={dataSource}
        id={"veiculo"}
        ref={gridRef}
        colunas={colunas}
        novoRegistro={handleNovoRegistro}
        editarRegistro={handleEditarRegistro}
        excluirRegistro={handleExcluirRegistro}
        nomeDoArquivoAoExportar={NomesTelas.veiculos}
      />
      <EditFormVeiculo
        visivel={modalEdicaoVisivel}
        idRegistroEdicao={idRegistroEdicao}
        configuracoesModal={{ largura: "max(50vw, 800px)", altura: "auto" }}
        callBackFecharModal={handleModalCallback}
      />
    </>
  );
});
