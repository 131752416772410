import { retornaDescricaoEnumDoDicionario } from "../../../utils/common/enum-utils";
import SelectItem from "../../shared/ui/select-item";

export enum EstadoDecodificado {
  Ativo = "Ativo",
  Inativo = "Inativo",
}

export enum TipoDecodificado {
  Municipal = "Municipal",
  Nacional = "Nacional",
}

export enum TipoInterestadual {
  DentroDoEstado = 68,
  ForaDoEstado = 70,
  Exterior = 88,
  NaoVerificar = 78,
}

export enum TipoCfop {
  Nacional = 78,
  Municipal = 77,
}

export enum EntradaSaida {
  Entrada = 69,
  Saida = 83,
}

const tipoInterestadualDicionario: { [key in TipoInterestadual]: string } = {
  [TipoInterestadual.DentroDoEstado]: "Dentro do estado",
  [TipoInterestadual.ForaDoEstado]: "Fora do estado",
  [TipoInterestadual.Exterior]: "Exterior",
  [TipoInterestadual.NaoVerificar]: "Não verificar",
};

const tipoCfopDicionario: { [key in TipoCfop]: string } = {
  [TipoCfop.Nacional]: "Nacional",
  [TipoCfop.Municipal]: "Municipal",
};

const entradaSaidaDicionario: { [key in EntradaSaida]: string } = {
  [EntradaSaida.Entrada]: "Entrada",
  [EntradaSaida.Saida]: "Saida",
};

export function tipoInterestadualFormataParaExibicaoGrid(
  valor: TipoInterestadual | null
): string | null {
  return retornaDescricaoEnumDoDicionario(tipoInterestadualDicionario, valor);
}

export function tipoCfopFormataParaExibicaoGrid(
  valor: TipoCfop | null
): string | null {
  return retornaDescricaoEnumDoDicionario(tipoCfopDicionario, valor);
}

export function entradaSaidaFormataParaExibicaoGrid(
  valor: EntradaSaida | null
): string | null {
  return retornaDescricaoEnumDoDicionario(entradaSaidaDicionario, valor);
}

export const tipoInterestadualAsSelectItem: SelectItem[] = Object.keys(
  TipoInterestadual
)
  .map((x) => parseInt(x))
  .filter((x) => !isNaN(x))
  .map((valor) => ({
    valor,
    descricao: tipoInterestadualDicionario[valor as TipoInterestadual],
  }));

export const tipoCfopAsSelectItem: SelectItem[] = Object.keys(TipoCfop)
  .map((x) => parseInt(x))
  .filter((x) => !isNaN(x))
  .map((valor) => ({
    valor,
    descricao: tipoCfopDicionario[valor as TipoCfop],
  }));

export const entradaSaidaAsSelectItem: SelectItem[] = Object.keys(EntradaSaida)
  .map((x) => parseInt(x))
  .filter((x) => !isNaN(x))
  .map((valor) => ({
    valor,
    descricao: entradaSaidaDicionario[valor as EntradaSaida],
  }));
