import { useState } from "react";
import Pagina from "../../../components/layout/pagina";
import RequerPermissao from "../../../components/seguranca/requer-permissao";
import { PermissoesImobilizado } from "../../../models/permissoes/contabilidade/imobilizado/permissoes-imobilizado";
import { GridImobilizado } from "../../../parts/contabilidade/imobilizado/grid";
import NomesTelas from "../../../utils/common/nomes-telas";
import { ItemContextMenu } from "../../../utils/context-menu/context-menu-utils";

export default function ImobilizadoPage() {
  const [menus, setMenus] = useState<ItemContextMenu[]>();
  return (
    <Pagina
      id={"imobilizado-page"}
      titulo={NomesTelas.imobilizado}
      area="Contabilidade"
      subMenuItems={menus}
    >
      <RequerPermissao codigoPermissoes={[PermissoesImobilizado.Consultar]}>
        <GridImobilizado definirMenu={setMenus} />
      </RequerPermissao>
    </Pagina>
  );
}
