import { Control, FieldValues } from "react-hook-form";
import FormTextArea from "../../../../../components/formularios/textarea";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import TabContainer from "../../../../../components/layout/tab-container";

interface ObservacoesProps {
  control: Control<FieldValues, any>;
}

export default function LoteAbaObservacoes({ control }: ObservacoesProps) {
  return (
    <TabContainer>
      <Linha>
        <Coluna md={8}>
          <FormTextArea
            name="observacoes"
            titulo={"Observações"}
            valor={""}
            control={control}
            height={25}
          />
        </Coluna>
      </Linha>
      <Linha>
        <Coluna md={8}>
          <FormTextArea
            name="observacoesInternas"
            titulo={"Observações internas"}
            valor={""}
            control={control}
            height={25}
          />
        </Coluna>
      </Linha>
    </TabContainer>
  );
}
