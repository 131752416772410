import getApi from "../../configs/api";
import { CadastrarEmMassaResponse } from "../../models/api/comum/cadastrar-varios";
import { ResponseModel } from "../../models/api/comum/response-base";
import {
  LoteCadastrarVariosExcelRequest as LoteCadastrarEmMassaExcelRequest,
  LoteRequestDTO,
  LoteResponseDTO,
} from "../../models/api/lote/lote";
import { tratarErroApi } from "../../utils/api/api-utils";
import { NomesEndpoints } from "../comum/nomesEndpoints";
import ApiEmpresa from "../empresa/empresa.service";
import { ItemService } from "../item/item.service";

const nomeEndpoint = NomesEndpoints.Lote;
const itemService = new ItemService();

async function cadastrarEmMassaExcel(
  dados: LoteCadastrarEmMassaExcelRequest[]
) {
  try {
    const [idsEmpresa, idsItem] = await Promise.all([
      ApiEmpresa.obterIdPorApelido(dados.map((m) => m.codigoFabricante)),
      itemService.obterIdPorCodigo(dados.map((m) => m.codigoItem)),
    ]);

    dados.forEach((m) => {
      if (!idsItem[m.codigoItem]) {
        m.erros += `\nNão foi possível encontrar item com o código: "${m.codigoItem}"`;
      }
      if (!idsEmpresa[m.codigoFabricante]) {
        m.erros += `\nNão foi possível encontrar fabricante com o apelido: "${m.codigoFabricante}"`;
      }
    });

    const dadosCadastrar = dados
      .filter((x) => x.erros.length === 0)
      .map((m) => ({
        id: 0,
        itemId: idsItem[m.codigoItem],
        fabricanteId: idsEmpresa[m.codigoFabricante],
        historico: null,
        arquivos: [],
        utilizarPrefixo: false,
        ...m,
      }));

    const resultado = await cadastrarEmMassa(dadosCadastrar);

    if (!resultado.sucesso) {
      return resultado;
    }

    resultado.model.forEach(
      (m, i) => (m.indiceDoRegistro = dadosCadastrar.at(i)?.linha ?? 0)
    );
    dados
      .filter((x) => x.erros.length > 0)
      .forEach((x) =>
        resultado.model.push({
          indiceDoRegistro: x.linha,
          id: 0,
          erros: x.erros,
        })
      );

    return resultado;
  } catch (error) {
    tratarErroApi(error);
    return {
      sucesso: false,
      model: [],
      mensagem: error,
    } as ResponseModel<CadastrarEmMassaResponse[]>;
  }
}

async function cadastrarEmMassa(model: LoteRequestDTO[]) {
  const api = getApi();
  const response = await api.post<ResponseModel<CadastrarEmMassaResponse[]>>(
    `${nomeEndpoint}/CadastrarEmMassa`,
    model
  );

  return response.data;
}

async function cancelar(id: number): Promise<ResponseModel<LoteResponseDTO>> {
  const api = getApi();
  const response = await api.patch<ResponseModel<LoteResponseDTO>>(
    `${nomeEndpoint}/CancelaLote?loteId=${id}`
  );

  return response.data;
}

export default {
  cancelar: cancelar,
  cadastrarEmMassaExcel,
  cadastrarEmMassa,
};
