import AuditavelDTO from "../comum/auditavel-dto";
import AuditavelGridModel from "../comum/auditavel-grid";

export enum TipoDocumentoEletronico {
  Nenhum = 0,
  NFe = 1,
  NFSe = 2,
  NFCe = 3,
  ConformeOperacaoFiscal = 4,
  MDFe = 5,
}

export const tipoDocumentoEletronicoDecodificado: {
  [key in TipoDocumentoEletronico]: string;
} = {
  [TipoDocumentoEletronico.Nenhum]: "Nenhum",
  [TipoDocumentoEletronico.NFe]: "NF-e",
  [TipoDocumentoEletronico.NFSe]: "NFS-e",
  [TipoDocumentoEletronico.NFCe]: "NFC-e",
  [TipoDocumentoEletronico.ConformeOperacaoFiscal]: "Conforme operação fiscal",
  [TipoDocumentoEletronico.MDFe]: "MDF-e",
};

export interface SerieFiscalRequestDTO {
  id: number;
  serie: string;
  proximoNumero: number;
  tipoDocumentoEletronico: TipoDocumentoEletronico;
  observacoes: string | null;
}

export interface SerieFiscalGridModel extends AuditavelGridModel {
  id: number;
  serie: string;
  proximoNumero: number;
  tipoDocumentoEletronicoDecodificado: string;
  observacoes: number | null;
}

export interface SerieFiscalResponseDTO
  extends SerieFiscalRequestDTO,
    AuditavelDTO {}
