import { RefObject, useCallback, useImperativeHandle } from "react";
import { renderToString } from "react-dom/server";
import { IGridSelecao } from "../models/shared/ui/formularios";
import { tratarErroApi } from "../utils/api/api-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../utils/common/permissoes-utils";
import { ItemContextMenu } from "../utils/context-menu/context-menu-utils";
import { exibirConfirmacao } from "../utils/dialogos";

export function useNovoRegistroGrid(handleNovoRegistro: () => void) {
  return useCallback(handleNovoRegistro, []);
}

export function useEditarRegistroGrid(
  handleEditarRegistro: (id: number) => void
) {
  return useCallback(handleEditarRegistro, []);
}

export function useExcluirRegistroGrid<T>(
  handleExcluirRegistro: (registro: T) => void
) {
  return useCallback(handleExcluirRegistro, []);
}

export function useExcluirRegistroComPermissaoGrid<T>(
  mensagemConfirmacao: (registro: T) => string,
  handleExcluirRegistro: (registro: T) => void,
  permissaoExcluir?: string
) {
  return useCallback(async (registro: T) => {
    if (
      permissaoExcluir &&
      !verificaComNotificacaoSeUsuarioPossuiPermissoes([permissaoExcluir])
    ) {
      return;
    }

    const mensagem = renderToString(<>{mensagemConfirmacao(registro)}</>);
    const excluir = await exibirConfirmacao("Confirmar exclusão", mensagem);

    if (excluir) {
      try {
        handleExcluirRegistro(registro);
      } catch (erro) {
        tratarErroApi(erro);
      }
    }
  }, []);
}

export function useGerarItensAdicionais<T>(
  gerarItensAdicionais: (
    getData: () => T | undefined,
    data?: T | undefined,
    getSelecionados?: () => T[] | undefined
  ) => ItemContextMenu[]
) {
  return useCallback(gerarItensAdicionais, []);
}

export function usePropagarReferenciaGrid(
  ref: React.ForwardedRef<unknown>,
  refGrid: RefObject<IGridSelecao>
) {
  useImperativeHandle(ref, (): IGridSelecao | null => refGrid.current);
}
