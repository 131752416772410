import { List } from "devextreme-react";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import TabContainer from "../../../../../components/layout/tab-container";

interface HistoricoProps {
  dataSource: string | null | undefined;
}

export default function LoteAbaHistorico({ dataSource }: HistoricoProps) {
  const textoHistorico =
    dataSource
      ?.split("\n")
      .filter((x) => x)
      .map((item) => ({
        texto: item,
      })) ?? [];

  function TemplateListaRegistros(data: any) {
    return <div>{data.texto}</div>;
  }

  return (
    <TabContainer>
      <Linha>
        <Coluna md={12}>
          <List
            dataSource={textoHistorico}
            height={390}
            searchExpr="texto"
            searchEnabled={true}
            searchMode="contains"
            itemRender={TemplateListaRegistros}
          />
        </Coluna>
      </Linha>
    </TabContainer>
  );
}
