import getApi from "../../configs/api";
import {
  ResponseBase,
  ResponseModel,
} from "../../models/api/comum/response-base";
import {
  ApuracaoDoInformativoDaComposicaoDeCustosDaEcfCadastrarRequest,
  ApuracaoDoInformativoDaComposicaoDeCustosDaEcfResponseDTO,
} from "../../models/api/informativo-composicao-custos-lucro-real/apuracao-informativo-composicao-custos-ecf";
import criarDatasourceGrid from "../../utils/grid/back-end-grid-utils";
import { NomesEndpoints } from "../comum/nomesEndpoints";

export class ApuracaoDoInformativoDaComposicaoDeCustosDaEcfService {
  protected _nomeEndpoint: NomesEndpoints;

  constructor() {
    this._nomeEndpoint = NomesEndpoints.ApuracaoInformativoComposicaoCustosEcf;
  }

  public async ObterPorIdComDadosAuditoria(id: number) {
    const response = await getApi().get<
      ResponseModel<ApuracaoDoInformativoDaComposicaoDeCustosDaEcfResponseDTO>
    >(`${this._nomeEndpoint}/ObterPorIdComDadosAuditoria?id=${id}`);

    return response.data;
  }

  public async Calcular(
    model: ApuracaoDoInformativoDaComposicaoDeCustosDaEcfCadastrarRequest
  ) {
    const api = getApi();
    const response = await api.post<ResponseBase>(
      `${this._nomeEndpoint}/Calcular`,
      model
    );

    return response.data;
  }

  public async Recalcular(id: number) {
    const response = await getApi().put<ResponseBase>(
      `${this._nomeEndpoint}/Recalcular?id=${id}`
    );

    return response.data;
  }

  public async Excluir(id: number) {
    const api = getApi();
    const response = await api.delete<ResponseBase>(
      `${this._nomeEndpoint}?id=${id}`
    );

    return response.data;
  }

  public GetGridSource() {
    return criarDatasourceGrid(`${this._nomeEndpoint}/grid`);
  }
}
