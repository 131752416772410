import Pagina from "../../../components/layout/pagina";
import RequerPermissao from "../../../components/seguranca/requer-permissao";
import { PermissoesCentroDeTrabalho } from "../../../models/permissoes/producao/centro-de-trabalho/permissoes-centro-de-trabalho";
import { GridCentroDeTrabalho } from "../../../parts/producao/centro-de-trabalho/grids/grid-padrao";
import NomesTelas from "../../../utils/common/nomes-telas";
import { PageProps } from "../../../utils/common/page-utils";
import FiltrosGridCentroDeTrabalho from "../../../utils/filtros-grid/producao/centro-de-trabalho/filtros-grid-centro-de-trabalho";

export default function CentroDeTrabalhoPage(props: PageProps) {
  return (
    <Pagina
      id="centros-trabalho-page"
      titulo={NomesTelas.centrosDeTrabalho}
      area={"Produção"}
    >
      <RequerPermissao
        codigoPermissoes={[PermissoesCentroDeTrabalho.Consultar]}
      >
        <GridCentroDeTrabalho
          abrirModalNovoRegistroAoCarregar={props.abrirModalNovoRegistro}
          valorPadraoDoFiltro={
            FiltrosGridCentroDeTrabalho.centroDeTrabalhoAtivo
          }
        />
      </RequerPermissao>
    </Pagina>
  );
}
