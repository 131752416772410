import { retornaDescricaoEnumDoDicionario } from "../../../utils/common/enum-utils";
import SelectItem from "../../shared/ui/select-item";

export enum TipoCentroDeTrabalho {
  SemEstacoes = 72,
  ComEstacoes = 77,
  Externo = 69,
}

export enum TipoSequenciamento {
  Livre = 0,
  Fixo = 1,
}

const tipoCentroDeTrabalhoDicionario: {
  [key in TipoCentroDeTrabalho]: string;
} = {
  [TipoCentroDeTrabalho.SemEstacoes]: "Sem estações",
  [TipoCentroDeTrabalho.ComEstacoes]: "Com estações",
  [TipoCentroDeTrabalho.Externo]: "Externo",
};

export function tipoCentroDeTrabalhoFormataParaExibicaoGrid(
  valor: TipoCentroDeTrabalho | null
): string | null {
  return retornaDescricaoEnumDoDicionario(
    tipoCentroDeTrabalhoDicionario,
    valor
  );
}

export const tipoCentroDeTrabalhoAsSelectItem: SelectItem[] = Object.keys(
  TipoCentroDeTrabalho
)
  .map((x) => parseInt(x))
  .filter((x) => !isNaN(x))
  .map((valor) => ({
    valor,
    descricao: tipoCentroDeTrabalhoDicionario[valor as TipoCentroDeTrabalho],
  }));

const tipoSequenciamentoDicionario: { [key in TipoSequenciamento]: string } = {
  [TipoSequenciamento.Livre]: "Livre",
  [TipoSequenciamento.Fixo]: "Fixo",
};

export function tipoSequenciamentoFormataParaExibicaoGrid(
  valor: TipoSequenciamento | null
): string | null {
  return retornaDescricaoEnumDoDicionario(tipoSequenciamentoDicionario, valor);
}

export const tipoSequenciamentoAsSelectItem: SelectItem[] = Object.keys(
  TipoSequenciamento
)
  .map((x) => parseInt(x))
  .filter((x) => !isNaN(x))
  .map((valor) => ({
    valor,
    descricao: tipoSequenciamentoDicionario[valor as TipoSequenciamento],
  }));
