import { Column } from "devextreme-react/data-grid";
import { forwardRef, useRef } from "react";
import { MxpGrid } from "../../../components/grid";
import {
  useGerarItensAdicionais,
  usePropagarReferenciaGrid,
} from "../../../hooks/grid.hooks";
import { useAppDispatch } from "../../../hooks/store.hooks";
import { decodificaBooleanoEmSimNao } from "../../../models/api/comum/decodificadores";
import { TiposTokenAcesso } from "../../../models/api/tokens/acesso-token";
import { UsuarioGridModel } from "../../../models/api/usuario/usuario-grid";
import { PermissoesUsuario } from "../../../models/permissoes/seguranca/usuario/permissoes-usuario";
import { IGridSelecao } from "../../../models/shared/ui/formularios";
import { NomesEndpoints } from "../../../services/comum/nomesEndpoints";
import APIBase from "../../../services/comum/serviceBase";
import { exibirModalGerenciamentoTokensAcessoUsuario } from "../../../store/usuario/usuario.slice";
import criarNameof from "../../../utils/common/cria-name-of";
import NomesTelas from "../../../utils/common/nomes-telas";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../utils/common/permissoes-utils";
import { GridBaseProps } from "../../../utils/grid/grid-utils";
import obterConfiguracaoColuna from "../../../utils/grid/padroes-colunas";
import GetColunasDeAuditoria from "../../layout/grid-defaults/colunasDeAuditoria";
import ModalTokenAcessoUsuario from "../modal-token-acesso";

const nomeEndpoint = NomesEndpoints.Usuario;
const dataSource = APIBase.getGridSource(nomeEndpoint);
const nameOfGridHandler = criarNameof<UsuarioGridModel>();
const colunas = [
  <Column
    key={nameOfGridHandler("apelido")}
    dataField={nameOfGridHandler("apelido")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Apelido"
  />,
  <Column
    key={nameOfGridHandler("nome")}
    dataField={nameOfGridHandler("nome")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Nome"
  />,
  <Column
    key={nameOfGridHandler("email")}
    dataField={nameOfGridHandler("email")}
    {...obterConfiguracaoColuna("stringG")}
    caption="E-mail"
  />,
  <Column
    key={nameOfGridHandler("tipoAcesso")}
    dataField={nameOfGridHandler("tipoAcesso")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Tipo de acesso"
  />,
  <Column
    key={nameOfGridHandler("ativo")}
    dataField={nameOfGridHandler("ativo")}
    {...obterConfiguracaoColuna("boolSimNao")}
    caption="Ativo"
    visible={false}
    cellRender={({ data }) => {
      const dados = data as UsuarioGridModel;
      return decodificaBooleanoEmSimNao(dados.ativo);
    }}
  />,
  ...GetColunasDeAuditoria(),
];

export const GridUsuario = forwardRef((props: GridBaseProps, ref) => {
  const gridRef = useRef<IGridSelecao>(null);
  usePropagarReferenciaGrid(ref, gridRef);

  const dispatch = useAppDispatch();

  const handleGerarItensAdicionais = useGerarItensAdicionais(
    (getData: () => UsuarioGridModel | undefined) => {
      const itensAdicionais = [
        {
          text: "Token de acesso API GraphQL",
          icon: "fa fa-key",
          onClick: () => gerenciarTokenAcesso(getData()?.id),
          ocultarNoMenuPrincipal: true,
          hint: "Permite gerar e revogar o token de acesso do usuário a API GraphQL.",
        },
      ];

      return itensAdicionais;
    }
  );

  function gerenciarTokenAcesso(idUsuario?: number) {
    if (
      !verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesUsuario.GerenciarTokensAcessoApi,
      ])
    ) {
      return;
    }

    if (idUsuario) {
      dispatch(
        exibirModalGerenciamentoTokensAcessoUsuario({
          idUsuario: idUsuario,
          tipo: TiposTokenAcesso.GraphQl,
        })
      );
    }
  }

  return (
    <>
      <MxpGrid<UsuarioGridModel>
        dataSource={dataSource}
        id={"usuario"}
        ref={gridRef}
        colunas={colunas}
        exibirIconeEditarMenuAcoes={false}
        exibirIconeExcluirMenuAcoes={false}
        gerarItensAdicionaisDeContexto={handleGerarItensAdicionais}
        definirMenuSuperior={props.definirMenu}
        nomeDoArquivoAoExportar={NomesTelas.usuarios}
        valorPadraoDoFiltro={[
          criarNameof<UsuarioGridModel>()("ativo"),
          "=",
          true,
        ]}
      />

      {/*Modal token acesso*/}
      <ModalTokenAcessoUsuario />
    </>
  );
});
