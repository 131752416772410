import { yupResolver } from "@hookform/resolvers/yup";
import { TabPanel } from "devextreme-react";
import { Item } from "devextreme-react/cjs/tab-panel";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import ProvedorAjuda from "../../../../components/ajuda/provedor-ajuda";
import BotaoLadoDireitoInput from "../../../../components/botoes/botao-lado-direito-input";
import {
  FormDateBox,
  FormNumberBox,
  FormTextBoxSimples,
} from "../../../../components/formularios";
import {
  FormBase2,
  FormularioEdicaoBaseProps,
} from "../../../../components/layout/form-base2";
import { Coluna, Linha } from "../../../../components/layout/grid-system";
import MenuSuperiorForm from "../../../../components/layout/menu-superior-form/menu-superior-form";
import TabContainer from "../../../../components/layout/tab-container";
import { useCarregarRegistro } from "../../../../hooks/form.hooks";
import { useAppSelector } from "../../../../hooks/store.hooks";
import { AdicaoOuExclusaoOuCompensacao } from "../../../../models/api/adicao-exclusao-compensacao/adicao-exclusao-compensacao-enums";
import {
  ApuracaoLalurIrpjDTO,
  ApuracaoLalurIrpjResponseDTO,
} from "../../../../models/api/apuracao-lalur-irpj/apuracao-lalur-irpj";
import AuditavelDTO from "../../../../models/api/comum/auditavel-dto";
import { CallBackModal } from "../../../../models/shared/ui/callback-modal";
import { ApuracaoLalurIrpjService } from "../../../../services/apuracao-lalur-irpj/apuracao-lalur-irpj.service";
import { checarResponse, tratarErroApi } from "../../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../utils/common/notificacoes-utils";
import { exibirConfirmacao } from "../../../../utils/dialogos";
import { obterFormatStringNumero } from "../../../../utils/formatadores/formatador-de-numeros";
import { formatarCpfCnpj } from "../../../../utils/formatadores/formatador-de-strings";
import GridInternaLancamentoParteBDoLalur from "./grid-lancamento-parte-b-lalur";
import ModalDetalhamentoAdicaoExclusaoCompensacaoApuracaoLalurIrpj from "./modal-grid-adicao-exclusao-compensacao-apuracao-lalur-irpj";
import { ModalDemonstrativoSaldosParteBLalur } from "./modal-grid-demonstrativo-saldos";

const rederizarTitulo = (item: any) => item.text;
const service = new ApuracaoLalurIrpjService();
const novoRegistro: ApuracaoLalurIrpjDTO = {
  id: 0,
  dataInicial: "",
  dataFinal: "",
  lucroOuPrejuizo: 0,
  lucroOuPrejuizoEstaFixo: false,
  adicoes: 0,
  exclusoes: 0,
  baseDeCalculoAntesDasCompensacoes: 0,
  compensacoes: 0,
  baseDeCalculo: 0,
  impostoDeRendaApuradoAAliquotaNormal: 0,
  impostoDeRendaApuradoAdicional: 0,
  impostoDeRendaAPagar: 0,
};

interface Detalhamento {
  modalVisivel: boolean;
  tipo?: AdicaoOuExclusaoOuCompensacao;
}

export default function EditFormAlteracaoApuracaoLalurIrpj(
  props: FormularioEdicaoBaseProps
) {
  const [dadosAuditoria, setDadosAuditoria] = useState<AuditavelDTO>();
  const [carregando, setCarregando] = useState(false);
  const empresaLogada = useAppSelector((state) => {
    return {
      Apelido: state.sessao.dadosSessao?.empresa.apelido,
      CpfCpj: state.sessao.dadosSessao?.empresa.cnpjCpfCodigo,
    };
  });

  const [detalhamento, setDetalhamento] = useState<Detalhamento>({
    modalVisivel: false,
    tipo: undefined,
  });

  const [modalDemonstrativoVisivel, setmodalDemonstrativoVisivel] =
    useState(false);

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    lucroOuPrejuizo: yup.number().required(),
  });

  const { register, control, handleSubmit, getValues, reset, watch, setValue } =
    useForm<ApuracaoLalurIrpjDTO>({
      mode: "onChange",
      reValidateMode: "onChange",
      resolver: yupResolver(schema),
    });

  useCarregarRegistro(props.idRegistroEdicao, carregarTela);

  // Usado para limpar o formulário se for NaN
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEdicao)) {
      reset(novoRegistro);
    }
  }, [props.idRegistroEdicao]);

  const itensMenuSuperior = [
    {
      text: "Consultar",
      icon: "ic-material-symbols-outlined ic-vertical",
      hint: "Menu com opções de consultar",
      items: [
        {
          text: " Demonstrativo de saldos",
          onClick: () => handleAbrirModalDemonstrativo(),
        },
      ],
    },
  ];

  function fechar(info: CallBackModal) {
    if (props.callBackFecharModal) {
      setDadosAuditoria(undefined);
      props.callBackFecharModal(info);
    }
  }

  function handleCancelar() {
    fechar({ concluido: false, precisaAtualizar: false });
  }

  async function carregarTela() {
    try {
      setCarregando(true);
      if (props.idRegistroEdicao > 0) {
        await carregarModel();
      } else {
        reset(novoRegistro);
        setDadosAuditoria(undefined);
      }
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  async function carregarModel() {
    try {
      const resposta =
        await service.ObterPorIdComDadosAuditoria<ApuracaoLalurIrpjResponseDTO>(
          props.idRegistroEdicao
        );
      checarResponse(resposta);
      reset(resposta.model);
      setDadosAuditoria(resposta.model);
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues();
    try {
      const resposta = await service.Atualizar({
        id: model.id,
        lucroOuPrejuizo: model.lucroOuPrejuizo,
      });

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar({
          concluido: true,
          precisaAtualizar: true,
        });
      }
      fechar({
        concluido: true,
        precisaAtualizar: false,
      });
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  function callBackUnprocessableEntity() {
    fechar({
      concluido: false,
      precisaAtualizar: true,
    });
  }

  function handleAbrirModalDetalhamento(tipo: AdicaoOuExclusaoOuCompensacao) {
    setDetalhamento({
      modalVisivel: true,
      tipo: tipo,
    });
  }

  function handleCallbackModal() {
    setDetalhamento({
      modalVisivel: false,
      tipo: undefined,
    });
  }

  function handleAbrirModalDemonstrativo() {
    setmodalDemonstrativoVisivel(true);
  }

  function handleCallbackModalDemonstrativo() {
    setmodalDemonstrativoVisivel(false);
  }

  async function handleTornarLucroOuPrejuizoLivre() {
    try {
      const resposta = await exibirConfirmacao(
        "Confirmação",
        "Deseja tornar livre o lucro líquido antes do IRPJ?"
      );

      if (resposta) {
        setCarregando(true);
        const retorno = await service.TornarLucroOuPrejuizoLivre(
          props.idRegistroEdicao
        );
        checarResponse(retorno);

        if (retorno.sucesso) {
          setValue("lucroOuPrejuizoEstaFixo", false);
        }
      }
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  return (
    <>
      <FormBase2
        carregando={carregando}
        titulo={"apuração do LALUR e IRPJ do Lucro Real"}
        onClickCancelar={handleCancelar}
        onClickSalvar={handleSubmit(handleSalvar)}
        visivel={props.visivel}
        modoEdicao={"editar"}
        auditoria={dadosAuditoria}
        configuracoesModal={props.configuracoesModal}
      >
        <MenuSuperiorForm itensMenuSuperior={itensMenuSuperior} />

        <ProvedorAjuda id="edit-form-apuracao-lalur-irpj">
          <input type="hidden" {...register("id")} defaultValue={0} />
          <Linha>
            <Coluna md={8}>
              <FormTextBoxSimples
                titulo={"Minha empresa"}
                valor={`${empresaLogada.Apelido} - ${formatarCpfCnpj(
                  empresaLogada.CpfCpj!
                )}`}
                somenteLeitura
              />
            </Coluna>
          </Linha>
          <Linha>
            <Coluna md={3}>
              <FormDateBox
                name="dataInicial"
                titulo="Período de"
                control={control}
                requerido
                exibirBotaoLimpar
                tipo="date"
                aceitaValorCustomizado={true}
                formatoExibicao="dd/MM/yy"
                aceitaDigitacaoComMascara={true}
                somenteLeitura
              />
            </Coluna>
            <Coluna md={3}>
              <FormDateBox
                name="dataFinal"
                titulo="a"
                control={control}
                requerido
                exibirBotaoLimpar
                tipo="date"
                aceitaValorCustomizado={true}
                formatoExibicao="dd/MM/yy"
                aceitaDigitacaoComMascara={true}
                somenteLeitura
              />
            </Coluna>
          </Linha>
          <TabPanel
            showNavButtons
            swipeEnabled={false}
            itemTitleRender={rederizarTitulo}
            height={"max(40vh, 415px)"}
          >
            <Item text="Parte A">
              <TabContainer>
                <Linha>
                  <Coluna md={6}>
                    <BotaoLadoDireitoInput
                      textoTooltip="Tornar livre"
                      onClick={handleTornarLucroOuPrejuizoLivre}
                      icone="ic-keep"
                      visivel={watch("lucroOuPrejuizoEstaFixo")}
                    >
                      <FormNumberBox
                        name="lucroOuPrejuizo"
                        titulo="Lucro líquido antes do IRPJ"
                        control={control}
                        requerido
                        formato={obterFormatStringNumero(2)}
                      />
                    </BotaoLadoDireitoInput>
                  </Coluna>
                </Linha>
                <Linha>
                  <Coluna md={6}>
                    <BotaoLadoDireitoInput
                      textoTooltip="Detalhamento"
                      icone="ic-pending"
                      onClick={() =>
                        handleAbrirModalDetalhamento(
                          AdicaoOuExclusaoOuCompensacao.Adicao
                        )
                      }
                    >
                      <FormNumberBox
                        name="adicoes"
                        titulo="Adições (+)"
                        control={control}
                        somenteLeitura
                        formato={obterFormatStringNumero(2)}
                        minimo={0}
                      />
                    </BotaoLadoDireitoInput>
                  </Coluna>
                </Linha>
                <Linha>
                  <Coluna md={6}>
                    <BotaoLadoDireitoInput
                      textoTooltip="Detalhamento"
                      icone="ic-pending"
                      onClick={() =>
                        handleAbrirModalDetalhamento(
                          AdicaoOuExclusaoOuCompensacao.Exclusao
                        )
                      }
                    >
                      <FormNumberBox
                        name="exclusoes"
                        titulo="Exclusões (-)"
                        control={control}
                        somenteLeitura
                        formato={obterFormatStringNumero(2)}
                        minimo={0}
                      />
                    </BotaoLadoDireitoInput>
                  </Coluna>
                </Linha>
                <Linha>
                  <Coluna md={6}>
                    <div style={{ marginRight: "21px" }}>
                      <FormNumberBox
                        name="baseDeCalculoAntesDasCompensacoes"
                        titulo="Base de cálculo antes das compensações"
                        control={control}
                        somenteLeitura
                        formato={obterFormatStringNumero(2)}
                        minimo={0}
                      />
                    </div>
                  </Coluna>
                  <Coluna md={6}>
                    <FormNumberBox
                      name="impostoDeRendaApuradoAAliquotaNormal"
                      titulo="Imposto apurado à alíquota de 15%"
                      control={control}
                      somenteLeitura
                      formato={obterFormatStringNumero(2)}
                      minimo={0}
                    />
                  </Coluna>
                </Linha>
                <Linha>
                  <Coluna md={6}>
                    <BotaoLadoDireitoInput
                      textoTooltip="Detalhamento"
                      icone="ic-pending"
                      onClick={() =>
                        handleAbrirModalDetalhamento(
                          AdicaoOuExclusaoOuCompensacao.Compensacao
                        )
                      }
                    >
                      <FormNumberBox
                        name="compensacoes"
                        titulo="Compensações (-)"
                        control={control}
                        somenteLeitura
                        formato={obterFormatStringNumero(2)}
                        minimo={0}
                      />
                    </BotaoLadoDireitoInput>
                  </Coluna>
                  <Coluna md={6}>
                    <FormNumberBox
                      name="impostoDeRendaApuradoAdicional"
                      titulo="Imposto adicional"
                      control={control}
                      somenteLeitura
                      formato={obterFormatStringNumero(2)}
                      minimo={0}
                    />
                  </Coluna>
                </Linha>
                <Linha>
                  <Coluna md={6}>
                    <div style={{ marginRight: "21px" }}>
                      <FormNumberBox
                        name="baseDeCalculo"
                        titulo="Base de cálculo do IRPJ"
                        control={control}
                        somenteLeitura
                        formato={obterFormatStringNumero(2)}
                        minimo={0}
                      />
                    </div>
                  </Coluna>
                  <Coluna md={6}>
                    <FormNumberBox
                      name="impostoDeRendaAPagar"
                      titulo="Imposto de renda a pagar"
                      control={control}
                      somenteLeitura
                      formato={obterFormatStringNumero(2)}
                      minimo={0}
                    />
                  </Coluna>
                </Linha>
              </TabContainer>
            </Item>
            <Item text="Lançamentos da parte B">
              <TabContainer>
                <GridInternaLancamentoParteBDoLalur
                  idApuracaoDoLalurEDoIrpj={props.idRegistroEdicao}
                  modalPaiVisivel={props.visivel} // Apenas para controlar a instância de datasource da grid
                />
              </TabContainer>
            </Item>
          </TabPanel>
        </ProvedorAjuda>
      </FormBase2>

      <ModalDetalhamentoAdicaoExclusaoCompensacaoApuracaoLalurIrpj
        modalVisivel={detalhamento.modalVisivel}
        apuracaoDoLalurEIrpjId={props.idRegistroEdicao}
        adicaoOuExclusaoOuCompensacao={detalhamento.tipo}
        titulo={"Adições, exclusões ou compensações de prejuízo"}
        handleCallbackModal={handleCallbackModal}
      />
      <ModalDemonstrativoSaldosParteBLalur
        modalVisivel={modalDemonstrativoVisivel}
        apuracaoDoLalurEIrpjId={props.idRegistroEdicao}
        titulo={"Demonstrativos de saldos da parte B"}
        handleCallbackModal={handleCallbackModalDemonstrativo}
      />
    </>
  );
}
