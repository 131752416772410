import { yupResolver } from "@hookform/resolvers/yup";
import { TabPanel } from "devextreme-react";
import { Item } from "devextreme-react/cjs/tab-panel";
import DataSource from "devextreme/data/data_source";
import _ from "lodash";
import { useEffect, useState } from "react";
import { renderToString } from "react-dom/server";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
  FormSelectBox,
  FormSelectBoxLazy,
  FormTextBox,
} from "../../../../components/formularios";
import {
  FormBase2,
  FormularioEdicaoBaseProps,
} from "../../../../components/layout/form-base2";
import { Coluna, Linha } from "../../../../components/layout/grid-system";
import TabContainer from "../../../../components/layout/tab-container";
import ComboContaContabilMxp from "../../../../components/mxp/inputs-custom/conta-contabil";
import { QuebrarLinhas } from "../../../../components/texto/quebrar-linhas";
import {
  useCarregarCombos,
  useCarregarRegistro,
} from "../../../../hooks/form.hooks";
import AuditavelDTO from "../../../../models/api/comum/auditavel-dto";
import { SelectBoxLazyFiltros } from "../../../../models/api/comum/selectboxlazy-options";
import {
  EstadoDoImobilizado,
  ImobilizadoResponse,
  TipoDeDepreciacao,
} from "../../../../models/api/imobilizado/imobilizado";
import { SelectItemEnumEstadoDoImobilizado } from "../../../../models/const/dicionario-combos/imobilizado";
import { PermissoesImobilizado } from "../../../../models/permissoes/contabilidade/imobilizado/permissoes-imobilizado";
import { CallBackModal } from "../../../../models/shared/ui/callback-modal";
import {
  CiapDoImobilizadoViewModel,
  ImobilizadoViewModel,
} from "../../../../models/viewmodels/contabilidade/imobilizado/imobilizado.viewmodel";
import { GridCentroDeCustos } from "../../../../parts/contabilidade/centro-de-custos/grids/grid-padrao";
import AbaCiapImobilizado from "../../../../parts/contabilidade/imobilizado/abas/aba-ciap";
import AbaDepreciacaoImobilizado from "../../../../parts/contabilidade/imobilizado/abas/aba-depreciacao";
import { GridItem } from "../../../../parts/itens/item/grids/grid-padrao";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../services/comum/serviceBase";
import { ImobilizadoService } from "../../../../services/contabilidade/imobilizado/imobilizado.service";
import ImobilizadoAdapter from "../../../../utils/adapters/contabilidade/imobilizado.adapter";
import { checarResponse, tratarErroApi } from "../../../../utils/api/api-utils";
import NomesModais from "../../../../utils/common/nomes-modais";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../utils/common/permissoes-utils";
import { exibirConfirmacao } from "../../../../utils/dialogos";

const imobilizadoService = new ImobilizadoService();

const novoRegistro: ImobilizadoViewModel = {
  id: 0,
  itemId: null,
  estado: EstadoDoImobilizado.Ativo,
  numeroDoPatrimonio: "",
  descricao: "",
  descricaoEstaFixa: false,
  valorDaAquisicao: 0,
  dataDaAquisicao: "",
  contaContabilId: null,
  utilizacaoDoBem: "",
  centroDeCustosId: null,
  contaDeDebitoDaDepreciacaoId: null,
  centroDeCustosDeDebitoDaDepreciacaoId: null,
  contaDeCreditoDaDepreciacaoId: null,
  centroDeCustosDeCreditoDaDepreciacaoId: null,
  percentualDeDepreciacao: null,
  tipoDeDepreciacao: null,
  vidaUtilEmMeses: 0,
  valorResidual: 0,
  valorInicialDepreciado: 0,
  quantidadeInicialDeParcelasDepreciadas: 0,
  valorDepreciadoPorLancamentoContabil: 0,
  quantidadeParcelasDepreciadasPorLancamentoContabil: 0,
  dataUltimaDepreciacao: null,
  sujeitoAoCiap: false,
  ciapDoImobilizado: {
    itemNotaFiscalId: null,
    parcelas: 0,
    parcelasApropriadas: 0,
    tipoDeDocumentoFiscalId: null,
    fornecedorId: null,
    numero: "",
    serie: null,
    chaveAcesso: null,
    dataEmissao: "",
    quantidade: 0,
    unidadeId: null,
    baseCalculoIcms: 0,
    aliquotaIcms: null,
    valorIcms: null,
    valorIcmsSt: null,
    valorIcmsFrete: null,
    valorIcmsDifal: null,
  },
};

const filtroPadraoParaItem: SelectBoxLazyFiltros[] = [
  { nomeCampo: "Estado", operador: "=", valor: "Ativo" },
];

const exibicaoItem = (c: any) => {
  if (c) {
    return `${c.Codigo} - ${c.Descricao}`;
  }

  return "";
};

const itemExpressaoDeBusca = ["Codigo", "Descricao"];

const centroDeCustosDataSource = APIBase.getDataSourceSelectBoxLazy(
  {
    camposRetorno: ["Id", "Codigo", "Descricao", "Classificacao"],
    camposOrdenacao: [
      {
        nomeCampo: "Classificacao",
        desc: false,
      },
    ],
  },
  NomesEndpoints.CentroDeCustos
);

const exibicaoCentroCustos = (c: any) => {
  if (c) {
    return c.Descricao == null
      ? `${c.Codigo}`
      : `${c.Classificacao} ${c.Descricao} (${c.Codigo})`;
  }

  return "";
};

const centroDeCustosExpressaoDeBusca = ["Codigo", "Descricao", "Classificacao"];

export default function EditFormImobilizado(props: FormularioEdicaoBaseProps) {
  const [carregando, setCarregando] = useState(false);
  const [dadosAuditoria, setDadosAuditoria] = useState<AuditavelDTO>();
  const [itemDataSource, setItemDataSource] = useState<
    DataSource | undefined
  >();
  const [descricaoAlterada, setDescricaoAlterada] = useState(false);

  const schema = yup
    .object()
    .shape<Record<keyof ImobilizadoViewModel, yup.AnySchema>>({
      id: yup.number().required().moreThan(-1).integer(),
      itemId: yup.number().required().moreThan(-1).integer(),
      estado: yup
        .mixed<EstadoDoImobilizado>()
        .required()
        .oneOf(
          Object.values(EstadoDoImobilizado).map((x) => x as number),
          "Valor inválido"
        ),
      numeroDoPatrimonio: yup.string().max(60).required(),
      descricao: yup.string().max(200).required(),
      descricaoEstaFixa: yup.boolean().required(),
      valorDaAquisicao: yup.number().required().moreThan(-1),
      dataDaAquisicao: yup.string().required(),
      contaContabilId: yup.number().required().moreThan(-1).integer(),
      utilizacaoDoBem: yup.string().max(200).required(),
      centroDeCustosId: yup.number().required().positive().integer(),
      contaDeDebitoDaDepreciacaoId: yup
        .number()
        .required()
        .moreThan(-1)
        .integer(),
      centroDeCustosDeDebitoDaDepreciacaoId: yup
        .number()
        .notRequired()
        .integer(),
      contaDeCreditoDaDepreciacaoId: yup
        .number()
        .required()
        .moreThan(-1)
        .integer(),
      centroDeCustosDeCreditoDaDepreciacaoId: yup
        .number()
        .notRequired()
        .integer(),
      percentualDeDepreciacao: yup
        .number()
        .notRequired()
        .moreThan(-1)
        .lessThan(100),
      tipoDeDepreciacao: yup
        .mixed<TipoDeDepreciacao>()
        .notRequired()
        .oneOf(
          Object.values(TipoDeDepreciacao).map((x) => x as number),
          "Valor inválido"
        ),
      vidaUtilEmMeses: yup
        .number()
        .required()
        .moreThan(-1)
        .lessThan(1000)
        .integer(),
      valorResidual: yup
        .number()
        .moreThan(-1)
        .test(
          "valor_residual_valido",
          "Deve ser menor ou igual ao campo 'Custo da aquisição'.",
          function (valorResidual, contexto) {
            if (valorResidual) {
              const valorDaAquisicao = contexto.parent
                .valorDaAquisicao as number;
              return valorResidual <= valorDaAquisicao;
            }
            return true;
          }
        ),
      valorInicialDepreciado: yup.number().required().moreThan(-1),
      quantidadeInicialDeParcelasDepreciadas: yup
        .number()
        .required()
        .moreThan(-1)
        .lessThan(1000)
        .integer(),
      valorDepreciadoPorLancamentoContabil: yup
        .number()
        .required()
        .moreThan(-1),
      quantidadeParcelasDepreciadasPorLancamentoContabil: yup
        .number()
        .required()
        .moreThan(-1)
        .lessThan(1000)
        .integer(),
      dataUltimaDepreciacao: yup.string().notRequired(),
      sujeitoAoCiap: yup.boolean().required(),
      ciapDoImobilizado: yup.object().when("sujeitoAoCiap", (valor, schema) => {
        const sujeitoAoCiap = valor[0] as boolean;
        if (!sujeitoAoCiap) {
          return schema;
        }
        schema = schema.shape<
          Record<keyof CiapDoImobilizadoViewModel, yup.AnySchema>
        >({
          itemNotaFiscalId: yup.number().notRequired().integer(),
          parcelas: yup
            .number()
            .required()
            .moreThan(-1)
            .lessThan(1000)
            .integer(),
          parcelasApropriadas: yup
            .number()
            .required()
            .moreThan(-1)
            .lessThan(1000)
            .integer()
            .test(
              "parcela_apropriada_valida",
              "Deve ser menor ou igual ao campo 'Parcelas'.",
              function (parcelaApropriada, contexto) {
                if (parcelaApropriada) {
                  const parcela = contexto.parent.parcelas as number;
                  return parcelaApropriada <= parcela;
                }
                return true;
              }
            ),
          tipoDeDocumentoFiscalId: yup
            .number()
            .required()
            .moreThan(-1)
            .integer(),
          fornecedorId: yup.number().required().moreThan(-1).integer(),
          numero: yup.string().max(9).required(),
          serie: yup.string().max(3).notRequired(),
          chaveAcesso: yup.string().max(44).notRequired(),
          dataEmissao: yup.string().required(),
          quantidade: yup.number().required().moreThan(-1),
          unidadeId: yup.number().required().moreThan(-1).integer(),
          baseCalculoIcms: yup.number().required().moreThan(-1),
          aliquotaIcms: yup.number().notRequired().moreThan(-1).lessThan(100),
          valorIcms: yup.number().notRequired().moreThan(-1),
          valorIcmsSt: yup.number().notRequired().moreThan(-1),
          valorIcmsFrete: yup.number().notRequired().moreThan(-1),
          valorIcmsDifal: yup.number().notRequired().moreThan(-1),
        });

        return schema;
      }),
    });

  const hookForm = useForm<ImobilizadoViewModel>({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const { register, control, reset, getValues, handleSubmit, setValue } =
    hookForm;

  useCarregarRegistro(props.idRegistroEdicao, CarregarRegistro);
  useCarregarCombos(props.idRegistroEdicao, carregarCombos);

  async function carregarCombos() {
    carregarItens();
  }

  function carregarItens() {
    setItemDataSource(
      APIBase.getDataSourceSelectBoxLazy(
        {
          camposRetorno: ["Id", "Codigo", "Descricao"],
          camposOrdenacao: [
            {
              nomeCampo: "Codigo",
              desc: false,
            },
          ],
          filtros: filtroPadraoParaItem,
        },
        NomesEndpoints.Item
      )
    );
  }

  async function tratarDescricao(e: any) {
    const descricaoAtual = getValues("descricao");
    const descricaoNova = e.selectedItem.Descricao;

    if (
      !descricaoAtual ||
      !descricaoAlterada ||
      !getValues("descricaoEstaFixa")
    ) {
      setValue("descricao", e.selectedItem?.Descricao);
      return;
    }

    const mensagem = renderToString(
      <QuebrarLinhas
        texto={obterMensagemAlteracaoDescricao(descricaoAtual, descricaoNova)}
      />
    );
    const alterar = await exibirConfirmacao("Confirmar alteração", mensagem);

    if (alterar) {
      setValue("descricao", e.selectedItem?.Descricao);
      setValue("descricaoEstaFixa", false);
    }
  }

  function obterMensagemAlteracaoDescricao(atual: string, novo: string) {
    return (
      `A descrição no cadastro do item (${novo}) é diferente da descrição nesta tela (${atual}).\n` +
      `Deseja sobrescrever a descrição em tela com a descrição do cadastro do item?`
    );
  }

  // Usado para limpar o formulário se for NaN
  useEffect(() => {
    if (Number.isNaN(props.idRegistroEdicao)) {
      reset(novoRegistro);
    }
  }, [props.idRegistroEdicao]);

  async function CarregarRegistro() {
    try {
      setCarregando(true);
      const resposta = await imobilizadoService.ObterPorId<ImobilizadoResponse>(
        props.idRegistroEdicao
      );
      const viewModel = ImobilizadoAdapter.ToViewModel(resposta.model);
      reset(viewModel);
      setValue("ciapDoImobilizado.parcelasApropriadas", 0);
      setDadosAuditoria(resposta.model);
    } catch (error) {
      tratarErroApi(error);
    } finally {
      setCarregando(false);
    }
  }

  function fechar(info: CallBackModal) {
    if (props.callBackFecharModal) {
      props.callBackFecharModal(info);
    }
  }

  function handleCancelar() {
    fechar({ concluido: false, precisaAtualizar: false });
  }

  function removerCiapDoImobilizadoSeNecessario(model: ImobilizadoViewModel) {
    if (
      _.isEqual(model.ciapDoImobilizado, novoRegistro.ciapDoImobilizado) ||
      !model.sujeitoAoCiap
    ) {
      model.ciapDoImobilizado = null;
    }
  }

  async function handleSalvar() {
    try {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesImobilizado.InserirEditar,
        ])
      ) {
        return;
      }
      setCarregando(true);
      const model = getValues();

      removerCiapDoImobilizadoSeNecessario(model);

      const request = ImobilizadoAdapter.ToRequest(model);

      const resposta =
        props.idRegistroEdicao > 0
          ? await imobilizadoService.Atualizar(request)
          : await imobilizadoService.Cadastrar(request);

      checarResponse(resposta);

      if (resposta?.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar({ concluido: true, precisaAtualizar: true });
      }
    } catch (error: any) {
      tratarErroApi(error);
    } finally {
      setCarregando(false);
    }
  }

  return (
    <FormBase2
      visivel={props.visivel}
      carregando={carregando}
      configuracoesModal={props.configuracoesModal}
      modoEdicao={props.idRegistroEdicao == 0 ? "criar" : "editar"}
      titulo={NomesModais.imobilizado}
      onClickSalvar={handleSubmit(handleSalvar)}
      onClickCancelar={handleCancelar}
      auditoria={dadosAuditoria}
    >
      <input type="hidden" {...register("id")} defaultValue={0} />
      <Linha>
        <Coluna md={3}>
          <FormTextBox
            name="numeroDoPatrimonio"
            titulo="Número do patrimônio"
            control={control}
            requerido
          />
        </Coluna>
        <Coluna md={3}>
          <FormSelectBox
            name="estado"
            titulo="Estado"
            control={control}
            requerido
            dataSource={SelectItemEnumEstadoDoImobilizado}
          />
        </Coluna>
      </Linha>
      <Linha>
        <Coluna md={6}>
          <FormSelectBoxLazy
            name="itemId"
            titulo="Item"
            control={control}
            requerido
            nomeCampoChave="Id"
            nomeCampoExibicao={exibicaoItem}
            expressaoDeBusca={itemExpressaoDeBusca}
            dataSource={itemDataSource}
            lupaConfig={{
              modo: "selecaoUnica",
              titulo: "Selecionar item",
              componente: (r) => (
                <GridItem
                  ref={r}
                  filtrosRealizadosNoServidor={filtroPadraoParaItem}
                />
              ),
            }}
            labelSemDados="Sem dados"
            onSelectionChanged={tratarDescricao}
          />
        </Coluna>
        <Coluna md={6}>
          <ComboContaContabilMxp
            name={"contaContabilId"}
            control={control}
            titulo="Conta contábil"
          />
        </Coluna>
      </Linha>
      <Linha>
        <Coluna md={12}>
          <FormTextBox
            name="descricao"
            titulo="Descrição"
            control={control}
            requerido
            onKeyDown={() => {
              setValue("descricaoEstaFixa", true);
              setDescricaoAlterada(true);
            }}
          />
        </Coluna>
      </Linha>
      <Linha>
        <Coluna md={6}>
          <FormTextBox
            name="utilizacaoDoBem"
            titulo="Utilização do bem"
            control={control}
            requerido
          />
        </Coluna>
        <Coluna md={6}>
          <FormSelectBoxLazy
            name="centroDeCustosId"
            titulo="Centro de custos"
            control={control}
            requerido
            nomeCampoChave="Id"
            nomeCampoExibicao={exibicaoCentroCustos}
            expressaoDeBusca={centroDeCustosExpressaoDeBusca}
            dataSource={centroDeCustosDataSource}
            lupaConfig={{
              modo: "selecaoUnica",
              titulo: "Selecionar centro de custos",
              componente: (r) => <GridCentroDeCustos ref={r} />,
            }}
            labelSemDados="Sem dados"
          />
        </Coluna>
      </Linha>
      <TabPanel
        showNavButtons
        swipeEnabled={false}
        itemTitleRender={(item: any) => item.text}
        deferRendering={false}
        height={"max(40vh, 465px)"}
      >
        <Item text="Depreciação">
          <TabContainer>
            <AbaDepreciacaoImobilizado hookForm={hookForm} />
          </TabContainer>
        </Item>
        <Item text="CIAP">
          <TabContainer>
            <AbaCiapImobilizado
              idRegistroEdicao={props.idRegistroEdicao}
              hookForm={hookForm}
            />
          </TabContainer>
        </Item>
      </TabPanel>
    </FormBase2>
  );
}
