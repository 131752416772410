import SelectItem from "../../models/shared/ui/select-item";

export function retornaDescricaoEnumDoDicionario(
  dicionarioEnum: { [key: number]: string },
  valor: number | null
): string | null {
  const item = valor ? dicionarioEnum[valor] : null;

  return item ? item : null;
}

export function gerarSelectItemDoDicionario(dicionario: {
  [key in number | string]: string;
}): SelectItem[] {
  const keys = Object.keys(dicionario).map((x) =>
    isNaN(Number(x)) ? x : Number(x)
  );

  const retorno: SelectItem[] = [];

  keys.forEach((key) => {
    retorno.push({
      valor: key,
      descricao: dicionario[key],
    });
  });

  return retorno;
}
