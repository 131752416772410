import { DataGrid } from "devextreme-react";
import { Column, Summary, TotalItem } from "devextreme-react/cjs/data-grid";
import { Modal } from "../../../../../components/layout/modal";
import { AdicaoExclusaoCompensacaoApuracaoLalurIrpjGrid } from "../../../../../models/api/adicao-exclusao-compensacao-apuracao/adicao-exclusao-compensacao-apuracao-lalur-irpj/adicao-exclusao-compensacao-apuracao-lalur-irpj";
import { AdicaoOuExclusaoOuCompensacao } from "../../../../../models/api/adicao-exclusao-compensacao/adicao-exclusao-compensacao-enums";
import AdicaoExclusaoCompensacaoApuracaoLalurIrpjService from "../../../../../services/adicao-exclusao-compensacao-apuracao-lalur-irpj/adicao-exclusao-compensacao-apuracao-lalur-irpj.service";
import criarNameof from "../../../../../utils/common/cria-name-of";
import { getGridDefaultProps } from "../../../../../utils/grid/grid-utils";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import GridDefaults from "../../../../layout/grid-defaults";
import GetColunasDeAuditoria from "../../../../layout/grid-defaults/colunasDeAuditoria";

const service = new AdicaoExclusaoCompensacaoApuracaoLalurIrpjService();

const nameOfGridHandler =
  criarNameof<AdicaoExclusaoCompensacaoApuracaoLalurIrpjGrid>();

interface ModalDetalhamentoAdicaoExclusaoCompensacaoApuracaoLalurIrpjProps {
  modalVisivel: boolean;
  apuracaoDoLalurEIrpjId: number;
  adicaoOuExclusaoOuCompensacao?: AdicaoOuExclusaoOuCompensacao;
  handleCallbackModal: () => void;
  titulo: string;
}

export default function ModalDetalhamentoAdicaoExclusaoCompensacaoApuracaoLalurIrpj(
  props: ModalDetalhamentoAdicaoExclusaoCompensacaoApuracaoLalurIrpjProps
) {
  let gridRef: DataGrid<any, any> = new DataGrid<any, any>({});
  function setGridRef(ref: any) {
    gridRef = ref;
  }

  function getGridRef() {
    return gridRef;
  }

  function atualizarGrid() {
    gridRef.instance.refresh();
  }

  return (
    <Modal
      titulo={props.titulo}
      visivel={props.modalVisivel}
      onFechar={props.handleCallbackModal}
      altura={"90vh"}
    >
      <DataGrid
        ref={setGridRef}
        dataSource={
          props.modalVisivel &&
          service.ObterGridSource(
            props.apuracaoDoLalurEIrpjId,
            props.adicaoOuExclusaoOuCompensacao!
          )
        }
        {...getGridDefaultProps({
          getGridRef: getGridRef,
          style: {
            height: "100%",
          },
        })}
      >
        {GridDefaults({
          gridId:
            "grid-editform-adicao-exclusao-compensacao-apuracao-lalur-irpj",
          exportavel: true,
          filtravel: true,
          paginavel: true,
          selecionavel: false,
          getGridRef,
          atualizarGrid,
        })}
        <Column
          dataField={nameOfGridHandler("apuracaoDoLalurEIrpjId")}
          visible={false}
          showInColumnChooser={false}
        />
        <Column
          dataField={nameOfGridHandler("adicaoOuExclusaoOuCompensacao")}
          visible={false}
          showInColumnChooser={false}
        />
        <Column
          dataField={nameOfGridHandler("adicaoExclusaoCompensacaoFormatado")}
          {...obterConfiguracaoColuna("stringGG")}
          caption="Adição, exclusão ou compensação"
        />
        <Column
          dataField={nameOfGridHandler("valorTotalDosLancamentosContabeis")}
          {...obterConfiguracaoColuna("monetario")}
          width={280}
          caption="Valor total dos lançamentos contábeis"
        />
        <Column
          dataField={nameOfGridHandler("valorDaParteB")}
          {...obterConfiguracaoColuna("monetario")}
          width={200}
          caption="Valor da parte B"
        />
        <Column
          dataField={nameOfGridHandler("valorDaParteA")}
          {...obterConfiguracaoColuna("monetario")}
          width={200}
          caption="Valor da parte A"
        />
        <Summary>
          <TotalItem
            column={nameOfGridHandler("valorDaParteA")}
            summaryType="sum"
            valueFormat={{ currency: "BRL", minimumFractionDigits: 2 }}
            displayFormat="{0}"
          />
          <TotalItem
            column={nameOfGridHandler("valorDaParteB")}
            summaryType="sum"
            valueFormat={{ currency: "BRL", minimumFractionDigits: 2 }}
            displayFormat="{0}"
          />
          <TotalItem
            column={nameOfGridHandler("valorTotalDosLancamentosContabeis")}
            summaryType="sum"
            valueFormat={{ currency: "BRL", minimumFractionDigits: 2 }}
            displayFormat="{0}"
          />
        </Summary>
        {GetColunasDeAuditoria()}
      </DataGrid>
    </Modal>
  );
}
