import { PropsWithChildren, useState } from "react";
import ContextoBaixarInsumo, {
  FuncoesColunaQuantidadeABaixarInsumo,
} from "../contexto-baixar-insumo";

export function ProvedorBaixarInsumo(props: PropsWithChildren<any>) {
  const [funcoesColunaQuantidadeABaixarInsumo] =
    useState<FuncoesColunaQuantidadeABaixarInsumo>(
      new FuncoesColunaQuantidadeABaixarInsumo()
    );
  return (
    <ContextoBaixarInsumo.Provider
      value={{
        funcoes: funcoesColunaQuantidadeABaixarInsumo,
      }}
    >
      {props.children}
    </ContextoBaixarInsumo.Provider>
  );
}
