import { Column } from "devextreme-react/cjs/data-grid";
import ArrayStore from "devextreme/data/array_store";
import { forwardRef, useRef, useState } from "react";
import { MxpGrid } from "../../../../../components/grid";
import { Modal } from "../../../../../components/layout/modal";
import { useEditarRegistroGrid } from "../../../../../hooks/grid.hooks";
import { DemonstrativoSaldosGridModel } from "../../../../../models/api/demonstrativo-saldos/demonstrativo-saldos-comun";
import { CallBackModal } from "../../../../../models/shared/ui/callback-modal";
import { IGridSelecao } from "../../../../../models/shared/ui/formularios";
import { DemonstrativoSaldosParteBLacsService } from "../../../../../services/demonstrativo-saldos-parte-b-lacs/demonstrativo-saldos-parte-b-lacs";
import criarNameof from "../../../../../utils/common/cria-name-of";
import { GridBaseProps } from "../../../../../utils/grid/grid-utils";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import GetColunasDeAuditoria from "../../../../layout/grid-defaults/colunasDeAuditoria";
import ModalEdicaoDemonstrativosSaldosLacs from "./modal-edicao-demonstrativo-saldos";

const service = new DemonstrativoSaldosParteBLacsService();

const nameOfGridHandler = criarNameof<DemonstrativoSaldosGridModel>();

const colunas = [
  <Column
    key={nameOfGridHandler("contaDaParteB")}
    dataField={nameOfGridHandler("contaDaParteB")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Conta da parte B"
  />,
  <Column
    key={nameOfGridHandler("saldoInicial")}
    dataField={nameOfGridHandler("saldoInicial")}
    {...obterConfiguracaoColuna("monetarioBalanceteCD")}
    caption="Saldo inicial"
    width={125}
  />,
  <Column
    key={nameOfGridHandler("lancamentoDaParteA")}
    dataField={nameOfGridHandler("lancamentoDaParteA")}
    {...obterConfiguracaoColuna("monetarioBalanceteCD")}
    caption="Lançamento da parte A"
    width={190}
  />,
  <Column
    key={nameOfGridHandler("lancamentoDaParteB")}
    dataField={nameOfGridHandler("lancamentoDaParteB")}
    {...obterConfiguracaoColuna("monetarioBalanceteCD")}
    caption="Lançamento da parte B"
    width={190}
  />,
  <Column
    key={nameOfGridHandler("saldoFinal")}
    dataField={nameOfGridHandler("saldoFinal")}
    {...obterConfiguracaoColuna("monetarioBalanceteCD")}
    caption="Saldo final"
    width={125}
  />,
  ...GetColunasDeAuditoria(),
];

interface ModalDemonstrativoSaldosParteBLacsProps extends GridBaseProps {
  modalVisivel: boolean;
  apuracaoLacsCsllId: number;
  handleCallbackModal: () => void;
  titulo: string;
}

export const ModalDemonstrativoSaldosParteBLacs = forwardRef(
  (props: ModalDemonstrativoSaldosParteBLacsProps) => {
    const gridRef = useRef<IGridSelecao>(null);

    const [modalEdicaoVisivel, setModalEdicaoVisivel] = useState(false);
    const [idRegistroEdicao, setIdRegistroEdicao] = useState(NaN);

    const handleEditarRegistro = useEditarRegistroGrid((id) => {
      setIdRegistroEdicao(id);
      setModalEdicaoVisivel(true);
    });

    function handleAtualizarGrid() {
      if (gridRef.current?.atualizarGrid) {
        gridRef.current?.atualizarGrid();
      }
    }

    function handleModalEdicaoCallback(info: CallBackModal) {
      setModalEdicaoVisivel(false);
      setIdRegistroEdicao(NaN);

      if (info.precisaAtualizar) {
        handleAtualizarGrid();
      }
    }

    const dataSource = props.modalVisivel
      ? service.GetGridSourceComParametros({
          idApuracao: props.apuracaoLacsCsllId,
        })
      : new ArrayStore();

    return (
      <>
        <Modal
          titulo={props.titulo}
          visivel={props.modalVisivel}
          onFechar={props.handleCallbackModal}
          altura={"90vh"}
        >
          <MxpGrid<DemonstrativoSaldosGridModel>
            id={"grid-demonstrativo-saldos-parte-b-lacs"}
            ref={gridRef}
            colunas={colunas}
            selecionavel={false}
            isGridInterno={true}
            dataSource={dataSource}
            style={{ height: "100%" }}
            exibirIconeExcluirMenuAcoes={false}
            editarRegistro={handleEditarRegistro}
          />

          <ModalEdicaoDemonstrativosSaldosLacs
            idRegistroEdicao={idRegistroEdicao}
            visivel={modalEdicaoVisivel}
            configuracoesModal={{
              largura: "max(30vw, 600px)",
              altura: "auto",
            }}
            callBackFecharModal={handleModalEdicaoCallback}
          />
        </Modal>
      </>
    );
  }
);
