import AuditavelDTO from "../comum/auditavel-dto";
import AuditavelGridModel from "../comum/auditavel-grid";

export interface ContaDaParteBGridModel extends AuditavelGridModel {
  codigo: string;
  descricao: string;
  tipoTributo: TipoTributoContaParteB;
  tipoTributoDecodificado: string;
  dataCriacaoDaConta: string;
  codigoPadraoDaParteB: string;
  ativo: boolean;
}

export enum TipoTributoContaParteB {
  IRPJ = 1,
  CSLL = 2,
}

export const tipoTributoContaParteBDecodificado: {
  [key in TipoTributoContaParteB]: string;
} = {
  [TipoTributoContaParteB.IRPJ]: "IRPJ",
  [TipoTributoContaParteB.CSLL]: "CSLL",
};

export interface ContaDaParteBRequest {
  id: number;
  codigo: string;
  descricao: string;
  ativo: boolean;
  tipoTributo: TipoTributoContaParteB;
  contaPadraoDaParteBId: number;
  dataCriacaoDaConta?: Date;
}

export interface ContaDaParteBResponse
  extends ContaDaParteBRequest,
    AuditavelDTO {}
