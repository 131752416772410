import { Column } from "devextreme-react/data-grid";
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { renderToString } from "react-dom/server";
import { MxpGrid } from "../../../../../components/grid";
import {
  useEditarRegistroGrid,
  useExcluirRegistroGrid,
  useGerarItensAdicionais,
  useNovoRegistroGrid,
  usePropagarReferenciaGrid,
} from "../../../../../hooks/grid.hooks";
import { useAppDispatch } from "../../../../../hooks/store.hooks";
import { EfdReinfApuracaoGridModel } from "../../../../../models/api/efd-reinf/apuracao/apuracao";
import { PermissoesEfdReinfApuracao } from "../../../../../models/permissoes/fiscal/efd-reinf/permissoes-apuracao-efd-reinf";
import { CallBackModal } from "../../../../../models/shared/ui/callback-modal";
import { IGridSelecao } from "../../../../../models/shared/ui/formularios";
import { EditFormEfdReinfApuracao } from "../../../../../pages/fiscal/efd-reinf/apuracao/edit-form";
import { NomesEndpoints } from "../../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../../services/comum/serviceBase";
import API from "../../../../../services/efd-reinf/apuracao/efd-reinf-apuracao.service";
import { bloquearUI, desbloquearUI } from "../../../../../store/ui/ui.slice";
import {
  checarResponse,
  checarResponseExibeMensagemExclusaoDeSucesso,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import { formatarData } from "../../../../../utils/formatadores/formatador-de-datas";
import { GridBaseProps } from "../../../../../utils/grid/grid-utils";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import GetColunasDeAuditoria from "../../../../layout/grid-defaults/colunasDeAuditoria";

const nomeEndpoint = NomesEndpoints.EfdReinfApuracao;
const dataSource = APIBase.getGridSource(nomeEndpoint);
const nameOfGridHandler = criarNameof<EfdReinfApuracaoGridModel>();
const colunas = [
  <Column
    key={nameOfGridHandler("dataInicial")}
    dataField={nameOfGridHandler("dataInicial")}
    {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
    width={130}
    sortIndex={0}
    sortOrder="desc"
    caption="Data inicial"
  />,
  <Column
    key={nameOfGridHandler("dataFinal")}
    dataField={nameOfGridHandler("dataFinal")}
    {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
    width={130}
    caption="Data final"
  />,
  <Column
    key={nameOfGridHandler("minhaEmpresaApelido")}
    dataField={nameOfGridHandler("minhaEmpresaApelido")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Minha empresa"
    visible={false}
  />,
  ...GetColunasDeAuditoria(true),
];

export const GridEfdReinfApuracao = forwardRef((props: GridBaseProps, ref) => {
  const gridRef = useRef<IGridSelecao>(null);
  useImperativeHandle(ref, () => gridRef.current as IGridSelecao);
  usePropagarReferenciaGrid(ref, gridRef);

  const [modalEdicaoVisivel, setModalEdicaoVisivel] = useState(false);
  const [idRegistroEdicao, setIdRegistroEdicao] = useState(NaN);

  const dispatch = useAppDispatch();

  function handleAtualizarGrid() {
    if (gridRef.current?.atualizarGrid) {
      gridRef.current?.atualizarGrid();
    }
  }

  const handleNovoRegistro = useNovoRegistroGrid(() => {
    setIdRegistroEdicao(0);
    setModalEdicaoVisivel(true);
  });

  const handleEditarRegistro = useEditarRegistroGrid((id: number) => {
    setIdRegistroEdicao(id);
    setModalEdicaoVisivel(true);
  });

  const handleExcluirRegistro = useExcluirRegistroGrid(
    async (registro: EfdReinfApuracaoGridModel) => {
      const mensagem = renderToString(<>{obterMensagemExclusao(registro)}</>);
      const excluir = await exibirConfirmacao("Confirmar exclusão", mensagem);

      if (excluir) {
        const resposta = await APIBase.excluir(registro.id, nomeEndpoint);

        if (resposta) {
          checarResponseExibeMensagemExclusaoDeSucesso(resposta);
          handleAtualizarGrid();
        }
      }
    }
  );

  function obterMensagemExclusao(registro: EfdReinfApuracaoGridModel) {
    return `Tem certeza que deseja excluir o registro entre o período ${formatarData(
      registro.dataInicial
    )} - ${formatarData(registro.dataFinal)}?`;
  }

  const handleGerarItensAdicionais = useGerarItensAdicionais(
    (getData: () => EfdReinfApuracaoGridModel | undefined) => {
      const itensAdicionais = [
        {
          text: "Ações",
          icon: "ic-material-symbols-outlined ic-vertical",
          hint: "Menu com opções de ação",
          items: [
            {
              text: "Recalcular apuração",
              onClick: () => recalcularApuracao(getData()?.id),
            },
          ],
        },
      ];

      return itensAdicionais;
    }
  );

  async function recalcularApuracao(idEfdReinfApuracao?: number) {
    if (
      !verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesEfdReinfApuracao.Recalcular,
      ])
    ) {
      return;
    }

    if (idEfdReinfApuracao) {
      try {
        dispatch(bloquearUI());
        const resposta = await API.RecalcularApuracao(idEfdReinfApuracao);
        checarResponse(resposta);

        if (resposta.sucesso) {
          handleAtualizarGrid();

          exibirNotificacaoToast({
            mensagem: resposta.mensagem,
            tipo: TipoNotificacao.Sucesso,
          });
        }
      } catch (erro) {
        tratarErroApi(erro);
      } finally {
        dispatch(desbloquearUI());
      }
    }
  }

  const handleModalCallback = useCallback((info: CallBackModal) => {
    setModalEdicaoVisivel(false);
    setIdRegistroEdicao(NaN);

    if (info.precisaAtualizar) {
      handleAtualizarGrid();
    }
  }, []);

  return (
    <>
      <MxpGrid<EfdReinfApuracaoGridModel>
        dataSource={dataSource}
        id={"efd-reinf-apuracao"}
        ref={gridRef}
        colunas={colunas}
        novoRegistro={handleNovoRegistro}
        editarRegistro={handleEditarRegistro}
        excluirRegistro={handleExcluirRegistro}
        gerarItensAdicionaisDeContexto={handleGerarItensAdicionais}
        definirMenuSuperior={props.definirMenu}
        nomeDoArquivoAoExportar={NomesTelas.efdReinfApuracao}
      />
      <EditFormEfdReinfApuracao
        visivel={modalEdicaoVisivel}
        idRegistroEdicao={idRegistroEdicao}
        configuracoesModal={{ largura: "max(30vw, 600px)", altura: "auto" }}
        callBackFecharModal={handleModalCallback}
      />
    </>
  );
});
