import { CustomStore } from "devextreme-aspnet-data-nojquery";
import { Column, Editing } from "devextreme-react/data-grid";
import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import { renderToStaticMarkup, renderToString } from "react-dom/server";
import { MxpGrid } from "../../../../../components/grid";
import ContextoBaixarInsumo from "../../../../../components/insumo/contexto-baixar-insumo";
import { ModalBaixarInsumo } from "../../../../../components/insumo/modal-baixar-insumo";
import { ModalEstornarInsumo } from "../../../../../components/insumo/modal-estornar-insumo";
import ContextoMenuEdicaoOrdemDeProducao from "../../../../../components/ordem-de-producao/contexto-menu-op";
import { NomesMenuEdicaoOrdemDeProducao } from "../../../../../components/ordem-de-producao/menu-edicao-op";
import {
  useEditarRegistroGrid,
  useExcluirRegistroGrid,
  useNovoRegistroGrid,
  usePropagarReferenciaGrid,
} from "../../../../../hooks/grid.hooks";
import { useAppDispatch } from "../../../../../hooks/store.hooks";
import {
  InsumoDaOrdemDeProducaoGridModel,
  ObterDescricaoInsumo,
} from "../../../../../models/api/insumo-do-centro-de-trabalho/insumo-da-ordem-de-producao";
import { PermissoesInsumosDasOrdensDeProducao } from "../../../../../models/permissoes/producao/insumo-da-ordem-de-producao/InsumoDaOrdemDeProducaoPermissoes";
import { CallBackModal } from "../../../../../models/shared/ui/callback-modal";
import { IGridSelecao } from "../../../../../models/shared/ui/formularios";
import { InsumoDaOrdemDeProducaoService } from "../../../../../services/insumo-da-ordem-de-producao/insumo-da-ordem-de-producao";
import { bloquearUI, desbloquearUI } from "../../../../../store/ui/ui.slice";
import { checarResponseExibeMensagemExclusaoDeSucesso } from "../../../../../utils/api/api-utils";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { ItemContextMenu } from "../../../../../utils/context-menu/context-menu-utils";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import { GridBaseProps } from "../../../../../utils/grid/grid-utils";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import RenderOnDemand from "../../../../utils/load-on-demand";
import EditFormEdicaoInsumoDaOrdemDeProducao from "../../form-edicao";
import EditFormInsercaoInsumoDaOrdemDeProducao from "../../form-insercao";
import { colunasInsumos } from "../colunas-insumos";
import { QuantidadeABaixarEditavelComponent } from "../quantidade-a-baixar-editavel";

const APIInsumos = new InsumoDaOrdemDeProducaoService();
const nameOfGridHandler = criarNameof<InsumoDaOrdemDeProducaoGridModel>();

const colunas = [
  <Column
    key={nameOfGridHandler("quantidadeABaixar")}
    dataField={nameOfGridHandler("quantidadeABaixar")}
    {...obterConfiguracaoColuna("quantidade")}
    allowEditing={true}
    showEditorAlways={true}
    width={200}
    visibleIndex={11}
    caption="Quantidade a baixar"
    editCellComponent={QuantidadeABaixarEditavelComponent}
  ></Column>,
  ...colunasInsumos,
];

interface GridEmbutidaInsumoDaOrdemDeProducaoProps extends GridBaseProps {
  ordemDeProducaoId: number;
}

const ConfirmarEstornarSelecionados = (
  dataSource: InsumoDaOrdemDeProducaoGridModel[]
) => {
  return (
    <div>
      <p>
        Tem certeza de que deseja estornar as baixas do(s) insumo(s)
        selecionados?
      </p>
      <ul
        style={{
          maxHeight: "200px",
          paddingInlineStart: "0px",
          listStyleType: "none",
          overflow: "auto",
        }}
      >
        {dataSource.map((item) => (
          <li
            className="dx-datagrid-filter-panel"
            style={{ padding: "10px" }}
            key={item.id}
          >
            {ObterDescricaoInsumo(item)}
          </li>
        ))}
      </ul>
    </div>
  );
};

export const GridEmbutidaInsumoDaOrdemDeProducao = forwardRef(
  (props: GridEmbutidaInsumoDaOrdemDeProducaoProps, ref) => {
    const gridRef = useRef<IGridSelecao>(null);
    usePropagarReferenciaGrid(ref, gridRef);
    const dispatch = useAppDispatch();

    const { funcoes } = useContext(ContextoBaixarInsumo);
    const { menu } = useContext(ContextoMenuEdicaoOrdemDeProducao);

    const menus: ItemContextMenu[] = [
      {
        icon: "ic-material-symbols-outlined ic-category",
        hint: "Menu de insumos",
        text: NomesMenuEdicaoOrdemDeProducao.insumos.name,
        name: NomesMenuEdicaoOrdemDeProducao.insumos.name,
        items: [
          {
            icon: "ic-material-symbols-outlined ic-undo",
            hint: "Estornar insumos selecionados",
            text: NomesMenuEdicaoOrdemDeProducao.insumos.estornarSelecionados,
            name: NomesMenuEdicaoOrdemDeProducao.insumos.estornarSelecionados,
            onClick: estornarSelecionados,
          },
        ],
      },
    ];
    useEffect(() => {
      funcoes.definfirAtualizaGridDeInsumos(() => {
        handleAtualizarGrid();
      });

      menu.itens = menu.itens.filter(
        (i) => i.name !== NomesMenuEdicaoOrdemDeProducao.insumos.name
      );

      menu.itens.push(...menus);
    }, []);

    const [modalInsercaoVisivel, setModalInsercaoVisivel] = useState(false);
    const [modalEdicaoVisivel, setModalEdicaoVisivel] = useState(false);
    const [idRegistroEdicao, setIdRegistroEdicao] = useState(NaN);
    const dataSource: CustomStore = APIInsumos.GetGridSourceFiltradoPorOp({
      idOrdemDeProducao: props.ordemDeProducaoId,
    });

    const handleNovoRegistro = useNovoRegistroGrid(() => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesInsumosDasOrdensDeProducao.InserirEditar,
        ])
      ) {
        return;
      }
      setIdRegistroEdicao(0);
      setModalInsercaoVisivel(true);
    });

    const handleEditarRegistro = useEditarRegistroGrid((id) => {
      if (
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesInsumosDasOrdensDeProducao.InserirEditar,
        ])
      ) {
        return;
      }
      setIdRegistroEdicao(id);
      setModalEdicaoVisivel(true);
    });

    async function estornarSelecionados() {
      const insumosSelecionados = (gridRef.current?.obterSelecao() ??
        []) as InsumoDaOrdemDeProducaoGridModel[];
      if (insumosSelecionados.length <= 0) {
        exibirNotificacaoToast({
          mensagem: `Necessário selecionar ao menos uma movimentação.`,
          tipo: TipoNotificacao.Erro,
        });
        return;
      }

      const htmlConfirmarEstorno = renderToStaticMarkup(
        ConfirmarEstornarSelecionados(insumosSelecionados)
      );
      console.log("htmlConfirmarEstorno", htmlConfirmarEstorno);
      const confirmar = await exibirConfirmacao(
        "Atenção",
        htmlConfirmarEstorno
      );

      if (!confirmar) {
        return;
      }

      dispatch(bloquearUI());
      const resultado = await APIInsumos.EstornarInsumos(
        insumosSelecionados.map((i) => i.id)
      );

      console.log("resultado", resultado);

      if (resultado.sucesso) {
        exibirNotificacaoToast({
          mensagem: `Insumos estornandos com sucesso.`,
          tipo: TipoNotificacao.Sucesso,
        });
      }

      dispatch(desbloquearUI());
    }

    function handleAtualizarGrid() {
      if (gridRef.current?.atualizarGrid) {
        gridRef.current?.atualizarGrid();
      }
    }

    const handleExcluirRegistro = useExcluirRegistroGrid(
      async (registro: InsumoDaOrdemDeProducaoGridModel) => {
        if (
          !verificaComNotificacaoSeUsuarioPossuiPermissoes([
            PermissoesInsumosDasOrdensDeProducao.Excluir,
          ])
        ) {
          return;
        }
        const mensagem = renderToString(<>{obterMensagemExclusao(registro)}</>);
        const excluir = await exibirConfirmacao("Confirmar exclusão", mensagem);

        if (excluir) {
          const resposta = await APIInsumos.Excluir(registro.id);

          if (resposta) {
            checarResponseExibeMensagemExclusaoDeSucesso(resposta);
            handleAtualizarGrid();
          }
        }
      }
    );

    function obterMensagemExclusao(registro: InsumoDaOrdemDeProducaoGridModel) {
      return `Tem certeza que deseja excluir o insumo ${ObterDescricaoInsumo(
        registro
      )}?`;
    }

    function handleModalInsercaoCallback(info: CallBackModal) {
      setModalInsercaoVisivel(false);
      setIdRegistroEdicao(NaN);

      if (info.precisaAtualizar) {
        handleAtualizarGrid();
      }
    }

    function handleModalEdicaoCallback(info: CallBackModal) {
      setModalEdicaoVisivel(false);
      setIdRegistroEdicao(NaN);

      if (info.precisaAtualizar) {
        handleAtualizarGrid();
      }
    }

    return (
      <>
        <MxpGrid<InsumoDaOrdemDeProducaoGridModel>
          dataSource={dataSource}
          id={"insumo-da-ordem-de-producao-embutida"}
          ref={gridRef}
          colunas={colunas}
          novoRegistro={handleNovoRegistro}
          editarRegistro={handleEditarRegistro}
          excluirRegistro={handleExcluirRegistro}
          definirMenuSuperior={props.definirMenu}
          nomeDoArquivoAoExportar={NomesTelas.insumosDasOrdensDeProducao}
          propriedadesAdicionaisDeGrid={{ remoteOperations: false }}
          componentesAdicionaisDeGrid={[
            <Editing
              key="Editing"
              allowUpdating={true}
              mode="batch"
              startEditAction="click"
              selectTextOnEditStart={true}
            />,
          ]}
        />

        <ModalBaixarInsumo />

        <ModalEstornarInsumo />

        <RenderOnDemand visivel={modalInsercaoVisivel}>
          <EditFormInsercaoInsumoDaOrdemDeProducao
            idRegistroEdicao={idRegistroEdicao}
            visivel={modalInsercaoVisivel}
            configuracoesModal={{ largura: "max(30vw, 800px)", altura: "auto" }}
            callBackFecharModal={handleModalInsercaoCallback}
            ordemDeProducaoId={props.ordemDeProducaoId}
          ></EditFormInsercaoInsumoDaOrdemDeProducao>
        </RenderOnDemand>

        <RenderOnDemand visivel={modalEdicaoVisivel}>
          <EditFormEdicaoInsumoDaOrdemDeProducao
            idRegistroEdicao={idRegistroEdicao}
            visivel={modalEdicaoVisivel}
            configuracoesModal={{
              largura: "max(30vw, 800px)",
              altura: "auto",
            }}
            callBackFecharModal={handleModalEdicaoCallback}
            ordemDeProducaoId={props.ordemDeProducaoId}
          ></EditFormEdicaoInsumoDaOrdemDeProducao>
        </RenderOnDemand>
      </>
    );
  }
);
