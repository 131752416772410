export enum ChavesBalanceteComparativoGridModel {
  Id = "Id",
  Codigo = "Codigo",
  Classificacao = "Classificacao",
  Descricao = "Descricao",
  Sintetica = "Sintetica",
  Nivel = "Nivel",
}

export enum PrefixosBalanceteComparativoGridModel {
  SaldoInicial = "SaldoInicial_",
  SaldoFinal = "SaldoFinal_",
  Debitos = "Debitos_",
  Creditos = "Creditos_",
  Liquido = "Liquido_",
}
