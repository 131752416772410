import { useState } from "react";
import * as yup from "yup";
import {
  FormSelectBoxLazy,
  FormTextBoxSimples,
} from "../../../../../../components/formularios";
import {
  FormBase2,
  FormularioEdicaoBaseProps,
} from "../../../../../../components/layout/form-base2";
import { Coluna, Linha } from "../../../../../../components/layout/grid-system";
import {
  useCarregarRegistro,
  useHookForms,
  useLimparFormSeIdForNaN,
} from "../../../../../../hooks/form.hooks";
import AuditavelDTO from "../../../../../../models/api/comum/auditavel-dto";
import { EnderecoEstoqueGridModel } from "../../../../../../models/api/endereco-estoque/endereco-estoque";
import {
  VinculoEnderecoEstoqueCentroDeTrabalhoRequestDTO,
  VinculoEnderecoEstoqueCentroDeTrabalhoResponseDTO,
} from "../../../../../../models/api/vinculo-endereco-estoque-centro-de-trabalho/vinculo-endereco-estoque-centro-de-trabalho";
import { CallBackModal } from "../../../../../../models/shared/ui/callback-modal";
import { NomesEndpoints } from "../../../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../../../services/comum/serviceBase";
import APIEnderecoEstoque from "../../../../../../services/estoque/endereco-estoque/endereco-estoque.service";
import {
  checarResponse,
  tratarErroApi,
} from "../../../../../../utils/api/api-utils";
import criarNameof from "../../../../../../utils/common/cria-name-of";
import NomesModais from "../../../../../../utils/common/nomes-modais";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../../utils/common/notificacoes-utils";
import { GridEnderecoEstoque } from "../../../../../estoque/grids/grid-padrao";

interface EditFormAdicionarVinculoEnderecoDeEstoqueProps
  extends FormularioEdicaoBaseProps {
  idCentroDeTrabalho: number;
  codigoCentroDeTrabalhoVinculado: string;
}

const nomeEndpoint = NomesEndpoints.VinculoEnderecosDeEstoqueCentroDeTrabalho;

const nameOfEnderecoDeEstoqueGridHandler =
  criarNameof<EnderecoEstoqueGridModel>();

const exibeEnderecoDeEstoque = (c: any) => {
  if (c) {
    return `${c.Codigo} - ${c.Descricao}`;
  }

  return "";
};

const enderecoDeEstoqueExpressaoDeBusca = [
  nameOfEnderecoDeEstoqueGridHandler("codigo"),
  nameOfEnderecoDeEstoqueGridHandler("descricao"),
];

const enderecosEstoqueCentroDeTrabalho =
  APIEnderecoEstoque.getDataSourceSelectBoxLazy({
    camposRetorno: ["Id", "Codigo", "Descricao"],
    camposOrdenacao: [
      {
        nomeCampo: "Codigo",
        desc: false,
      },
    ],
  });

export const EditFormAdicionarVinculoEnderecoDeEstoque = (
  props: EditFormAdicionarVinculoEnderecoDeEstoqueProps
) => {
  const [carregando, setCarregando] = useState(false);
  const [dadosAuditoria, setDadosAuditoria] = useState<AuditavelDTO>();

  const novoRegistro: VinculoEnderecoEstoqueCentroDeTrabalhoRequestDTO = {
    id: 0,
    idCentroDeTrabalho: props.idCentroDeTrabalho,
    idEnderecoEstoque: 0,
  };

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    idCentroDeTrabalho: yup.number().required().moreThan(0).integer(),
    idEnderecoEstoque: yup.number().required().moreThan(0).integer(),
  });

  const hookForm = useHookForms(schema);
  useCarregarRegistro(props.idRegistroEdicao, carregarTela);
  useLimparFormSeIdForNaN(hookForm, novoRegistro, props.idRegistroEdicao);

  const { control, handleSubmit, getValues, reset } = hookForm;

  async function carregarTela() {
    try {
      setCarregando(true);
      reset(novoRegistro);
      setDadosAuditoria(undefined);

      if (props.idRegistroEdicao > 0) {
        await carregarModel();
      }
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  async function carregarModel() {
    try {
      const resposta =
        await APIBase.obterPorId<VinculoEnderecoEstoqueCentroDeTrabalhoResponseDTO>(
          props.idRegistroEdicao,
          nomeEndpoint
        );
      checarResponse(resposta);
      reset(resposta.model);
      setDadosAuditoria(resposta.model);
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues();

    try {
      const resposta =
        props.idRegistroEdicao > 0
          ? await APIBase.atualizar(model, nomeEndpoint)
          : await APIBase.cadastrar(model, nomeEndpoint);

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar({
          concluido: true,
          precisaAtualizar: true,
        });
      }
      fechar({
        concluido: true,
        precisaAtualizar: false,
      });
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  function fechar(info: CallBackModal) {
    if (props.callBackFecharModal) {
      props.callBackFecharModal(info);
    }
  }

  function handleCancelar() {
    setDadosAuditoria(undefined);
    fechar({ concluido: false, precisaAtualizar: false });
  }

  function callBackUnprocessableEntity() {
    fechar({
      concluido: false,
      precisaAtualizar: true,
    });
  }

  return (
    <>
      <FormBase2
        visivel={props.visivel}
        carregando={carregando}
        onClickSalvar={handleSubmit(handleSalvar)}
        onClickCancelar={handleCancelar}
        configuracoesModal={props.configuracoesModal}
        modoEdicao={props.idRegistroEdicao == 0 ? "criar" : "editar"}
        titulo={NomesModais.vinculoDoCentroDeTrabalhoComEnderecoDeEstoque}
        auditoria={dadosAuditoria}
      >
        <Linha>
          <Coluna md={12}>
            <FormTextBoxSimples
              titulo="Centro de trabalho"
              valor={props.codigoCentroDeTrabalhoVinculado}
              toolTip="Campo referente ao centro de trabalho"
              tipo="text"
              somenteLeitura={true}
            />
          </Coluna>
        </Linha>
        <Linha>
          <Coluna md={12}>
            <FormSelectBoxLazy
              name="idEnderecoEstoque"
              titulo="Endereço de estoque"
              control={control}
              requerido
              nomeCampoChave="Id"
              nomeCampoExibicao={exibeEnderecoDeEstoque}
              expressaoDeBusca={enderecoDeEstoqueExpressaoDeBusca}
              dataSource={enderecosEstoqueCentroDeTrabalho}
              lupaConfig={{
                modo: "selecaoUnica",
                titulo:
                  "Selecionar o endereco de estoque do centro de trabalho",
                componente: (r) => <GridEnderecoEstoque ref={r} />,
              }}
              labelSemDados="Sem dados"
            />
          </Coluna>
        </Linha>
      </FormBase2>
    </>
  );
};
