import { Column } from "devextreme-react/data-grid";
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { renderToString } from "react-dom/server";
import { MxpGrid } from "../../../../../components/grid";
import { Modal } from "../../../../../components/layout/modal";
import {
  useEditarRegistroGrid,
  useExcluirRegistroGrid,
  useGerarItensAdicionais,
  useNovoRegistroGrid,
  usePropagarReferenciaGrid,
} from "../../../../../hooks/grid.hooks";
import { CentroDeTrabalhoGridModel } from "../../../../../models/api/centro-de-trabalho/centro-de-trabalho";
import { CentroDeTrabalhoGrupoGridModel } from "../../../../../models/api/grupo-centro-de-trabalho/grupo-centro-de-trabalho";
import { PermissoesCentroDeTrabalho } from "../../../../../models/permissoes/producao/centro-de-trabalho/permissoes-centro-de-trabalho";
import { CallBackModal } from "../../../../../models/shared/ui/callback-modal";
import { IGridSelecao } from "../../../../../models/shared/ui/formularios";
import EditFormCentroDeTrabalhoGrupo from "../../../../../pages/producao/grupo-centro-de-trabalho/edit-form";
import { NomesEndpoints } from "../../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../../services/comum/serviceBase";
import {
  checarResponseExibeMensagemExclusaoDeSucesso,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import { GridBaseProps } from "../../../../../utils/grid/grid-utils";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import GetColunasDeAuditoria from "../../../../layout/grid-defaults/colunasDeAuditoria";
import { GridCentroDeTrabalho } from "../../../centro-de-trabalho/grids/grid-padrao";

const nomeEndpoint = NomesEndpoints.CentroDeTrabalhoGrupo;
const dataSource = APIBase.getGridSource(nomeEndpoint);
const nameOfGridHandler = criarNameof<CentroDeTrabalhoGrupoGridModel>();
const nameOfGridHandlerCentroDeTrabalho =
  criarNameof<CentroDeTrabalhoGridModel>();
const colunas = [
  <Column
    key={nameOfGridHandler("codigo")}
    dataField={nameOfGridHandler("codigo")}
    {...obterConfiguracaoColuna("stringP")}
    sortIndex={0}
    sortOrder="asc"
    caption="Código"
  />,
  <Column
    key={nameOfGridHandler("descricao")}
    dataField={nameOfGridHandler("descricao")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Descrição"
  />,
  <Column
    key={nameOfGridHandler("grupoDeCentroDeTrabalhoCalendario")}
    dataField={nameOfGridHandler("grupoDeCentroDeTrabalhoCalendario")}
    {...obterConfiguracaoColuna("stringGG")}
    caption="Calendário do grupo de centros de trabalho"
  />,
  ...GetColunasDeAuditoria(),
];

export const GridCentroDeTrabalhoGrupo = forwardRef(
  (props: GridBaseProps, ref) => {
    const gridRef = useRef<IGridSelecao>(null);
    useImperativeHandle(ref, () => gridRef.current as IGridSelecao);
    usePropagarReferenciaGrid(ref, gridRef);

    const [modalEdicaoVisivel, setModalEdicaoVisivel] = useState(false);
    const [idRegistroEdicao, setIdRegistroEdicao] = useState(NaN);

    const [
      filtroCodigoGrupoCentroDeTrabalho,
      setFiltroCodigoGrupoCentroDeTrabalho,
    ] = useState<Array<any>>();

    function handleAtualizarGrid() {
      if (gridRef.current?.atualizarGrid) {
        gridRef.current?.atualizarGrid();
      }
    }

    const handleNovoRegistro = useNovoRegistroGrid(() => {
      setIdRegistroEdicao(0);
      setModalEdicaoVisivel(true);
    });

    const handleEditarRegistro = useEditarRegistroGrid((id: number) => {
      setIdRegistroEdicao(id);
      setModalEdicaoVisivel(true);
    });

    const handleExcluirRegistro = useExcluirRegistroGrid(
      async (registro: CentroDeTrabalhoGrupoGridModel) => {
        const mensagem = renderToString(<>{obterMensagemExclusao(registro)}</>);
        const excluir = await exibirConfirmacao("Confirmar exclusão", mensagem);

        if (excluir) {
          try {
            const resposta = await APIBase.excluir(registro.id, nomeEndpoint);

            if (resposta) {
              checarResponseExibeMensagemExclusaoDeSucesso(resposta);
              handleAtualizarGrid();
            }
          } catch (erro) {
            tratarErroApi(erro);
          }
        }
      }
    );

    function obterMensagemExclusao(registro: CentroDeTrabalhoGrupoGridModel) {
      return `Tem certeza de que deseja excluir o grupo de centro de trabalho ${registro.codigo}?`;
    }

    const handleGerarItensAdicionais = useGerarItensAdicionais(
      (getData: () => CentroDeTrabalhoGrupoGridModel | undefined) => {
        const itensAdicionais = [
          {
            text: "Consultar",
            icon: "ic-material-symbols-outlined ic-manage-search",
            hint: "Menu com opções de consulta",
            items: [
              {
                text: NomesTelas.centrosDeTrabalho,
                icon: "ic-material-symbols-outlined ic-quick-reference-all",
                onClick: () => consultarCentrosDeTrabalho(getData()),
              },
            ],
          },
        ];

        return itensAdicionais;
      }
    );

    function consultarCentrosDeTrabalho(
      centroDeTrabalhoGrupo: CentroDeTrabalhoGrupoGridModel | undefined
    ) {
      if (
        centroDeTrabalhoGrupo &&
        verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesCentroDeTrabalho.Consultar,
        ])
      ) {
        setFiltroCodigoGrupoCentroDeTrabalho([
          nameOfGridHandlerCentroDeTrabalho("centroDeTrabalhoGrupo"),
          "=",
          centroDeTrabalhoGrupo?.descricao
            ? `${centroDeTrabalhoGrupo?.codigo} - ${centroDeTrabalhoGrupo?.descricao}`
            : centroDeTrabalhoGrupo?.codigo,
        ]);
      }
    }

    const handleModalCallback = useCallback((info: CallBackModal) => {
      setModalEdicaoVisivel(false);
      setIdRegistroEdicao(NaN);

      if (info.precisaAtualizar) {
        handleAtualizarGrid();
      }
    }, []);

    const handleModalConsultarCentroDeTrabalhoCallBack = useCallback(() => {
      setFiltroCodigoGrupoCentroDeTrabalho(undefined);
    }, []);

    return (
      <>
        <MxpGrid<CentroDeTrabalhoGrupoGridModel>
          dataSource={dataSource}
          id={"grupo-centros-trabalho"}
          ref={gridRef}
          colunas={colunas}
          novoRegistro={handleNovoRegistro}
          editarRegistro={handleEditarRegistro}
          excluirRegistro={handleExcluirRegistro}
          gerarItensAdicionaisDeContexto={handleGerarItensAdicionais}
          definirMenuSuperior={props.definirMenu}
          nomeDoArquivoAoExportar={NomesTelas.gruposCentrosTrabalho}
        />
        <EditFormCentroDeTrabalhoGrupo
          visivel={modalEdicaoVisivel}
          idRegistroEdicao={idRegistroEdicao}
          configuracoesModal={{ largura: "max(50vw, 800px)", altura: "auto" }}
          callBackFecharModal={handleModalCallback}
        />
        {/*Modal Consultar Centro de Trabalho*/}
        <Modal
          titulo="Consultar centro de trabalho"
          largura={"max(50vw, 800px)"}
          altura={"auto"}
          visivel={filtroCodigoGrupoCentroDeTrabalho !== undefined}
          onFechar={handleModalConsultarCentroDeTrabalhoCallBack}
        >
          <GridCentroDeTrabalho
            prefixoDoIdDoGrid="modal-grupo-centro-de-trabalho"
            sobreporFiltroSalvoComOFiltroPadrao={true}
            valorPadraoDoFiltro={filtroCodigoGrupoCentroDeTrabalho}
            limparFiltroAoTrocarFiltroPadrao={true}
            isModal
            style={{
              minHeight: "30em",
              maxHeight: "calc(100vh - 34em)",
            }}
          />
        </Modal>
      </>
    );
  }
);
