import getApi from "../../configs/api";
import { ListaSimplesResponse } from "../../models/api/comum/response-base";
import { TipoDocumentoEletronico } from "../../models/api/serie-fiscal/serie-fiscal";
import { NomesEndpoints } from "../comum/nomesEndpoints";

const nomeEndpoint = NomesEndpoints.SerieFiscal;

async function obterListaSimples(tipo: TipoDocumentoEletronico) {
  const api = getApi();

  const response = await api.get<ListaSimplesResponse>(
    `${nomeEndpoint}/lista-simples`,
    {
      params: {
        tipo: tipo,
      },
    }
  );

  return response.data;
}

export default {
  obterListaSimples,
};
