import { DataGrid } from "devextreme-react";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { isMobile } from "react-device-detect";
import { SelectBoxLazyFiltros } from "../../models/api/comum/selectboxlazy-options";
import { geraNomeDeArquivoValido } from "../common/common-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../common/notificacoes-utils";
import {
  ItemContextMenu,
  criarTooltipsMenuContexto,
} from "../context-menu/context-menu-utils";

const checarContemFiltroPadrao = (
  filtroPassado: string,
  filtroPadrao: string
) => {
  // O slice retira o primeiro e último colchete da string do JSON do filtro padrão.
  // Em filtros mais complexos os colchetes atrapalham e não são necessários
  // para realizar a comparação do filtro padrão com o filtro passado
  return filtroPassado.includes(filtroPadrao?.slice(1, -1));
};

export function getGridDefaultProps(props: GridConstrutorProps): any {
  return {
    className: isMobile ? "" : "mxp-grid-compacto",
    remoteOperations: props.executarOperacoesNoServidor ?? true,
    allowColumnResizing: true,
    rowAlternationEnabled: true,
    columnResizingMode: "widget",
    allowColumnReordering: true,
    showColumnLines: true,
    width: "100%",
    errorRowEnabled: false,
    columnAutoWidth: false,
    hoverStateEnabled: true,
    repaintChangesOnly: true,
    showCheckBoxesMode: "always",
    noDataText: "Nenhum registro encontrado.",
    defaultFilterValue: props.valorPadraoDoFiltro ?? null,
    onFilterValueChange: (value: any) => {
      //não permitir remover o filtro padrão caso deva sobrepor o filtro com o padrão
      if (
        !checarContemFiltroPadrao(
          JSON.stringify(value),
          JSON.stringify(props.valorPadraoDoFiltro)
        ) &&
        props.sobreporFiltroSalvoComOFiltroPadrao &&
        props.getGridRef &&
        props.valorPadraoDoFiltro
      ) {
        props
          .getGridRef()
          .instance.option("filterValue", props.valorPadraoDoFiltro);
      }
    },
    onContentReady: () => {
      //não permitir remover o filtro padrão caso deva sobrepor o filtro com o padrão
      if (
        props.getGridRef &&
        props.sobreporFiltroSalvoComOFiltroPadrao &&
        props.valorPadraoDoFiltro
      ) {
        const instance = props.getGridRef().instance;

        if (
          instance &&
          !checarContemFiltroPadrao(
            JSON.stringify(instance.state().filterValue),
            JSON.stringify(props.valorPadraoDoFiltro)
          )
        ) {
          instance.option("filterValue", props.valorPadraoDoFiltro);
        }
      }
    },
    onContextMenuPreparing: (e: any) => {
      if (
        props.getGridRef &&
        props.gerarOpcoesDoRegistro &&
        e.row &&
        e.row.rowType === "data"
      ) {
        props.getGridRef().instance.selectRows([e.row.key], false);
        e.items = props.gerarOpcoesDoRegistro(e.row.data);
        criarTooltipsMenuContexto(e);
      }
    },
    height:
      props.style?.height ?? "calc(100vh - (2.35 * var(--header-menu-height)))",
    style: props.style,
    onExporting: (e: any) =>
      exportarGrid(e, props?.nomeDoArquivoAoExportar ?? "Grade"),
  };
}

/**
 * Propriedades base dos grids.
 *
 * @interface GridBaseProps
 * @member {string} nomeDoArquivoAoExportar? Utilizado como nome para o arquivo quando se exportam os dados para Excel.
 * @member {string} prefixoDoIdDoGrid? Prefixo do id do grid, o id é usado para salvar o estado da grid, então deve passar esse prefixo quando quiser que tenha um estado independente.
 *  Ex: Quando gerar um grid para consulta e quiser que o sort e filter dele seja independente.
 * @member {Array<any>} valorPadraoDoFiltro? Diz o filtro padrao para essa grid, de preferencia deve ser usado com o `prefixoDoIdDoGrid` para não sobrescerever outros filtros.
 * @member {(data: ItemContextMenu[]) => void} definirMenu? Função usada para setar o menu da página com os dados de menu do grid.
 * @member {boolean} sobreporFiltroSalvoComOFiltroPadrao? Diz se o filtro deve ser forçado ignorando o filtro que estiver salvo no estado do grid, geralmente usado em grids de consulta.
 * @member {boolean} abrirModalNovoRegistroAoCarregar? Define se já deve instanciar a grid com o modal de novo registro aberto.
 * @member {boolean} executarOperacoesNoServidor? Se a grid deve usar remote operations ou não.
 * @member {boolean} isModal? Define se a grid é um modal ou não.
 * @member {React.CSSProperties | undefined} style? Define as propriedades de estilização da grid.
 * @member {boolean} limparFiltroAoTrocarFiltroPadrao? Define se a grid irá limpar o filtro sempre for inicialmente carregada.
 * @member {SelectBoxLazyFiltros[]} filtrosRealizadosNoServidor? Define os filtros aplicados diretamente no servidor, sem a possibilidade do usuário alterar.
 */
export interface GridBaseProps {
  nomeDoArquivoAoExportar?: string;
  prefixoDoIdDoGrid?: string;
  valorPadraoDoFiltro?: Array<any>;
  definirMenu?: (data: ItemContextMenu[]) => void;
  sobreporFiltroSalvoComOFiltroPadrao?: boolean;
  abrirModalNovoRegistroAoCarregar?: boolean;
  executarOperacoesNoServidor?: boolean;
  isModal?: boolean;
  style?: React.CSSProperties | undefined;
  limparFiltroAoTrocarFiltroPadrao?: boolean;
  ocultarBotaoNovo?: boolean;
  filtrosRealizadosNoServidor?: SelectBoxLazyFiltros[];
}

/**
 * Propriedades dos grids.
 *
 * @interface GridProps
 * @member {boolean} somenteLeitura? Configuração para deixar a grid apenas em modo de leitura.
 */
export interface GridProps extends GridBaseProps {
  somenteLeitura?: boolean;
}

/**
 * Propriedades para construir um grid padrão.
 *
 * @interface GridConstrutorProps
 * @member {DataGrid<any, any>} getGridRef? Função que retorna a referencia do grid.
 * @member {(data: any) => any} gerarOpcoesDoRegistro? Função que retorna os menus da grid.
 */
export interface GridConstrutorProps extends GridBaseProps {
  getGridRef?: () => DataGrid<any, any>;
  gerarOpcoesDoRegistro?: (data: any) => any;
}

export interface PropriedadesExportacaoExtendida {
  allowExportSelectedData?: boolean;
}

export function exportarGrid(e: any, filename: string, worksheetName?: string) {
  const workbook = new Workbook();
  const worksheet = workbook.addWorksheet(worksheetName ?? "Main sheet");
  filename = geraNomeDeArquivoValido(filename);

  if (
    e.component.getController("export")._selectionOnly &&
    e.component.getSelectedRowKeys().length === 0
  ) {
    exibirNotificacaoToast({
      mensagem: "É necessário selecionar um registro.",
      tipo: TipoNotificacao.Advertencia,
    });
    return;
  }

  exportDataGrid({
    component: e.component,
    worksheet,
    autoFilterEnabled: true,
  }).then(() => {
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        `${filename}.xlsx`
      );
    });
  });
}

export function obterDadosLinhasSelecionadasGrid<T>(gridRef: any): T[] {
  return gridRef.getSelectedRowsData() as T[];
}

export function gerarGridId(props: GridBaseProps, gridId: string): string {
  return `${(props.prefixoDoIdDoGrid ?? "") + gridId}`;
}
