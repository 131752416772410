import getApi, {
  processarRequestComPossivelArquivo,
} from "../../../configs/api";
import { ResponseBase } from "../../../models/api/comum/response-base";
import { GerarEcfRequest } from "../../../models/api/ecf/ecf";
import { NomesEndpoints } from "../../comum/nomesEndpoints";

export class EcfService {
  private _nomeEndpoint: NomesEndpoints;

  constructor() {
    this._nomeEndpoint = NomesEndpoints.Ecf;
  }

  public async ObterArquivoIgnorandoDivergencias(model: GerarEcfRequest) {
    const url =
      `${this._nomeEndpoint}/Gerar?dataInicial=${model.dataInicial}` +
      `&dataFinal=${model.dataFinal}&arquivoRetificado=${model.arquivoRetificado}` +
      `&numeroDoReciboAnterior=${model.numeroDoReciboAnterior ?? ""}`;

    const response = await getApi().get(url, {
      headers: {
        ContinuarMesmoComDivergenciaNaApuracao: true,
      },
    });

    return await processarRequestComPossivelArquivo(response);
  }

  public async ObterArquivo(model: GerarEcfRequest) {
    const url =
      `${this._nomeEndpoint}/Gerar?dataInicial=${model.dataInicial}` +
      `&dataFinal=${model.dataFinal}&arquivoRetificado=${model.arquivoRetificado}` +
      `&numeroDoReciboAnterior=${model.numeroDoReciboAnterior ?? ""}`;

    const response = await getApi().get(url);

    return await processarRequestComPossivelArquivo(response);
  }

  public async PodeGerarEcf() {
    const resposta = await getApi().get<ResponseBase>(
      `${this._nomeEndpoint}/VerificarParaGerar`
    );

    return resposta.data;
  }
}
