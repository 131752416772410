import Decimal from "decimal.js";
import { ArquivoBaseModel } from "../../arquivos/arquivo";
import AuditavelDTO from "../../comum/auditavel-dto";
import AuditavelGridModel from "../../comum/auditavel-grid";
import { OrdemDeProducaoEstado } from "./ordem-de-producao-enums";

export interface OrdemDeProducaoGridModel extends AuditavelGridModel {
  id: number;
  numero?: number | null;
  quantidade: number;
  quantidadeConcluida: number;
  quantidadeAConcluir: number;
  necessidadeData: Date;
  itemCodigo?: string | null;
  itemDescricao?: string | null;
  itemDescricaoComplementar?: string | null;
  itemUnidadePrincipalCodigo?: string | null;
  estadoDecodificado: string;
  grupoCodigo?: string | null;
  grupoDescricao?: string | null;
  itemDesenho: string;
  itemDesenhoRevisao?: string | null;
  quantidadeDePerdaPrevista: number;
  quantidadeBruta: number;
  quantidadeNaUnidadeDeVenda: number;
  itemUnidadeDeVendaCodigo?: string | null;
  itemPesoLiquidoUnitario?: number | null;
  pesoLiquidoTotal?: number | null;
  itemPesoBrutoUnitario?: number | null;
  pesoBrutoTotal?: number | null;
  emissaoData?: Date | null;
  emitidoPorApelido?: string | null;
  quantidadeSobra: number;
  origemDecodificada: string;
  inicioPrevistoOuRealizadoData?: Date | null;
  fimPrevistoOuRealizadoData?: Date | null;
  tempoRealizadoEmHoras?: number | null;
  observacoes?: string | null;
  tempoPrevistoEmHoras?: number | null;
  quantidadeOuTempoRealizadoEmPercentual: number;
  quantidadeConcluidaEmPercentual: number;
  maoDeObraCusto: number;
  insumosDisponibilidadeDecodificada: string;
  insumosBaixasEstadoDecodificado: string;
  itemPontoDeRessuprimento: number;
}

export interface OrdemDeProducaoRequest {
  id: number;
  numero: number;
  itemId: number;
  quantidade: number;
  fixarQuantidade: boolean;
  quantidadeDePerdaPrevista?: number;
  quantidadeBruta?: number;
  necessidadeData: Date;
  fixarNecessidadeData: boolean;
  estado: OrdemDeProducaoEstado;
  estadoConfiguravelId?: number;
  observacoes: string;
  arquivos: Array<ArquivoBaseModel>;
}

export interface OrdemDeProducaoResponse
  extends OrdemDeProducaoRequest,
    AuditavelDTO {
  numero: number;
  itemDescricao: string;
}

export const quantidadeMinima: Decimal = new Decimal(0);
export const quantidadeMaxima: Decimal = new Decimal(9_999_999_999.99999);
export const quantidadeDePerdaPrevistaMinima: Decimal = new Decimal(0);
export const quantidadeDePerdaPrevistaMaxima: Decimal = new Decimal(
  9_999_999_999.99999
);
export const proximoNumeroDaOrdemDeProducaoMinimo: Decimal = new Decimal(1);
export const proximoNumeroDaOrdemDeProducaoMaximo: Decimal = new Decimal(
  9_999_999_999
);
