import AuditavelGridModel from "../comum/auditavel-grid";

export enum ContaContabilTipo {
  Analitica = 65,
  Sintetica = 83,
}

export enum ObrigatoriedadeCentroDeCustos {
  Obrigatoria = 83,
  Opcional = 79,
  Proibida = 78,
}

export interface ContaContabilGridModel extends AuditavelGridModel {
  classificacao: string;
  descricao: string;
  codigo: string | null;
  tipo: ContaContabilTipo;
  ativo: boolean;
  obrigatoriedadeCentroDeCustos: ObrigatoriedadeCentroDeCustos;
}
