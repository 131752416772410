import { naoPorExtenso, simPorExtenso } from "../../utils/common/constantes";
import SelectItem from "../shared/ui/select-item";

export const CaracterInvisivel = "‎";

export const SelectItemBoolSimNao: SelectItem[] = selectBooleanoSimNao();

function selectBooleanoSimNao(): SelectItem[] {
  const retorno: SelectItem[] = [];

  retorno.push({
    valor: true,
    descricao: simPorExtenso,
  });

  retorno.push({
    valor: false,
    descricao: naoPorExtenso,
  });

  return retorno;
}
