import { TokenResponse } from "../../models/api/tokens/token-responses";
import Sessao from "../../models/dto/sessao/sessao";
import { NomesEndpoints } from "../../services/comum/nomesEndpoints";
import store from "../../store";
import {
  SessaoRedux,
  gravarDadosSessao,
  removerDadosSessao,
} from "../../store/sessao/sessao.slice";
import GestorLocalStorage, {
  ChavesLocalstorage,
} from "../storage/gestor-storage";

export function criarUrlCallbackAntigoMaxiprod(
  payload: string,
  returnUrl: string | null
) {
  const urlHomeAntigoMxp = process.env
    .REACT_APP_BACKEND_ANTIGO_ENDPOINT as string;
  const url = new URL(`${urlHomeAntigoMxp}/Login/OauthCallBack`);
  url.searchParams.append("payload", payload);

  if (returnUrl) {
    url.searchParams.append("returnUrl", returnUrl);
  }

  return url.toString();
}

export function VerificaSeRotaPertenceAoLoginOAuth(rota: string) {
  if (!rota.startsWith("/")) {
    rota = `/${rota}`;
  }

  const rotasOauth: string[] = ["/oauth", "/logout"];

  return rotasOauth.includes(rota.toLowerCase());
}

export function GravarSessaoReduxELocalStorage(
  sessao: Sessao,
  token: TokenResponse
) {
  const sessaoRedux: SessaoRedux = {
    usuarioAutenticado: true,
    dadosSessao: sessao.dadosSessao,
  };

  store.dispatch(gravarDadosSessao(sessaoRedux));
  GestorLocalStorage.GravarItem<TokenResponse>(
    ChavesLocalstorage.DadosSessao,
    token
  );
}

export function RemoverSessaoReduxELocalStorage(
  tentarRemoverSessaoBackEnd?: boolean
) {
  if (tentarRemoverSessaoBackEnd) {
    tentarRemoverSessaoBackend().finally(() => {
      store.dispatch(removerDadosSessao());
      GestorLocalStorage.RemoverItem(ChavesLocalstorage.DadosSessao);
    });
  } else {
    store.dispatch(removerDadosSessao());
    GestorLocalStorage.RemoverItem(ChavesLocalstorage.DadosSessao);
  }
}

async function tentarRemoverSessaoBackend() {
  const dadosLocalStorage = GestorLocalStorage.LerItem<TokenResponse>(
    ChavesLocalstorage.DadosSessao
  );
  await fetch(
    `${process.env.REACT_APP_BACKEND_ENDPOINT}${NomesEndpoints.Login}/logoff`,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        "Accept-Language": "pt-BR,pt;q=0.9,en-US;q=0.8,en;q=0.7",
        Authorization: `Bearer ${dadosLocalStorage?.token}`,
      },
      method: "GET",
    }
  );
}
