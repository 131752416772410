import Pagina from "../../../components/layout/pagina";
import RequerPermissao from "../../../components/seguranca/requer-permissao";
import { CertificadoDigitalPermissoes } from "../../../models/permissoes/sistema/certificado-digital/CertificadoDigitalPermissoes";
import { GridCertificadoDigital } from "../../../parts/sistema/certificado-digital/grid";
import NomesTelas from "../../../utils/common/nomes-telas";
import { PageProps } from "../../../utils/common/page-utils";

export default function CertificadoDigitalPage(props: PageProps) {
  return (
    <Pagina
      id="certificado-digital-page"
      titulo={NomesTelas.certificadoDigital}
      area={"Fiscal"}
    >
      <RequerPermissao
        codigoPermissoes={[CertificadoDigitalPermissoes.Consultar]}
      >
        <GridCertificadoDigital
          abrirModalNovoRegistroAoCarregar={props.abrirModalNovoRegistro}
        />
      </RequerPermissao>
    </Pagina>
  );
}
