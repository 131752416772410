import { AxiosResponse, HttpStatusCode } from "axios";
import getApi from "../../configs/api";
import {
  ResponseBase,
  ResponseModel,
} from "../../models/api/comum/response-base";
import { DadosSessaoResponse } from "../../models/api/tokens/dados-sessao-response";
import {
  PayloadRequest,
  TokenBypassRequest,
  TokenFrontRequest,
} from "../../models/api/tokens/token-requests";
import {
  AcaoAdicionalLoginDto,
  TokenResponse,
} from "../../models/api/tokens/token-responses";
import { TrocaEmpresaLoginRequest } from "../../models/api/tokens/troca-empresa-login-request";
import { ExtrasLogin } from "../../models/dto/sessao/sessao";
import { tratarErroApi } from "../../utils/api/api-utils";
import { NomesEndpoints } from "../comum/nomesEndpoints";

const nomeEndpoint = NomesEndpoints.Login;
export async function getToken(
  request: TokenFrontRequest
): Promise<[TokenResponse | undefined, ExtrasLogin | undefined]> {
  try {
    const api = getApi();

    api.defaults.headers.common;

    const response = await api.post<TokenFrontRequest, AxiosResponse>(
      `/${nomeEndpoint}/GetTokenCaptchaAsync`,
      request
    );

    return [response.data as TokenResponse, undefined];
  } catch (error) {
    const erro = error as any;
    if (erro?.response?.status == HttpStatusCode.PreconditionRequired) {
      return [
        undefined,
        {
          acaoAdicional: erro.response!
            .data as ResponseModel<AcaoAdicionalLoginDto>,
        },
      ];
    }

    tratarErroApi(error);
    const msg =
      (error as any)?.response?.data?.mensagem ?? (error as any)?.message;
    return [undefined, { mensagemErro: msg }];
  }
}

export async function getTokenPassandoInformacoesExcluirSessoes(
  request: TokenFrontRequest,
  excluirSessoes: boolean,
  token: string
): Promise<TokenResponse | undefined> {
  try {
    const api = getApi();
    const response = await api.post<
      TokenFrontRequest,
      AxiosResponse<TokenResponse>
    >(`/${nomeEndpoint}/GetTokenCaptchaAsync`, request, {
      headers: {
        DeleteAnterior: String(excluirSessoes),
        DeleteAnteriorToken: token,
      },
    });

    return response.data;
  } catch (error) {
    tratarErroApi(error);
  }
}

export async function getTokenAutoLogin(
  payload: string
): Promise<TokenResponse | undefined> {
  const request: PayloadRequest = {
    payload: payload,
  };
  try {
    const api = getApi();
    const response = await api.post<
      PayloadRequest,
      AxiosResponse<TokenResponse>
    >(`/${nomeEndpoint}/GetTokenAutologin`, request);
    return response.data;
  } catch (error) {
    tratarErroApi(error);
  }
}

export async function getTokenSSO(
  request: TokenBypassRequest
): Promise<TokenResponse | undefined> {
  try {
    const api = getApi();
    const response = await api.post<
      TokenBypassRequest,
      AxiosResponse<TokenResponse>
    >(`/${nomeEndpoint}/GetTokenFromString`, request);
    return response.data;
  } catch (error) {
    tratarErroApi(error);
  }
}

export async function TrocaEmpresaLogin(
  trocaEmpresaRequest: TrocaEmpresaLoginRequest
): Promise<TokenResponse | undefined> {
  try {
    const api = getApi();
    const response = await api.post<
      TrocaEmpresaLoginRequest,
      AxiosResponse<ResponseModel<TokenResponse>>
    >(`/${nomeEndpoint}/TrocarEmpresa`, trocaEmpresaRequest);

    return response.data.model as TokenResponse;
  } catch (error) {
    tratarErroApi(error);
  }
}

export async function getDadosSessao(
  tokenResponse: TokenResponse
): Promise<DadosSessaoResponse | undefined> {
  try {
    const api = getApi(tokenResponse);
    const response = await api.get<ResponseModel<DadosSessaoResponse>>(
      `/${nomeEndpoint}/ObterDadosSessao`
    );

    return response.data.model as DadosSessaoResponse;
  } catch (error) {
    tratarErroApi(error);
  }
}

export async function efetuarLogoffPayload(payload: string) {
  const api = getApi();
  const url = `/${nomeEndpoint}/LogoffOauth?payload=${encodeURIComponent(
    payload
  )}`;
  const response = await api.get<ResponseBase>(url);
  return response.data;
}

export async function efetuarLogoff() {
  const api = getApi();
  const url = `/${nomeEndpoint}/Logoff`;
  const response = await api.get<ResponseBase>(url);
  return response.data;
}

export async function validarPayloadOauthNaoAutenticado(payload: string) {
  const api = getApi();
  const response = await api.get<ResponseBase>(
    `/${nomeEndpoint}/ValidarPayloadNaoAutenticado?payload=${encodeURIComponent(
      payload
    )}`
  );

  return response.data;
}

export async function validarPayloadOauthAutenticado(payload: string) {
  const api = getApi();
  const response = await api.get<ResponseModel<string>>(
    `/${nomeEndpoint}/ValidarPayloadAutenticado?payload=${encodeURIComponent(
      payload
    )}`
  );

  return response.data;
}

export async function obterPayloadCallBackOauth() {
  const api = getApi();
  const response = await api.get<ResponseModel<string>>(
    `/${nomeEndpoint}/ObterPayloadCallBackOauth`
  );

  return response.data;
}
