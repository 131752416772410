import { Column, DataGridTypes } from "devextreme-react/cjs/data-grid";
import { decodificaBooleanoEmSimNao } from "../../../../models/api/comum/decodificadores";
import { InsumoDaOrdemDeProducaoGridModel } from "../../../../models/api/insumo-do-centro-de-trabalho/insumo-da-ordem-de-producao";
import criarNameof from "../../../../utils/common/cria-name-of";
import obterConfiguracaoColuna from "../../../../utils/grid/padroes-colunas";

const nameOfGridHandler = criarNameof<InsumoDaOrdemDeProducaoGridModel>();

export const colunasInsumos = [
  <Column
    key={nameOfGridHandler("operacao")}
    dataField={nameOfGridHandler("operacao")}
    {...obterConfiguracaoColuna("stringM")}
    allowEditing={false}
    caption="Operação"
  />,
  <Column
    key={nameOfGridHandler("codigo")}
    dataField={nameOfGridHandler("codigo")}
    {...obterConfiguracaoColuna("stringM")}
    allowEditing={false}
    caption="Código"
  />,
  <Column
    key={nameOfGridHandler("descricao")}
    dataField={nameOfGridHandler("descricao")}
    {...obterConfiguracaoColuna("stringM")}
    allowEditing={false}
    caption="Descrição"
    cellRender={(data: DataGridTypes.ColumnCellTemplateData) => {
      const dadosLinha: InsumoDaOrdemDeProducaoGridModel = data.row.data;

      if (dadosLinha.exigeBaixa == false) {
        return (
          <>
            <div className={`ic-text-cell-with-icon`}>
              <span className={`ic-text-cell`}>{dadosLinha.descricao}</span>
              <span
                className={`ic-column-icon ic-2x ic-material-symbols-outlined ic-block ic-warning`}
                title="O item não exige baixa"
              ></span>
            </div>
          </>
        );
      }

      return <>{dadosLinha.descricao}</>;
    }}
  />,
  <Column
    key={nameOfGridHandler("descricaoComplementar")}
    dataField={nameOfGridHandler("descricaoComplementar")}
    {...obterConfiguracaoColuna("stringG")}
    allowEditing={false}
    visible={false}
    caption="Descrição complementar"
  />,
  <Column
    key={nameOfGridHandler("quantidadeSemPercentualDePerda")}
    dataField={nameOfGridHandler("quantidadeSemPercentualDePerda")}
    {...obterConfiguracaoColuna("quantidade")}
    allowEditing={false}
    caption="Qt sem percentual de perda"
  />,
  <Column
    key={nameOfGridHandler("perdaEmPercentual")}
    dataField={nameOfGridHandler("perdaEmPercentual")}
    {...obterConfiguracaoColuna("stringG")}
    allowEditing={false}
    caption="Perda em percentual"
  />,
  <Column
    key={nameOfGridHandler("quantidadeTotal")}
    dataField={nameOfGridHandler("quantidadeTotal")}
    {...obterConfiguracaoColuna("quantidade")}
    allowEditing={false}
    caption="Qt total"
  />,
  <Column
    key={nameOfGridHandler("unidade")}
    dataField={nameOfGridHandler("unidade")}
    {...obterConfiguracaoColuna("stringG")}
    allowEditing={false}
    caption="Unidade"
    visible={false}
  />,
  <Column
    key={nameOfGridHandler("quantidadeParaUmaUnidade")}
    dataField={nameOfGridHandler("quantidadeParaUmaUnidade")}
    {...obterConfiguracaoColuna("quantidade")}
    allowEditing={false}
    caption="Qt para uma unidade"
    visible={false}
  />,
  <Column
    key={nameOfGridHandler("quantidadeBaixada")}
    dataField={nameOfGridHandler("quantidadeBaixada")}
    {...obterConfiguracaoColuna("quantidade")}
    allowEditing={false}
    caption="Qt baixada"
    cellRender={(data: DataGridTypes.ColumnCellTemplateData) => {
      const dadosLinha: InsumoDaOrdemDeProducaoGridModel = data.row.data;

      if (dadosLinha.quantidadeBaixada > dadosLinha.quantidadeTotal) {
        return (
          <>
            {dadosLinha.quantidadeBaixada}
            <span
              className={`ic-column-icon ic-2x ic-material-symbols-outlined ic-keyboard-arrow-right ic-warning`}
              title="Estoque baixado acima do necessário."
            ></span>
          </>
        );
      }

      return <>{dadosLinha.quantidadeBaixada}</>;
    }}
  />,
  <Column
    key={nameOfGridHandler("loteInterno")}
    dataField={nameOfGridHandler("loteInterno")}
    {...obterConfiguracaoColuna("stringG")}
    allowEditing={false}
    caption="Lote interno"
  />,
  <Column
    key={nameOfGridHandler("loteDoFabricante")}
    dataField={nameOfGridHandler("loteDoFabricante")}
    {...obterConfiguracaoColuna("stringG")}
    allowEditing={false}
    caption="Lote do fabricante"
  />,
  <Column
    key={nameOfGridHandler("enderecoDeEstoque")}
    dataField={nameOfGridHandler("enderecoDeEstoque")}
    {...obterConfiguracaoColuna("stringG")}
    allowEditing={false}
    caption="Endereço de estoque"
  />,
  <Column
    key={nameOfGridHandler("estoqueDisponivel")}
    dataField={nameOfGridHandler("estoqueDisponivel")}
    {...obterConfiguracaoColuna("stringM")}
    allowEditing={false}
    caption="Estoque disponível"
  />,
  <Column
    key={nameOfGridHandler("estoqueDisponivelExplosao")}
    dataField={nameOfGridHandler("estoqueDisponivelExplosao")}
    {...obterConfiguracaoColuna("stringG")}
    allowEditing={false}
    caption="Estoque disponível para explosão"
    visible={false}
  />,
  <Column
    key={nameOfGridHandler("reservado")}
    dataField={nameOfGridHandler("reservado")}
    {...obterConfiguracaoColuna("boolSimNao")}
    allowEditing={false}
    caption="Reservado"
    cellRender={({ data }) => {
      const dados = data as InsumoDaOrdemDeProducaoGridModel;
      return decodificaBooleanoEmSimNao(dados.reservado);
    }}
  />,
  <Column
    key={nameOfGridHandler("dono")}
    dataField={nameOfGridHandler("dono")}
    {...obterConfiguracaoColuna("stringG")}
    allowEditing={false}
    caption="Dono"
  />,
  <Column
    key={nameOfGridHandler("tipoDoEstoque")}
    dataField={nameOfGridHandler("tipoDoEstoque")}
    {...obterConfiguracaoColuna("stringM")}
    allowEditing={false}
    caption="Tipo do estoque"
    visible={false}
  />,
  <Column
    key={nameOfGridHandler("itemPai")}
    dataField={nameOfGridHandler("itemPai")}
    {...obterConfiguracaoColuna("stringM")}
    allowEditing={false}
    caption="Item pai"
    visible={false}
  />,
  <Column
    key={nameOfGridHandler("descricaoDoItemPai")}
    dataField={nameOfGridHandler("descricaoDoItemPai")}
    {...obterConfiguracaoColuna("quantidade")}
    allowEditing={false}
    caption="Descrição do item pai"
    visible={false}
  />,
];
