import { createContext } from "react";
import { ItemContextMenu } from "../../../utils/context-menu/context-menu-utils";

export class ItensMenuEdicaoOrdemDeProducao {
  itens: ItemContextMenu[];

  constructor() {
    this.itens = [];
  }
}

interface ContextoMenuEdicaoOrdemDeProducaoProps {
  menu: ItensMenuEdicaoOrdemDeProducao;
}

const ContextoMenuEdicaoOrdemDeProducao =
  createContext<ContextoMenuEdicaoOrdemDeProducaoProps>(
    {} as ContextoMenuEdicaoOrdemDeProducaoProps
  );

export default ContextoMenuEdicaoOrdemDeProducao;
