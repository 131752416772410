import getApi from "../../configs/api";
import { ApuracaoLacsCsllAlterarRequest } from "../../models/api/apuracao-lacs-csll/apuracao-lacs-csll";
import {
  ResponseBase,
  ResponseModel,
} from "../../models/api/comum/response-base";
import criarDatasourceGrid from "../../utils/grid/back-end-grid-utils";
import { NomesEndpoints } from "../comum/nomesEndpoints";

export class ApuracaoLacsCsllService {
  private _nomeEndpoint: NomesEndpoints;

  constructor() {
    this._nomeEndpoint = NomesEndpoints.ApuracaoLacsCsll;
  }

  public async ObterPorIdComDadosAuditoria<T>(id: number) {
    const api = getApi();
    const response = await api.get<ResponseModel<T>>(
      `${this._nomeEndpoint}/ObterPorIdComDadosAuditoria?id=${id}`
    );

    return response.data;
  }

  public async Cadastrar<T>(model: T) {
    const api = getApi();
    const response = await api.post<ResponseBase>(this._nomeEndpoint, model);

    return response.data;
  }

  public async Recalcular(id: number) {
    const api = getApi();
    const response = await api.put<ResponseBase>(
      `${this._nomeEndpoint}/Recalcular?id=${id}`
    );

    return response.data;
  }

  public async Atualizar(model: ApuracaoLacsCsllAlterarRequest) {
    const api = getApi();
    const response = await api.put<ResponseBase>(this._nomeEndpoint, model);

    return response.data;
  }

  public GetGridSource() {
    return criarDatasourceGrid(`${this._nomeEndpoint}/grid`);
  }

  public async Excluir(id: number) {
    const api = getApi();
    const response = await api.delete<ResponseBase>(
      `${this._nomeEndpoint}?id=${id}`
    );

    return response.data;
  }

  public async TornarLucroOuPrejuizoLivre(id: number) {
    const api = getApi();
    const response = await api.patch<ResponseBase>(
      `${this._nomeEndpoint}/TornarLucroOuPrejuizoLivre?id=${id}`
    );

    return response.data;
  }
}
