export interface iconeAjudaProps{
  linhaUnica?: boolean
}

export default function IconeAjuda(props: iconeAjudaProps) {
  return (
    <span
      style={{ color: "cadetblue", paddingLeft: props.linhaUnica ? "10px" : undefined}}
      className="fa fa-question"
    ></span>
  );
}
