import { Button } from "devextreme-react";
import { ContainerLogo, Link } from "../../../pages/autenticacao/login/styles";
import {
  ContainerBotao,
  ContainerCardLogin,
  ContainerRodape,
  HeaderCardLogin,
} from "./styles";
import "./index.scss";

interface CardLoginProps {
  titulo: string;
  children: React.ReactNode;
  onClickEntrar: () => void;
  botoesVisiveis: boolean;
}

export default function CardLogin(props: CardLoginProps) {
  const urlMxp1 = process.env.REACT_APP_BACKEND_ANTIGO_ENDPOINT as string;
  return (
    <>
      <ContainerCardLogin className="dx-card content">
        <HeaderCardLogin>
          <ContainerLogo>
            <img src="./img/Logo_ERP_MAXIPROD.svg" alt="Logotipo da Maxiprod" />
          </ContainerLogo>
          <Button
            text="Ir para o site"
            stylingMode="outlined"
            style={{ borderRadius: "30px" }}
            onClick={() =>
              window.open("https://www.maxiprod.com.br/", "_blank")
            }
          />
        </HeaderCardLogin>
        <div className="tituloLogin">{props.titulo}</div>

        {props.children}

        <ContainerRodape>
          <ContainerBotao>
            {props.botoesVisiveis && (
              <Link id="botaoCriarConta" href={`${urlMxp1}/Assinante/Create`}>
                {" "}
                <b>Criar conta</b>
              </Link>
            )}
            <Button
              text="Entrar"
              onClick={props.onClickEntrar}
              type="default"
              width={"6rem"}
              height={30}
              visible={props.botoesVisiveis}
            />
          </ContainerBotao>
        </ContainerRodape>
      </ContainerCardLogin>
    </>
  );
}
