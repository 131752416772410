import { Button, TabPanel } from "devextreme-react";
import { Item } from "devextreme-react/tab-panel";
import { useState } from "react";
import { renderToString } from "react-dom/server";
import * as yup from "yup";
import ProvedorAjuda from "../../../../components/ajuda/provedor-ajuda";
import {
  FormBase2,
  FormularioEdicaoBaseProps,
} from "../../../../components/layout/form-base2";
import { QuebrarLinhas } from "../../../../components/texto/quebrar-linhas";
import useEffectOnLoad from "../../../../hooks/effect.hooks";
import {
  useCarregarDadosDoModelo,
  useHookForms,
} from "../../../../hooks/form.hooks";
import AuditavelDTO from "../../../../models/api/comum/auditavel-dto";
import { ResponseBase } from "../../../../models/api/comum/response-base";
import {
  LoteRequestDTO,
  LoteResponseDTO,
  loteDoFabricanteOuNumeroDeSerieMaxLength,
} from "../../../../models/api/lote/lote";
import { CallBackModal } from "../../../../models/shared/ui/callback-modal";
import LoteAbaAnexos from "../../../../parts/estoque/lote/abas/anexos";
import LoteAbaDadosGerais from "../../../../parts/estoque/lote/abas/dados-gerais";
import LoteAbaHistorico from "../../../../parts/estoque/lote/abas/historico";
import LoteAbaObservacoes from "../../../../parts/estoque/lote/abas/observacoes";
import APIAnexos from "../../../../services/comum/anexos/anexos.service";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../services/comum/serviceBase";
import ApiConfiguracoes from "../../../../services/configuracoes/configuracoes.service";
import APILoteENumeroDeSeriePrefixo from "../../../../services/item/numero-de-serie-prefixo/numero-de-serie-prefixo.service";
import { checarResponse, tratarErroApi } from "../../../../utils/api/api-utils";
import NomesModais from "../../../../utils/common/nomes-modais";
import exibirNotificacaoToast, {
  JanelasDeNotificacaoTitulos,
  TipoNotificacao,
} from "../../../../utils/common/notificacoes-utils";
import { exibirAlerta, exibirConfirmacao } from "../../../../utils/dialogos";
import { abrirModalDetalhesDoLoteMxp1 } from "../../../../utils/especifico/lote/lote-utils";

const nomeEndpoint = NomesEndpoints.Lote;

const novoRegistro: LoteRequestDTO = {
  id: 0,
  itemId: null,
  fabricanteId: null,
  loteDoFabricanteOuNumeroDeSerie: null,
  historico: null,
  reanaliseData: null,
  vencimentoData: null,
  fabricacaoData: null,
  observacoes: null,
  observacoesInternas: null,
  arquivos: [],
  utilizaPrefixo: false,
};

export const EditFormLote = (props: FormularioEdicaoBaseProps) => {
  const [loteDataRequerido, setLoteDataRequerido] = useState(true);
  const [controleLoteFabricacao, setControleLoteFabricacao] = useState(true);
  const [carregando, setCarregando] = useState(true);
  const [dadosAuditoria, setDadosAuditoria] = useState<AuditavelDTO>();

  const isRegistroEmEdicao = props.idRegistroEdicao != 0;

  useCarregarDadosDoModelo(props.idRegistroEdicao, carregarTela);

  useEffectOnLoad(() => {
    ApiConfiguracoes.obterConfiguracoesDeEstoque().then((resposta) => {
      setLoteDataRequerido(resposta.loteDatasRequerido ?? false);
      setControleLoteFabricacao(resposta.controleLoteFabricacao ?? false);
    });
  });

  async function carregarTela() {
    try {
      setCarregando(true);
      if (props.idRegistroEdicao > 0) {
        await carregarModel();
      } else {
        reset(novoRegistro);
        setDadosAuditoria(undefined);
      }
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  async function carregarModel() {
    try {
      const [resposta, anexos] = await Promise.all([
        APIBase.obterPorIdComDadosAuditoria<LoteResponseDTO>(
          props.idRegistroEdicao,
          nomeEndpoint
        ),
        APIAnexos.obterAnexos(props.idRegistroEdicao, nomeEndpoint),
      ]);

      checarResponse(resposta);

      resposta.model.arquivos = anexos;
      reset(resposta.model);
      setDadosAuditoria({
        criacaoUsuarioApelido: resposta.model.criacaoUsuarioApelido,
        criacaoData: resposta.model.criacaoData,
        alteracaoUsuarioApelido: resposta.model.alteracaoUsuarioApelido,
        ultimaAlteracaoData: resposta.model.ultimaAlteracaoData,
      });
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    }
  }

  function fechar(info: CallBackModal) {
    if (props.callBackFecharModal) {
      props.callBackFecharModal(info);
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues() as LoteRequestDTO;
    try {
      let resposta: ResponseBase;

      const confirmaCriacaoDeLoteComDadosNaoEncontradosEmTagPrefixoESufixoDoLote =
        await handlerVerificacaoDeTranscricaoDoPrefixoESufixoDoLote(model);

      if (
        !confirmaCriacaoDeLoteComDadosNaoEncontradosEmTagPrefixoESufixoDoLote
      ) {
        return;
      }

      if (props.idRegistroEdicao > 0) {
        const [respostaApi, respostaAnexo] = await Promise.all([
          APIBase.atualizar(model, nomeEndpoint),
          APIAnexos.anexoAlterarLista(
            model.id,
            model.arquivos ?? [],
            nomeEndpoint
          ),
        ]);

        resposta = respostaApi;

        checarResponse(respostaAnexo);
      } else {
        const respostaInicial = await APIBase.cadastrarComRetorno<
          LoteRequestDTO,
          LoteResponseDTO
        >(model as LoteRequestDTO, nomeEndpoint);
        if (respostaInicial.sucesso) {
          const id = respostaInicial.model.id;
          const respostaAnexo = await APIAnexos.anexoAlterarLista(
            id,
            model.arquivos ?? [],
            nomeEndpoint
          );
          await verificaSeLoteDoFabricanteNumeroSerieSaoDiferentesExibeAlerta(
            model.loteDoFabricanteOuNumeroDeSerie ?? "",
            respostaInicial.model.loteDoFabricanteOuNumeroDeSerie ?? ""
          );
          checarResponse(respostaAnexo);
        }

        resposta = {
          sucesso: respostaInicial.sucesso,
          mensagem: respostaInicial.mensagem,
        };
      }

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar({
          concluido: true,
          precisaAtualizar: true,
        });
      }
      fechar({
        concluido: true,
        precisaAtualizar: false,
      });
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  async function verificaSeLoteDoFabricanteNumeroSerieSaoDiferentesExibeAlerta(
    loteDoFabricanteNumeroSerieInicial: string,
    loteDoFabricanteNumeroSerieFinal: string
  ) {
    if (
      loteDoFabricanteNumeroSerieInicial == "" ||
      loteDoFabricanteNumeroSerieFinal == ""
    ) {
      return;
    }

    if (
      loteDoFabricanteNumeroSerieInicial !== loteDoFabricanteNumeroSerieFinal
    ) {
      await exibirAlerta(
        JanelasDeNotificacaoTitulos.Atencao,
        `O lote do fabricante número ${loteDoFabricanteNumeroSerieInicial} já foi utilizado. Foi criado o lote do fabricante ${loteDoFabricanteNumeroSerieFinal}.`
      );
    }
  }

  function callBackUnprocessableEntity() {
    fechar({
      concluido: false,
      precisaAtualizar: true,
    });
  }

  async function handleCancelar() {
    if (formState.isDirty) {
      const confirmacao = await exibirConfirmacao(
        "Aviso",
        "Há dados não salvos. Deseja cancelar?"
      );

      if (!confirmacao) {
        return;
      }
    }

    fechar({ concluido: false, precisaAtualizar: false });
  }

  function funcaoParaBaixarAnexo(idAnexo: number) {
    return APIAnexos.anexoObterDadosParaDownload(
      getValues().id,
      idAnexo,
      nomeEndpoint
    );
  }

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    itemId: yup.number().required().moreThan(-1).integer(),
    fabricanteId: yup.number().required().moreThan(-1).integer(),
    loteDoFabricanteOuNumeroDeSerie: yup
      .string()
      .required()
      .max(loteDoFabricanteOuNumeroDeSerieMaxLength),
    fabricacaoData: yup.string().when("$deveSerObrigatorio", (_, schema) => {
      return loteDataRequerido ? schema.required() : schema.nullable();
    }),
    vencimentoData: yup.string().when("$deveSerObrigatorio", (_, schema) => {
      return loteDataRequerido ? schema.required() : schema.nullable();
    }),
  });

  const hookForm = useHookForms(schema);

  const {
    register,
    getValues,
    setValue,
    reset,
    trigger,
    watch,
    control,
    formState,
    handleSubmit,
  } = hookForm;

  const botoesAdicionais = [
    <Button
      key="btn-imprimir-lote"
      type="normal"
      text="Imprimir"
      visible={isRegistroEmEdicao}
      onClick={handleImprimir}
      icon="print"
    />,
  ];

  async function handleImprimir() {
    abrirModalDetalhesDoLoteMxp1(props.idRegistroEdicao);
  }

  async function handlerVerificacaoDeTranscricaoDoPrefixoESufixoDoLote(
    model: LoteRequestDTO
  ) {
    if (!model.utilizaPrefixo) {
      return true;
    }

    const teste =
      await APILoteENumeroDeSeriePrefixo.verificaSeTranscricaoDePrefixoOuSufixoNaoEncontrouAlgumDadoAsync(
        model.itemId ?? 0
      );

    if (teste.algumDadoNaoFoiEncontrado) {
      const confirmacao = await exibirConfirmacao(
        JanelasDeNotificacaoTitulos.Atencao,
        renderToString(
          <QuebrarLinhas texto={teste.mensagemDeDadosNaoEncontrados} />
        )
      );

      return confirmacao;
    } else {
      return true;
    }
  }

  return (
    <>
      <FormBase2
        visivel={props.visivel}
        carregando={carregando}
        auditoria={dadosAuditoria}
        configuracoesModal={props.configuracoesModal}
        onClickSalvar={handleSubmit(handleSalvar)}
        onClickCancelar={handleCancelar}
        modoEdicao={props.idRegistroEdicao == 0 ? "criar" : "editar"}
        titulo={NomesModais.lote}
        botoesAdicionais={botoesAdicionais}
      >
        <ProvedorAjuda id="edit-form-lote">
          <input type="hidden" {...register("id")} defaultValue={0} />
          <input
            type="hidden"
            {...register("arquivos")}
            defaultValue={JSON.stringify([])}
          />
          <TabPanel
            key={props.idRegistroEdicao}
            deferRendering={false}
            showNavButtons
            swipeEnabled={false}
            itemTitleRender={(item) => item.text}
            height={"95%"}
          >
            <Item text="Dados gerais">
              <LoteAbaDadosGerais
                control={control}
                loteDataRequerido={loteDataRequerido ?? undefined}
                isRegistroEmEdicao={isRegistroEmEdicao}
                getValues={getValues}
                setValue={setValue}
                watch={watch}
                trigger={trigger}
                controleLoteFabricacao={controleLoteFabricacao}
              />
            </Item>
            <Item text="Observações">
              <LoteAbaObservacoes control={control} />
            </Item>
            <Item text="Histórico">
              <LoteAbaHistorico dataSource={getValues().historico} />
            </Item>
            <Item text="Anexos">
              <LoteAbaAnexos
                idRegistro={props.idRegistroEdicao}
                getValues={getValues}
                funcaoParaBaixarAnexo={funcaoParaBaixarAnexo}
              />
            </Item>
          </TabPanel>
        </ProvedorAjuda>
      </FormBase2>
    </>
  );
};
