import getApi from "../../../configs/api";
import { ResponseModel } from "../../../models/api/comum/response-base";
import {
  EstadoContaContabil,
  RegistroBalanceteComparativo,
} from "../../../models/api/contabilidade/balancete-comparativo";
import { FiltrosBalanceteComparativoViewModel } from "../../../models/viewmodels/contabilidade/balancete-comparativo/filtros-balancete-comparativo-view-model";
import { NomesEndpoints } from "../../comum/nomesEndpoints";

const nomeEndpoint = NomesEndpoints.BalanceteComparativo;

async function obterBalanceteComparativo(
  filtros: FiltrosBalanceteComparativoViewModel
) {
  const api = getApi();

  const parametros: [string, string][] = [];

  if (filtros.dataInicial) {
    parametros.push(["dataInicial", filtros.dataInicial]);
  }

  if (filtros.dataFinal) {
    parametros.push(["dataFinal", filtros.dataFinal]);
  }

  if (filtros.minhaEmpresaId) {
    parametros.push(["minhaEmpresaId", String(filtros.minhaEmpresaId)]);
  }

  if (filtros.incluirLancamentosDeEncerramento) {
    parametros.push([
      "incluirLancamentosDeEncerramento",
      String(filtros.incluirLancamentosDeEncerramento),
    ]);
  }

  if (filtros.exibirContasZeradas) {
    parametros.push([
      "exibirContasZeradas",
      String(filtros.exibirContasZeradas),
    ]);
  }

  if (filtros.estadoDaConta.length > 0) {
    const estado =
      filtros.estadoDaConta.length == 2
        ? EstadoContaContabil.Ambas
        : filtros.estadoDaConta[0];
    parametros.push(["estadoConta", String(estado)]);
  }

  parametros.push(["agrupamento", String(filtros.agrupamento)]);

  const queryString = new URLSearchParams(parametros).toString();

  const response = await api.get<ResponseModel<RegistroBalanceteComparativo[]>>(
    `${nomeEndpoint}?${queryString}`
  );

  return response.data;
}

export default {
  obterBalanceteComparativo,
};
