import { Column } from "devextreme-react/cjs/data-grid";
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { renderToString } from "react-dom/server";
import { MxpGrid } from "../../../../components/grid";
import {
  useEditarRegistroGrid,
  useExcluirRegistroGrid,
  useNovoRegistroGrid,
  usePropagarReferenciaGrid,
} from "../../../../hooks/grid.hooks";
import { useAppDispatch } from "../../../../hooks/store.hooks";
import { decodificaBooleanoEmSimNao } from "../../../../models/api/comum/decodificadores";
import { ImobilizadoGridModel } from "../../../../models/api/imobilizado/imobilizado";
import { PermissoesImobilizado } from "../../../../models/permissoes/contabilidade/imobilizado/permissoes-imobilizado";
import { CallBackModal } from "../../../../models/shared/ui/callback-modal";
import { IGridSelecao } from "../../../../models/shared/ui/formularios";
import EditFormImobilizado from "../../../../pages/contabilidade/imobilizado/edit-form";
import { ImobilizadoService } from "../../../../services/contabilidade/imobilizado/imobilizado.service";
import { bloquearUI, desbloquearUI } from "../../../../store/ui/ui.slice";
import {
  checarResponseExibeMensagemExclusaoDeSucesso,
  tratarErroApi,
} from "../../../../utils/api/api-utils";
import criarNameof from "../../../../utils/common/cria-name-of";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../utils/common/permissoes-utils";
import { exibirConfirmacao } from "../../../../utils/dialogos";
import { GridBaseProps } from "../../../../utils/grid/grid-utils";
import obterConfiguracaoColuna from "../../../../utils/grid/padroes-colunas";
import GetColunasDeAuditoria from "../../../layout/grid-defaults/colunasDeAuditoria";

const imobilizadoService = new ImobilizadoService();
const dataSource = imobilizadoService.GetGridSource();

const nameof = criarNameof<ImobilizadoGridModel>();
const colunas = [
  <Column
    key={nameof("numeroPatrimonio")}
    dataField={nameof("numeroPatrimonio")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Número"
    width={130}
  />,
  <Column
    key={nameof("item")}
    dataField={nameof("item")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Item"
    width={130}
  />,
  <Column
    key={nameof("descricao")}
    dataField={nameof("descricao")}
    {...obterConfiguracaoColuna("stringXG")}
    caption="Descrição"
    visible={false}
  />,
  <Column
    key={nameof("estado")}
    dataField={nameof("estado")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Estado"
    width={100}
    visible={false}
  />,
  <Column
    key={nameof("valorAquisicao")}
    dataField={nameof("valorAquisicao")}
    {...obterConfiguracaoColuna("monetario")}
    caption="Valor da aquisição"
    width={150}
  />,
  <Column
    key={nameof("dataAquisicao")}
    dataField={nameof("dataAquisicao")}
    {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
    caption="Data da aquisição"
    width={150}
  />,
  <Column
    key={nameof("contaContabil")}
    dataField={nameof("contaContabil")}
    {...obterConfiguracaoColuna("stringMG")}
    caption="Conta contábil"
    width={180}
    visible={false}
  />,
  <Column
    key={nameof("utilizacaoDoBem")}
    dataField={nameof("utilizacaoDoBem")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Utilização do bem"
    width={170}
    visible={false}
  />,
  <Column
    key={nameof("centroCustos")}
    dataField={nameof("centroCustos")}
    {...obterConfiguracaoColuna("stringGG")}
    caption="Centro de custos"
    visible={false}
  />,
  <Column
    key={nameof("contaDeDebitoDaDepreciacao")}
    dataField={nameof("contaDeDebitoDaDepreciacao")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Conta de débito da depreciação"
    width={240}
    visible={false}
  />,
  <Column
    key={nameof("centroDeCustosDeDebitoDaDepreciacao")}
    dataField={nameof("centroDeCustosDeDebitoDaDepreciacao")}
    {...obterConfiguracaoColuna("stringGG")}
    caption="Centro de custos de débito da depreciação"
    visible={false}
  />,
  <Column
    key={nameof("contaDeCreditoDaDepreciacao")}
    dataField={nameof("contaDeCreditoDaDepreciacao")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Conta de crédito da depreciação"
    width={240}
    visible={false}
  />,
  <Column
    key={nameof("centroDeCustosDeCreditoDaDepreciacao")}
    dataField={nameof("centroDeCustosDeCreditoDaDepreciacao")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Centro de custos de crédito da depreciação"
    width={300}
    visible={false}
  />,
  <Column
    key={nameof("percentualDeDepreciacao")}
    dataField={nameof("percentualDeDepreciacao")}
    {...obterConfiguracaoColuna("percentual")}
    caption="Percentual de depreciação"
    width={200}
  />,
  <Column
    key={nameof("tipoDeDepreciacao")}
    dataField={nameof("tipoDeDepreciacao")}
    {...obterConfiguracaoColuna("stringM")}
    caption="Tipo de depreciação"
    width={160}
    visible={false}
  />,
  <Column
    key={nameof("vidaUtilEmMeses")}
    dataField={nameof("vidaUtilEmMeses")}
    caption="Vida útil (em meses)"
    width={170}
    dataType="number"
    format={{ precision: 0 }}
    visible={false}
  />,
  <Column
    key={nameof("valorDaParcelaDeDepreciacao")}
    dataField={nameof("valorDaParcelaDeDepreciacao")}
    {...obterConfiguracaoColuna("monetario")}
    caption="Valor da parcela de depreciação"
    width={230}
  />,
  <Column
    key={nameof("valorResidual")}
    dataField={nameof("valorResidual")}
    {...obterConfiguracaoColuna("monetario")}
    caption="Valor residual"
    visible={false}
  />,
  <Column
    key={nameof("valorDepreciado")}
    dataField={nameof("valorDepreciado")}
    {...obterConfiguracaoColuna("monetario")}
    caption="Valor depreciado"
    width={150}
    visible={false}
  />,
  <Column
    key={nameof("vidaRestanteEmMeses")}
    dataField={nameof("vidaRestanteEmMeses")}
    caption="Vida restante (em meses)"
    width={200}
    dataType="number"
    format={{ precision: 0 }}
  />,
  <Column
    key={nameof("dataUltimaDepreciacao")}
    dataField={nameof("dataUltimaDepreciacao")}
    {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
    caption="Data da última depreciação"
    width={210}
  />,
  <Column
    key={nameof("estadoDaDepreciacao")}
    dataField={nameof("estadoDaDepreciacao")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Estado da depreciação"
  />,
  <Column
    key={nameof("sujeitoAoCiap")}
    dataField={nameof("sujeitoAoCiap")}
    {...obterConfiguracaoColuna("boolSimNao")}
    caption="Sujeito ao CIAP"
    width={150}
    cellRender={({ data }) => {
      const dados = data as ImobilizadoGridModel;
      return decodificaBooleanoEmSimNao(dados.sujeitoAoCiap);
    }}
  />,
  <Column
    key={nameof("estadoDoCiap")}
    dataField={nameof("estadoDoCiap")}
    {...obterConfiguracaoColuna("stringGG")}
    caption="Estado do CIAP"
    width={150}
  />,
  <Column
    key={nameof("parcelasDoCiap")}
    dataField={nameof("parcelasDoCiap")}
    caption="Parcelas do CIAP"
    width={150}
    dataType="number"
    format={{ precision: 0 }}
  />,
  <Column
    key={nameof("parcelasApropriadas")}
    dataField={nameof("parcelasApropriadas")}
    caption="Parcelas apropriadas"
    width={170}
    dataType="number"
    format={{ precision: 0 }}
  />,
  <Column
    key={nameof("dataEmissao")}
    dataField={nameof("dataEmissao")}
    {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
    caption="Data emissão"
    width={150}
    visible={false}
  />,
  <Column
    key={nameof("numero")}
    dataField={nameof("numero")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Número"
    width={100}
    visible={false}
  />,
  <Column
    key={nameof("serie")}
    dataField={nameof("serie")}
    {...obterConfiguracaoColuna("serie")}
    caption="Série"
    width={100}
    visible={false}
  />,
  <Column
    key={nameof("chaveAcesso")}
    dataField={nameof("chaveAcesso")}
    {...obterConfiguracaoColuna("chaveAcesso")}
    visible={false}
  />,
  <Column
    key={nameof("baseDeCalculoIcms")}
    dataField={nameof("baseDeCalculoIcms")}
    {...obterConfiguracaoColuna("monetario")}
    caption={"Base de cálculo do ICMS"}
    width={200}
    visible={false}
  />,
  <Column
    key={nameof("aliquotaIcms")}
    dataField={nameof("aliquotaIcms")}
    {...obterConfiguracaoColuna("percentual")}
    visible={false}
    width={150}
    caption={"Alíquota do ICMS"}
  />,
  <Column
    key={nameof("valorDoIcms")}
    dataField={nameof("valorDoIcms")}
    {...obterConfiguracaoColuna("monetario")}
    visible={false}
    width={150}
    caption={"Valor do ICMS"}
  />,
  <Column
    key={nameof("valorDoIcmsSt")}
    dataField={nameof("valorDoIcmsSt")}
    {...obterConfiguracaoColuna("monetario")}
    visible={false}
    width={150}
    caption={"Valor do ICMS ST"}
  />,
  <Column
    key={nameof("valorDoIcmsFrete")}
    dataField={nameof("valorDoIcmsFrete")}
    {...obterConfiguracaoColuna("monetario")}
    visible={false}
    width={180}
    caption={"Valor do ICMS do frete"}
  />,
  <Column
    key={nameof("valorDoIcmsDifal")}
    dataField={nameof("valorDoIcmsDifal")}
    {...obterConfiguracaoColuna("monetario")}
    visible={false}
    width={170}
    caption={"Valor do ICMS DIFAL"}
  />,
  ...GetColunasDeAuditoria(),
];

export const GridImobilizado = forwardRef((props: GridBaseProps, ref) => {
  const gridRef = useRef<IGridSelecao>(null);
  useImperativeHandle(ref, () => gridRef.current as IGridSelecao);
  usePropagarReferenciaGrid(ref, gridRef);

  const [modalEdicaoVisivel, setModalEdicaoVisivel] = useState(false);
  const [idRegistroEdicao, setIdRegistroEdicao] = useState(NaN);

  const dispatch = useAppDispatch();

  function handleAtualizarGrid() {
    if (gridRef.current?.atualizarGrid) {
      gridRef.current?.atualizarGrid();
    }
  }

  const handleNovoRegistro = useNovoRegistroGrid(() => {
    if (
      !verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesImobilizado.InserirEditar,
      ])
    ) {
      return;
    }
    setIdRegistroEdicao(0);
    setModalEdicaoVisivel(true);
  });

  const handleEditarRegistro = useEditarRegistroGrid((id: number) => {
    if (
      !verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesImobilizado.InserirEditar,
      ])
    ) {
      return;
    }
    setIdRegistroEdicao(id);
    setModalEdicaoVisivel(true);
  });

  const handleModalCallback = useCallback((info: CallBackModal) => {
    setModalEdicaoVisivel(false);
    setIdRegistroEdicao(NaN);

    if (info.precisaAtualizar) {
      handleAtualizarGrid();
    }
  }, []);

  const handleExcluirRegistro = useExcluirRegistroGrid(
    async (registro: ImobilizadoGridModel) => {
      try {
        dispatch(bloquearUI());
        if (
          !verificaComNotificacaoSeUsuarioPossuiPermissoes([
            PermissoesImobilizado.Excluir,
          ])
        ) {
          return;
        }
        const mensagem = renderToString(<>{obterMensagemExclusao(registro)}</>);
        const excluir = await exibirConfirmacao("Confirmar exclusão", mensagem);

        if (excluir) {
          const resposta = await imobilizadoService.Excluir(registro.id);

          if (resposta) {
            checarResponseExibeMensagemExclusaoDeSucesso(resposta);
            handleAtualizarGrid();
          }
        }
      } catch (erro) {
        tratarErroApi(erro);
      } finally {
        dispatch(desbloquearUI());
      }
    }
  );

  function obterMensagemExclusao(registro: ImobilizadoGridModel) {
    return `Tem certeza que deseja excluir o registro ${registro.descricao}?`;
  }

  return (
    <>
      <MxpGrid<ImobilizadoGridModel>
        dataSource={dataSource}
        id={"grid-imobilizado"}
        colunas={colunas}
        ref={gridRef}
        novoRegistro={handleNovoRegistro}
        editarRegistro={handleEditarRegistro}
        excluirRegistro={handleExcluirRegistro}
        valorPadraoDoFiltro={props.valorPadraoDoFiltro}
        sobreporFiltroSalvoComOFiltroPadrao={
          props.sobreporFiltroSalvoComOFiltroPadrao
        }
      />

      <EditFormImobilizado
        visivel={modalEdicaoVisivel}
        idRegistroEdicao={idRegistroEdicao}
        configuracoesModal={{ largura: "max(40vw, 900px)", altura: "auto" }}
        callBackFecharModal={handleModalCallback}
      />
    </>
  );
});
