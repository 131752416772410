import { Button } from "devextreme-react";
import { LoadOptions } from "devextreme/data";
import DataSource from "devextreme/data/data_source";
import { useState } from "react";
import * as yup from "yup";
import ProvedorAjuda from "../../../../components/ajuda/provedor-ajuda";
import {
  FormNumberBox,
  FormSelectBox,
  FormSelectBoxLazy,
  FormTextBoxSimples,
} from "../../../../components/formularios";
import FormTextArea from "../../../../components/formularios/textarea";
import {
  FormBase2,
  FormularioEdicaoBaseProps,
} from "../../../../components/layout/form-base2";
import { Coluna, Linha } from "../../../../components/layout/grid-system";
import useEffectOnLoad from "../../../../hooks/effect.hooks";
import {
  useHookForms,
  useLimparFormSeIdForNaN,
} from "../../../../hooks/form.hooks";
import { SelectBoxLazyFiltros } from "../../../../models/api/comum/selectboxlazy-options";
import { EstoqueGridModel } from "../../../../models/api/estoque/estoque";
import {
  InsumoDaOrdemDeProducaoCadastrarRequest,
  InsumosTipoEstoque,
} from "../../../../models/api/insumo-do-centro-de-trabalho/insumo-da-ordem-de-producao";
import {
  EstadoDaTerceirizacao,
  OrigemInsumo,
} from "../../../../models/api/insumo-do-centro-de-trabalho/insumo-da-ordem-de-producao-enums";
import { OrdemDeProducaoResponse } from "../../../../models/api/producao/ordem-de-producao/ordem-de-producao";
import { CallBackModal } from "../../../../models/shared/ui/callback-modal";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../services/comum/serviceBase";
import { InsumoDaOrdemDeProducaoService } from "../../../../services/insumo-da-ordem-de-producao/insumo-da-ordem-de-producao";
import { ItemService } from "../../../../services/item/item.service";
import { OrdemDeProducaoService } from "../../../../services/producao/ordem-de-producao/ordem-de-producao.service";
import { checarResponse, tratarErroApi } from "../../../../utils/api/api-utils";
import criarNameof from "../../../../utils/common/cria-name-of";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../utils/common/notificacoes-utils";
import { obterFormatStringNumero } from "../../../../utils/formatadores/formatador-de-numeros";
import { GridEstoque } from "../../../estoque/estoque/grids/grid-padrao";
import { GridItem } from "../../../itens/item/grids/grid-padrao";

const service = new InsumoDaOrdemDeProducaoService();
const serviceOrdemDeProducao = new OrdemDeProducaoService();
const serviceItem = new ItemService();

const exibeOperacaoDeOrdemDeProducao = (operacaoOrdemProducao: any) => {
  if (operacaoOrdemProducao) {
    return operacaoOrdemProducao.Descricao == null
      ? `${operacaoOrdemProducao.Numero}`
      : `${operacaoOrdemProducao.Numero} - ${operacaoOrdemProducao.Descricao}`;
  }

  return "";
};

const operacoesOrdemDeProducaoExpressaoBusca = ["Numero", "Descricao"];

const exibeItem = (item: any) => {
  if (item) {
    return item.Descricao == null
      ? `${item.Codigo}`
      : `${item.Codigo} (${item.Descricao})`;
  }

  return "";
};

const itemExpressaoBusca = ["Codigo", "Descricao"];

const exibeEstoque = (estoque: any) => {
  if (estoque) {
    return `${estoque.CodigoItem} (${estoque.Estoque})`;
  }

  return "";
};

const estoqueExpressaoBusca = ["CodigoItem", "Estoque"];

const exibeNumerosDeSerie = (numeroDeSerie: any) => {
  if (numeroDeSerie) {
    return `${numeroDeSerie.Id}`;
  }

  return "";
};

const numeroDeSerieExpressaoBusca = ["Id"];

interface FormularioInsercaoInsumosProps extends FormularioEdicaoBaseProps {
  ordemDeProducaoId: number;
}

export default function EditFormInsercaoInsumoDaOrdemDeProducao(
  props: FormularioInsercaoInsumosProps
) {
  const [carregando, setCarregando] = useState(false);
  const [itens, setItens] = useState<DataSource>();
  const [operacoesOrdemDeProducao, setOperacoesOrdemDeProducao] =
    useState<DataSource>();
  const [estoques, setEstoques] = useState<DataSource>();
  const [filtroEstoqueCodigoItem, setfiltroEstoqueCodigoItem] =
    useState<Array<any>>();
  const [numerosDeSerie, setNumerosDeSerie] = useState<DataSource>();
  const [ordemDeProducao, setOrdemDeProducao] =
    useState<OrdemDeProducaoResponse>();

  const novoRegistro: InsumoDaOrdemDeProducaoCadastrarRequest = {
    ordemDeProducaoId: props.ordemDeProducaoId,
    operacaoId: NaN,
    itemId: NaN,
    quantidadeLiquida: 0,
    observacoes: null,
    ativo: true,
    origem: OrigemInsumo.Manual,
    itemPaiId: 0,
    ordem: 0,
    perdaEmPercentual: 0,
    estoqueId: null,
    numeroDeSerieId: null,
    tipoDoEstoque: null,
    estadoDaTerceirizacao: EstadoDaTerceirizacao.SemTerceirizacao,
  };

  useEffectOnLoad(() => {
    carregarOrdemDeProducao();
    carregaEstoques();
    carregaItens();
    carregaOperacoesOrdemDeProducao();
    carregaNumerosDeSerie();
  });

  function carregaItens() {
    setItens(
      serviceItem.GetDataSourceSelectBoxLazyItensInsumos({
        camposRetorno: ["Id", "Codigo", "Descricao"],
        camposOrdenacao: [
          {
            nomeCampo: "Codigo",
            desc: false,
          },
        ],
      })
    );
  }

  async function carregarOrdemDeProducao() {
    try {
      const resposta = await serviceOrdemDeProducao.obterPorIdComItem(
        props.ordemDeProducaoId
      );
      checarResponse(resposta);
      setOrdemDeProducao(resposta.model);
    } catch (erro) {
      tratarErroApi(erro);
    }
  }

  function carregaOperacoesOrdemDeProducao() {
    const filtros: SelectBoxLazyFiltros[] = [
      {
        nomeCampo: "OrdemDeProducaoId",
        operador: "=",
        valor: props.ordemDeProducaoId,
      },
    ];

    setOperacoesOrdemDeProducao(
      APIBase.getDataSourceSelectBoxLazy(
        {
          camposRetorno: ["Id", "OrdemDeProducaoId", "Numero", "Descricao"],
          filtros: filtros,
          camposOrdenacao: [
            {
              nomeCampo: "Numero",
              desc: false,
            },
          ],
        },
        NomesEndpoints.OperacaoDeOrdemDeProducao
      )
    );
  }

  function carregaEstoques() {
    setEstoques(
      APIBase.getDataSourceSelectBoxLazy(
        {
          camposRetorno: ["Id", "CodigoItem", "Estoque", "IdItem"],
          camposOrdenacao: [
            {
              nomeCampo: "CodigoItem",
              desc: false,
            },
          ],
        },
        NomesEndpoints.Estoque
      )
    );
  }

  function carregaNumerosDeSerie() {
    setNumerosDeSerie(
      APIBase.getDataSourceSelectBoxLazy(
        {
          camposRetorno: ["Id"],
          camposOrdenacao: [
            {
              nomeCampo: "Id",
              desc: false,
            },
          ],
        },
        NomesEndpoints.NumeroDeSeriePrefixo
      )
    );
  }

  const schema = yup.object().shape({
    operacaoId: yup.number().required().positive().integer(),
    itemId: yup.number().required().positive().integer(),
    quantidadeLiquida: yup.number().required().positive().moreThan(0),
  });

  const hookForm = useHookForms(schema);
  useLimparFormSeIdForNaN(hookForm, novoRegistro, props.idRegistroEdicao);

  const { control, handleSubmit, getValues, register, setValue } = hookForm;

  function handleCancelar() {
    fechar({ concluido: false, precisaAtualizar: false });
  }

  function fechar(info: CallBackModal) {
    if (props.callBackFecharModal) {
      props.callBackFecharModal(info);
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    setValue("itemPaiId", ordemDeProducao?.itemId);

    const model = getValues();

    try {
      const resposta = await service.Cadastrar(model);

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar({
          concluido: true,
          precisaAtualizar: true,
        });
      }
      fechar({
        concluido: true,
        precisaAtualizar: false,
      });
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  function callBackUnprocessableEntity() {
    fechar({
      concluido: false,
      precisaAtualizar: true,
    });
  }

  function handleAdicionarEBaixar() {
    exibirNotificacaoToast({
      mensagem: "Funcionalidade não implementada",
      tipo: TipoNotificacao.Informacao,
    });
  }

  function handleOnChangeItem(value: any) {
    const codigoItem = value?.selectedItem?.Codigo;

    let filtroEstoqueCodigoItem: LoadOptions = [];
    let filtroGridEstoqueCodigoItem: Array<any> = [];

    if (codigoItem) {
      filtroEstoqueCodigoItem = ["codigoItem", "=", codigoItem];
      filtroGridEstoqueCodigoItem = [
        criarNameof<EstoqueGridModel>()("codigoItem"),
        "=",
        codigoItem,
      ];
    }

    setValue("estoqueId", null);

    setfiltroEstoqueCodigoItem(filtroGridEstoqueCodigoItem);

    estoques?.filter(filtroEstoqueCodigoItem);
    estoques?.load();
  }

  function handleOnChangeEstoque(value: any) {
    const itemId = value?.selectedItem?.IdItem;

    if (itemId != getValues("itemId")) {
      itens?.load();
      setValue("itemId", itemId);
    }
  }

  function formataExibicaoOrdemProducao() {
    return ordemDeProducao?.numero == null
      ? `${ordemDeProducao?.itemDescricao}`
      : `${ordemDeProducao?.numero} - ${ordemDeProducao?.itemDescricao}`;
  }

  function gerarDadosGridItens() {
    return serviceItem.GetGridSourceItensInsumos();
  }

  const botoesAdicionais = [
    <Button
      key="btn-adicionar-e-baixar-insumo"
      type="success"
      text="Adicionar e baixar"
      onClick={() => handleAdicionarEBaixar()}
      icon="save"
    />,
  ];

  return (
    <>
      <FormBase2
        carregando={carregando}
        titulo={"Adicionar insumo"}
        onClickSalvar={handleSubmit(handleSalvar)}
        onClickCancelar={handleCancelar}
        visivel={props.visivel}
        modoEdicao={"não aplicável"}
        configuracoesModal={props.configuracoesModal}
        botaoSalvar={{ texto: "Adicionar" }}
        botoesAdicionais={botoesAdicionais}
      >
        <ProvedorAjuda id="edit-form-insumo-da-ordem-de-producao">
          <input type="hidden" {...register("itemPaiId")} />
          <input type="hidden" {...register("ordem")} />
          <input type="hidden" {...register("ordemDeProducaoId")} />

          <Linha>
            <Coluna md={5}>
              <FormTextBoxSimples
                titulo="Ordem de produção"
                valor={formataExibicaoOrdemProducao()}
                somenteLeitura
              />
            </Coluna>
            <Coluna md={4}>
              <FormSelectBoxLazy
                name="operacaoId"
                titulo="Operação"
                control={control}
                requerido
                nomeCampoChave="Id"
                expressaoDeBusca={operacoesOrdemDeProducaoExpressaoBusca}
                nomeCampoExibicao={exibeOperacaoDeOrdemDeProducao}
                dataSource={operacoesOrdemDeProducao}
                labelSemDados="Sem dados"
              />
            </Coluna>
          </Linha>
          <Linha>
            <Coluna md={6}>
              <FormSelectBoxLazy
                name="itemId"
                titulo="Item"
                control={control}
                requerido
                nomeCampoChave="Id"
                expressaoDeBusca={itemExpressaoBusca}
                nomeCampoExibicao={exibeItem}
                dataSource={itens}
                onSelectionChanged={handleOnChangeItem}
                lupaConfig={{
                  modo: "selecaoUnica",
                  titulo: "Selecionar item",
                  componente: (r) => (
                    <GridItem
                      funcaoGeradoraDeDados={gerarDadosGridItens()}
                      ref={r}
                    />
                  ),
                }}
                labelSemDados="Sem dados"
              />
            </Coluna>
            <Coluna md={6}>
              <FormSelectBoxLazy
                name="estoqueId"
                titulo="Estoque"
                control={control}
                nomeCampoChave="Id"
                expressaoDeBusca={estoqueExpressaoBusca}
                nomeCampoExibicao={exibeEstoque}
                dataSource={estoques}
                onSelectionChanged={handleOnChangeEstoque}
                lupaConfig={{
                  modo: "selecaoUnica",
                  titulo: "Selecionar estoque",
                  componente: (r) => (
                    <GridEstoque
                      valorPadraoDoFiltro={filtroEstoqueCodigoItem}
                      sobreporFiltroSalvoComOFiltroPadrao={true}
                      limparFiltroAoTrocarFiltroPadrao={true}
                      ocultarBotaoNovo
                      ref={r}
                    />
                  ),
                }}
                labelSemDados="Sem dados"
              />
            </Coluna>
          </Linha>
          <Linha>
            <Coluna md={2}>
              <FormNumberBox
                name="quantidadeLiquida"
                titulo="Quantidade"
                formato={obterFormatStringNumero(5)}
                control={control}
                requerido
              />
            </Coluna>
            <Coluna md={4}>
              <FormSelectBoxLazy
                name="numeroDeSerieId"
                titulo="Numero de série"
                control={control}
                nomeCampoChave="Id"
                expressaoDeBusca={numeroDeSerieExpressaoBusca}
                nomeCampoExibicao={exibeNumerosDeSerie}
                dataSource={numerosDeSerie}
                desabilitado
                labelSemDados="Sem dados"
              />
            </Coluna>
            <Coluna md={6}>
              <FormSelectBox
                name="tipoDoEstoque"
                control={control}
                titulo="Tipo do estoque"
                dataSource={InsumosTipoEstoque}
              />
            </Coluna>
          </Linha>
          <Linha>
            <Coluna md={12}>
              <FormTextArea
                name="observacoes"
                titulo={"Observações"}
                valor={""}
                control={control}
                height={15}
              />
            </Coluna>
          </Linha>
        </ProvedorAjuda>
      </FormBase2>
    </>
  );
}
