import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import RedirecionadorLogin from "../../components/navegacao/login-redirect";
import SingleSignOn from "../../pages/autenticacao/SingleSignOn";
import Autologin from "../../pages/autenticacao/autologin";
import LoginPage from "../../pages/autenticacao/login";
import LogoutRedirect from "../../pages/autenticacao/logout";
import RecuperacaoDeSenhaViaEmailPage from "../../pages/autenticacao/recuperacao-senha-via-email";
import DefineTituloDaAba from "../../utils/header/define-titulo-da-aba";

export default function RotasAnonimo() {
  DefineTituloDaAba();
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/oauth" element={<LoginPage oAuth />} />
        <Route path="/logout" element={<LogoutRedirect />} />
        <Route path="/singlesignon" element={<SingleSignOn />} />
        <Route path="/autologin" element={<Autologin />} />
        <Route
          path="/RecuperarSenha"
          element={<RecuperacaoDeSenhaViaEmailPage />}
        />
        <Route path="*" element={<RedirecionadorLogin />} />
      </Routes>
    </Router>
  );
}
