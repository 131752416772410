import getApi from "../../configs/api";
import {
  IdStringResponse,
  ResponseModel,
} from "../../models/api/comum/response-base";
import { SelectBoxLazyOpcoes } from "../../models/api/comum/selectboxlazy-options";
import { criarDataSourceSelectBoxLazy } from "../../utils/api/api-utils";
import criarDatasourceGrid from "../../utils/grid/back-end-grid-utils";
import { NomesEndpoints } from "../comum/nomesEndpoints";
import { ServiceBase } from "../comum/serviceBase";

export class ItemService extends ServiceBase {
  constructor() {
    super(NomesEndpoints.Item);
  }

  public async obterIdPorCodigo(codigos: string[]) {
    const api = getApi();
    const response = await api.post<ResponseModel<IdStringResponse[]>>(
      `${this._nomeEndpoint}/ObterIdPorCodigo`,
      Array.from(new Set(codigos))
    );

    const map = {} as Record<string, number>;
    if (!response.data.sucesso) {
      return map;
    }
    for (const x of response.data.model) {
      map[x.valor] = x.id;
    }
    return map;
  }

  public GetDataSourceSelectBoxLazyItensInsumos(opcoes: SelectBoxLazyOpcoes) {
    const camposSelect = JSON.stringify(opcoes.camposRetorno);

    const dataSource = criarDatasourceGrid(
      `${this._nomeEndpoint}/GridItensDosInsumos?select=${camposSelect}`
    );

    return criarDataSourceSelectBoxLazy(dataSource, opcoes);
  }

  public GetGridSourceItensInsumos() {
    return criarDatasourceGrid(`${this._nomeEndpoint}/GridItensDosInsumos`);
  }
}
