import Pagina from "../../../components/layout/pagina";
import RequerPermissao from "../../../components/seguranca/requer-permissao";
import { PermissoesVinculoEnderecoEstoqueCentroDeTrabalho } from "../../../models/permissoes/producao/vinculo-endereco-estoque-centro-de-trabalho/permissoes-vinculo-endereco-estoque-centro-de-trabalho";
import { GridVinculoEnderecoEstoqueCentroDeTrabalho } from "../../../parts/producao/vinculo-endereco-estoque-centro-de-trabalho/grids/grid-padrao";
import NomesDasCategoriasDoMenuPrincipal from "../../../utils/common/nome-categorias-menu-principal";
import NomesTelas from "../../../utils/common/nomes-telas";

export default function VinculoEnderecoEstoqueCentroDeTrabalhoPage() {
  return (
    <Pagina
      id="vinculo-endereco-estoque-centro-de-trabalho-page"
      titulo={NomesTelas.vinculosDoCentroDeTrabalhoComEnderecosDeEstoque}
      area={NomesDasCategoriasDoMenuPrincipal.producao}
    >
      <RequerPermissao
        codigoPermissoes={[
          PermissoesVinculoEnderecoEstoqueCentroDeTrabalho.Consultar,
        ]}
      >
        <GridVinculoEnderecoEstoqueCentroDeTrabalho />
      </RequerPermissao>
    </Pagina>
  );
}
