import { Column } from "devextreme-react/data-grid";
import ArrayStore from "devextreme/data/array_store";
import { forwardRef, useMemo, useRef, useState } from "react";
import { MxpGrid } from "../../../../../components/grid";
import { Modal } from "../../../../../components/layout/modal";
import TituloGrid from "../../../../../components/texto/titulo-grid";
import { usePropagarReferenciaGrid } from "../../../../../hooks/grid.hooks";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/store.hooks";
import { IGridSelecao } from "../../../../../models/shared/ui/formularios";
import { GridPercursoViewModel } from "../../../../../models/viewmodels/vendas/mdfe/mdfe-edit-form-view-model";
import { removerPercurso } from "../../../../../store/mdfe/mdfe.slice";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import exibirNotificacaoToast, {
  MensagensParaNotificacao,
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { GridProps } from "../../../../../utils/grid/grid-utils";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import ModalAdicionarPercurso from "./modal-adicionar-percurso";

const gridId = "mdfe-lista-percurso";
const colunas = [
  <Column
    key="ordem"
    dataField="ordem"
    caption="Ordem"
    dataType="number"
    allowResizing
    width={70}
  />,
  <Column
    key="municipio"
    dataField="municipio"
    caption="Município do carregamento"
    allowResizing
    width={70}
    {...obterConfiguracaoColuna("stringG")}
  />,
  <Column key="uf" dataField="uf" caption="UF" allowResizing width={70} />,
];

export const GridPercursos = forwardRef((props: GridProps, ref) => {
  const [modalAddPercursoVisivel, setModalAddPercursoVisivel] = useState(false);

  const modelPercurso = useAppSelector((state) => {
    return state.mdfe.documentoAtual.percursos.map((p) => {
      return {
        id: p.id,
        nome: p.estado.nome,
        ordem: p.ordem,
        uf: p.estado.uf,
        idUf: p.idUf,
      } as GridPercursoViewModel;
    });
  });

  const dataSourcePercurso = useMemo(
    () => new ArrayStore({ data: modelPercurso, key: "ufId" }),
    [modelPercurso]
  );

  const gridRef = useRef<IGridSelecao>(null);
  usePropagarReferenciaGrid(ref, gridRef);

  const dispatch = useAppDispatch();

  function novoRegistroPercurso() {
    setModalAddPercursoVisivel(true);
  }

  function excluirRegistro(percurso: GridPercursoViewModel | undefined) {
    if (percurso) {
      dispatch(removerPercurso(percurso));

      exibirNotificacaoToast({
        mensagem: MensagensParaNotificacao.ExcluidoComSucesso,
        tipo: TipoNotificacao.Sucesso,
      });
    }
  }

  return (
    <>
      <TituloGrid texto="Percurso" />
      <MxpGrid<GridPercursoViewModel>
        id={gridId}
        ref={gridRef}
        dataSource={dataSourcePercurso}
        colunas={colunas}
        excluirRegistro={excluirRegistro}
        exibirIconeEditarMenuAcoes={false}
        style={props.style}
        novoRegistro={props.somenteLeitura ? undefined : novoRegistroPercurso}
        nomeDoArquivoAoExportar={NomesTelas.empresas}
        isGridInterno
      />

      <Modal
        titulo="Adicionar percurso"
        largura={"70%"}
        altura={"auto"}
        visivel={modalAddPercursoVisivel}
        onFechar={() => setModalAddPercursoVisivel(false)}
      >
        <ModalAdicionarPercurso
          visivel={modalAddPercursoVisivel}
          fecharCallback={() => {
            setModalAddPercursoVisivel(false);
          }}
        />
      </Modal>
    </>
  );
});
