import { Button, Toolbar } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { useEffect, useState } from "react";
import { Modal } from "../../../components/layout/modal";
import PainelCarregamento from "../../../components/layout/painel-carregamento";
import { useAppDispatch, useAppSelector } from "../../../hooks/store.hooks";
import { TiposTokenAcesso } from "../../../models/api/tokens/acesso-token";
import API from "../../../services/tokens/tokens-acesso.service";
import { ocultarModalGerenciamentoTokensAcessoUsuario } from "../../../store/usuario/usuario.slice";
import { checarResponse, tratarErroApi } from "../../../utils/api/api-utils";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../utils/common/notificacoes-utils";
import { exibirConfirmacao } from "../../../utils/dialogos";
import { ContainerTokenAcessoUsuario } from "./styles";

export default function ModalTokenAcessoUsuario() {
  const estado = useAppSelector((state) => {
    return {
      visivel:
        state.estadoTelaUsuario.modalGerenciamentoTokensAcessoUsuario.visivel,
      idUsuario:
        state.estadoTelaUsuario.modalGerenciamentoTokensAcessoUsuario.dadosToken
          .idUsuario,
      tipo: state.estadoTelaUsuario.modalGerenciamentoTokensAcessoUsuario
        .dadosToken.tipo,
    };
  });

  const [token, setToken] = useState<string>();
  const [carregando, setCarregando] = useState(false);

  const tokenGerado = token ? true : false;

  useEffect(() => {
    if (!estado.visivel) {
      return;
    }

    carregarTela();
  }, [estado.visivel]);

  const dispatch = useAppDispatch();

  async function carregarTela() {
    try {
      setCarregando(true);
      const resposta = await API.Obter(estado.idUsuario, estado.tipo);
      checarResponse(resposta);

      if (resposta.model) {
        setToken(resposta.model?.token);
      }
    } catch (erro) {
      tratarErroApi(erro, () =>
        dispatch(ocultarModalGerenciamentoTokensAcessoUsuario())
      );
    } finally {
      setCarregando(false);
    }
  }

  async function onClickGerar() {
    try {
      let gerarRecriar = true;

      if (tokenGerado) {
        gerarRecriar = await exibirConfirmacao(
          "Confirmar recriação?",
          "Já existe um token para este usuário, deseja o sobrescrever?"
        );
      }

      if (!gerarRecriar) {
        return;
      }

      setCarregando(true);
      const resposta = await API.Gerar({
        usuarioTokenId: estado.idUsuario,
        tipo: estado.tipo,
      });
      checarResponse(resposta);

      if (resposta.model) {
        setToken(resposta.model?.token);
      }
    } catch (erro) {
      tratarErroApi(erro, () =>
        dispatch(ocultarModalGerenciamentoTokensAcessoUsuario())
      );
    } finally {
      setCarregando(false);
    }
  }

  async function onClickRevogar() {
    try {
      const revogar = await exibirConfirmacao(
        "Confirmar revogação?",
        "Tem certeza de que deseja revogar este token? Certifique-se de que ele não está mais sendo utilizado."
      );

      if (!revogar) {
        return;
      }

      setCarregando(true);
      const resposta = await API.Revogar(estado.idUsuario, estado.tipo);
      checarResponse(resposta);

      if (resposta.model) {
        setToken(undefined);
      }
    } catch (erro) {
      tratarErroApi(erro, () =>
        dispatch(ocultarModalGerenciamentoTokensAcessoUsuario())
      );
    } finally {
      setCarregando(false);
    }
  }

  function onClickCancelar() {
    setToken(undefined);
    dispatch(ocultarModalGerenciamentoTokensAcessoUsuario());
  }

  const titulo: Record<TiposTokenAcesso, Partial<string>> = {
    "1": "Token de acesso API GraphQL",
  };

  return (
    <>
      <Modal
        titulo={titulo[estado.tipo]}
        visivel={estado.visivel}
        onFechar={onClickCancelar}
        larguraMaxima={"700px"}
      >
        {carregando && <PainelCarregamento />}

        <ContainerTokenAcessoUsuario>
          {token ?? "O usuário não possui um token de acesso."}
        </ContainerTokenAcessoUsuario>

        <Toolbar multiline>
          <Item location="before">
            <Button
              type="success"
              text={tokenGerado ? "Recriar" : "Gerar"}
              icon={tokenGerado ? "refresh" : "save"}
              onClick={onClickGerar}
            />
          </Item>
          <Item location="before">
            <Button
              type="normal"
              text={"Revogar"}
              icon={"trash"}
              onClick={onClickRevogar}
              disabled={!tokenGerado}
            />
          </Item>
          <Item location="before">
            <Button
              type="normal"
              text={"Copiar"}
              icon={"ic-copy-content ic-material-symbols-outlined"}
              onClick={() => {
                navigator.clipboard.writeText(token ?? "");
                exibirNotificacaoToast({
                  mensagem: "Copiado com sucesso!",
                  tipo: TipoNotificacao.Sucesso,
                });
              }}
              visible={tokenGerado}
            />
          </Item>
          <Item location="before">
            <Button
              type="normal"
              text={"Cancelar"}
              icon={"close"}
              onClick={onClickCancelar}
            />
          </Item>
        </Toolbar>
      </Modal>
    </>
  );
}
