import { DataGrid } from "devextreme-react";
import { Column } from "devextreme-react/data-grid";
import { ValueChangedEvent } from "devextreme/ui/check_box";
import { useState } from "react";
import { renderToString } from "react-dom/server";
import {
  Control,
  FieldValues,
  UseFormGetValues,
  UseFormSetValue,
} from "react-hook-form";
import { FormCheckBox } from "../../../../../components/formularios";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import TabContainer from "../../../../../components/layout/tab-container";
import TituloGrid from "../../../../../components/texto/titulo-grid";
import { useCarregarCombos } from "../../../../../hooks/form.hooks";
import { useAppDispatch } from "../../../../../hooks/store.hooks";
import { CentroDeTrabalhoRequestDTO } from "../../../../../models/api/centro-de-trabalho/centro-de-trabalho";
import { VinculoEnderecoEstoqueCentroDeTrabalhoAbaGridModel } from "../../../../../models/api/vinculo-endereco-estoque-centro-de-trabalho/vinculo-endereco-estoque-centro-de-trabalho";
import { PermissoesVinculoEnderecoEstoqueCentroDeTrabalho } from "../../../../../models/permissoes/producao/vinculo-endereco-estoque-centro-de-trabalho/permissoes-vinculo-endereco-estoque-centro-de-trabalho";
import { CallBackModal } from "../../../../../models/shared/ui/callback-modal";
import { NomesEndpoints } from "../../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../../services/comum/serviceBase";
import APICentroDeTrabalho from "../../../../../services/producao/centro-de-trabalho/centro-de-trabalho.service";
import { bloquearUI, desbloquearUI } from "../../../../../store/ui/ui.slice";
import {
  checarResponse,
  checarResponseExibeMensagemExclusaoDeSucesso,
  tratarErroApi,
} from "../../../../../utils/api/api-utils";
import criarNameof from "../../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../../utils/common/nomes-telas";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../../utils/common/permissoes-utils";
import {
  criarItensMenuContextoPadrao,
  tratarDadosContextMenu,
} from "../../../../../utils/context-menu/context-menu-utils";
import { exibirConfirmacao } from "../../../../../utils/dialogos";
import { getGridDefaultProps } from "../../../../../utils/grid/grid-utils";
import obterConfiguracaoColuna from "../../../../../utils/grid/padroes-colunas";
import GridDefaults from "../../../../layout/grid-defaults";
import { EditFormAdicionarVinculoEnderecoDeEstoque } from "./modal-adicionar-vinculo-endereco-estoque";

interface CentroDeTrabalhoAbaVinculoEnderecoEstoqueProps {
  idCentroDeTrabalhoVinculado: number;
  control: Control<FieldValues, any>;
  getValues: UseFormGetValues<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
}

export interface ICentroDeTrabalhoAbaVinculoEnderecoEstoque {
  carregarDados: (idCentroDeTrabalhoVinculado: number) => void;
}

const nomeEndpointVinculo =
  NomesEndpoints.VinculoEnderecosDeEstoqueCentroDeTrabalho;

const nameOfCentroDeTrabalhoRequestDTOHandler =
  criarNameof<CentroDeTrabalhoRequestDTO>();

const CentroDeTrabalhoAbaVinculoEnderecoEstoque = (
  props: CentroDeTrabalhoAbaVinculoEnderecoEstoqueProps
) => {
  const isRegistroEdicao = props.idCentroDeTrabalhoVinculado != 0;

  const [
    isGridVinculoEnderecoDeEstoqueVisivel,
    setGridVinculoEnderecoDeEstoqueVisivel,
  ] = useState(false);
  const [
    limitarBaixasPorEnderecoDeEstoqueCheckBoxValue,
    setLimitarBaixasPorEnderecoDeEstoqueCheckBoxValue,
  ] = useState(false);

  useCarregarCombos(props.idCentroDeTrabalhoVinculado, carregarDados);

  async function carregarDados() {
    if (!isRegistroEdicao) {
      return;
    }

    const valores = props.getValues() as CentroDeTrabalhoRequestDTO;
    props.setValue(
      nameOfCentroDeTrabalhoRequestDTOHandler(
        "baixasLimitarPorEnderecoDeEstoque"
      ),
      valores?.baixasLimitarPorEnderecoDeEstoque ?? false
    );
    setLimitarBaixasPorEnderecoDeEstoqueCheckBoxValue(
      valores?.baixasLimitarPorEnderecoDeEstoque ?? false
    );
    setGridVinculoEnderecoDeEstoqueVisivel(
      valores?.baixasLimitarPorEnderecoDeEstoque ?? false
    );
  }

  async function visualizaGrid(valueEvent: ValueChangedEvent) {
    setLimitarBaixasPorEnderecoDeEstoqueCheckBoxValue(
      valueEvent.value as boolean
    );
    if (
      (!valueEvent.value as boolean) &&
      !isNaN(Number(props.idCentroDeTrabalhoVinculado))
    ) {
      valueEvent.event?.preventDefault();
      if (isRegistroEdicao && limitarBaixasPorEnderecoDeEstoqueCheckBoxValue) {
        const existeVinculos = await verificaSeCtTemVinculosEnderecoEstoque();

        if (existeVinculos) {
          exibirNotificacaoToast({
            mensagem:
              'Existem um ou mais vínculos com endereços de estoque cadastrados para este centro de trabalho, não sendo possível desmarcar a opção de "Limitar as baixas de insumos por endereços de estoque neste centro de trabalho". Para prosseguir, caso deseje, realize a exclusão de todos os vínculos.',
            tipo: TipoNotificacao.Informacao,
          });

          props.setValue(
            nameOfCentroDeTrabalhoRequestDTOHandler(
              "baixasLimitarPorEnderecoDeEstoque"
            ),
            true
          );
          setLimitarBaixasPorEnderecoDeEstoqueCheckBoxValue(true);
          setGridVinculoEnderecoDeEstoqueVisivel(true);
          return;
        }
      }
    }

    props.setValue(
      nameOfCentroDeTrabalhoRequestDTOHandler(
        "baixasLimitarPorEnderecoDeEstoque"
      ),
      valueEvent.value as boolean
    );
    setGridVinculoEnderecoDeEstoqueVisivel(valueEvent.value as boolean);
  }

  async function verificaSeCtTemVinculosEnderecoEstoque() {
    try {
      const resposta =
        await APICentroDeTrabalho.existeVinculosEnderecoEstoqueCentroDeTrabalho(
          props.idCentroDeTrabalhoVinculado
        );
      checarResponse(resposta);

      return resposta.model;
    } catch (erro) {
      tratarErroApi(erro);

      return true;
    }
  }

  return (
    <TabContainer>
      <Linha>
        <Coluna md={12}>
          <FormCheckBox
            name={nameOfCentroDeTrabalhoRequestDTOHandler(
              "baixasLimitarPorEnderecoDeEstoque"
            )}
            titulo="Limitar as baixas de insumos por endereços de estoque neste centro de trabalho"
            defaultValue={false}
            desabilitado={!isRegistroEdicao}
            control={props.control}
            onValueChanged={visualizaGrid}
          />
        </Coluna>
      </Linha>
      {isGridVinculoEnderecoDeEstoqueVisivel && (
        <Linha>
          <Coluna md={12}>
            <TituloGrid texto={NomesTelas.vinculosEnderecoEstoque} />
            <CentroDeTrabalhoAbaGridVinculoEnderecoEstoque
              idCentroDeTrabalhoVinculado={props.idCentroDeTrabalhoVinculado}
              control={props.control}
              getValues={props.getValues}
              setValue={props.setValue}
            />
          </Coluna>
        </Linha>
      )}
    </TabContainer>
  );
};

export default CentroDeTrabalhoAbaVinculoEnderecoEstoque;

export function CentroDeTrabalhoAbaGridVinculoEnderecoEstoque({
  idCentroDeTrabalhoVinculado,
  getValues,
}: CentroDeTrabalhoAbaVinculoEnderecoEstoqueProps) {
  const idCentroDeTrabalho = idCentroDeTrabalhoVinculado ?? (0 as number);
  const [registroEmEdicao, setRegistroEmEdicao] = useState<number>(NaN);
  const [
    modalAddVinculoEnderecoDeEstoqueVisivel,
    setModalAddVinculoEnderecoDeEstoqueVisivel,
  ] = useState(false);
  const [codigoCentroDeTrabalhoEditForm, setCodigoCentroDeTrabalhoEditForm] =
    useState<string>("");

  const dispatch = useAppDispatch();
  const dataSource = APIBase.getGridSource(nomeEndpointVinculo);

  let gridRef: DataGrid<any, any> = new DataGrid<any, any>({});

  function novoRegistro() {
    handlerExibicaoModalEditForm(0);
  }

  function handlerExibicaoModalEditForm(id?: number) {
    const cod = getValues().codigo;
    if (id != undefined && cod != undefined) {
      if (
        verificaComNotificacaoSeUsuarioPossuiPermissoes([
          PermissoesVinculoEnderecoEstoqueCentroDeTrabalho.InserirEditar,
        ])
      ) {
        setRegistroEmEdicao(id);
        setCodigoCentroDeTrabalhoEditForm(cod);
        setModalAddVinculoEnderecoDeEstoqueVisivel(true);
      }
    }
  }

  function setGridRef(ref: any) {
    gridRef = ref;
  }

  function getGridRef() {
    return gridRef;
  }

  function atualizarGrid() {
    gridRef.instance.refresh();
  }

  function editarRegistro(id?: number) {
    handlerExibicaoModalEditForm(id);
  }

  async function excluirRegistro(id?: number) {
    if (id) {
      const possuiPermissao = verificaComNotificacaoSeUsuarioPossuiPermissoes([
        PermissoesVinculoEnderecoEstoqueCentroDeTrabalho.Excluir,
      ]);

      if (!possuiPermissao) {
        return;
      }

      const mensagem = renderToString(
        <>Tem certeza de que deseja excluir o vínculo do endereco de estoque?</>
      );

      const excluir = await exibirConfirmacao("Confirmar exclusão", mensagem);

      if (excluir) {
        try {
          dispatch(bloquearUI());
          const resposta = await APIBase.excluir(id, nomeEndpointVinculo);
          checarResponseExibeMensagemExclusaoDeSucesso(resposta);
        } catch (erro) {
          tratarErroApi(erro);
        } finally {
          dispatch(desbloquearUI());
        }
      }
    }
  }

  const nameOfGridHandler =
    criarNameof<VinculoEnderecoEstoqueCentroDeTrabalhoAbaGridModel>();

  function handleModalCallBack(info: CallBackModal) {
    setModalAddVinculoEnderecoDeEstoqueVisivel(false);
    setRegistroEmEdicao(NaN);
    setCodigoCentroDeTrabalhoEditForm("");
    if (info.precisaAtualizar) {
      atualizarGrid();
    }
  }

  function gerarContextMenu(data: any) {
    const [getData] =
      tratarDadosContextMenu<VinculoEnderecoEstoqueCentroDeTrabalhoAbaGridModel>(
        data,
        gridRef,
        getGridRef
      );

    return criarItensMenuContextoPadrao({
      editar: () => {
        const data = getData();
        editarRegistro(data?.id);
      },
      excluir: () => excluirRegistro(getData()?.id),
    });
  }

  return (
    <>
      <DataGrid
        ref={setGridRef}
        dataSource={dataSource}
        {...getGridDefaultProps({
          nomeDoArquivoAoExportar: NomesTelas.vinculosEnderecoEstoque,
          getGridRef: getGridRef,
          gerarOpcoesDoRegistro: gerarContextMenu,
          valorPadraoDoFiltro: [
            nameOfGridHandler("centroDeTrabalhoId"),
            "=",
            idCentroDeTrabalho,
          ],
          sobreporFiltroSalvoComOFiltroPadrao: true,
          style: {
            height: "100%",
          },
        })}
      >
        {GridDefaults({
          gridId:
            "grid-editform-vinculo-endereco-de-estoque-centro-de-trabalho",
          novoRegistro: novoRegistro,
          exportavel: false,
          filtravel: false,
          paginavel: false,
          selecionavel: false,
          gerarContextMenu,
          getGridRef,
        })}
        <Column
          dataField={nameOfGridHandler("centroDeTrabalhoId")}
          {...obterConfiguracaoColuna("idPropridadeDeNavegacaoNaoVisivel")}
        />
        <Column
          dataField={nameOfGridHandler("enderecoEstoque")}
          {...obterConfiguracaoColuna("stringGG")}
          caption="Endereço de estoque"
        />
        <Column
          dataField={nameOfGridHandler("minhaEmpresa")}
          {...obterConfiguracaoColuna("stringGG")}
          caption="Minha empresa"
        />
      </DataGrid>
      <EditFormAdicionarVinculoEnderecoDeEstoque
        idCentroDeTrabalho={idCentroDeTrabalho}
        codigoCentroDeTrabalhoVinculado={codigoCentroDeTrabalhoEditForm}
        visivel={modalAddVinculoEnderecoDeEstoqueVisivel}
        idRegistroEdicao={registroEmEdicao}
        configuracoesModal={{ largura: "max(50vw, 800px)", altura: "auto" }}
        callBackFecharModal={handleModalCallBack}
      />
    </>
  );
}
