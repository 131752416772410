import { NumberBox } from "devextreme-react";
import { DataGridTypes } from "devextreme-react/cjs/data-grid";
import { NumberBoxTypes } from "devextreme-react/cjs/number-box";
import ArrayStore from "devextreme/data/array_store";
import { custom } from "devextreme/ui/dialog";
import { useCallback, useContext } from "react";
import ContextoBaixarInsumo from "../../../../components/insumo/contexto-baixar-insumo";
import { LinkButton } from "../../../../components/templates-celulas-grid/celula-controle-edicao/styles";
import {
  InsumoDaOrdemDeProducaoGridModel,
  InsumoEstado,
  ObterDescricaoInsumo,
} from "../../../../models/api/insumo-do-centro-de-trabalho/insumo-da-ordem-de-producao";
import { InsumoDaOrdemDeProducaoService } from "../../../../services/insumo-da-ordem-de-producao/insumo-da-ordem-de-producao";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../utils/common/notificacoes-utils";
import { exibirConfirmacao } from "../../../../utils/dialogos";
import { obterFormatStringNumero } from "../../../../utils/formatadores/formatador-de-numeros";

const APIInsumos = new InsumoDaOrdemDeProducaoService();
const focarBaixaTrue = true;
const forcarBaixaFalse = false;

export const QuantidadeABaixarEditavelComponent = ({
  data,
}: {
  data: DataGridTypes.ColumnEditCellTemplateData<
    InsumoDaOrdemDeProducaoGridModel,
    number
  >;
}) => {
  const onValueChanged = useCallback((e: NumberBoxTypes.ValueChangedEvent) => {
    data.setValue(e.value);
  }, []);

  const {
    setRegistroEstornar,
    setModalBaixarInsumosProps,
    atualizaGridDeInsumos,
  } = useContext(ContextoBaixarInsumo).funcoes;

  function getData() {
    return data.data;
  }

  function getDataAtualizada() {
    data.component.hasEditData();
    const colunaEditada = data.component
      .getVisibleRows()
      .filter((x) => x.key == data.data?.id)[0].data;
    return colunaEditada;
  }

  const onClickBaixarInsumo = useCallback(() => {
    confirmarBaixarInsumos(getDataAtualizada());
  }, [data]);

  const onClickForcarBaixaInsumo = useCallback(() => {
    forcarBaixaInsumos(getDataAtualizada());
  }, [data]);

  const onClickEstornarBaixaInsumo = useCallback(() => {
    estornarInsumo(getData());
  }, [data]);

  async function forcarBaixaInsumos(dados?: InsumoDaOrdemDeProducaoGridModel) {
    if (!dados) {
      exibirNotificacaoToast({
        mensagem:
          "Não foi possivel encontrar o insumo selecionado para forçar a baixa.",
        tipo: TipoNotificacao.Erro,
      });
      return;
    }
    await APIInsumos.ForcarConclusaoDeInsumo(dados?.id);
    atualizaGridDeInsumos();
  }

  async function estornarInsumo(dados?: InsumoDaOrdemDeProducaoGridModel) {
    if (!dados) {
      exibirNotificacaoToast({
        mensagem:
          "Não foi possivel encontrar o insumo selecionado para estornar.",
        tipo: TipoNotificacao.Erro,
      });
      return;
    }

    setRegistroEstornar(dados);
  }

  async function BaixarInsumos(
    dados: InsumoDaOrdemDeProducaoGridModel,
    forcar: boolean
  ) {
    const resultado = await APIInsumos.BaixaAutomatica(
      dados.id,
      dados.quantidadeABaixar,
      forcar
    );

    if (resultado.model.estoquesParaBaixar.length > 0) {
      setModalBaixarInsumosProps({
        popupVisivel: true,
        dados: new ArrayStore({
          data: resultado.model.estoquesParaBaixar,
          key: "id",
        }),
        insumoId: dados.id,
      });
    } else {
      if (resultado.model.quantidadeBaixada <= 0) {
        exibirNotificacaoToast({
          mensagem: "Não foi encontrado nenhum estoque disponível para baixar.",
          tipo: TipoNotificacao.Erro,
        });
        return;
      }

      atualizaGridDeInsumos();
    }
  }

  async function confirmarBaixarInsumos(
    dados?: InsumoDaOrdemDeProducaoGridModel
  ) {
    if (!dados) {
      exibirNotificacaoToast({
        mensagem: "Não foi possível carregar o insumo selecionado.",
        tipo: TipoNotificacao.Erro,
      });
      return;
    }

    const quantidadePrevista = dados.quantidadeTotal - dados.quantidadeBaixada;
    const quantidadeABaixar = dados.quantidadeABaixar;
    const unidade = dados.unidade ?? "un";
    const codigoDescricao = `${ObterDescricaoInsumo(dados)}`;

    if (quantidadeABaixar == 0) {
      exibirNotificacaoToast({
        mensagem: "A quantidade a estornar deve ser maior que zero.",
        tipo: TipoNotificacao.Advertencia,
      });
      return;
    }

    if (quantidadeABaixar < quantidadePrevista) {
      const mensagem = `
        <div style="max-width: 50vw;">
          <p>Você está baixando uma quantidade parcial (${quantidadeABaixar} ${unidade}) de "${codigoDescricao}".</p>
          <p>Ao clicar em "Baixar", a quantidade informada será baixada.</p>
          <p>Ao clicar em "Baixar e forçar a conclusão do insumo", a quantidade informada será baixada, e a quantidade restante do insumo será concluída forçadamente, logo, a quantidade restante que não foi informada não movimentará estoque.</p>
          <p>O que deseja fazer?</p>
        </div>
      `;

      const confirm = custom({
        title: "Confirmar baixa parcial",
        messageHtml: mensagem,
        buttons: [
          {
            text: "Baixar",
            onClick: () => BaixarInsumos(dados, forcarBaixaFalse),
          },
          {
            text: "Baixar e forçar a conclusão do insumo",
            onClick: () => BaixarInsumos(dados, focarBaixaTrue),
          },
          { text: "Cancelar" },
        ],
      });

      confirm.show();
      return;
    }

    if (quantidadeABaixar > quantidadePrevista) {
      const confirmacao = await exibirConfirmacao(
        "Atenção",
        `A quantidade a movimentar (${quantidadeABaixar}) é maior que a quantidade prevista (${quantidadePrevista}) para o insumo. Deseja continuar?`
      );

      if (!confirmacao) {
        return;
      }
    }

    BaixarInsumos(dados, forcarBaixaFalse);
  }

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <NumberBox
        name="quantidadeABaixar"
        format={obterFormatStringNumero(5)}
        defaultValue={data.value}
        onValueChanged={onValueChanged}
        min={0}
        style={{
          margin: "0px 5px",
          boxShadow: "inset 0 0 0 1px rgba(0,0,0,.22)",
          textAlignLast: "right",
        }}
        stylingMode="outlined"
      />
      <div style={{ width: "64px", display: "flex", flex: "none" }}>
        {data.data != undefined &&
          data.data.estado != InsumoEstado.BaixadoCompletamente && (
            <LinkButton onClick={onClickBaixarInsumo} title="Baixar Insumo">
              <i className="ic-material-symbols-outlined ic-success ic-download icone-linha-grid"></i>
            </LinkButton>
          )}
        {data.data != undefined &&
          data.data.estado != InsumoEstado.BaixadoCompletamente && (
            <LinkButton
              onClick={onClickForcarBaixaInsumo}
              title="Força a baixa do insumo"
            >
              <i className="ic-material-symbols-outlined ic-task-alt icone-linha-grid"></i>
            </LinkButton>
          )}
        {data.data != undefined && data.data.quantidadeBaixada > 0 && (
          <LinkButton
            onClick={onClickEstornarBaixaInsumo}
            title="Estornar Insumo"
          >
            <i className="ic-material-symbols-outlined ic-undo icone-linha-grid"></i>
          </LinkButton>
        )}
      </div>
    </div>
  );
};
