import { NumberBox, TextBox } from "devextreme-react";
import { NumberBoxTypes } from "devextreme-react/cjs/number-box";
import ArrayStore from "devextreme/data/array_store";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../../hooks/store.hooks";
import { EstoqueMovimentacaoSelecionarGridModel } from "../../../models/api/estoque-movimentacao/estoque-movimentacao";
import {
  InsumoDaOrdemDeProducaoGridModel,
  ObterDescricaoInsumo,
} from "../../../models/api/insumo-do-centro-de-trabalho/insumo-da-ordem-de-producao";
import { IGridSelecao } from "../../../models/shared/ui/formularios";
import { GridSelecaoEstoqueMovimentacao } from "../../../parts/estoque/estoque-movimentacao/grid-padrao";
import { InsumoDaOrdemDeProducaoService } from "../../../services/insumo-da-ordem-de-producao/insumo-da-ordem-de-producao";
import { bloquearUI, desbloquearUI } from "../../../store/ui/ui.slice";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../utils/common/notificacoes-utils";
import { exibirConfirmacao } from "../../../utils/dialogos";
import { obterFormatStringNumero } from "../../../utils/formatadores/formatador-de-numeros";
import FormBase from "../../layout/form-base";
import { Coluna, Linha } from "../../layout/grid-system";
import { Modal } from "../../layout/modal";
import ContextoBaixarInsumo from "../contexto-baixar-insumo";

const APIInsumos = new InsumoDaOrdemDeProducaoService();

interface ModalSelecionarMovimentacoesProps {
  dados: ArrayStore | undefined;
  fecharModal: () => void;
}

const ModalSelecionarMovimentacoesParaEstornar = (
  props: ModalSelecionarMovimentacoesProps
) => {
  const gridSelecaoEstoqueRef = useRef<IGridSelecao>(null);
  const dispatch = useAppDispatch();

  const fecharModal = () => {
    gridSelecaoEstoqueRef?.current?.getGridRef().instance.cancelEditData();
    props.fecharModal();
  };

  async function baixarEstoquesSelecionados() {
    const gridRef = gridSelecaoEstoqueRef.current;
    if (!gridRef) {
      exibirNotificacaoToast({
        mensagem: "Ocorreu um erro ao estornar o insumo.",
        tipo: TipoNotificacao.Erro,
      });
      return;
    }

    dispatch(bloquearUI("Carregando..."));

    try {
      const estoqueParaBaixar = gridRef
        .getGridRef()
        .instance.getVisibleRows()
        .map((row) => row.data as EstoqueMovimentacaoSelecionarGridModel)
        .filter((data) => data.quantidadeParaEstornar > 0);

      const resposta = await APIInsumos.EstornarBaixasDoInsumo(
        estoqueParaBaixar
      );

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: "Insumo estornado com sucesso!",
          tipo: TipoNotificacao.Sucesso,
        });
      }
    } catch (error) {
      exibirNotificacaoToast({
        mensagem: "Ocorreu um erro ao estornar o insumo.",
        tipo: TipoNotificacao.Erro,
      });
    } finally {
      dispatch(desbloquearUI());
      fecharModal();
    }
  }

  return (
    <Modal
      titulo={"Seleção de movimentações para estornar"}
      visivel={props.dados != undefined}
      onFechar={fecharModal}
    >
      <FormBase
        formId={"form-seletor-estoque"}
        modal
        onClickCancelar={fecharModal}
        onClickSalvar={baixarEstoquesSelecionados}
        botaoSalvar={{ texto: "Confirmar", icon: "check" }}
      >
        <GridSelecaoEstoqueMovimentacao
          ref={gridSelecaoEstoqueRef}
          dataSource={props.dados ?? new ArrayStore({})}
        />
      </FormBase>
    </Modal>
  );
};

export const ModalEstornarInsumo = () => {
  const [insumoEstornar, setInsumoEstornar] = useState<
    InsumoDaOrdemDeProducaoGridModel | undefined
  >(undefined);
  const [quantidadeAEstornar, setQuantidadeAEstornar] = useState(0);
  const [dataSourceSelecionarEstoque, setDataSourceSelecionarEstoque] =
    useState<ArrayStore | undefined>(undefined);
  const dispatch = useAppDispatch();

  const { funcoes } = useContext(ContextoBaixarInsumo);

  useEffect(() => {
    funcoes.definfirSetRegistroEstornar(
      (insumo: InsumoDaOrdemDeProducaoGridModel) => {
        setInsumoEstornar(insumo);
      }
    );
  }, []);

  useEffect(() => {
    setQuantidadeAEstornar(insumoEstornar?.quantidadeBaixada ?? 0);
  }, [insumoEstornar]);

  const valueChanged = useCallback((e: NumberBoxTypes.ValueChangedEvent) => {
    setQuantidadeAEstornar(e.value);
  }, []);

  function fechar() {
    setInsumoEstornar(undefined);
  }

  const fecharModal = () => {
    setDataSourceSelecionarEstoque(undefined);

    fechar();
  };

  async function confirmarEstorno() {
    if (quantidadeAEstornar == 0) {
      exibirNotificacaoToast({
        mensagem: "A quantidade para estornar deve ser maior que zero",
        tipo: TipoNotificacao.Advertencia,
      });
    }

    const quantidadeBaixada = insumoEstornar?.quantidadeBaixada ?? 0;
    if (quantidadeAEstornar > quantidadeBaixada) {
      const confirmacao = await exibirConfirmacao(
        "Aviso",
        `A quantidade a estornar (${quantidadeAEstornar}) é maior que a quantidade total 
            já baixada do insumo (${quantidadeBaixada}). 
            Deseja prosseguir com o estorno da quantidade total já baixada?`
      );

      if (!confirmacao) {
        return;
      }
    }

    dispatch(bloquearUI("Carregando..."));
    const resposta = await APIInsumos.EstornarInsumo(
      insumoEstornar?.id ?? 0,
      quantidadeAEstornar ?? 0
    );

    if (resposta.sucesso) {
      if (resposta.precisaSelecionarMovs) {
        setDataSourceSelecionarEstoque(
          new ArrayStore({
            data: resposta.movimentacoes,
            key: "id",
          })
        );
      } else {
        exibirNotificacaoToast({
          mensagem: "Insumo estornado com sucesso",
          tipo: TipoNotificacao.Sucesso,
        });

        fechar();
      }
    }
    dispatch(desbloquearUI());
  }

  return (
    <>
      <Modal
        titulo={"Estornar baixa de insumo"}
        visivel={insumoEstornar != undefined}
        onFechar={fechar}
        larguraMaxima={"max(30vw, 400px)"}
      >
        <FormBase
          formId={"form-estornar-insumo"}
          modal
          onClickCancelar={fechar}
          onClickSalvar={confirmarEstorno}
          botaoSalvar={{ texto: "Confirmar", icon: "check" }}
        >
          <Linha>
            <Coluna md={12}>
              <TextBox
                label="Insumo a estornar"
                labelMode="outside"
                readOnly
                style={{ marginBottom: "10px" }}
                value={`${ObterDescricaoInsumo(insumoEstornar)}`}
              />
            </Coluna>
          </Linha>
          <Linha>
            <Coluna md={12}>
              <NumberBox
                name="quantidadeABaixar"
                labelMode="outside"
                label="Quantidade a estornar"
                style={{ marginBottom: "10px" }}
                format={obterFormatStringNumero(5)}
                value={quantidadeAEstornar}
                onValueChanged={valueChanged}
                min={0}
              />
            </Coluna>
          </Linha>
        </FormBase>
      </Modal>
      <ModalSelecionarMovimentacoesParaEstornar
        dados={dataSourceSelecionarEstoque}
        fecharModal={fecharModal}
      />
    </>
  );
};
