export enum EstocagemTipo {
  Codigo = "Código do item",
  Interno = "Lote interno",
  LoteFabricante = "Lote do fabricante",
  Serie = "Número de série",
}

export enum Estado {
  Ativo = "Ativo",
  Inativo = "Inativo",
  Digitacao = "Digitação",
}
