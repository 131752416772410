export enum EstadoDecodificado {
  Sugerida = "Sugerida",
  AProduzir = "A produzir",
  Fechada = "Fechada",
  Cancelada = "Cancelada",
  Suspensa = "Suspensa",
}

export enum OrdemDeProducaoEstado {
  Sugerida = 83,
  AProduzir = 70,
  Fechada = 67,
  Cancelada = 75,
  Suspensa = 66,
}

export enum EstadoOrdemProducao {
  Sugerida = "S",
  AProduzir = "F",
  Fechada = "C",
  Cancelada = "K",
  SuspensaSugerida = "B",
}

export const ordemDeProducaoEstadoDicionario: {
  [key in OrdemDeProducaoEstado]: string;
} = {
  [OrdemDeProducaoEstado.Sugerida]: "Sugerida",
  [OrdemDeProducaoEstado.AProduzir]: "A produzir",
  [OrdemDeProducaoEstado.Fechada]: "Fechada",
  [OrdemDeProducaoEstado.Cancelada]: "Cancelada",
  [OrdemDeProducaoEstado.Suspensa]: "Suspensa",
};
