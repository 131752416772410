import { createContext } from "react";
import { InsumoDaOrdemDeProducaoGridModel } from "../../../models/api/insumo-do-centro-de-trabalho/insumo-da-ordem-de-producao";
import { ModalBaixarInsumosProps } from "../modal-baixar-insumo";

export type FuncaoDeInsumo = (insumo: InsumoDaOrdemDeProducaoGridModel) => void;

export class FuncoesColunaQuantidadeABaixarInsumo {
  public setRegistroEstornar: FuncaoDeInsumo;
  public setModalBaixarInsumosProps: (x: ModalBaixarInsumosProps) => void;
  public atualizaGridDeInsumos: () => void;

  constructor() {
    this.atualizaGridDeInsumos = () => {
      undefined;
    };
    this.setModalBaixarInsumosProps = (x: ModalBaixarInsumosProps) => {
      x;
    };
    this.setRegistroEstornar = (insumo: InsumoDaOrdemDeProducaoGridModel) => {
      insumo;
    };
  }

  definfirSetRegistroEstornar(x: FuncaoDeInsumo) {
    this.setRegistroEstornar = x;
  }

  definfirSetModalBaixarInsumosProps(y: (x: ModalBaixarInsumosProps) => void) {
    this.setModalBaixarInsumosProps = y;
  }

  definfirAtualizaGridDeInsumos(x: () => void) {
    this.atualizaGridDeInsumos = x;
  }
}

interface ContextoBaixarInsumoProps {
  funcoes: FuncoesColunaQuantidadeABaixarInsumo;
}

const ContextoBaixarInsumo = createContext<ContextoBaixarInsumoProps>(
  {} as ContextoBaixarInsumoProps
);

export default ContextoBaixarInsumo;
