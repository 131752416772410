import { Popup } from "devextreme-react";
import { useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/store.hooks";
import { ocultarNecessitaOpcionais } from "../../../store/ui/ui.slice";
import { ButtonContainer } from "../../../utils/dialogos/styles";
import BotaoDialogo, { IBotaoDialogo } from "../../botoes/botao-dialogo";

export default function NecessitaOpcionaisPopup() {
  const dispatch = useAppDispatch();
  const necessitaOpcionaisVisivel = useAppSelector(
    (state) => state.estadoUI.necessitaOpcionais.visivel
  );
  const dados = useAppSelector(
    (state) => state.estadoUI.necessitaOpcionais.dados
  );

  const botaoRef = useRef<IBotaoDialogo>(null);

  return (
    <Popup
      visible={necessitaOpcionaisVisivel}
      dragEnabled={false}
      hideOnOutsideClick={false}
      showCloseButton={false}
      showTitle={true}
      titleRender={() => (
        <div style={{ fontSize: "16px", fontWeight: "500" }}>Atenção</div>
      )}
      maxWidth={750}
      height="auto"
      onShown={() => {
        botaoRef.current?.focus();
      }}
    >
      <div>
        <span>
          O plano contratado pela sua empresa não inclui a(s) funcionalidade(s)
          de:
        </span>
        <ul>
          {dados.map((item, index) => (
            <li key={`p${index}`}>
              {item.descricao} [{item.codigo}].
            </li>
          ))}
        </ul>
        <span>
          Para incluir esta funcionalidade, a empresa deve contratar um dos
          seguintes planos ou opcionais:{" "}
        </span>
        <ul>
          {dados.map((item, index) => (
            <li key={`r${index}`}>{item.resumo}</li>
          ))}
        </ul>
      </div>
      <ButtonContainer>
        <BotaoDialogo
          ref={botaoRef}
          texto="OK"
          onClick={() => dispatch(ocultarNecessitaOpcionais())}
        />
      </ButtonContainer>
    </Popup>
  );
}
