import { Button } from "devextreme-react";
import { ReactNode } from "react";
import { renderToString } from "react-dom/server";
import FormBase from "../../../components/layout/form-base";
import { CallBackModal } from "../../../models/shared/ui/callback-modal";
import { GridAjudaPaginas } from "../../../parts/ajuda/ajuda-paginas/grid";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../utils/common/notificacoes-utils";
import { exibirConfirmacao } from "../../../utils/dialogos";
import { ProvedorLinkAjuda } from "../../../utils/help/provedor-links";

interface AjudaPaginasPageProps {
  codigo: string;
  callBackFecharModal?: (info: CallBackModal) => void;
}

export default function AjudaPaginasPage({
  codigo,
  callBackFecharModal,
}: AjudaPaginasPageProps) {
  const botoesAdicionais: ReactNode[] = [
    <Button
      key="btn-limpar"
      type="normal"
      text="Limpar cache local"
      visible
      onClick={onLimparCacheLocal}
    />,
  ];

  function fechar(info: CallBackModal) {
    if (callBackFecharModal) {
      callBackFecharModal(info);
    }
  }

  async function onLimparCacheLocal() {
    const confirmacao = await exibirConfirmacao(
      "Confirmar limpeza",
      renderToString(
        <>
          Tem certeza de que deseja excluir o cache local dos links de ajuda?
          <br />
          <br />
          <i>
            Essa operação visa apenas facilitar testes internos e apagará apenas
            os dados armazenados localmente.
            <br />
            Se o seu intuito é limpar o cache no servidor, basta
            adicionar/editar/excluir algum registro nessa página.
          </i>
        </>
      )
    );

    if (confirmacao) {
      ProvedorLinkAjuda.limparCache();
      exibirNotificacaoToast({
        mensagem: "Limpeza realizada com sucesso! Favor atualizar a página.",
        tipo: TipoNotificacao.Sucesso,
      });
    }
  }

  function handleCancelar() {
    fechar({ concluido: false, precisaAtualizar: false });
  }

  return (
    <>
      <FormBase
        formId="listagem-links-ajuda-pagina"
        botoesAdicionais={botoesAdicionais}
        onClickCancelar={handleCancelar}
        modal
      >
        <GridAjudaPaginas codigo={codigo} />
      </FormBase>
    </>
  );
}
