import Pagina from "../../../components/layout/pagina";
import { GridCodigoTributacaoServico } from "../../../parts/tributacao/codigo-tributacao-servico/grid";
import NomesTelas from "../../../utils/common/nomes-telas";

export default function CodigoTributacaoServicoPage() {
  return (
    <Pagina
      id="codigo-tributacao-servico-page"
      titulo={NomesTelas.codigosTributacaoServicos}
      area={"Fiscal > Cadastros"}
    >
      <GridCodigoTributacaoServico />
    </Pagina>
  );
}
