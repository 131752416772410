import { Column } from "devextreme-react/data-grid";
import { forwardRef, useRef, useState } from "react";
import { MxpGrid } from "../../../../components/grid";
import {
  useEditarRegistroGrid,
  useExcluirRegistroGrid,
  useNovoRegistroGrid,
  usePropagarReferenciaGrid,
} from "../../../../hooks/grid.hooks";
import { ConjuntoVeiculoGridModel } from "../../../../models/api/conjunto-veiculo/conjunto-veiculo";
import { CallBackModal } from "../../../../models/shared/ui/callback-modal";
import { IGridSelecao } from "../../../../models/shared/ui/formularios";
import { EditFormConjuntoVeiculo } from "../../../../pages/vendas/conjunto-veiculo/edit-form";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../services/comum/serviceBase";
import API from "../../../../services/conjunto-veiculo/conjunto-veiculo.service";
import criarNameof from "../../../../utils/common/cria-name-of";
import exibirNotificacaoToast, {
  MensagensParaNotificacao,
  TipoNotificacao,
} from "../../../../utils/common/notificacoes-utils";
import { exibirConfirmacao } from "../../../../utils/dialogos";
import obterConfiguracaoColuna from "../../../../utils/grid/padroes-colunas";
import GetColunasDeAuditoria from "../../../layout/grid-defaults/colunasDeAuditoria";

const dataSource = API.getGridSource();
const nameOfGridHandler = criarNameof<ConjuntoVeiculoGridModel>();

const colunas = [
  <Column
    key={nameOfGridHandler("descricao")}
    dataField={nameOfGridHandler("descricao")}
    {...obterConfiguracaoColuna("stringG")}
    sortIndex={0}
    sortOrder="asc"
    caption="Descrição"
  />,
  <Column
    key={nameOfGridHandler("veiculoTracao")}
    dataField={nameOfGridHandler("veiculoTracao")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Tração"
  />,
  <Column
    key={nameOfGridHandler("veiculoReboque1")}
    dataField={nameOfGridHandler("veiculoReboque1")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Reboque 1"
  />,
  <Column
    key={nameOfGridHandler("veiculoReboque2")}
    dataField={nameOfGridHandler("veiculoReboque2")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Reboque 2"
  />,
  <Column
    key={nameOfGridHandler("veiculoReboque3")}
    dataField={nameOfGridHandler("veiculoReboque3")}
    {...obterConfiguracaoColuna("stringG")}
    caption="Reboque 3"
  />,
  ...GetColunasDeAuditoria(),
];

export const GridConjuntoVeiculo = forwardRef((_, ref) => {
  const gridRef = useRef<IGridSelecao>(null);
  const [modalEdicaoVisivel, setModalEdicaoVisivel] = useState(false);
  const [idRegistroEdicao, setIdRegistroEdicao] = useState(NaN);

  function handleModalCallback(info: CallBackModal) {
    setModalEdicaoVisivel(false);
    setIdRegistroEdicao(NaN);

    if (info.precisaAtualizar) {
      handleAtualizarGrid();
    }
  }

  usePropagarReferenciaGrid(ref, gridRef);

  function handleAtualizarGrid() {
    if (gridRef.current?.atualizarGrid) {
      gridRef.current?.atualizarGrid();
    }
  }

  const handleNovoRegistro = useNovoRegistroGrid(() => {
    setIdRegistroEdicao(0);
    setModalEdicaoVisivel(true);
  });

  const handleEditarRegistro = useEditarRegistroGrid((id: number) => {
    setIdRegistroEdicao(id);
    setModalEdicaoVisivel(true);
  });

  const handleExcluirRegistro = useExcluirRegistroGrid(
    async (registro: ConjuntoVeiculoGridModel) => {
      const excluir = await exibirConfirmacao(
        "Confirmar exclusão",
        `Tem certeza que deseja excluir o registro ${registro.descricao}?`
      );

      if (excluir) {
        const resposta = await APIBase.excluir(
          registro.id,
          NomesEndpoints.VeiculoConjunto
        );

        if (resposta.sucesso) {
          exibirNotificacaoToast({
            mensagem: MensagensParaNotificacao.ExcluidoComSucesso,
            tipo: TipoNotificacao.Sucesso,
          });
          handleAtualizarGrid();
        }
      }
    }
  );

  return (
    <>
      <MxpGrid<ConjuntoVeiculoGridModel>
        id="grid-conjunto-veiculo"
        ref={gridRef}
        dataSource={dataSource}
        novoRegistro={handleNovoRegistro}
        editarRegistro={handleEditarRegistro}
        excluirRegistro={handleExcluirRegistro}
        colunas={colunas}
      />

      <EditFormConjuntoVeiculo
        visivel={modalEdicaoVisivel}
        idRegistroEdicao={idRegistroEdicao}
        configuracoesModal={{ largura: "max(50vw, 800px)" }}
        callBackFecharModal={handleModalCallback}
      />
    </>
  );
});
