import { useState } from "react";
import * as yup from "yup";
import ProvedorAjuda from "../../../../components/ajuda/provedor-ajuda";
import {
  FormCheckBox,
  FormGrupo,
  FormNumberBox,
  FormSelectBox,
  FormTextBox,
} from "../../../../components/formularios";
import FormTextArea from "../../../../components/formularios/textarea";
import {
  FormBase2,
  FormularioEdicaoBaseProps,
} from "../../../../components/layout/form-base2";
import { Coluna, Linha } from "../../../../components/layout/grid-system";
import {
  useCarregarRegistro,
  useHookForms,
  useLimparFormSeIdForNaN,
} from "../../../../hooks/form.hooks";
import {
  CfopRequestDTO,
  CfopResponseDTO,
} from "../../../../models/api/cfop/cfop";
import {
  EntradaSaida,
  TipoCfop,
  TipoInterestadual,
  entradaSaidaAsSelectItem,
  tipoCfopAsSelectItem,
  tipoInterestadualAsSelectItem,
} from "../../../../models/api/cfop/cfop-enums";
import AuditavelDTO from "../../../../models/api/comum/auditavel-dto";
import { CallBackModal } from "../../../../models/shared/ui/callback-modal";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import API from "../../../../services/comum/serviceBase";
import { checarResponse, tratarErroApi } from "../../../../utils/api/api-utils";
import NomesModais from "../../../../utils/common/nomes-modais";
import exibirNotificacaoToast, {
  TipoNotificacao,
} from "../../../../utils/common/notificacoes-utils";
import { NUMERO_INTEIRO_FORMATADOR } from "../../../../utils/formatadores/formatador-de-numeros";

const nomeEndpoint = NomesEndpoints.Cfop;

const novoRegistro: CfopRequestDTO = {
  id: 0,
  codigo: null,
  descricao: "",
  interstadual: null,
  entradaSaida: null,
  ativo: true,
  devolucao: false,
  tipo: null,
  descricaoDetalhada: "",
  observacoes: "",
};

export const EditFormCfop = (props: FormularioEdicaoBaseProps) => {
  const [carregando, setCarregando] = useState(false);
  const [dadosAuditoria, setDadosAuditoria] = useState<AuditavelDTO>();

  const schema = yup.object().shape({
    id: yup.number().required().moreThan(-1).integer(),
    codigo: yup.number().required().typeError("O código tem que ser numérico"),
    descricao: yup.string().required().max(255),
    interstadual: yup
      .mixed<TipoInterestadual>()
      .nullable()
      .transform((v) => (v >= 0 ? v : null))
      .oneOf(
        Object.values(TipoInterestadual).map((x) => x as number),
        "Valor inválido"
      )
      .required(),
    entradaSaida: yup
      .mixed<EntradaSaida>()
      .nullable()
      .transform((v) => (v >= 0 ? v : null))
      .oneOf(
        Object.values(EntradaSaida).map((x) => x as number),
        "Valor inválido"
      )
      .required(),
    tipo: yup
      .mixed<TipoCfop>()
      .nullable()
      .transform((v) => (v >= 0 ? v : null))
      .oneOf(
        Object.values(TipoCfop).map((x) => x as number),
        "Valor inválido"
      )
      .required(),
  });

  const hookForm = useHookForms(schema);
  useCarregarRegistro(props.idRegistroEdicao, carregarTela);
  useLimparFormSeIdForNaN(hookForm, novoRegistro, props.idRegistroEdicao);

  const { register, control, handleSubmit, getValues, reset } = hookForm;

  function fechar(info: CallBackModal) {
    if (props.callBackFecharModal) {
      setDadosAuditoria(undefined);
      props.callBackFecharModal(info);
    }
  }

  function handleCancelar() {
    fechar({ concluido: false, precisaAtualizar: false });
  }

  async function carregarTela() {
    try {
      setCarregando(true);
      if (props.idRegistroEdicao > 0) {
        await carregarModel();
      } else {
        reset(novoRegistro);
        setDadosAuditoria(undefined);
      }
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      setCarregando(false);
    }
  }

  async function carregarModel() {
    try {
      const resposta = await API.obterPorIdComDadosAuditoria<CfopResponseDTO>(
        props.idRegistroEdicao,
        nomeEndpoint
      );
      checarResponse(resposta);
      reset(resposta.model);
      setDadosAuditoria(resposta.model);
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    }
  }

  async function handleSalvar() {
    setCarregando(true);
    const model = getValues();
    try {
      const resposta =
        props.idRegistroEdicao > 0
          ? await API.atualizar(model, nomeEndpoint)
          : await API.cadastrar(model, nomeEndpoint);

      checarResponse(resposta);

      if (resposta.sucesso) {
        exibirNotificacaoToast({
          mensagem: resposta.mensagem,
          tipo: TipoNotificacao.Sucesso,
        });
        fechar({
          concluido: true,
          precisaAtualizar: true,
        });
      }
      fechar({
        concluido: true,
        precisaAtualizar: false,
      });
    } catch (erro) {
      tratarErroApi(erro, callBackUnprocessableEntity);
    } finally {
      setCarregando(false);
    }
  }

  function callBackUnprocessableEntity() {
    fechar({
      concluido: false,
      precisaAtualizar: true,
    });
  }

  return (
    <>
      <div>
        <FormBase2
          visivel={props.visivel}
          carregando={carregando}
          onClickSalvar={handleSubmit(handleSalvar)}
          onClickCancelar={handleCancelar}
          configuracoesModal={props.configuracoesModal}
          modoEdicao={props.idRegistroEdicao == 0 ? "criar" : "editar"}
          titulo={NomesModais.cfop}
          auditoria={dadosAuditoria}
        >
          <ProvedorAjuda id="edit-form-cfop">
            <input type="hidden" {...register("id")} defaultValue={0} />
            <FormGrupo>
              <Linha>
                <Coluna md={2}>
                  <FormNumberBox
                    name="codigo"
                    titulo="Código"
                    control={control}
                    requerido
                    formato={NUMERO_INTEIRO_FORMATADOR}
                    minimo={0}
                    maximo={9999}
                  />
                </Coluna>
                <Coluna md={7}>
                  <FormTextBox
                    name="descricao"
                    titulo={"Descrição"}
                    requerido
                    tamanhoMaximo={255}
                    control={control}
                  />
                </Coluna>
                <Coluna md={1} classe="centralizar-itens">
                  <FormCheckBox name="ativo" titulo="Ativo" control={control} />
                </Coluna>
                <Coluna md={2} classe="centralizar-itens">
                  <FormCheckBox
                    name="devolucao"
                    titulo="Devolução"
                    control={control}
                  />
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={4}>
                  <FormSelectBox
                    name="interstadual"
                    titulo="Interestadual"
                    toolTip="Interestadual"
                    control={control}
                    requerido
                    dataSource={tipoInterestadualAsSelectItem}
                    habilitaBusca
                    tipoBusca="contains"
                  />
                </Coluna>
                <Coluna md={4}>
                  <FormSelectBox
                    name="entradaSaida"
                    titulo="Entrada/Saída"
                    toolTip="Entrada ou saída"
                    control={control}
                    requerido
                    dataSource={entradaSaidaAsSelectItem}
                    habilitaBusca
                    tipoBusca="contains"
                  />
                </Coluna>
                <Coluna md={4}>
                  <FormSelectBox
                    name="tipo"
                    titulo="Tipo"
                    toolTip="Tipo"
                    control={control}
                    requerido
                    dataSource={tipoCfopAsSelectItem}
                    habilitaBusca
                    tipoBusca="contains"
                  />
                </Coluna>
              </Linha>
              <Linha>
                <Coluna md={6}>
                  <FormTextArea
                    name="descricaoDetalhada"
                    titulo={"Descrição detalhada"}
                    valor={""}
                    control={control}
                    height={15}
                  />
                </Coluna>
                <Coluna md={6}>
                  <FormTextArea
                    name="observacoes"
                    titulo={"Observações"}
                    valor={""}
                    control={control}
                    height={15}
                  />
                </Coluna>
              </Linha>
            </FormGrupo>
          </ProvedorAjuda>
        </FormBase2>
      </div>
    </>
  );
};

export default EditFormCfop;
