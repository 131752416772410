import { useState } from "react";
import Pagina from "../../../components/layout/pagina";
import { GridUsuario } from "../../../parts/usuario/grid";
import NomesTelas from "../../../utils/common/nomes-telas";
import { ItemContextMenu } from "../../../utils/context-menu/context-menu-utils";

export default function UsuarioPage() {
  const [menus, setMenus] = useState<ItemContextMenu[]>();

  return (
    <Pagina id="usuario-page" titulo={NomesTelas.usuarios} subMenuItems={menus}>
      <GridUsuario definirMenu={setMenus} />
    </Pagina>
  );
}
