import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "devextreme-react";
import { useEffect, useRef, useState } from "react";
import { renderToString } from "react-dom/server";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { FormGrupo, FormTextBox } from "../../../components/formularios/";
import CardLogin from "../../../components/layout/card-login";
import { Coluna, Linha } from "../../../components/layout/grid-system";
import LoginBg from "../../../components/layout/login-bg";
import { useAppDispatch, useAppSelector } from "../../../hooks/store.hooks";
import {
  TokenFrontRequest,
  TokenRequest,
} from "../../../models/api/tokens/token-requests";
import {
  AcaoAdicionalLogin,
  AcaoAdicionalLoginDto,
} from "../../../models/api/tokens/token-responses";
import Sessao from "../../../models/dto/sessao/sessao";
import RenderOnDemand from "../../../parts/utils/load-on-demand";
import {
  getDadosSessao,
  getToken,
  getTokenPassandoInformacoesExcluirSessoes,
  validarPayloadOauthNaoAutenticado,
} from "../../../services/tokens/tokens.service";
import {
  bloquearUI,
  desabilitarToasts,
  desbloquearUI,
  habilitarToasts,
  limparMensagemLogoff,
} from "../../../store/ui/ui.slice";
import { checarResponse, tratarErroApi } from "../../../utils/api/api-utils";
import { exibirConfirmacao } from "../../../utils/dialogos";
import { perderFocoElementoAtual } from "../../../utils/html-dom/html-dom-utils";
import { focaNoInputAoRenderizarComponente } from "../../../utils/inputs/inputs-utils";
import { GravarSessaoReduxELocalStorage } from "../../../utils/oauth/oauth-utils";
import { EditFormRecuperacaoSenha } from "./edit-form/edit-form-recuperacao-senha";
import { ContainerBotaoOauthInvalido, Link, MensagemErro } from "./styles";

interface LoginPageProps {
  oAuth?: boolean;
}

export default function LoginPage({ oAuth }: LoginPageProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const returnUrl = useAppSelector((state) => state.estadoUI.loginReturnUrl);
  const motivoLogoff = useAppSelector((state) => state.estadoUI.mensagemLogoff);
  const [mostrarMfa, setMostrarMfa] = useState(false);
  const [oauthInvalido, setOauthInvalido] = useState(false);
  let form: HTMLFormElement | null;
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const oAuthData = oAuth ? searchParams.get("payload") : null;
  const returnUrlAntigoMxp = oAuth ? searchParams.get("return") : null;
  const [msgErro, setMsgErro] = useState<string | null>(
    searchParams.get("erro") ?? motivoLogoff
  );

  const capthaHabilitado = process.env.REACT_APP_RECAPTCHA_ENABLED == "true";

  const [modalRecuperacaoVisivel, setModalRecuperacaoVisivel] = useState(false);

  useEffect(() => {
    validaOauth();
  }, []);

  async function validaOauth() {
    if (!oAuth) {
      return;
    }

    if (!oAuthData) {
      setOauthInvalido(true);
      return;
    }

    try {
      dispatch(bloquearUI("Carregando..."));
      const resposta = await validarPayloadOauthNaoAutenticado(oAuthData);
      checarResponse(resposta);
    } catch (erro) {
      setOauthInvalido(true);
      tratarErroApi(erro);
    } finally {
      dispatch(desbloquearUI());
    }
  }

  function handleEnter() {
    /* Faz com que o campo perca o foco para ser validado.
        Isso impedia o submit mesmo que tenha conteúdo válido mas estava
        com estado de inválido em uma validação anterior */
    perderFocoElementoAtual();
    form?.requestSubmit();
  }

  function redirecionarParaUrlPreferencial(url: string | null | undefined) {
    const urlMxp1 = process.env.REACT_APP_BACKEND_ANTIGO_ENDPOINT as string;

    if (url && url != "/") {
      const urlCompleta = `${urlMxp1}/${url}`;
      window.location.href = urlCompleta;
    } else {
      if (process.env.REACT_APP_IR_PARA_ANTIGO_AO_LOGAR == "true") {
        window.location.href = urlMxp1;
      } else {
        navigate("/");
      }
    }
  }

  async function efetuarLogin() {
    dispatch(bloquearUI("Logando..."));
    let logou = false;
    try {
      const loginData = getValues();
      const captchValue = capthaHabilitado
        ? await recaptchaRef.current?.executeAsync()
        : "Testes";

      if (!mostrarMfa) {
        loginData.codigoMFA = "";
      }

      const loginRequest: TokenFrontRequest = {
        tokenRequest: loginData,
        captchaToken: captchValue as string,
      };

      dispatch(desabilitarToasts());
      const [resposta, extras] = await getToken(loginRequest);
      dispatch(habilitarToasts());

      if (resposta) {
        if (resposta.usaAutenticacaoDeDoisFatores && !mostrarMfa) {
          setMostrarMfa(true);
        } else {
          logou = true;
          const dadosSessaoResposta = await getDadosSessao(resposta);
          const sessao: Sessao = {
            dadosSessao: dadosSessaoResposta,
          };

          GravarSessaoReduxELocalStorage(sessao, resposta);
          //Aguarda persistir a sessão
          if (oAuth && oAuthData) {
            navigate(
              `/oauth?payload=${encodeURIComponent(oAuthData)}${
                returnUrlAntigoMxp
                  ? `&return=${encodeURIComponent(returnUrlAntigoMxp)}`
                  : ""
              }`
            );
          } else if (returnUrl) {
            navigate(returnUrl);
          } else {
            redirecionarParaUrlPreferencial(
              dadosSessaoResposta?.usuario?.urlPreferencial
            );
          }
        }
      } else if (extras?.acaoAdicional && extras.acaoAdicional.sucesso) {
        await loginComAcaoAdicional(extras.acaoAdicional.model);
      } else {
        if (extras?.mensagemErro) {
          setMsgErro(extras.mensagemErro);
        }
      }
    } finally {
      // Caso tenha logado não vai mais conseguir acessar o recaptcha
      if (!logou) {
        recaptchaRef.current?.reset();
      }
      dispatch(desbloquearUI());
      dispatch(limparMensagemLogoff());
    }
  }

  async function loginComAcaoAdicional(acaoAdicional: AcaoAdicionalLoginDto) {
    switch (acaoAdicional.acao) {
      case AcaoAdicionalLogin.QuestionarDerrubarSessoesAnteriores:
        {
          const html = renderToString(
            <>
              Você possui outra(s) conexão(ões) ativa(s).
              <br />
              Gostaria de derrubar a(s) sessão(ões) anterior(es)?
            </>
          );
          const excluir = await exibirConfirmacao(
            "Exclusão de sessões anteriores",
            html
          );
          const loginData = getValues();
          loginData.codigoMFA = "AÇÃO ADICIONAL";
          const loginRequest: TokenFrontRequest = {
            tokenRequest: loginData,
            captchaToken: "AÇÃO ADICIONAL",
          };

          const resposta = await getTokenPassandoInformacoesExcluirSessoes(
            loginRequest,
            excluir,
            acaoAdicional.hash
          );

          if (resposta) {
            const dadosSessaoResposta = await getDadosSessao(resposta);
            const sessao: Sessao = {
              dadosSessao: dadosSessaoResposta,
            };

            GravarSessaoReduxELocalStorage(sessao, resposta);
            redirecionarParaUrlPreferencial(
              dadosSessaoResposta?.usuario?.urlPreferencial
            );
          }
        }
        break;
      default: {
        throw new Error("Ação adicional inválida");
      }
    }
  }

  function handleModalRecuperacaoSenhaCallBack() {
    setModalRecuperacaoVisivel(false);
  }

  function voltarParaHome() {
    navigate("/");
    window.location.reload();
  }

  const schema = yup.object().shape({
    codigoMFA: yup.string().when("deveAtivar", (deveAtivar, schema) => {
      return mostrarMfa
        ? schema.required("Necessário informar código de autenticação")
        : schema;
    }),
    email: yup.string().email().required(),
    senha: yup.string().required(),
  });

  const { getValues, control, handleSubmit } = useForm<TokenRequest>({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  return (
    <>
      <LoginBg>
        <CardLogin
          titulo="Fazer login"
          onClickEntrar={() => form?.requestSubmit()}
          botoesVisiveis={!oauthInvalido}
        >
          <div>
            {oauthInvalido ? (
              <div>
                <p>Dados inválidos!</p>
                <ContainerBotaoOauthInvalido>
                  <Button
                    text="Entrar novamente"
                    onClick={voltarParaHome}
                    type="default"
                    height={30}
                  />
                </ContainerBotaoOauthInvalido>
              </div>
            ) : (
              <>
                <form
                  ref={(ref) => (form = ref)}
                  onSubmit={handleSubmit(efetuarLogin)}
                >
                  <FormGrupo titulo="">
                    {msgErro && (
                      <Linha>
                        <Coluna md={12}>
                          <MensagemErro>{msgErro}</MensagemErro>
                        </Coluna>
                      </Linha>
                    )}
                    <Linha>
                      <Coluna md={12}>
                        <FormTextBox
                          name="email"
                          titulo="E-mail"
                          control={control}
                          invisivel={mostrarMfa}
                          tipo="email"
                          requerido
                          onEnter={handleEnter}
                          autocompletar
                          onContentReady={focaNoInputAoRenderizarComponente}
                        />
                      </Coluna>
                    </Linha>
                    <Linha>
                      <Coluna md={12}>
                        <FormTextBox
                          name="senha"
                          titulo="Senha"
                          control={control}
                          invisivel={mostrarMfa}
                          requerido
                          tipo="password"
                          onEnter={handleEnter}
                        />
                      </Coluna>
                    </Linha>
                    <Linha>
                      <Coluna md={12}>
                        <RenderOnDemand visivel={mostrarMfa}>
                          <FormTextBox
                            name="codigoMFA"
                            titulo="Código de autenticação"
                            control={control}
                            invisivel={!mostrarMfa}
                            requerido={mostrarMfa}
                            onEnter={handleEnter}
                            onContentReady={focaNoInputAoRenderizarComponente}
                          />
                        </RenderOnDemand>
                      </Coluna>
                    </Linha>
                  </FormGrupo>

                  {capthaHabilitado && (
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      size="invisible"
                      sitekey={
                        process.env.REACT_APP_RECAPTCHA_SITE_KEY as string
                      }
                    />
                  )}
                </form>
                <div>
                  <span>
                    <b>Esqueceu a senha?</b>
                    <Link
                      onClick={() => setModalRecuperacaoVisivel(true)}
                      style={{ marginLeft: "5px" }}
                    >
                      {" "}
                      Clique aqui para recuperá-la
                    </Link>
                  </span>
                </div>
              </>
            )}
          </div>
        </CardLogin>
      </LoginBg>
      <EditFormRecuperacaoSenha
        visivel={modalRecuperacaoVisivel}
        configuracoesModal={{
          largura: "30rem",
        }}
        callBackFecharModal={handleModalRecuperacaoSenhaCallBack}
        idRegistroEdicao={0}
        email={getValues().email}
      />
    </>
  );
}
