import { Menu } from "devextreme-react";
import { useState } from "react";
import useEffectOnLoad from "../../../hooks/effect.hooks";
import {
  ItemContextMenu,
  criarItensMenuContextoPadrao,
} from "../../../utils/context-menu/context-menu-utils";
import "./menu-superior-form.scss";

interface MenuSuperiorFormProps {
  itensMenuSuperior?: ItemContextMenu[];
}

export default function MenuSuperiorForm({
  itensMenuSuperior,
}: MenuSuperiorFormProps) {
  const [menus, setMenus] = useState<ItemContextMenu[]>();

  // Carrega quando o modal for carregado
  useEffectOnLoad(() => {
    if (itensMenuSuperior) {
      setMenus(
        criarItensMenuContextoPadrao({
          isMenuPrincipal: true,
          itensAdicionais: itensMenuSuperior,
        })
      );
    }
  });

  return (
    <>
      <Menu
        visible={menus ? true : false}
        className="menu-superior-form"
        items={menus ?? undefined}
        displayExpr="name"
        showFirstSubmenuMode="onHover"
        showSubmenuMode="onHover"
        orientation={"horizontal"}
        hideSubmenuOnMouseLeave={true}
      />
      <hr className="separador-menu-superior"></hr>
    </>
  );
}
