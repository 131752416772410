import AuditavelDTO from "../comum/auditavel-dto";
import AuditavelGridModel from "../comum/auditavel-grid";

export enum EstadoDoImobilizado {
  Ativo = 1,
  Baixado = 2,
}

export const estadoDoImobilizadoDecodificado: {
  [key in EstadoDoImobilizado]: string;
} = {
  [EstadoDoImobilizado.Ativo]: "Ativo",
  [EstadoDoImobilizado.Baixado]: "Baixado",
};

export enum TipoDeDepreciacao {
  Mensal = 1,
  Anual = 2,
}

export const tipoDeDepreciacaoDecodificado: {
  [key in TipoDeDepreciacao]: string;
} = {
  [TipoDeDepreciacao.Mensal]: "Mensal",
  [TipoDeDepreciacao.Anual]: "Anual",
};

export enum EstadoDaDepreciacao {
  NaoIniciada = 1,
  EmAndamento = 2,
  Concluida = 3,
}

export const estadoDaDepreciacaoDecodificado: {
  [key in EstadoDaDepreciacao]: string;
} = {
  [EstadoDaDepreciacao.NaoIniciada]: "Não iniciada",
  [EstadoDaDepreciacao.EmAndamento]: "Em andamento",
  [EstadoDaDepreciacao.Concluida]: "Concluída",
};

export enum EstadoDoCiapDoImobilizado {
  NaoIniciado = 1,
  EmAndamento = 2,
  Concluido = 3,
}

export const estadoDoCiapDoImobilizadoDecodificado: {
  [key in EstadoDoCiapDoImobilizado]: string;
} = {
  [EstadoDoCiapDoImobilizado.NaoIniciado]: "Não iniciado",
  [EstadoDoCiapDoImobilizado.EmAndamento]: "Em andamento",
  [EstadoDoCiapDoImobilizado.Concluido]: "Concluído",
};

export interface CiapDoImobilizadoRequest {
  itemNotaFiscalId: number | null;
  parcelas: number;
  parcelasApropriadas: number;
  tipoDeDocumentoFiscalId: number;
  fornecedorId: number;
  numero: string;
  serie: string | null;
  chaveAcesso: string | null;
  dataEmissao: string;
  quantidade: number;
  unidadeId: number;
  baseCalculoIcms: number;
  aliquotaIcms: number | null;
  valorIcms: number | null;
  valorIcmsSt: number | null;
  valorIcmsFrete: number | null;
  valorIcmsDifal: number | null;
}

export interface CiapDoImobilizadoResponse
  extends CiapDoImobilizadoRequest,
    AuditavelDTO {
  estado: EstadoDoCiapDoImobilizado;
}

export interface ImobilizadoRequest {
  id: number;
  itemId: number;
  estado: EstadoDoImobilizado;
  numeroDoPatrimonio: string;
  descricao: string;
  descricaoEstaFixa: boolean;
  valorDaAquisicao: number;
  dataDaAquisicao: string;
  contaContabilId: number;
  utilizacaoDoBem: string;
  centroDeCustosId: number;
  contaDeDebitoDaDepreciacaoId: number;
  centroDeCustosDeDebitoDaDepreciacaoId: number | null;
  contaDeCreditoDaDepreciacaoId: number;
  centroDeCustosDeCreditoDaDepreciacaoId: number | null;
  percentualDeDepreciacao: number | null;
  tipoDeDepreciacao: TipoDeDepreciacao | null;
  vidaUtilEmMeses: number;
  valorResidual: number;
  valorInicialDepreciado: number;
  quantidadeInicialDeParcelasDepreciadas: number;
  sujeitoAoCiap: boolean;
  ciapDoImobilizado: CiapDoImobilizadoRequest | null;
}

export interface ImobilizadoResponse extends AuditavelDTO {
  id: number;
  itemId: number;
  estado: EstadoDoImobilizado;
  numeroDoPatrimonio: string;
  descricao: string;
  descricaoEstaFixa: boolean;
  valorDaAquisicao: number;
  dataDaAquisicao: string;
  contaContabilId: number;
  utilizacaoDoBem: string;
  centroDeCustosId: number;
  contaDeDebitoDaDepreciacaoId: number;
  centroDeCustosDeDebitoDaDepreciacaoId: number | null;
  contaDeCreditoDaDepreciacaoId: number;
  centroDeCustosDeCreditoDaDepreciacaoId: number | null;
  percentualDeDepreciacao: number | null;
  tipoDeDepreciacao: TipoDeDepreciacao | null;
  vidaUtilEmMeses: number;
  valorDaParcelaDeDepreciacao: number;
  valorResidual: number;
  vidaRestanteEmMeses: number;
  valorInicialDepreciado: number;
  quantidadeInicialDeParcelasDepreciadas: number;
  valorDepreciadoPorLancamentoContabil: number;
  quantidadeParcelasDepreciadasPorLancamentoContabil: number;
  valorDepreciado: number;
  dataUltimaDepreciacao: string | null;
  estadoDaDepreciacao: EstadoDaDepreciacao;
  sujeitoAoCiap: boolean;
  ciapDoImobilizado: CiapDoImobilizadoResponse | null;
}

export interface ImobilizadoGridModel extends AuditavelGridModel {
  id: number;
  numeroPatrimonio: string;
  item: string;
  descricao: string;
  estado: string;
  valorAquisicao: number;
  dataAquisicao: string;
  contaContabil: string;
  utilizacaoDoBem: string;
  centroCustos: string;
  contaDeDebitoDaDepreciacao: string;
  centroDeCustosDeDebitoDaDepreciacao: string;
  contaDeCreditoDaDepreciacao: string;
  centroDeCustosDeCreditoDaDepreciacao: string;
  percentualDeDepreciacao: number | null;
  tipoDeDepreciacao: string;
  vidaUtilEmMeses: number;
  valorDaParcelaDeDepreciacao: number;
  valorResidual: number;
  valorDepreciado: number;
  vidaRestanteEmMeses: number;
  dataUltimaDepreciacao: string | null;
  estadoDaDepreciacao: string;
  sujeitoAoCiap: boolean;
  estadoDoCiap: string;
  parcelasDoCiap: number;
  parcelasApropriadas: number;
  dataEmissao: string;
  numero: string;
  serie: string;
  chaveAcesso: string;
  baseDeCalculoIcms: number;
  aliquotaIcms: number | null;
  valorDoIcms: number | null;
  valorDoIcmsSt: number | null;
  valorDoIcmsFrete: number | null;
  valorDoIcmsDifal: number | null;
}
