import Pagina from "../../../components/layout/pagina";
import RequerPermissao from "../../../components/seguranca/requer-permissao";
import { PermissoesVeiculo } from "../../../models/permissoes/vendas/veiculo/permissoes-veiculo";
import { GridVeiculo } from "../../../parts/vendas/veiculo/grid";
import NomesTelas from "../../../utils/common/nomes-telas";

export default function VeiculoPage() {
  return (
    <Pagina
      id="veiculo-page"
      titulo={NomesTelas.veiculos}
      area={"Fiscal > Cadastros"}
    >
      <RequerPermissao codigoPermissoes={[PermissoesVeiculo.Consultar]}>
        <GridVeiculo />
      </RequerPermissao>
    </Pagina>
  );
}
