import { Column } from "devextreme-react/data-grid";
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { renderToString } from "react-dom/server";
import { MxpGrid } from "../../../../components/grid";
import {
  useEditarRegistroGrid,
  useExcluirRegistroGrid,
  useNovoRegistroGrid,
  usePropagarReferenciaGrid,
} from "../../../../hooks/grid.hooks";
import { CertificadoDigitalGridModel } from "../../../../models/api/certificado-digital/certificado-digital";
import { decodificaBooleanoEmSimNao } from "../../../../models/api/comum/decodificadores";
import { CallBackModal } from "../../../../models/shared/ui/callback-modal";
import { IGridSelecao } from "../../../../models/shared/ui/formularios";
import EditFormCertificadoDigital from "../../../../pages/sistema/certificado-digital/edit-form";
import { NomesEndpoints } from "../../../../services/comum/nomesEndpoints";
import APIBase from "../../../../services/comum/serviceBase";
import { checarResponseExibeMensagemExclusaoDeSucesso } from "../../../../utils/api/api-utils";
import criarNameof from "../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../utils/common/nomes-telas";
import { exibirConfirmacao } from "../../../../utils/dialogos";
import { GridBaseProps } from "../../../../utils/grid/grid-utils";
import obterConfiguracaoColuna from "../../../../utils/grid/padroes-colunas";
import GetColunasDeAuditoria from "../../../layout/grid-defaults/colunasDeAuditoria";

const nomeEndpoint = NomesEndpoints.CertificadoDigital;
const dataSource = APIBase.getGridSource(nomeEndpoint);
const nameOfGridHandler = criarNameof<CertificadoDigitalGridModel>();
const colunas = [
  <Column
    key={nameOfGridHandler("id")}
    dataField={nameOfGridHandler("id")}
    {...obterConfiguracaoColuna("id")}
  />,
  <Column
    key={nameOfGridHandler("descricao")}
    dataField={nameOfGridHandler("descricao")}
    {...obterConfiguracaoColuna("stringGG")}
    caption="Descrição"
  />,
  <Column
    key={nameOfGridHandler("vencimentoData")}
    dataField={nameOfGridHandler("vencimentoData")}
    {...obterConfiguracaoColuna("dataAnoCurtoSemHora")}
    caption="Data de vencimento"
    width={180}
  />,
  <Column
    key={nameOfGridHandler("ativo")}
    dataField={nameOfGridHandler("ativo")}
    {...obterConfiguracaoColuna("boolSimNao")}
    caption="Ativo"
    visible={false}
    cellRender={({ data }) => {
      const dados = data as CertificadoDigitalGridModel;
      return decodificaBooleanoEmSimNao(dados.ativo);
    }}
  />,
  ...GetColunasDeAuditoria(),
];

export const GridCertificadoDigital = forwardRef((_: GridBaseProps, ref) => {
  const gridRef = useRef<IGridSelecao>(null);
  useImperativeHandle(ref, () => gridRef.current as IGridSelecao);
  usePropagarReferenciaGrid(ref, gridRef);

  const [modalEdicaoVisivel, setModalEdicaoVisivel] = useState(false);
  const [idRegistroEdicao, setIdRegistroEdicao] = useState(NaN);

  function handleAtualizarGrid() {
    if (gridRef.current?.atualizarGrid) {
      gridRef.current?.atualizarGrid();
    }
  }

  const handleNovoRegistro = useNovoRegistroGrid(() => {
    setIdRegistroEdicao(0);
    setModalEdicaoVisivel(true);
  });

  const handleEditarRegistro = useEditarRegistroGrid((id: number) => {
    setIdRegistroEdicao(id);
    setModalEdicaoVisivel(true);
  });

  const handleExcluirRegistro = useExcluirRegistroGrid(
    async (registro: CertificadoDigitalGridModel) => {
      const mensagem = renderToString(<>{obterMensagemExclusao(registro)}</>);
      const excluir = await exibirConfirmacao("Confirmar exclusão", mensagem);

      if (excluir) {
        const resposta = await APIBase.excluir(registro.id, nomeEndpoint);

        if (resposta) {
          checarResponseExibeMensagemExclusaoDeSucesso(resposta);
          handleAtualizarGrid();
        }
      }
    }
  );

  function obterMensagemExclusao(registro: CertificadoDigitalGridModel) {
    return `Tem certeza que deseja excluir o registro ${registro.descricao}?`;
  }

  const handleModalCallback = useCallback((info: CallBackModal) => {
    setModalEdicaoVisivel(false);
    setIdRegistroEdicao(NaN);

    if (info.precisaAtualizar) {
      handleAtualizarGrid();
    }
  }, []);

  return (
    <>
      <MxpGrid<CertificadoDigitalGridModel>
        dataSource={dataSource}
        id={"certificado-digital"}
        ref={gridRef}
        colunas={colunas}
        novoRegistro={handleNovoRegistro}
        editarRegistro={handleEditarRegistro}
        excluirRegistro={handleExcluirRegistro}
        nomeDoArquivoAoExportar={NomesTelas.certificadoDigital}
      />
      <EditFormCertificadoDigital
        visivel={modalEdicaoVisivel}
        idRegistroEdicao={idRegistroEdicao}
        configuracoesModal={{ largura: "max(50vw, 800px)", altura: "auto" }}
        callBackFecharModal={handleModalCallback}
      />
    </>
  );
});
