import { AxiosResponse, HttpStatusCode } from "axios";
import { FileUploader } from "devextreme-react";
import { Column } from "devextreme-react/data-grid";
import { FileUploaderTypes } from "devextreme-react/file-uploader";
import { Item } from "devextreme-react/toolbar";
import ArrayStore from "devextreme/data/array_store";
import { forwardRef, useCallback, useMemo, useRef } from "react";
import { MxpGrid } from "../../../../components/grid";
import {
  useGerarItensAdicionais,
  usePropagarReferenciaGrid,
} from "../../../../hooks/grid.hooks";
import { ArquivoBaseModel } from "../../../../models/api/arquivos/arquivo";
import { ResponseBaseArquivo } from "../../../../models/api/comum/response-base";
import { IGridSelecao } from "../../../../models/shared/ui/formularios";
import { checarResponse, tratarErroApi } from "../../../../utils/api/api-utils";
import criarNameof from "../../../../utils/common/cria-name-of";
import exibirNotificacaoToast from "../../../../utils/common/notificacoes-utils";
import { verificaComNotificacaoSeUsuarioPossuiPermissoes } from "../../../../utils/common/permissoes-utils";
import { exibirConfirmacao } from "../../../../utils/dialogos";
import {
  createUrlFromBlobAndDownload,
  extractFileNameFromContentDisposition,
} from "../../../../utils/file/file-utils";
import obterConfiguracaoColuna from "../../../../utils/grid/padroes-colunas";
import GetColunasDeAuditoria from "../../../layout/grid-defaults/colunasDeAuditoria";
import "./arquivo-grid.css";

const nameOfGridHandler = criarNameof<ArquivoBaseModel>();

const colunas = [
  <Column
    key={nameOfGridHandler("nomeOriginalSemExtensao")}
    dataField={nameOfGridHandler("nomeOriginalSemExtensao")}
    {...obterConfiguracaoColuna("stringXG")}
    caption="Nome"
  />,
  <Column
    key={nameOfGridHandler("extensao")}
    dataField={nameOfGridHandler("extensao")}
    {...obterConfiguracaoColuna("stringP")}
    caption="Extensão"
  />,
  ...GetColunasDeAuditoria(),
];

interface GridEmMemoriaArquivoProps {
  idRegistro: number;
  arquivos: Array<ArquivoBaseModel>;
  funcaoParaBaixarAnexo: (
    idArquivo: number
  ) => Promise<AxiosResponse<ResponseBaseArquivo, any>>;
  permissaoIncluir?: string;
  permissaoExcluir?: string;
  permissaoVisualizarEBaixar?: string;
}

const gridId = "arquivoEmMemoria";

/**
 * Gera um grid com dados em memória para upload e gestão de anexos.
 * A lista de arquivos passada poderá sofrer alterações pelo componente.
 */
export const GridEmMemoriaArquivo = forwardRef(
  (props: GridEmMemoriaArquivoProps, ref) => {
    const gridRef = useRef<IGridSelecao>(null);

    usePropagarReferenciaGrid(ref, gridRef);

    const dataSource = useMemo(() => {
      return new ArrayStore({
        data: props.arquivos.values == undefined ? [] : props.arquivos,
        key: "nomeOriginalSemExtensao",
      });
    }, [props.arquivos, props.idRegistro]);

    function atualizarGrid() {
      if (gridRef.current?.atualizarGrid) {
        gridRef.current?.atualizarGrid();
      }
    }

    const onSelectedFilesChanged = useCallback(
      async (e: FileUploaderTypes.ValueChangedEvent) => {
        if (!e.value) {
          return;
        }

        if (
          props.permissaoIncluir &&
          !verificaComNotificacaoSeUsuarioPossuiPermissoes([
            props.permissaoIncluir,
          ])
        ) {
          return;
        }

        e.value.forEach(async (anexo) => {
          const name = anexo.name;
          const nomeSemExtensao = name?.substring(0, name.lastIndexOf("."));
          if (
            props.arquivos.some(
              (item) => item.nomeOriginalSemExtensao == nomeSemExtensao
            )
          ) {
            exibirNotificacaoToast({
              mensagem: `Já existe um anexo com o nome "${nomeSemExtensao}".`,
            });
            return;
          }
          const valor = await getBase64(anexo as File);
          props.arquivos.push({
            id: 0,
            nomeOriginalSemExtensao: nomeSemExtensao,
            extensao: "." + name?.split(".").pop(),
            arquivo: valor,
          } as ArquivoBaseModel);
        });

        atualizarGrid();
      },
      [props.arquivos, props.idRegistro]
    );

    const getBase64 = (file: File): Promise<string | null> =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () =>
          resolve((reader.result as string).split(",")[1] as string);
        reader.onerror = reject;
      });

    async function excluirRegistro(registro: ArquivoBaseModel) {
      if (
        props.permissaoExcluir &&
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          props.permissaoExcluir,
        ])
      ) {
        return;
      }

      const confirmacao = await exibirConfirmacao(
        "Confirmar exclusão",
        `Tem certeza de que deseja excluir o anexo "${registro.nomeOriginalSemExtensao}.${registro.extensao}"? ` +
          `Após excluir, clique em "Salvar" para confirmar a operação.`
      );

      if (!confirmacao) {
        return;
      }

      let index = props.arquivos.length - 1;
      while (index >= 0) {
        if (
          props.arquivos[index].nomeOriginalSemExtensao ==
          registro.nomeOriginalSemExtensao
        ) {
          props.arquivos.splice(index, 1);
        }
        index--;
      }

      atualizarGrid();
      return;
    }

    async function baixarAnexo(idAnexo: number) {
      if (idAnexo == 0) {
        exibirNotificacaoToast({
          mensagem: `Não é posssível baixar um anexo que ainda não foi salvo.`,
        });
        return;
      }

      if (
        props.permissaoVisualizarEBaixar &&
        !verificaComNotificacaoSeUsuarioPossuiPermissoes([
          props.permissaoVisualizarEBaixar,
        ])
      ) {
        return;
      }

      try {
        const resposta = await props.funcaoParaBaixarAnexo(idAnexo);

        if (resposta.status != HttpStatusCode.Ok) {
          checarResponse(resposta.data);
        }
        const filename = extractFileNameFromContentDisposition(
          resposta.headers
        );

        createUrlFromBlobAndDownload(resposta, filename);
      } catch (erro) {
        tratarErroApi(erro);
      }
    }

    const handleGerarItensAdicionais = useGerarItensAdicionais(
      (getData: () => ArquivoBaseModel | undefined, data?: any) => {
        const itensAdicionais = [
          {
            text: "Baixar anexo",
            icon: "ic-material-symbols-outlined ic-download",
            onClick: () => {
              const data = getData();
              baixarAnexo(data?.id ?? 0);
            },
            mostraNaColunaDeAcoes: true,
            disabledIcon: data == undefined || data?.id == 0,
            hint: "Baixar anexo",
          },
        ];

        return itensAdicionais;
      }
    );

    return (
      <>
        <MxpGrid<ArquivoBaseModel>
          id={gridId}
          ref={gridRef}
          dataSource={dataSource}
          colunas={colunas}
          excluirRegistro={excluirRegistro}
          exibirIconeEditarMenuAcoes={false}
          selecionavel={false}
          style={{ height: "100%" }}
          gerarItensAdicionaisDeContexto={handleGerarItensAdicionais}
          toolbarItensAdicionais={() => [
            <Item key={"fileUploaderAnexos"} location="before">
              <FileUploader
                selectButtonText="Anexar"
                accept="*"
                className="arquivo-grid"
                multiple={true}
                width={110}
                labelText=""
                showFileList={false}
                uploadMode={"useForm"}
                onValueChanged={onSelectedFilesChanged}
                onContentReady={(e) => {
                  const buttonNormal =
                    e.element.querySelector(".dx-button-normal");
                  if (buttonNormal) {
                    buttonNormal.classList.remove("dx-button-normal");
                    buttonNormal.classList.add("dx-button-success");
                    buttonNormal.classList.add("dx-button-has-icon");
                  }

                  const buttonText =
                    e.element.querySelector(".dx-button-content");

                  if (buttonText) {
                    const iconElement = document.createElement("i");
                    iconElement.className = "dx-icon dx-icon-attach";

                    buttonText.insertBefore(iconElement, buttonText.firstChild);
                  }
                }}
              />
            </Item>,
          ]}
        />
      </>
    );
  }
);
