import Pagina from "../../../components/layout/pagina";
import RequerPermissao from "../../../components/seguranca/requer-permissao";
import { PermissoesSerieFiscal } from "../../../models/permissoes/fiscal/serie-fiscal/permissoes-serie-fiscal";
import { GridSerieFiscal } from "../../../parts/fiscal/serie-fiscal/grids/grid-padrao";
import NomesTelas from "../../../utils/common/nomes-telas";

export default function SerieFiscalPage() {
  return (
    <Pagina
      id="serie-fiscal-page"
      titulo={NomesTelas.seriesDeDocumentosFiscais}
      area="Fiscal"
    >
      <RequerPermissao codigoPermissoes={[PermissoesSerieFiscal.Consultar]}>
        <GridSerieFiscal />
      </RequerPermissao>
    </Pagina>
  );
}
