import { CustomStore } from "devextreme-aspnet-data-nojquery";
import { NumberBox } from "devextreme-react";
import { Column, Editing } from "devextreme-react/cjs/data-grid";
import { NumberBoxTypes } from "devextreme-react/cjs/number-box";
import ArrayStore from "devextreme/data/array_store";
import { forwardRef, useCallback, useRef } from "react";
import { MxpGrid } from "../../../../components/grid";
import { usePropagarReferenciaGrid } from "../../../../hooks/grid.hooks";
import { EstoqueMovimentacaoSelecionarGridModel } from "../../../../models/api/estoque-movimentacao/estoque-movimentacao";
import { IGridSelecao } from "../../../../models/shared/ui/formularios";
import criarNameof from "../../../../utils/common/cria-name-of";
import NomesTelas from "../../../../utils/common/nomes-telas";
import { obterFormatStringNumero } from "../../../../utils/formatadores/formatador-de-numeros";
import { GridBaseProps } from "../../../../utils/grid/grid-utils";
import obterConfiguracaoColuna from "../../../../utils/grid/padroes-colunas";
import { colunasEstoqueMovimentacao } from "../colunasEstoqueMovimentacao";

export interface GridSelecionarEstoqueMovimentacaoProps extends GridBaseProps {
  dataSource: CustomStore | ArrayStore;
}

const nameOfGridHandler = criarNameof<EstoqueMovimentacaoSelecionarGridModel>();

const QuantidadeEditavelComponent = (props: any) => {
  const onValueChanged = useCallback(
    (e: NumberBoxTypes.ValueChangedEvent) => {
      props.data.setValue(e.value);
    },
    [props]
  );

  return (
    <NumberBox
      name="quantidadeMovimentar"
      format={obterFormatStringNumero(5)}
      defaultValue={props.data.value}
      onValueChanged={onValueChanged}
      min={0}
      style={{
        margin: "0px 5px",
        boxShadow: "inset 0 0 0 1px rgba(0,0,0,.22)",
        textAlignLast: "right",
      }}
      stylingMode="outlined"
    />
  );
};

const colunas = [
  <Column
    key={nameOfGridHandler("quantidadeParaEstornar")}
    dataField={nameOfGridHandler("quantidadeParaEstornar")}
    {...obterConfiguracaoColuna("quantidade")}
    allowEditing={true}
    showEditorAlways={true}
    visibleIndex={8}
    caption="Quantidade para estornar"
    editCellComponent={QuantidadeEditavelComponent}
  />,
  ...colunasEstoqueMovimentacao,
];

export const GridSelecaoEstoqueMovimentacao = forwardRef(
  (props: GridSelecionarEstoqueMovimentacaoProps, ref) => {
    const gridRef = useRef<IGridSelecao>(null);
    usePropagarReferenciaGrid(ref, gridRef);

    return (
      <>
        <MxpGrid<EstoqueMovimentacaoSelecionarGridModel>
          dataSource={props.dataSource}
          id={"selecionar-estoque-movimentacao"}
          ref={gridRef}
          colunas={colunas}
          exibirMenuDeContexto={false}
          definirMenuSuperior={props.definirMenu}
          valorPadraoDoFiltro={props.valorPadraoDoFiltro}
          sobreporFiltroSalvoComOFiltroPadrao={
            props.sobreporFiltroSalvoComOFiltroPadrao ?? false
          }
          limparFiltroAoTrocarFiltroPadrao={
            props.limparFiltroAoTrocarFiltroPadrao ?? false
          }
          nomeDoArquivoAoExportar={NomesTelas.estoqueMovimentacao}
          propriedadesAdicionaisDeGrid={{ remoteOperations: false }}
          selecionavel={false}
          componentesAdicionaisDeGrid={[
            <Editing
              key="Editing"
              allowUpdating={true}
              mode="batch"
              startEditAction="click"
              selectTextOnEditStart={true}
            />,
          ]}
        />
      </>
    );
  }
);
