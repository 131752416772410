import GitInfo from "react-git-info/macro";
import getApi from "../../configs/api";
import { ResponseModel } from "../../models/api/comum/response-base";
import { DetalhesPermissoesResponse } from "../../models/api/tokens/dados-sessao-response";

const nomeEndpoint: string = "Permissoes";

const gitInfo = GitInfo();

async function obterDetalhesPermissoes() {
  const api = getApi();
  const response = await api.get<ResponseModel<DetalhesPermissoesResponse[]>>(
    `${nomeEndpoint}/DetalhesPermissoes?v=${gitInfo.commit.shortHash}`
  );

  return response.data.model;
}

export default {
  obterDetalhesPermissoes,
};
