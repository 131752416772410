export default class NomesModais {
  static centroDeTrabalho = "centro de trabalho";

  static grupoCentroDeTrabalho = "grupo de centros de trabalho";

  static codigoDeTributacaoDeServicos = "código de tributação de serviços";

  static veiculo = "veículo";

  static mdfe = "MDF-e";

  static cfop = "CFOP";

  static conjuntoVeiculo = "conjunto de veículos";

  static condutor = "condutor";

  static empresa = "empresa";

  static naturezaDoRendimento = "natureza do rendimento";

  static vinculoDoCentroDeTrabalhoComEnderecoDeEstoque =
    "vínculo entre centro de trabalho com endereço de estoque";

  static linkDeAjuda = "link de ajuda";

  static efdReinfApuracao = "apuração";

  static serieDeDocumentosFiscais = "série de documento fiscal";

  static numeroDeSeriePrefixoESufixo =
    "prefixo e sufixo de lotes e números de série";

  static ordemDeProducao = "ordem de produção";

  static certificadoDigital = "certificado digital";

  static documentoAvulso = "documento avulso";

  static item = "item";

  static lote = "lote";

  static contaParteB = "conta da parte B do Lucro Real";

  static lancamentoNaParteB = "lançamento na parte B do Lucro Real";

  static informativoComposicaoCustosLucroReal =
    "informativo da composição de custos do Lucro Real";

  static imobilizado = "imobilizado";
}
