import store from "../../store";
import { exibirModalRequerPermissao } from "../../store/ui/ui.slice";
import { primeiraLetraCaixaAlta } from "./common-utils";

/**
 * Verifica se o usuário possui as permissões informadas e caso não, apresenta
 * o modal de erro de permissão.
 * @param codigoPermissoes array com os códigos das permissões
 * @param modo se deve validar todas as permissões ou apenas alguma
 * @returns
 */
export function verificaComNotificacaoSeUsuarioPossuiPermissoes(
  codigoPermissoes: string[],
  modo: "todas" | "alguma" = "todas"
): boolean {
  const possuiPermissoes = verificaSeUsuarioPossuiPermissoes(
    codigoPermissoes,
    modo
  );

  if (!possuiPermissoes) {
    const permissoesFaltantes =
      obterPermissoesFaltantesFormatada(codigoPermissoes);

    store.dispatch(exibirModalRequerPermissao(permissoesFaltantes));

    return false;
  }

  return true;
}

/**
 * Verifica se o usuário possui as permissões informadas
 * @param codigoPermissoes array com os códigos das permissoes
 * @param modo se deve validar todas as permissões ou apenas alguma
 * @returns
 */
export function verificaSeUsuarioPossuiPermissoes(
  codigoPermissoes: string[],
  modo: "todas" | "alguma" = "todas"
): boolean {
  const permissoesStore =
    store.getState().sessao.dadosSessao?.permissoesDoUsuario ?? [];

  let possuiPermissoes: boolean = false;

  switch (modo) {
    case "todas":
      possuiPermissoes = codigoPermissoes.every((x) =>
        permissoesStore.includes(x)
      );
      break;
    case "alguma":
      possuiPermissoes = codigoPermissoes.some((x) =>
        permissoesStore.includes(x)
      );

      break;
  }

  return possuiPermissoes;
}

/**
 * Obtém uma lista de mensagem formatada, das permissões que estão faltando. A formatação
 * é no seguinte formato "- Descricao da permissão [XXX001]"
 * @param permissoesATestar array de permissões para testar quais delas estão faltando
 * @returns
 */
export function obterPermissoesFaltantesFormatada(permissoesATestar: string[]) {
  const permissoesStore =
    store.getState().sessao.dadosSessao?.permissoesDoUsuario ?? [];

  const permissoesFaltantes = permissoesATestar.filter(
    (x) => !permissoesStore.includes(x)
  );

  const detalhesPermissoes = store.getState().estadoUI.detalhesPermissoes;

  return permissoesFaltantes.map(
    (registro) =>
      `- ${primeiraLetraCaixaAlta(
        detalhesPermissoes.find((x) => x.codigo == registro)?.descricao ?? ""
      )} [${registro}]`
  );
}
