import { Control, FieldValues } from "react-hook-form";
import { FormTextBox } from "../../../../../components/formularios";
import FormTextArea from "../../../../../components/formularios/textarea";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import TabContainer from "../../../../../components/layout/tab-container";
import {
  operacaoDescricaoComplementarPreferencialTamanhoMaximo,
  operacaoDescricaoPreferencialTamanhoMaximo,
} from "../../../../../pages/producao/centro-de-trabalho/edit-form/constantes";

interface RoteiroItemProps {
  idRegistro: number;
  abaSomenteLeitura: boolean;
  control: Control<FieldValues, any>;
}

export default function CentroDeTrabalhoAbaRoteiroItem({
  abaSomenteLeitura,
  control,
}: RoteiroItemProps) {
  return (
    <TabContainer>
      <Linha>
        <Coluna md={6}>
          <FormTextBox
            name="operacaoDescricaoPreferencial"
            titulo="Descrição da operação"
            control={control}
            tamanhoMaximo={operacaoDescricaoPreferencialTamanhoMaximo}
            tipo="text"
            somenteLeitura={abaSomenteLeitura}
          />
        </Coluna>
        <Coluna md={6}>
          <FormTextArea
            name="operacaoDescricaoComplementarPreferencial"
            titulo={"Descrição complementar da operação"}
            valor={""}
            control={control}
            tamanhoMaximo={
              operacaoDescricaoComplementarPreferencialTamanhoMaximo
            }
            height={25}
            somenteLeitura={abaSomenteLeitura}
          />
        </Coluna>
      </Linha>
    </TabContainer>
  );
}
