import getApi from "../../configs/api";
import { ListaSimplesResponse } from "../../models/api/comum/response-base";

const nomeEndpoint: string = "TributacaoDeServicoCodigo";

async function obterCodigosServico() {
  const api = getApi();
  const response = await api.get<ListaSimplesResponse>(
    `${nomeEndpoint}/codigos-servicos`
  );

  return response.data;
}

export default {
  obterCodigosServico,
};
