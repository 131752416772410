import NecessitaOpcionaisPopup from "../../../components/dialogos/necessita-opcionais";
import ModalRequerPermissao from "../../../components/dialogos/requer-permissao";

export default function AgregacaoDeModais() {
  return (
    <>
      <ModalRequerPermissao />
      <NecessitaOpcionaisPopup />
    </>
  );
}
