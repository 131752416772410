import DataSource from "devextreme/data/data_source";
import { Control, FieldValues } from "react-hook-form";
import {
  FormSelectBox,
  FormSelectBoxLazy,
} from "../../../../../components/formularios";
import { Coluna, Linha } from "../../../../../components/layout/grid-system";
import TabContainer from "../../../../../components/layout/tab-container";
import { tipoSequenciamentoAsSelectItem } from "../../../../../models/api/centro-de-trabalho/centro-de-trabalho-enums";
import { GridCentroDeTrabalhoGrupo } from "../../../grupo-centro-de-trabalho/grids/grid-padrao";

interface CapacidadeFinitaProps {
  gruposCentroDeTrabalho: DataSource<any, any> | undefined;
  abaSomenteLeitura: boolean;
  control: Control<FieldValues, any>;
}

const exibeCentroDeTrabalhoGrupo = (c: any) => {
  if (c) {
    return c.Descricao == null ? `${c.Codigo}` : `${c.Codigo} - ${c.Descricao}`;
  }

  return "";
};

const centroDeTrabalhoGrupoExpressaoDeBusca = ["Codigo", "Descricao"];

export default function CentroDeTrabalhoAbaCapacidadeFinita({
  gruposCentroDeTrabalho,
  abaSomenteLeitura,
  control,
}: CapacidadeFinitaProps) {
  return (
    <TabContainer>
      <Linha>
        <Coluna md={6}>
          <FormSelectBoxLazy
            name="centroDeTrabalhoGrupoId"
            titulo="Grupo do centro de trabalho"
            control={control}
            nomeCampoChave="Id"
            nomeCampoExibicao={exibeCentroDeTrabalhoGrupo}
            expressaoDeBusca={centroDeTrabalhoGrupoExpressaoDeBusca}
            dataSource={gruposCentroDeTrabalho}
            lupaConfig={{
              modo: "selecaoUnica",
              titulo: "Selecionar o grupo de centro de trabalho",
              componente: (r) => (
                <GridCentroDeTrabalhoGrupo
                  ref={r}
                  prefixoDoIdDoGrid="select-"
                />
              ),
            }}
            labelSemDados="Sem dados"
            somenteLeitura={abaSomenteLeitura}
          />
        </Coluna>
        <Coluna md={6}>
          <FormSelectBox
            name="sequenciamentoEstaFixo"
            titulo="Sequenciamento"
            control={control}
            dataSource={tipoSequenciamentoAsSelectItem}
            habilitaBusca
            tipoBusca="contains"
            somenteLeitura={abaSomenteLeitura}
          />
        </Coluna>
      </Linha>
    </TabContainer>
  );
}
