import Pagina from "../../../components/layout/pagina";
import { GridCFOP } from "../../../parts/fiscal/cfop/grids/grid-padrao/index";
import NomesTelas from "../../../utils/common/nomes-telas";
import FiltrosGridCfop from "../../../utils/filtros-grid/fiscal/cfop/filtros-grid-cfop";

export default function CfopPage() {
  return (
    <Pagina id="cfop-page" titulo={NomesTelas.cfop}>
      <GridCFOP valorPadraoDoFiltro={FiltrosGridCfop.cfopAtivoETipoNacional} />
    </Pagina>
  );
}
