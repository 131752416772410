import getApi from "../../configs/api";
import {
  ResponseBase,
  ResponseModel,
} from "../../models/api/comum/response-base";
import { SelectBoxLazyOpcoes } from "../../models/api/comum/selectboxlazy-options";
import { criarDataSourceSelectBoxLazy } from "../../utils/api/api-utils";
import criarDatasourceGrid, {
  criarDatasourceGridComParametros,
} from "../../utils/grid/back-end-grid-utils";
import { NomesEndpoints } from "./nomesEndpoints";

export abstract class ServiceBase {
  protected _nomeEndpoint: NomesEndpoints;

  constructor(nomeEndpoint: NomesEndpoints) {
    this._nomeEndpoint = nomeEndpoint;
  }

  public async ObterPorId<T>(id: number) {
    const api = getApi();
    const response = await api.get<ResponseModel<T>>(
      `${this._nomeEndpoint}?id=${id}`
    );

    return response.data;
  }

  public async ObterPorIdComDadosAuditoria<T>(id: number) {
    const api = getApi();
    const response = await api.get<ResponseModel<T>>(
      `${this._nomeEndpoint}/ObterPorIdComDadosAuditoria?id=${id}`
    );

    return response.data;
  }

  public async Cadastrar<T>(model: T) {
    const api = getApi();
    const response = await api.post<ResponseBase>(this._nomeEndpoint, model);

    return response.data;
  }

  public async CadastrarComRetorno<T, TRetorno>(model: T) {
    const api = getApi();
    const response = await api.post<ResponseModel<TRetorno>>(
      this._nomeEndpoint,
      model
    );

    return response.data;
  }

  public async Atualizar<T>(model: T) {
    const api = getApi();
    const response = await api.put<ResponseBase>(this._nomeEndpoint, model);

    return response.data;
  }

  public async Excluir(id: number) {
    const api = getApi();
    const response = await api.delete<ResponseBase>(
      `${this._nomeEndpoint}?id=${id}`
    );

    return response.data;
  }

  public GetGridSource() {
    return criarDatasourceGrid(`${this._nomeEndpoint}/grid`);
  }

  public GetGridSourceComParametros(parametros: object) {
    return criarDatasourceGridComParametros(
      `${this._nomeEndpoint}/grid`,
      parametros
    );
  }

  public GetDataSourceSelectBoxLazy(opcoes: SelectBoxLazyOpcoes) {
    const camposSelect = JSON.stringify(opcoes.camposRetorno);

    const dataSource = criarDatasourceGrid(
      `${this._nomeEndpoint}/grid?select=${camposSelect}`
    );

    return criarDataSourceSelectBoxLazy(dataSource, opcoes);
  }
}

async function obterPorId<T>(id: number, nomeEndpoint: NomesEndpoints) {
  const api = getApi();
  const response = await api.get<ResponseModel<T>>(`${nomeEndpoint}?id=${id}`);

  return response.data;
}

async function obterPorIdComDadosAuditoria<T>(
  id: number,
  nomeEndpoint: NomesEndpoints
) {
  const api = getApi();
  const response = await api.get<ResponseModel<T>>(
    `${nomeEndpoint}/ObterPorIdComDadosAuditoria?id=${id}`
  );

  return response.data;
}

function getGridSource(nomeEndpoint: NomesEndpoints) {
  return criarDatasourceGrid(`${nomeEndpoint}/grid`);
}

function getGridSourceComParametros(
  nomeEndpoint: NomesEndpoints,
  parametros: object
) {
  return criarDatasourceGridComParametros(`${nomeEndpoint}/grid`, parametros);
}

async function cadastrar<T>(model: T, nomeEndpoint: NomesEndpoints) {
  const api = getApi();
  const response = await api.post<ResponseBase>(nomeEndpoint, model);

  return response.data;
}

async function atualizar<T>(model: T, nomeEndpoint: NomesEndpoints) {
  const api = getApi();
  const response = await api.put<ResponseBase>(nomeEndpoint, model);

  return response.data;
}

async function cadastrarComRetorno<T, TRetorno>(
  model: T,
  nomeEndpoint: NomesEndpoints
) {
  const api = getApi();
  const response = await api.post<ResponseModel<TRetorno>>(nomeEndpoint, model);

  return response.data;
}

async function excluir(id: number, nomeEndpoint: NomesEndpoints) {
  const api = getApi();
  const response = await api.delete<ResponseBase>(`${nomeEndpoint}?id=${id}`);

  return response.data;
}

function getDataSourceSelectBoxLazy(
  opcoes: SelectBoxLazyOpcoes,
  nomeEndpoint: NomesEndpoints
) {
  const camposSelect = JSON.stringify(opcoes.camposRetorno);

  const dataSource = criarDatasourceGrid(
    `${nomeEndpoint}/grid?select=${camposSelect}`
  );

  return criarDataSourceSelectBoxLazy(dataSource, opcoes);
}

export default {
  obterPorId,
  obterPorIdComDadosAuditoria,
  getDataSourceSelectBoxLazy,
  getGridSource,
  getGridSourceComParametros,
  cadastrar,
  atualizar,
  cadastrarComRetorno,
  excluir,
};
