import getApi from "../../configs/api";
import { ListaSimplesResponse } from "../../models/api/comum/response-base";
import { NomesEndpoints } from "../comum/nomesEndpoints";

export default class ValorOpcaoService {
  private _nomeEndpoint: NomesEndpoints;

  constructor() {
    this._nomeEndpoint = NomesEndpoints.ValorOpcao;
  }

  public async ObterListaSimplesDoTiposDeDocumentosFiscaisDoIcmsParaOSped() {
    const response = await getApi().get<ListaSimplesResponse>(
      `${this._nomeEndpoint}/tipos-documentos-fiscais-para-sped/lista-simples`
    );

    return response.data;
  }

  public async ObterEstadosConfiguraveisDaOrdemDeProducao() {
    const response = await getApi().get<ListaSimplesResponse>(
      `${this._nomeEndpoint}/estados-configuraveis-da-ordem-de-producao/lista-simples`
    );

    return response.data;
  }
}
