import ContextMenu, { Position } from "devextreme-react/context-menu";
import List from "devextreme-react/list";
import { useMemo } from "react";

//import "./user-panel.scss";
import { Tooltip } from "devextreme-react";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/store.hooks";
import TrocaEmpresa from "../../../parts/empresas/empresa/troca-empresa";
import { efetuarLogoff } from "../../../services/tokens/tokens.service";
import { bloquearUI, desbloquearUI } from "../../../store/ui/ui.slice";
import { tratarErroApi } from "../../../utils/api/api-utils";
import { RemoverSessaoReduxELocalStorage } from "../../../utils/oauth/oauth-utils";
import "./user-panel.scss";

interface IProps {
  menuMode: "context" | "list";
  irParaPaginaInicial: () => void;
}

export default function ({ menuMode, irParaPaginaInicial }: IProps) {
  const apelidoUsuario = useAppSelector(
    (state) => state.sessao.dadosSessao?.usuario.codigo
  );
  const apelidoEmpresa = useAppSelector(
    (state) => state.sessao.dadosSessao?.empresa.apelido
  );
  const [mostrar, setMostrar] = useState(false);

  const dispatch = useAppDispatch();
  const handleLogout = async () => {
    try {
      dispatch(bloquearUI("Saindo..."));
      const resposta = await efetuarLogoff();
      if (resposta.sucesso) {
        irParaPaginaInicial();
        RemoverSessaoReduxELocalStorage();
      }
    } catch (erro) {
      tratarErroApi(erro);
    } finally {
      dispatch(desbloquearUI());
    }
  };

  const menuItems = useMemo(
    () => [
      {
        text: "Alterar a minha empresa",
        icon: "fas fa-building",
        onClick: () => {
          setMostrar(true);
        },
      },
      {
        text: "Sair",
        icon: "runner",
        onClick: handleLogout,
      },
    ],
    [handleLogout]
  );

  //TODO: Buscar isso da sessão (redux)
  const user = {
    email: apelidoUsuario,
    empresa: apelidoEmpresa,
  };

  const closeCallback = () => {
    setMostrar(false);
  };

  return (
    <div className={"user-panel"}>
      <div id="dados-empresa" className={"user-info"}>
        <div
          style={{
            marginRight: "4px",
            lineHeight: "initial",
          }}
        >
          <p className={"user-empresa user-text"}>{user?.empresa}</p>
          <p className={"user-name user-text"}>{user?.email}</p>
        </div>
        <i className="fas fa-angle-down fa-xs"></i>
      </div>

      <Tooltip
        target="#dados-empresa"
        showEvent="mouseenter"
        hideEvent="mouseleave"
        hideOnOutsideClick={false}
      >
        <div>{user?.empresa}</div>
      </Tooltip>

      {menuMode === "context" && (
        <ContextMenu
          items={menuItems}
          target={".user-button"}
          showEvent={"dxclick"}
          width={210}
          cssClass={"user-menu"}
        >
          <Position
            my={{ x: "top", y: "center" }}
            at={{ x: "bottom", y: "center" }}
          />
        </ContextMenu>
      )}
      {menuMode === "list" && (
        <List className={"dx-toolbar-menu-action"} items={menuItems} />
      )}

      <div className="dx-swatch-revert-base">
        <TrocaEmpresa visivel={mostrar} closeCallback={closeCallback} />
      </div>
    </div>
  );
}
